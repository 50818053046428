<template>
    <section class="dialog-body">
        <el-row>
            <form-row
                :label="__('Назва скрипту')"
                :errors="$errors['name']"
                :style="{ 'width':100 + '%' }">
                <el-input v-model="doc.name"/>
            </form-row>
        </el-row>
        <el-row>
            <form-row
                :errors="$errors['content']"
                :style="{ 'width':100 + '%' }">
                <editor v-model="doc.content"/>
            </form-row>
        </el-row>
    </section>
</template>

<script>
import FormRow from "@app/core/components/form/form-row";
import editor from '@app/script/components/script/editor';

export default {
    components: {
        FormRow,
        editor,
    },
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
}
</script>
