import Document, {
    Attribute } from '@candybox/structures/document.js';
import RelationAttribute from '@candybox/repository/relation.js';
import category from "@app/category/repositories/categories.js";
import { get } from '@candybox/helpers.js';

class Category extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            parent_id: Number,
            $parent: RelationAttribute.oneToOne(category(), 'parent_id', 'id'),
            original_id: Number,
            $original: RelationAttribute.oneToOne(category(), 'original_id', 'id'),
            type: Number,
            unified_code: String,
        };
    }
     get type_text() {
        if (this.type === 1) {
            return 'Категорія'
        } else {
            return 'Синонім'
        }
     }

     get parent_name() {
         if (this.original_id === null) {
             let parentName = get(this.$parent.$, 'name');
             if (parentName == null) {
                 if (this.name === 'Не визначені') {
                     return 'Звичайна(не редагуема)'
                 } else {
                     return 'Нема(основна)'
                 }
             } else {
                 return parentName
             }
         } else {
             return get(this.$original.$, 'name')
         }
     }


}

export default Category;
