import Document from '@candybox/structures/document.js';
import Employee from '@app/employee/documents/employee.js';
import {get} from "@candybox/helpers.js";

class User extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            login: String,
            password: String,
            rnokpp: String,
            roles: Array,
            permissions: Array,
            has_voip: Boolean,
            employee: Employee,
            status: String,
        };
    }

    /**
     * Checks if user can perform the given action
     *
     * @param {String} action
     * @returns {Boolean}
     */
    can(action) {
        if (this.status === 'removed' || this.status === 'fired'){
            return false;
        }else {
            return this.permissions.includes(action);
        }
    }

    /**
     * @returns {String}
     */
    get name() {
        return get(this.employee, 'full_name');
    }
}

export default User;
