import Document from '@candybox/structures/document.js';

class Handbook extends Document
{
    /**
     * Attributes definition
     * 
     * @returns {Object}
     */
     attributes() {
        return {
            id: Number,
            key: String,
            category: String,
            value: String,
        };
    }
}

export default Handbook;