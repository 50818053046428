<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="el-col-p">Картка № - {{ doc.card_code }}</div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="16">
                <form-row
                    :label="__('Статус картки')">
                    <options-provider
                        source="card_status">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.status"
                                :disabled="allowedChangeStatus">
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Превипуск картки')">
                    <el-button ref="reissue_button"
                               v-if="permissionToReissue"
                               @click="reissue">
                        {{ ('Ініціювати') }}
                    </el-button>
                    <el-button v-else disabled>
                        {{ ('Ініціювати') }}
                    </el-button>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            initialStatus: null,
        }
    },
    methods: {
        reissue() {
            this.doc.status_by_questionnaire = 'reissue';
            this.$error(this.__('Якщо данні картки будут оновлені, статус картки вже не повернути!'))
        },
    },
    computed: {
        allowedChangeStatus() {
            return this.doc.status === 'invalid';
        },
        permissionToReissue() {
            return this.doc.status_by_questionnaire === 'awarded' && this.doc.status !== 'invalid';
        },
    },
}
</script>

<style scoped>
.el-col-p {
    font-weight: bold;
    font-size: 16px;
}
</style>
