<template>
    <div>
        <div>
            <OperatorsFilter
                :ext-filters="filters"
            />
        </div>
        <el-scrollbar height="calc(100vh - 265px)">
            <PieChartSet
                :items="totalCountAnalytics"
                :loading="totalCountAnalyticsLoading"
                class="mb-80"
            />
            <BarChart
                :config="callReasonsOperatorsAnalytics"
                :loading="callReasonsOperatorsAnalyticsLoading"
            />
            <BarChart
                :config="callsOperatorsAnalytics"
                :loading="callsOperatorsAnalyticsLoading"
            />
            <BarChart
                :config="operatorsDurationsAnalytics"
                :loading="operatorsDurationAnalyticsLoading"
            />
        </el-scrollbar>
    </div>
</template>

<script>
import OperatorsFilter from "@app/analytics/components/base/filters/operators/OperatorsFilter.vue";
import BarChart from "@app/analytics/components/base/charts/BarChart.vue";
import PieChart from "@app/analytics/components/base/charts/PieChart.vue";
import LineChart from "@app/analytics/components/base/charts/LineChart.vue";
import PieChartSet from "@app/analytics/components/base/chart-sets/PieChartSet.vue";
import OperatorsAnalytics from "@app/analytics/repositories/operators-analytics.js";
import {each, get} from "lodash";

export default {
    name: "Calls",
    components: {
        OperatorsFilter,
        BarChart,
        PieChart,
        LineChart,
        PieChartSet,
    },
    data() {
        return {
            filters: {
                periodBetween: {
                    from: null,
                    to: null,
                },
                operator: null,
            },
            totalCountAnalyticsLoading: true,
            operatorsDurationAnalyticsLoading: true,
            callReasonsOperatorsAnalyticsLoading: true,
            callsOperatorsAnalyticsLoading: true,

            totalCountAnalytics: [
                {
                    title: 'Всього дзвінків',
                    colors: {
                        'Всього дзвінків': '#17783C',
                    },
                    data: {
                        'Всього дзвінків': 248,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Оброблених дзвінків',
                    colors: {
                        'Оброблених дзвінків': '#F44336',
                    },
                    data: {
                        'Оброблених дзвінків': 170,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Закритих дзвінків(архів)',
                    colors: {
                        'Закритих дзвінків(архів)': '#DEA000',
                    },
                    data: {
                        'Закритих дзвінків(архів)': 140,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Дзвінків в роботі',
                    colors: {
                        'Дзвінків в роботі': '#4F5A6E',
                    },
                    data: {
                        'Дзвінків в роботі': 30,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Середня тривалість дзвінка',
                    colors: {
                        'Середня тривалість дзвінка': '#00BBD3',
                    },
                    data: {
                        'Середня тривалість дзвінка': 4.8,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
            ],
            callReasonsOperatorsAnalytics: {
                title: 'Розподіл дзвінків по причині звернення',
                columns: [
                    'Скарги',
                    'Питання по КХ',
                    'Питання по сервісу',
                    'Питання по торгівельним мережам'
                ],
                withDescriptions: true,
                colors: {
                    'Скарги': '#1B6EBE',
                    'Питання по КХ': '#F44336',
                    'Питання по сервісу': '#4F5A6E',
                    'Питання по торгівельним мережам': '#DEA000'
                },
                data: {
                    'Байдин Д С.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                    'Ломако К.С.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                    'Джапарідзе А.Л.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                    'Мартинов А.А.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                    'Сидоров С.Г.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                    'Петров П.П.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                    'Іванов І.І.': {
                        'Скарги': '#1B6EBE',
                        'Питання по КХ': '#F44336',
                        'Питання по сервісу': '#4F5A6E',
                        'Питання по торгівельним мережам': '#DEA000'
                    },
                },
                barWidth: 30,
            },
            callsOperatorsAnalytics: {
                title: 'Розподіл дзвінків за операторами ',
                columns: [
                    'Оброблених дзвінків',
                    'Дзвінків в роботі',
                    'Закритих дзвінків'
                ],
                withDescriptions: true,
                colors: {
                    'Прийнятих дзвінків': '#DEA000',
                    'Оброблених': '#F44336',
                    'Закритих': '#4F5A6E'
                },
                data: {
                    'Байдин Д С.': {
                        'Прийнятих дзвінків': '#DEA000',
                        'Оброблених': '#F44336',
                        'Закритих': '#4F5A6E'
                    },
                    'Ломако К.С.': {
                        'Прийнятих дзвінків': '#DEA000',
                        'Оброблених': '#F44336',
                        'Закритих': '#4F5A6E'
                    },
                    'Джапарідзе А.Л.': {
                        'Прийнятих дзвінків': '#DEA000',
                        'Оброблених': '#F44336',
                        'Закритих': '#4F5A6E'
                    },
                },
                barWidth: 30,
            },
            operatorsDurationsAnalytics: {
                title: 'Середня триивалість дзвінка',
                columns: [
                    'Середня триивалість дзвінка',
                ],
                withDescriptions: true,
                colors: {
                    'Середня триивалість дзвінка': '#00BBD3',
                },
                data: {
                    'Байдин Д С.': {
                        'Середня триивалість дзвінка': 4.6,
                    },
                    'Ломако К.С.': {
                        'Середня триивалість дзвінка': 4.5,
                    },
                    'Джапарідзе А.Л.': {
                        'Середня триивалість дзвінка': 4.7,
                    },
                    'Мартинов А.А.': {
                        'Середня триивалість дзвінка': 4.0,
                    },
                    'Сидоров С.Г.': {
                        'Середня триивалість дзвінка': 4.8,
                    },
                    'Петров П.П.': {
                        'Середня триивалість дзвінка': 5,
                    },
                    'Іванов І.І.': {
                        'Оцінки': 4.8,
                    },
                },
                barWidth: 15,
            },
        }
    },
    methods: {
        async totalCountAnalyticsLoad(filters) {
            this.totalCountAnalyticsLoading = true;
            return OperatorsAnalytics()
                .getTotal2({filters})
                .then((res) => {
                    this.totalCountAnalytics[0].data['Всього дзвінків'] = get(res, 'total', 0);
                    this.totalCountAnalytics[1].data['Оброблених дзвінків'] = get(res, 'not_ended', 0);
                    this.totalCountAnalytics[2].data['Закритих дзвінків(архів)'] = get(res, 'ended', 0);
                    this.totalCountAnalytics[3].data['Дзвінків в роботі'] = get(res, 'on_work', 0);
                    this.totalCountAnalytics[4].data['Середня тривалість дзвінка'] = get(res, 'avg_duration', 0);
                    this.totalCountAnalyticsLoading = false;
                });
        },
        async operatorsDurationAnalyticsLoad(filters) {
            this.operatorsDurationAnalyticsLoading = true;
            return OperatorsAnalytics()
                .getDurations({filters})
                .then((res) => {
                    this.operatorsDurationsAnalytics.data = this.prepareOperatorsDurationData(res, {
                        label: 'Середня триивалість дзвінка',
                    });
                    this.operatorsDurationAnalyticsLoading = false;
                });
        },
        async callReasonsOperatorsAnalyticsLoad(filters) {
            this.callReasonsOperatorsAnalyticsLoading = true;
            return OperatorsAnalytics()
                .getCallsReasons({filters})
                .then((res) => {
                    this.callReasonsOperatorsAnalytics.data = this.prepareOperatorsCallReasonData(res, {
                        complaint: 'Скарги',
                        answer: 'Питання по КХ',
                        service: 'Питання по сервісу',
                        retail: 'Питання по торгівельним мережам',
                    });
                    this.callReasonsOperatorsAnalyticsLoading = false;
                });
        },
        async callsOperatorsAnalyticsLoad(filters) {
            this.callsOperatorsAnalyticsLoading = true;
            return OperatorsAnalytics()
                .getCalls({filters})
                .then((res) => {
                    this.callsOperatorsAnalytics.data = this.prepareOperatorsCallData(res, {
                        not_ended: 'Оброблених дзвінків',
                        in_work: 'Дзвінків в роботі',
                        ended: 'Закритих дзвінків',
                    });
                    this.callsOperatorsAnalyticsLoading = false;
                });
        },
        async loadData() {
            const filters = {
                from: this.filters.periodBetween.from,
                to: this.filters.periodBetween.to,
                operator: this.filters.operator,
            };

            await this.totalCountAnalyticsLoad(filters)
            await this.callReasonsOperatorsAnalyticsLoad(filters)
            await this.callsOperatorsAnalyticsLoad(filters)
            await this.operatorsDurationAnalyticsLoad(filters)
        },
        prepareOperatorsDurationData(data, kwargs = {}) {
            const dict = {};
            each(data, (item) => {
                const operator = get(item, 'operator');
                const duration = get(item, 'duration');
                dict[operator] = {
                    [kwargs.label]: duration
                }
            });
            return dict;
        },
        prepareOperatorsCallReasonData(data, kwargs = {}) {
            const dict = {};
            each(data, (items, operator) => {
                if(!dict[operator]) {
                    dict[operator] = {
                        [kwargs.complaint]: 0,
                        [kwargs.answer]: 0,
                        [kwargs.service]: 0,
                        [kwargs.retail]: 0,
                    }
                }
                each(items, (item) => {
                    const count = get(item, 'count');
                    const status = get(item, 'process_reason');
                    const statusLabel = get(kwargs, status);
                    if(statusLabel) {
                        dict[operator][statusLabel] += count;
                    }
                })
            });
            return dict;
        },
        prepareOperatorsCallData(data, kwargs = {}) {
            const dict = {};
            each(data, (items, operator) => {
                if(!dict[operator]) {
                    dict[operator] = {
                        [kwargs.not_ended]: 0,
                        [kwargs.in_work]: 0,
                        [kwargs.ended]: 0,
                    }
                }
                each(items, (item) => {
                    const count = get(item, 'count');
                    const status = get(item, 'process_status');
                    const statusLabel = get(kwargs, status);
                    if(statusLabel) {
                        dict[operator][statusLabel] += count;
                    }
                })
            });
            return dict;
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        ['filters.periodBetween']: {
            handler(filters) {
                if(filters.from && filters.to) {
                    this.loadData()
                } else if (!filters.from && !filters.to) {
                    this.loadData()
                }
            },
            deep: true,
        },
        ['filters.operator']: {
            handler() {
                this.loadData()
            },
            deep: true,
        }
    },
}
</script>
