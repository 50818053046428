<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('questionnaire.update')"
                :disabled="selectedItem === null"
                @click="editQuestion">
                {{ __('Редагувати') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/questionnaire-constructor/components/questions/form2.vue';
import manage from '@app/core/mixins/manage.js';
import questions from "@app/questionnaire-constructor/repositories/questions";
import Question from "@app/questionnaire/documents/question";
import {handbookEntry} from "@app/core/formatter.js";
import {set} from 'lodash';

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'label',
                title: this.__('Назва'),
                filterable: true,
            }, {
                name: 'view_type',
                title: this.__('Тип'),
                formatter: handbookEntry('questionnaire_field_view_type'),
                filterable: 'questionnaire_field_view_type',
            }],
            filter: {
                is_reserved: 1,
            },
            select: ['*'],
        }
    },
    methods: {
        getRepository() {
            return questions();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Question, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати питання'),
                editTitle: this.__('Редагувати питання'),
                width: '760px',
            };
        },
        editQuestion() {
            set(this.selectedItem, 'is_reserved', 0);
            this.edit();
        }
    },
}
</script>

