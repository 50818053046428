import App from '@candybox/app.js';
import sample from "@app/sample/components/sample/sample";

App.boot(({router}) =>{
    router.addRoute('sample', {
        component: sample,
        path: 'sample',
        name: 'sample',
    })
});
