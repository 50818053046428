import { NO_CONTEXT,
    translator } from '@app/core/services/translation.js';

export default {
    computed: {
        $translator() {
            return translator();
        },
    },
    methods: {
        __(key, params = {}, context = NO_CONTEXT) {
            return translator().translate(key, params, context);
        },
    },
}