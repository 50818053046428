import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import {get, singleton} from '@candybox/helpers.js';
import {phoneNumber} from "@app/core/validation.js";
import constants from "@app/core/constants.js";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/call');

    mapping.validation('store', () => {
        return {
            reason: validator()
                .required()
                .length(0, 30),
            is_first: validator()
                .required()
                .number(),
            is_card_owner: validator()
                .required()
                .number(),
            first_name: validator()
                .required()
                .length(0, 100),
            last_name: validator()
                .required()
                .length(0, 100),
            middle_name: validator()
                .required()
                .length(0, 100),
            birth_date: validator()
                .required(),
            call_result_kc: validator()
                .required()
                .length(0, 20),
            contact_number: validator()
                .required()
                .custom(phoneNumber),
            comment: validator()
                .length(0, 400),

            date: validator()
                .when((attr, data) => {
                    return get(data, 'reason') !== constants.CALL.COMPLAINT;
                }, (chain) => {
                    chain.required();
                }),
            rnkoop: validator()
                .when((attr, data) => {
                    return get(data, 'reason') !== constants.CALL.COMPLAINT;
                }, (chain) => {
                    chain.required()
                        .integer()
                        .length(10);
                })
                .length(0, 100),
            district: validator()
                .when((attr, data) => {
                    return get(data, 'reason') !== constants.CALL.COMPLAINT;
                }, (chain) => {
                    chain.required();
                })
                .length(0, 100),
            card_number: validator()
                .when((attr, data) => {
                    return get(data, 'reason') !== constants.CALL.COMPLAINT;
                }, (chain) => {
                    chain.required();
                })
                .length(0, 20),
            call_result_hp: validator()
                .when((attr, data) => {
                    return get(data, 'reason') !== constants.CALL.COMPLAINT;
                }, (chain) => {
                    chain.required();
                })
                .length(0, 20),
        }
    });

    return mapping;
});
