<template>
    <form-container>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="24">
                <form-upload
                    ref="file"
                    :label="__('Файл заяви')"
                    :errors="$errors['statement_id']"
                    :entity="doc"
                    accept=".pdf,.jpeg,.jpg"
                    property="statement_id"
                    button-text="Прикріпити"
                    showFileList
                >
                    <template #tip>
                        <div class="el-upload__tip">
                            pdf, jpeg
                        </div>
                    </template>
                </form-upload>
            </el-col>
        </el-row>
    </section>
    <section class="dialog-footer">
        <el-button
            @click="cancel">
            {{ __('Скасувати') }}
        </el-button>
        <el-button
            type="primary"
            :loading="loading"
            @click="done">
            {{ __('Видалити') }}
        </el-button>
    </section>
    </form-container>
</template>
<script>

import fileLoader from "@app/services/file-loader";
import FormRow from "@app/core/components/form/form-row";
import SvgIcon from "@app/core/components/svg-icon.vue";

export default {
    inject: [
        'modalApi',
    ],
    data() {
        return {
            loading: false,
            doc: {
                statement_id: null,
            }
        };
    },
    created() {
        console.log('created', this.doc);
    },
    methods: {
        done() {
            this.loading = true;
            this.$emit('done', {
                statement_id: this.doc.statement_id,
                stopLoading: () => {
                    this.loading = false;
                }
            });
        },
        cancel() {
            this.modalApi.close();
        },
    }
  }
</script>
