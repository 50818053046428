import Document, {Attribute} from '@candybox/structures/document.js';

class Call extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            reason: String,
            is_first: Number,
            is_card_owner: Number,
            first_name: String,
            last_name: String,
            middle_name: String,
            birth_date: Date,
            date: Date,
            contact_number: String,
            rnkoop: String,
            district: String,
            card_number: String,
            call_result_kc: String,
            call_result_hp: String,
            comment: String,
            reaction: String,
            contact: Attribute.object(Object, () => {}, false),
        };
    }
}

export default Call;
