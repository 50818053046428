import { singleton } from '@candybox/helpers.js';
import {Method, RequestMap} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RequestMap();

    mapping.map('sampleBackEndRequest', {
        method: Method.POST,
        route: '/api/sample/back-end-sample'
    });

    mapping.map('sampleArchiveRequest', {
        method: Method.POST,
        route: '/api/sample/archive'
    });

    return mapping;
});
