<template>
    <section class="dialog-body">
        <el-row
            class="form-block"
            :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Внутрішня назва')"
                    :errors="$errors['inner_name']"
                    required>
                    <el-input v-model="doc.inner_name" />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Відображати')"
                    :errors="$errors['is_show']"
                    required
                >
                    <options-provider
                        source="yes_no">
                        <template v-slot:default="data">
                            <el-radio-group
                                v-model="doc.is_show"
                                :disabled="disabledShowing"
                            >
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Номер')"
                    :errors="$errors['order']"
                    required>
                    <el-input v-model="doc.order" />
                </form-row>
            </el-col>
            <el-col :span="24">
                <form-row
                    :label="__('Назва')"
                    :errors="$errors['title']"
                    required
                >
                    <el-input
                        v-model="doc.title"
                        type="textarea"
                        resize="none"
                    />
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import showItemMixin from "@app/questionnaire-constructor/components/mixins/showItemMixin.js";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        showItemMixin,
    ],
    computed: {
        disabledShowing() {
            return this.hasRequired(this.children);
        }
    },
}
</script>
