<template>
    <div class="sample-control">
        <el-row :gutter="20">
            <el-col :span="10">
                <el-select
                    v-model="selectedField"
                    @change="getSelectedFieldDate"
                    :disabled="!newSample">
                    <el-option
                        v-for="item in fields"
                        :key="item.id"
                        :label="item.alias"
                        :value="item.field_name"
                    />
                </el-select>
            </el-col>
            <el-col :span="6" v-if="dataType === 'string'">
                <el-select
                    v-model="selectFirstCondition"
                    :disabled="!newSample">
                    <el-option
                        v-for="item in firstConditionContentText"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-col>
            <el-col :span="6" v-if="dataType === 'string'">
                <el-input
                    v-model="selectSecondCondition"
                    :disabled="!newSample"/>
            </el-col>
            <el-col :span="12" v-if="dataType !== 'string'">
                <el-date-picker
                    v-if="dataType === 'date'"
                    v-model="selectFirstCondition"
                    unlink-panels
                    type="daterange"
                    start-placeholder="Початкова дата"
                    end-placeholder="Кінцева дата"
                    format="DD.MM.YYYY"
                    value-format="YYYY-MM-DD"
                    class="el-input"
                    style="border-style: solid; border-width: 2px"
                    :disabled="!newSample"/>
                <el-select
                    v-if="dataType === 'dropdown'"
                    v-model="selectFirstCondition"
                    multiple
                    :disabled="!newSample">
                    <el-option
                        v-for="item in answers"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-col>
            <el-col :span="2" v-if="newSample">
                <svg-icon
                    name="delete"
                    class="icon-grey icon-hover"
                    @click="deleteCondition" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import types from "@app/questionnaire/repositories/types";
import statuses from "@app/questionnaire/repositories/statuses";
import glossary from "@app/handbook/repositories/glossary";
import questions from "@app/questionnaire/repositories/questions";
import Query from "@candybox/query/query";

export default {
    name: 'condition',
    props: {
        fields: {
            type: Array,
            required: true
        },
        dbAnswersInGlossary: {
            type: Array,
            required: true
        },
        selectedCondition: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        newSample: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            monitoringChanges: false,
            selectedField: null,
            selectFirstCondition: null,
            selectSecondCondition: null,
            firstConditionContentText: [
                {
                    id: 1,
                    label: 'дорівнює',
                    value: '='
                },
                {
                    id: 2,
                    label: 'містить',
                    value: 'like'
                }
            ],
            dataType: null,
            answers: [],
            source: {
                questionnaire_type_id: types(),
                questionnaire_status_id: statuses(),
                glossary: glossary(),
                questions: questions(),
            },
            selectedFieldDate: null,
            origin: null,
        }
    },
    methods: {
        getSelectedFieldDate() {
            this.clearFields()
            //Получаем информацию о поле из конструктора
            this.source.questions
                .search((new Query()).where('field_name', '=', this.selectedField))
                .then((questions) => {
                    if (questions.length > 0) {
                        // откидываем дубли
                        this.selectedFieldDate = questions[0]
                        // если поле простое, определяем его тип
                        if (this.selectedFieldDate.view_type === 'simple') {
                            this.dataType = this.selectedFieldDate.answers[0].input.type.name
                            this.origin = 'constructor'
                        } else {
                            // если поле не простое, ищем его в глоссарие
                            this.source.glossary
                                .search((new Query()).where('type', '=', this.selectedField))
                                .then((glossary) => {
                                    if (glossary.length > 0) {
                                        this.origin = 'glossary_id'
                                        this.dataType = 'dropdown'
                                        for (let value of glossary) {
                                            this.dbAnswersInGlossary.every((answer) => {
                                                if (answer.glossary_id === value.id) {
                                                    value.id = answer.id
                                                    return false
                                                } else {
                                                    return true
                                                }
                                            })
                                        }
                                        this.fillingAnswers(glossary)
                                    } else {
                                        this.dataType = 'dropdown'
                                        let i = 1
                                        //если в глоссание не найдено, ищем ответы в хендбуке или берем из конструктора
                                        if (this.selectedFieldDate.answers.length === 1 && this.selectedFieldDate.answers[0].handbook_name !== null) {
                                            this.origin = 'glossary_name'
                                            this.source.glossary
                                                .search((new Query()).where('type', '=', this.selectedFieldDate.answers[0].handbook_name))
                                                .then((handbook) => {
                                                    handbook.forEach((row) => {
                                                        this.answers.push({
                                                            id: i++,
                                                            label: row.name,
                                                            value: row.name
                                                        })
                                                    })
                                                })
                                        } else {
                                            this.origin = 'constructor'
                                            Object.values(this.selectedFieldDate.answers).forEach((answer) => {
                                                if (answer.prefix) {
                                                    this.answers.push({
                                                        id: i++,
                                                        value: answer.id,
                                                        label: answer.prefix
                                                    })
                                                }
                                            })
                                        }
                                    }
                                })
                        }
                    } else {
                        // Если нет поля в конструкторе то ищем в доп ресурсах
                        if (this.source.hasOwnProperty(this.selectedField)) {
                            this.source[this.selectedField]
                                .search(new Query())
                                .then((data) => {
                                    this.dataType = 'dropdown'
                                    this.fillingAnswers(data)
                                })
                        }
                        if (this.selectedField === 'status' || this.selectedField === 'status_by_questionnaire')
                        {
                            let key
                            if (this.selectedField === 'status') {
                                key = 'card_status'
                            } else {
                                key = 'card_status_by_questionnaire'
                            }
                            this.origin = 'card'
                            this.dataType = 'dropdown'
                            let i = 1;
                            this.$handbook(key).forEach((answer) => {
                                this.answers.push({
                                    id: i++,
                                    value: answer.id,
                                    label: answer.value
                                })
                            })
                        }
                    }
                })
        },
        fillingAnswers(data) {
            data.forEach((row) => {
                this.answers.push({
                    id: row.id,
                    label: row.name,
                    value: row.id
                })
            })
        },
        clearFields() {
            this.selectFirstCondition = null
            this.selectSecondCondition = null
            this.answers = []
            this.selectedFieldDate = null
            this.origin = null
        },
        deleteCondition() {
            this.$emit('deleteCondition', this.id)
        },
        update() {
            this.selectedField = this.selectedCondition.selectedField
            this.getSelectedFieldDate()
            this.selectFirstCondition = this.selectedCondition.firstCondition
            this.selectSecondCondition = this.selectedCondition.secondCondition
            this.monitoringChanges = true
        }
    },
    computed: {
        resultCondition() {
            return {
                id: this.id,
                selectedField: this.selectedField,
                firstCondition: this.selectFirstCondition,
                secondCondition: this.selectSecondCondition,
                source: this.origin
            }
        },
    },
    watch: {
        resultCondition() {
            if (this.monitoringChanges) {
                this.$emit('updateConditions', this.resultCondition)
            }
        },
        selectedCondition() {
            this.monitoringChanges = false
            this.update()
        }
    },
    created() {
        this.update()
    }
}
</script>
