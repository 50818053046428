<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Скрипти'),
            pages: [
                {
                    index: '1',
                    title: this.__('Скрипти'),
                    route: { name: 'script' },
                    visible: () => this.$can('script.access'),
                },
            ],
        }
    }
}
</script>
