import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';


export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/institution/group');

    mapping.validation('store', () => {
        return {
            name: validator()
                .required()
                .length(0, 100),
            entry_year: validator()
                .required(),
            manager_id: validator()
                .required(),
            institution_id: validator()
                .required(),
        }
    });

    return mapping;
});
