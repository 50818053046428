import App from '@candybox/app.js';
import cardList from "@app/card/components/card/table";

App.boot(({router}) => {
    router.addRoute('card', {
        component: cardList,
        path: 'card',
        name: 'card',
    });
});


