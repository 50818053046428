<template>
    <el-input 
        :size="size"
        v-model="query" 
        clearable
        @change="changedQuery">
        <template v-slot:prepend>
            <el-select 
                v-model="mode"
                @change="changedMode">
                <el-option 
                    label="~" 
                    value="~">
                    {{ __('Починається з (~)') }}
                </el-option>
                <el-option 
                    label="=" 
                    value="=">
                    {{ __('Точний збіг (=)') }}
                </el-option>
                <el-option 
                    label="*" 
                    value="*">
                    {{ __('Містить (*)') }}
                </el-option>
            </el-select>
        </template>
    </el-input>
</template>

<script>
import { isFilled } from '@app/core/helpers.js';
import { isObject } from '@candybox/helpers.js';

export default {
    props: {
        modelValue: {
            type: [String, Number, Object]
        },
        size: {
            type: String,
            default: 'default',
        },
        defaultMode: {
            type: String,
            default: '~',
        },
    },
    data() {
        return {
            query: this.getQuery(this.modelValue),
            mode: this.getMode(this.modelValue),
        }
    },
    methods: {
        changedQuery(query) {
            this.emitChanges(isFilled(query) ? {
                query,
                mode: this.mode,
            } : null);
        },
        changedMode(mode) {
            this.emitChanges(isFilled(this.query) ? {
                query: this.query,
                mode,
            } : null);
        },
        getQuery(v, def = null) {
            return (isObject(v) ? v.query : v) || def || '';
        },
        getMode(v, def = null) {
            return isObject(v) ? v.mode : (def || this.defaultMode);
        },
        emitChanges(v) {
            this.$emit('querychange', v);
            this.$emit('update:modelValue', v);
        },
    },
    watch: {
        modelValue(v) {
            this.query = this.getQuery(v, this.query);
            this.mode = this.getMode(v, this.mode);
        },
    },
}
</script>