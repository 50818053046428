import { singleton } from '@candybox/helpers.js';
import {Method, RequestMap} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RequestMap();

    mapping.map('totalSmallAdminAnalytics', {
        method: Method.POST,
        route: '/api/analytics/small-admin/total'
    });

    mapping.map('statusesAnalytics', {
        method: Method.POST,
        route: '/api/analytics/small-admin/statuses'
    });

    return mapping;
});
