import questions from "@app/questionnaire/repositories/questions.js";
import Query from "@candybox/query/query.js";
import glossary from "@app/handbook/repositories/glossary.js";
import {first, get, map} from "lodash";
import handbook from "@app/core/services/handbook.js";


class ConditionSource
{
    _fieldName = null;
    _source = null;
    _origin = null;
    _dataType = null;
    _answers = [];
    _mapFields = [
        'id',
        'id',
        'name',
    ];
    _signs = [];
    _isMultiple = true;


    constructor(fieldName, source, origin = null, dataType = 'dropdown') {
        this._fieldName = fieldName;
        this._source = source;
        this._origin = origin;
        this._dataType = dataType;
    }

    async getRepository() {
        return await this._source
            .search(
                (new Query())
                    .limitTo(1000)
            )
            .then((items) => items);
    }

    async run() {
        this._answers = await this.getRepository();
    }

    _getMapper(item, ind) {
        return {
            id: item[this._mapFields[0]],
            value: item[this._mapFields[1]],
            label: item[this._mapFields[2]],
        }
    }

    getAnswers() {
        return map(this._answers, (item) => this._getMapper(item));
    }
}

class QuestionConditionSource extends ConditionSource
{
    constructor(fieldName, source = questions(), origin = 'constructor', dataType = null) {
        super(fieldName, source, origin, dataType);
    }

    async getRepository() {
        return await this._source
            .search(
                (new Query())
                    .where('field_name', '=', this._fieldName)
            )
            .then((items) => first(items));
    }

    async run() {
        await this.setDataType();
        this.setSigns(this._dataType);
    }

    async setDataType() {
        const question = await this.getRepository();
        this._dataType = get(first(question.answers), 'input.type.name')
    }

    setSigns(dataType) {
        if(dataType === 'string') {
            this._signs = [
                {
                    id: 1,
                    label: 'дорівнює',
                    value: '='
                },
                {
                    id: 2,
                    label: 'містить',
                    value: 'like'
                }
            ];
        } else if(dataType === 'date') {
            this._signs = [
                {
                    id: 1,
                    label: 'Точний збіг',
                    value: '*'
                },
                {
                    id: 2,
                    label: 'Більше або рівне',
                    value: '>='
                },
                {
                    id: 3,
                    label: 'Менше або рівне',
                    value: '<='
                },
                {
                    id: 4,
                    label: 'Діапазон',
                    value: '='
                },
            ];
        }
    }
}

class NativeConditionSource extends QuestionConditionSource
{
    constructor(fieldName, source, origin = null, dataType = 'string') {
        super(fieldName, source, origin, dataType)
    }

    async setDataType() {
        //
    }
}

class GlossaryIdConditionSource extends ConditionSource
{
    constructor(fieldName, origin = null, dataType = 'dropdown') {
        super(fieldName, glossary(), origin, dataType);
    }

    async getRepository() {
        return await this._source
            .search(
                (new Query())
                    .where('type', '=', this._fieldName)
            )
            .then((items) => items);
    }
}

class GlossaryNameConditionSource extends ConditionSource
{
    _mapFields = [
        'id',
        'name',
        'name',
    ];

    constructor(fieldName, origin = null, dataType = 'dropdown') {
        super(fieldName, glossary(), origin, dataType);
    }

    async getQuestion() {
        const questionRepository = new QuestionConditionSource(this._fieldName);
        return await questionRepository.getRepository(this._fieldName);
    }

    async getRepository() {
        const questionData = await this.getQuestion();
        const glossaryName = get(first(questionData.answers), 'handbook_name');

        return await this._source
            .search(
                (new Query())
                    .where('type', '=', glossaryName)
            )
            .then((items) => items);
    }
}

class HandbookConditionSource extends ConditionSource
{
    _mapFields = [
        'id',
        'id',
        'value',
    ];

    constructor(key, origin = null) {
        super(key, null, origin, 'dropdown')
    }

    getRepository() {
        return handbook().get(this._fieldName)
    }

    async run() {
        this._answers = this.getRepository();
    }
}

class BooleanConditionSource extends ConditionSource
{
    _mapFields = [
        'id',
        'value',
        'label',
    ];
    _signs = [
        {
            id: 1,
            label: 'Присутній',
            value: '!='
        },
        {
            id: 2,
            label: 'Відсутній',
            value: '='
        },
    ];
    _isMultiple = false;

    constructor(fieldName) {
        super(fieldName, null, 'native', 'boolean');
    }

    async getRepository() {
        return null;
    }

    async run() {
        //
    }
}


export {
    ConditionSource,
    HandbookConditionSource,
    GlossaryNameConditionSource,
    GlossaryIdConditionSource,
    QuestionConditionSource,
    BooleanConditionSource,
    NativeConditionSource,
}
