import App from '@candybox/app.js';
import glossaryList from '@app/handbook/components/glossary/table.vue';
import cardHandbookList from '@app/handbook/components/card-handbook/table.vue';

App.boot(({router}) => {
    router.addRoute('settings', {
        component: glossaryList,
        path: 'glossary',
        name: 'glossary',
    });
    router.addRoute('settings', {
        component: cardHandbookList,
        path: 'card-handbook',
        name: 'card-handbook',
    })
});
