import RestRepository from '@candybox/repository/rest.js';
import RetailAnalytics from '@app/analytics/documents/retail-analytics.js';
import RetailAnalyticsMap from '@app/analytics/requests/retail-analytics-repository.js';
import { singleton } from '@candybox/helpers.js';

class RetailAnalyticsRepository extends RestRepository
{
    getBetween(data) {
        return this._request('betweenRetailAnalytics', data);
    }
}

export default singleton(() => {
    return new RetailAnalyticsRepository(RetailAnalytics, RetailAnalyticsMap());
});
