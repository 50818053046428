import {get} from 'lodash';

export default {
    computed: {
        getInn() {
            return this.getField('rnokpp');
        }
    },
    methods: {
        getField(name) {
            return get(this.doc, name, null);
        }
    }
}
