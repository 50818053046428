<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        :row-class-predicate="getRowClassPredicate"
        @selection-changed="selectionChanged"
    >
        <template v-slot:controls>
            <el-button
                v-if="$can('process-log.access')"
                @click="filterByResult">
                {{ changeResultButtonText }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import processLogs from '@app/call-center/repositories/process-logs.js';
import manage from '@app/core/mixins/manage.js';
import {
    date,
    datetime, handbookEntry,
    phoneNumber
} from '@app/core/formatter.js';
import fullName from "@app/call-center/components/call-center/components/fullName.vue";
import {get, isEqual, isNull} from "lodash";
import constants from '@app/core/constants.js';
import ProcessLog from "@app/call-center/documents/process-log";
import {join} from "lodash/array";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'fullName',
                title: this.__('ПІБ'),
                sortable: true,
                filterable: true,
                width: 250,
                component: fullName,
            }, {
                name: 'birth_date',
                title: this.__('Дата народження'),
                formatter: date('dd MMM yyyy'),
                sortable: true,
                filterable: true,
                visible: false,
                width: 160,
            }, {
                name: 'contact_number',
                title: this.__('Номер телефону'),
                formatter: phoneNumber(),
                sortable: true,
                filterable: true,
                width: 180,
            }, {
                name: 'rnkoop',
                title: this.__('РНКООП'),
                sortable: true,
                filterable: true,
                visible: false,
                width: 180,
            }, {
                name: 'district',
                title: this.__('Район'),
                sortable: true,
                filterable: 'district',
                formatter: handbookEntry('district'),
                visible: false,
                width: 180,
            }, {
                name: 'card_number',
                title: this.__('Картка'),
                sortable: true,
                filterable: true,
                width: 180,
            }, {
                name: 'call_result_kc',
                title: this.__('Результат опрацювання'),
                sortable: true,
                filterable: 'call_status',
                formatter: handbookEntry('call_status'),
                width: 190,
            }, {
                name: 'reaction',
                title: this.__('Реакція'),
                formatter: handbookEntry('call_reaction'),
                sortable: true,
                filterable: 'call_reaction',
                visible: false,
                width: 180,
            }, {
                name: 'delayed_time',
                title: this.__('Час відкладеного дзвінка'),
                formatter: datetime('dd MMM HH:mm'),
                width: 180,
            }, {
                name: 'started_at',
                title: this.__('Почато'),
                sortable: true,
                formatter: datetime('dd MMM HH:mm'),
                width: 180,
            }, {
                name: 'processed_at',
                title: this.__('Закінчено'),
                sortable: true,
                formatter: datetime('dd MMM HH:mm'),
                width: 180,
            }, {
                name: 'source',
                title: this.__('Джерело'),
                sortable: true,
                formatter: handbookEntry('call_source'),
                filterable: 'call_source',
                width: 130,
            }, {
                name: 'reason',
                title: this.__('Причина звернення'),
                sortable: true,
                filterable: 'call_reason',
                formatter: handbookEntry('call_reason'),
                width: 250,
            }, {
                name: 'is_card_owner',
                title: this.__('Власник карти'),
                sortable: true,
                filterable: 'yes_no',
                component: 'table-yes-mark',
                width: 140,
            }, {
                name: 'operator.full_name',
                title: this.__('Оператор'),
                sortable: true,
                sortProp: 'operator_id',
                filterable: true,
                filterProp: 'operator_name',
                width: 180,
            }, {
                name: 'comment',
                title: this.__('Коментар'),
                width: 400,
            }],
            filter: {},
            select: ['*'],
            relations: [],
        }
    },
    created() {
        this.$events.on('processLog:completed', this.refresh);
    },
    beforeDestroy() {
        this.$events.off('processLog:completed', this.refresh);
    },
    computed: {
        changeResultButtonText() {
            return this.filter.call_result_kc ? this.__('Всі') : this.__('Тільки перетелефонувати');
        }
    },
    methods: {
        getRepository() {
            return processLogs();
        },
        getRowClassPredicate(row) {
            return isEqual(get(row, 'call_result_kc'), constants.PROCESS_LOG.STATUS.RECALL) && !isNull(get(row, 'delayed_time'))
                ? 'error'
                : null;
        },
        filterByResult() {
            if(!this.filter.call_result_kc) {
                this.filter.call_result_kc = constants.PROCESS_LOG.STATUS.RECALL;
            } else {
                delete this.filter.call_result_kc;
            }

        }
    },
}
</script>
