<template>
    <section class="dialog-body">
        <el-button type="primary" plain @click="loadExportedFiles">Оновити список</el-button>
        <el-row v-for="file in files">
            <el-col>
                <a :href="getUrl(file)">
                    <el-button>
                        {{ file }}
                    </el-button>
                </a>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import questionnaire from "@app/questionnaire/repositories/questionnaires";

export default {
    data() {
        return {
            repository: questionnaire,
            files: []
        }
    },

    created() {
        this.loadExportedFiles();
    },

    methods: {
        loadExportedFiles() {
            this.repository()
                .loadFiles()
                .then(response => {
                    this.files = response;
                })
        },

        getUrl(file) {
            return `https://${window.location.host}/api/questionnaires/download/${file}`
        }
    }
}
</script>
