<script>
import BaseHistory from '@app/core/components/manage/history.vue';
import { boolean } from '@app/core/formatter.js';

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'position';
        },
        getAttributes() {
            return {
                name: this.__('Назва'),
            };
        },
    }
}
</script>
