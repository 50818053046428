import Document, { Attribute } from '@candybox/structures/document.js';
import { isFilled } from '@app/core/helpers.js';
import RelationAttribute from '@candybox/repository/relation.js';
import types from '@app/questionnaire/repositories/types.js';
import employees from "@app/employee/repositories/employees.js";
import AnswerQuestionnaire from "@app/questionnaire/documents/AnswerQuestionnaire";
import FileAttachment from "@app/file-attachment/documents/file-attachment";
import constants from "@app/core/constants";
import Status from "@app/questionnaire/documents/status.js";
import Card from "@app/card/documents/card.js";
import Group from "@app/institution/documents/group.js";

class Questionnaire extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            first_name_attr: String,
            last_name_attr: String,
            middle_name_attr: String,
            birth_date_attr: String,
            contact_number_attr: String,
            additional_number_attr: String,
            rnokpp_attr: String,
            district_attr: String,
            group_name_attr:String,
            educational_institution_name_attr:String,
            place_of_receiving_id: Number,
            act_of_acceptance: String,
            has_plastic: Attribute.string('0', false),
            has_digital: Attribute.string('1', false),
            is_archive: Attribute.string('0', false),
            source_id: Number,
            responsible_name: String,
            has_chip: Attribute.string('1', false),
            issue_condition_id: Number,
            changed_tags: Array,
            statement_id: Number,

            id: Number,
            child_id: Number,
            parent_id: Number,
            parent: Attribute.object(Object, () => ({
                rnkopp: null,
                card_number: null,
                first_name: null,
                last_name: null,
                middle_name: null,
            }), false),
            questionnaire_type_id: Number,
            $type: RelationAttribute.oneToOne(types(), 'questionnaire_type_id'),
            employee_short_name: String,
            questionnaire_status_id: Number,
            status: Attribute.object(Status, () => new Status({code: constants.QUESTIONNAIRE_STATUS.NEW}), false),
            card: Attribute.object(Card),
            family_status: String,
            last_name: String,
            first_name: String,
            middle_name: String,
            birth_date: Date,
            birth_state: String,
            birth_region: String,
            birth_district: String,
            birth_locality: String,
            rnokpp: String,
            registration_state: String,
            registration_region: String,
            registration_district: String,
            registration_locality: String,
            registration_street: String,
            registration_house: String,
            registration_building: String,
            registration_apartment: String,
            registration_room: String,
            registration_index: String,
            residential_state: String,
            residential_region: String,
            residential_district: String,
            residential_locality: String,
            residential_street: String,
            residential_house: String,
            residential_building: String,
            residential_apartment: String,
            residential_room: String,
            residential_index: String,
            contact_number: String,
            additional_number: String,
            communication_ways: String,
            email: String,
            passport_series: String,
            passport_number: String,
            passport_date: Date,
            passport_issue: String,
            passport_id: String,
            passport_id_issue: String,
            reasons: String,
            other_services: String,
            catch_way: String,
            social_status: String,
            privileges_category: String,
            pension_number: String,
            is_working_pensioner: Number,
            has_wish_children: Number,
            educational_institution: Number,
            class_number: Number,
            transport: String,
            skills: String,
            sport_sections: String,
            paid_foreign_languages: String,
            pupil_privileges_category: String,
            organization_name: String,
            organization_name_edrpou: String,
            position: String,
            position_private: String,
            organization_activity_field: String,
            organization_activity_field_private: String,
            experience: String,
            organization_name_volunteer: String,
            position_volunteer: String,
            organization_activity_field_volunteer: String,
            experience_volunteer: String,
            is_live_in_city: String,
            living_type: String,
            student_educational_institution: Number,
            faculty: String,
            course_number: String,
            student_privileges_category: String,
            answers: Attribute.collection(AnswerQuestionnaire),
            answers_to_save: Attribute.object(Object, {}, false),
            image: Blob,
            photo_id: Number,
            photo: Attribute.object(FileAttachment, () => new FileAttachment(), false),
            order_files_ids: Attribute.array([], false),
            note: String,
            operator_note: String,
            is_agree: Attribute.string('1', false),
            last_card: Attribute.object(Card),
            reserv_1: String,
            reserv_2: String,
            reserv_3: String,
            reserv_4: String,
            reserv_5: String,
            reserv_6: String,
            reserv_7: String,
            reserv_8: String,
            reserv_9: String,
        };
    }

    /**
     * Get employee full name
     *
     * @returns {String}
     */
    get full_name_attr() {
        return [
            this.last_name_attr,
            this.first_name_attr,
            this.middle_name_attr,
        ]
            .filter(isFilled)
            .join(' ');
    }

    get full_passport_data_attr() {
        return [
            this.passport_series_attr,
            this.passport_number_attr,
            this.passport_date_attr,
            this.passport_issue_attr,
            this.passport_id_attr,
            this.passport_id_issue_attr,
        ]
            .filter(isFilled)
            .join(' ');
    }

    get full_registration_attr() {
        return [
            this.registration_state_attr,
            this.registration_region_attr,
            this.registration_district_attr,
            this.registration_locality_attr,
            this.registration_street_attr,
            this.registration_house_attr,
            this.registration_building_attr,
            this.registration_apartment_attr,
            this.registration_room_attr,
            this.registration_index_attr,
        ]
            .filter(isFilled)
            .join(' ');
    }

    get full_registration_attr() {
        return [
            this.registration_state_attr,
            this.registration_region_attr,
            this.registration_district_attr,
            this.registration_locality_attr,
            this.registration_street_attr,
            this.registration_house_attr,
            this.registration_building_attr,
            this.registration_apartment_attr,
            this.registration_room_attr,
            this.registration_index_attr,
        ]
            .filter(isFilled)
            .join(' ');
    }

    get full_privileges_attr() {
        return [
            this.student_privileges_category_attr,
            this.pupil_privileges_category_attr,
            this.privileges_category_attr,
        ]
            .filter(isFilled)
            .join(' ');
    }

    get full_parent_attr() {
        return [
            this.parent.card_number,
            this.parent.rnkopp,
            this.parent.last_name,
            this.parent.first_name,
            this.parent.middle_name,
        ]
            .filter(isFilled)
            .join(' ');
    }
    get full_organization_name_attr() {
        return [
            this.organization_name_attr,
            this.organization_name_edrpou_attr,
        ]
            .filter(isFilled)
            .join(' ');
    }
}

export default Questionnaire;
