import RestRepository from '@candybox/repository/rest.js';
import importEntityMap from '@app/category/requests/import-entity-repository.js';
import { singleton } from '@candybox/helpers.js';
import ImportEntity from "@app/category/documents/import-entity.js";

class ImportEntityRepository extends RestRepository
{
    importRollback(import_entity_id) {
        return this._request('importRollback', {import_entity_id});
    }

    removeAll(type) {
        return this._request(`${type}RemoveImports`);
    }
}

export default singleton(() => {
    return new ImportEntityRepository(ImportEntity, importEntityMap());
});
