<script>
import base from "@app/core/components/page-groups/base.vue";

export default {
    extends: base,
    data() {
        return {
            title: this.__('Звітність'),
            pages: [
                {
                    index: '1',
                    title: this.__('Загальна'),
                    route: { name: 'report' },
                    visible: () => this.$can('report.access'),
                },
            ]
        }
    }
}
</script>
