<template>
    <section class="dialog-body">
        <el-row
            class="form-block"
            :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Внутрішня назва')"
                    :errors="$errors['inner_name']"
                    required>
                    <el-input v-model="doc.inner_name" />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Відображати')"
                    :errors="$errors['is_show']"
                    required
                >
                    <options-provider
                        source="yes_no">
                        <template v-slot:default="data">
                            <el-radio-group
                                v-model="doc.is_show"
                                :disabled="disabledShowing"
                            >
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Номер')"
                    :errors="$errors['order']"
                    required>
                    <el-input v-model="doc.order" />
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Префікс')"
                    :errors="$errors['prefix']"
                >
                    <el-input
                        v-model="doc.prefix"
                        type="textarea"
                        resize="none"
                    />
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Постфікс')"
                    :errors="$errors['suffix']"
                >
                    <el-input
                        v-model="doc.suffix"
                        type="textarea"
                        resize="none"
                    />
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Словник')"
                    :errors="$errors['handbook_name']"
                    required
                >
                    <options-provider
                        source="value_for_questionnaires"
                        :item-builder="glossaryNameBuilder()"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.handbook_name"
                                :loading="data.loading"
                                clearable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Елемент словника')"
                    :errors="$errors['glossary_id']"
                    required
                >
                    <options-provider
                        :source="getGlossaries()"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.glossary_id"
                                :loading="data.loading"
                                :disabled="!doc.handbook_name"
                                clearable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import glossary from "@app/handbook/repositories/glossary.js";
import Query from "@candybox/query/query.js";
import { get, map } from "lodash";
import showItemMixin from "@app/questionnaire-constructor/components/mixins/showItemMixin.js";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        showItemMixin,
    ],
    data() {
        return {
            currentGlossary: null,
            glossariesNames: [],
        }
    },
    computed: {
        disabledShowing() {
            return !!get(this.parent, 'is_required', 0);
        }
    },
    methods: {
        glossaryNameBuilder() {
            return (item) => ({
                id: item.key,
                value: item.value
            });
        },
        getGlossaries() {
            return glossary()
                .search(
                    (new Query)
                        .where('type', '=', this.doc.handbook_name)
                )
                .then((items) => {
                    return map(items, (item) => ({
                        id: item.id,
                        value: item.name,
                    }));
                });
        },
    }
}
</script>
