import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import {Method} from "@candybox/transport/request";


export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/card/card');

    mapping.map('importExportLog', {
        route: '/api/card/importExportLog',
        method: Method.POST,
    });

    return mapping;
});
