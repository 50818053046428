<template>

    <data-table v-if="isTableShow"
                flex
                ref="table"
                :repository="getRepository()"
                :columns="columns"
                :filter="filter"
                :select="select"
                :relations="relations"
                @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('category.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('category.update')"
                :disabled="!selectedItem || selectedItem.name == 'Не визначені'"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('category.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                @click="swapOutput">
                {{ __('Змінити вид(дерево)') }}
            </el-button>
        </template>

    </data-table>

    <div v-else>
        <tree
            :data="categoriesTree"
            height="calc(100vh - 150px)">

            <template v-slot:additional="data">
                    <span>
                        {{ data.node.label }}
                    </span>
            </template>
        </tree>
        <el-button
            class="category-tree-btn"
            @click="swapOutput">
            {{ __('Змінити вид(таблица)') }}
        </el-button>
    </div>
</template>

<script>
    import formLayout from '@app/category/components/category/form.vue';
    import manage from '@app/core/mixins/manage.js';
    import categories from "@app/category/repositories/categories";
    import Category from "@app/category/documents/category.js";
    import fileLoader from "@app/services/file-loader";
    import formImport from "@app/questionnaire/components/mixins/formImport";
    import FormImport from "@app/category/components/category/form-import.vue";
    import Tree from "@app/core/components/tree/tree";
    import Query from "@candybox/query/query";
    import products from "@app/category/repositories/products";


    export default {
        mixins: [
            manage,
            formImport,
        ],
        components: {
            Tree
        },
        data() {
            return {
                columns:
                    [
                        {
                            name: 'name',
                            title: this.__('Назва'),
                            sortable: true,
                            filterable: true,
                        },
                        {
                            name: 'unified_code',
                            title: this.__('УКТ ЗЕД'),
                            sortable: true,
                            filterable: true,
                        },
                        /*{
                            name: 'type_text',
                            title: this.__('Тип'),
                            sortable: true,
                            filterable: false,
                            sortProp: 'type',
                        },*/
                        {
                            name: '$parent.$.name',
                            title: this.__('Батьківська категорія'),
                            sortable: true,
                            filterable: true,
                            filterProp: 'parent_name',
                            sortProp: 'parent_id',
                        },
                        {
                            name: '$parent.$.unified_code',
                            title: this.__('УКТ ЗЕД - Батьківська категорія'),
                            filterable: true,
                            filterProp: 'parent_unified_code',
                        },
                        /*{
                            name: '$original.$.name',
                            title: this.__('Оригінал'),
                            sortable: true,
                            filterable: true,
                            filterProp: 'original_name',
                            sortProp: 'original_id',
                        },*/
                    ],
                filter: {},
                select: ['*'],
                relations: ['$parent', '$original'],
                categoriesTree: [],
                productsTree: [],
                isTableShow: true,
                filterOptionList: []
            }
        },
        methods: {
            getRepository() {
                return categories();
            },
            getModalOptions() {
                return {
                    createForm: this.makeBasicCreate(Category, formLayout),
                    editForm: this.makeBasicEdit(formLayout),
                    createTitle: this.__('Додати '),
                    editTitle: this.__('Редагувати '),
                    width: '760px',
                };
            },
            getImportXlsxComponent() {
                return FormImport;
            },
            getMessages() {
                return {
                    deleteConfirmation: this.__('Ви певні, що хочете видалити цей запис? Усі категорії та продукти, пов\'язані з поточною категорією, будуть прив\'язані до категорії "Не визначені"'),
                };
            },
            documentUpdated(doc) {
                this.prepareDataTree();
            },
            documentCreated(doc) {
                this.prepareDataTree();
            },
            documentDeleted(doc) {
                this.prepareDataTree();
            },
            swapOutput() {
                this.isTableShow = !this.isTableShow;
            },

            prepareDataTree() {
                Promise.all([this.getProducts(), this.getCategories()]).then(([products, categories]) => {
                    this.productsTree = products.map((item) => ({
                        id: item.id,
                        label: item.name,
                        parent_id: item.category_id,
                        original_id: item.original_id,
                        product: true
                    }));

                    const itemsTemp = categories.map((item) => ({
                        id: item.id,
                        label: `${(item.unified_code ? item.unified_code : '')} ${item.name}`,
                        parent_id: item.parent_id,
                        original_id: item.original_id,
                    }));

                    const maxId = Math.max.apply(Math, itemsTemp.map((cat) => { return cat.id; }));

                    for (let i = 0; i < this.productsTree.length; i++) {
                        this.productsTree[i].id = maxId + (i + 1);
                        itemsTemp.push(this.productsTree[i]);
                    }
                    this.categoriesTree = this.buildTree(itemsTemp);
                })
            },
            buildTree(items, parent) {
                parent = parent || null;
                let result = [];

                items.forEach((item) => {
                    if (item.parent_id === parent) {
                        result.push(item);
                        item.children = this.buildTree(items, item.id);

                        if (!item.children.length) {
                            item.children = undefined;
                        } else {
                            if (item.children[0].product === true) {
                                item.label = '(' + item.children.length + ') ' + item.label
                            }
                        }
                    }
                });
                return result;
            },
            getProducts() {
                return products()
                    .search((new Query)
                        .where('type', '=', '1')
                        .limitTo(20000));
            },
            getCategories() {
                return categories()
                    .search((new Query)
                        .where('type', '=', '1')
                        .limitTo(20000));
            }
        },
        created() {
            this.prepareDataTree();
        },

        beforeDestroy() {
            fileLoader.revokeAll();
        },
    }
</script>
