<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="($can('institution.create') || $can('institution.create-institution'))"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="($can('institution.update') || $can('institution.update-institution'))"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="($can('institution.delete') || $can('institution.delete-institution'))"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Історія') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/institution/components/institution/form.vue';
import manage from '@app/core/mixins/manage.js';
import institution from "@app/institution/repositories/institutions";
import Institution from "@app/institution/documents/institution.js";
import history from "@app/institution/components/institution/history.vue";
import employees from "@app/employee/repositories/employees";


export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: this.__('Назва'),
                    width: 400,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'type',
                    width: 180,
                    title: this.__('Тип'),
                    sortable: true,
                    filterable: 'institution_type',
                    formatter: (val) => {
                        return this.$handbook(`institution_type.${val}`);
                    },
                },
                {
                    name: 'address',
                    title: this.__('Адреса'),
                    width: 300,
                    filterable: true,
                },
                {
                    name: 'chief_name',
                    title: this.__('ПІБ керівника'),
                    width: 200,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'manager.name',
                    title: this.__('ПІБ адміністратора'),
                    width: 200,
                    sortable: true,
                    filterable: employees(),
                    filterProp: 'manager_id',
                    filterOption: {
                        valueProp: 'full_name'
                    },
                    sortProp: 'manager_id',
                },
                {
                    name: 'contact_number',
                    title: this.__('Телефон'),
                    width: 200,
                    filterable: true,
                },
            ],
            filter: {},
            select: ['*'],
            relations: ['$manager'],
        }
    },
    methods: {
        getRepository() {
            return institution();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Institution, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати '),
                editTitle: this.__('Редагувати '),
                width: '760px',
            };
        },
        getHistoryComponent() {
            return history;
        },
    },
}
</script>
