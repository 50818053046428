import { singleton } from '@candybox/helpers.js';
import {Method, RequestMap} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RequestMap();

    mapping.map('betweenRetailAnalytics', {
        method: Method.POST,
        route: '/api/analytics/retail/between'
    });

    return mapping;
});
