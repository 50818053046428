<template>
    <section class="dialog-body">
        <form-row
            :label="__('Тип')"
            :errors="$errors['type']"
            required>
            <options-provider
                source="value_for_questionnaires">
                <template v-slot:default="data">
                    <el-select
                        :loading="data.loading"
                        v-model="doc.type">
                        <el-option
                            v-for="item in data.items"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id" />
                    </el-select>
                </template>
            </options-provider>
        </form-row>
        <form-row
            :label="__('Назва')"
            :errors="$errors['name']"
            required>
            <el-input
                v-model="doc.name"
                :rows="8"
                type="textarea"/>
        </form-row>
        <form-row
            :label="__('Код')"
            :errors="$errors['code']">
            <el-input v-model="doc.code" />
        </form-row>
    </section>
</template>

<script>

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
}
</script>
