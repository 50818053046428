<template>
    <AnalyticsFiltersBase
    >
        <template #filters>
            <el-row :gutter="20">
                <el-col :span="6">
                    <form-row
                        :label="__('Період')"
                    >
                        <div class="form-input-group">
                            <el-date-picker
                                v-model="period"
                                unlink-panels
                                type="daterange"
                                start-placeholder="Початкова дата"
                                end-placeholder="Кінцева дата"
                                format="DD.MM.YYYY"
                                value-format="YYYY-MM-DD"
                                class="el-input"
                                style="border-style: solid; border-width: 2px" />
                        </div>
                    </form-row>
                </el-col>
            </el-row>
        </template>
    </AnalyticsFiltersBase>
</template>

<script>
import AnalyticsFiltersBase from "@app/analytics/components/base/filters/AnalyticsFiltersBase.vue";

export default {
    name: "ProductsFilter",
    components: {
        AnalyticsFiltersBase,
    },
    data() {
        return {
            period: null,
        }
    },
    watch: {
        period: {
            handler(period) {
                if (period !== null) {
                    this.$emit('loadDataForPeriod', this.period)
                }
            },
            deep: true,
        },
    }
}
</script>
