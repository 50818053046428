import Document, {Attribute} from '@candybox/structures/document.js';
import Employee from "@app/employee/documents/employee";
import {isFilled} from "@app/core/helpers.js";
import { isEmpty } from "lodash";

class CallRequest extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            call_id: Number,
            status: String,
            source: String,
            comment: String,
            started_at: Date,
            processed_at: Date,
            delayed_time: Date,
            contact_id: null,
            contact_type: null,
            unprocessibility_reason: String,
            unprocessibility_reason_comment: String,
            is_questionnaire: Number,
            questionnaire_id: Number,
            operator_task_id: Number,
            is_first_visit: Number,
            questionnaire_type: Number,
            operator: Attribute.object(Employee, () => new Employee(), false),

            reason: String,
            is_card_owner: Number,
            first_name: String,
            last_name: String,
            middle_name: String,
            birth_date: Date,
            contact_number: String,
            rnkoop: String,
            district: String,
            card_number: String,
            call_result_kc: String,
            reaction: String,
            updated_at: Date,
        };
    }

    get fullName() {
        return [
            this.last_name,
            this.first_name,
            this.middle_name,
        ]
            .filter(isFilled)
            .join(' ');
    }

    get hasData() {
        const arr = [
            this.fullName,
            this.is_card_owner,
            this.birth_date,
            this.reason,
            this.contact_number,
            this.rnkoop,
            this.district,
            this.card_number,
            this.call_result_kc,
            this.reaction,
            this.is_first_visit,
            this.delayed_time,
        ]
            .filter(isFilled);

        return !isEmpty(arr);
    }

    serialize() {
        return {
            last_name: this.last_name,
            first_name: this.first_name,
            middle_name: this.middle_name,
            is_card_owner: this.is_card_owner,
            birth_date: this.birth_date,
            reason: this.reason,
            contact_number: this.contact_number,
            rnkoop: this.rnkoop,
            district: this.district,
            card_number: this.card_number,
            call_result_kc: this.call_result_kc,
            reaction: this.reaction,
            is_first_visit: this.is_first_visit,
            delayed_time: this.delayed_time,
        }
    }
}

export default CallRequest;
