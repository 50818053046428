import { intervalToDuration, sub, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

class ServerTime
{
    /**
     * Constructor
     */
    constructor() {
        this.difference = {};
        this._tz = 'Europe/London';
    }

    /**
     * Sync local and server time
     *
     * @param {string} time
     */
    sync(time) {
        this.difference = intervalToDuration({
            start: zonedTimeToUtc(new Date(), this._tz),
            end: zonedTimeToUtc(time, this._tz),
        });
    }

    /**
     * Get current server time
     *
     * @returns {object}
     */
    now() {
        return sub(new Date(), this.difference);
    }

    /**
     * Get current server time as string
     *
     * @returns {string}
     */
    asString() {
        return format(this.now(), 'yyyy-MM-dd HH:mm:ss');
    }
}

export default new ServerTime();
