import Document, { Attribute } from '@candybox/structures/document.js';

class Report extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            'issued': String,
            'stand_out': String,
            'refusal': String,
            'preparation_for_issuance': String,
            'under_review': String,
            'on_print': String,
            'not_pass_test': String,
            'stuck_in_system': String,
            'clarification_and_verification': String,
            'no_benefit_confirmation': String,
            'per-date': Date,
            'issued_sum': Number,
            'stand_out_sum': Number,
            'refusal_sum': Number,
            'preparation_for_issuance_sum': Number,
            'under_review_sum': Number,
            'on_print_sum': Number,
            'not_pass_test_sum': Number,
            'stuck_in_system_sum': Number,
            'clarification_and_verification_sum': Number,
            'no_benefit_confirmation_sum': Number,
            'printed': Number,
            'uploaded_for_printing': Number,
            'total': Number,
        };
    }
}

export default Report;
