<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('distributor.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('distributor.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('distributor.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from '@app/core/mixins/manage.js';
import outlet from "@app/distributor/repositories/outlet";
import formLayout from "@app/distributor/components/outlet/form";
import Outlet from "@app/distributor/documents/outlet";
import distributor from "@app/distributor/repositories/distributor";
import glossaries from "@app/handbook/repositories/glossary";
import Query from "@candybox/query/query";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'distributor.name',
                    title: this.__('Назва торговельної мережі'),
                    sortable: true,
                    sortProp: 'distributor_name',
                    filterable: distributor(),
                    filterProp: 'distributor_id',
                    filterOption: {
                        valueProp: 'name'
                    },
                },
                {
                    name: 'name',
                    title: this.__('Назва торгової точки'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'manager_full_name',
                    title: this.__('ПІБ керівника'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'phone_number',
                    title: this.__('Номер мобільного телефону'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'district.name',
                    title: this.__('Район міста'),
                    sortable: true,
                    filterable: this.getDistrict(),
                    filterProp: 'glossary_id',
                    sortProp: 'glossary_id',
                    filterOption: {
                        valueProp: 'name',
                    },
                },
                {
                    name: 'address',
                    title: this.__('Адреса філії'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'outlet_id',
                    title: this.__('Ідентифікатор'),
                },

            ],
            filter: {},
            select: ['*'],
            relations: [],
            glossary: null,
        }
    },
    methods: {
        getRepository() {
            return outlet();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Outlet, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати '),
                editTitle: this.__('Редагувати '),
                width: '760px',
            };
        },
        getDistrict() {
            return glossaries()
                .search((new Query).where('type', '=', 'district'))
                .then((items) => {
                    this.glossary = items.map((district) => ({
                        id: district.id,
                        value: district.name,
                    }));
                    return this.glossary;
                });
        },
    },
}
</script>
