<template>
    <BaseChart
        v-if="!loading"
        :chart-type="chartType"
        :chart-data="chartData"
        :title="title"
        :options="options"
    />
    <div
        v-else
        v-loading="loading"
        class="loading-block"
    ></div>
</template>

<script>
import constants from "@app/core/constants.js";
import BaseChart from "@app/analytics/components/base/charts/BaseChart.vue";
import chartMixin from "@app/analytics/components/base/charts/mixins/chartMixin.js";
import { count } from '@candybox/helpers.js';

export default {
    name: "BarChart",
    mixins: [
        chartMixin,
    ],
    components: {
        BaseChart,
    },
    data() {
        return {
            chartType: constants.CHART_TYPE.BAR,
            itemHeight: 150,
            height: 0,
        }
    },
    computed: {
        options() {
            return {
                hAxis: {
                    xTitle: this.xTitle,
                    minValue: 0,
                },
                vAxis: {
                    yTitle: this.yTitle,
                },
                legend: {
                    position: 'right',
                    alignment: 'end',
                    textStyle: {
                        color: 'grey',
                        fontSize: 12
                    },
                },
                chartArea: {
                    left: this.left,
                    top: 20,
                    width: '70%',
                },
                height: this.height,
                series: {},
                bar: {
                    groupWidth: '75%',
                },
            }
        },
        chartData() {
            const _arr = [];

            const header = [''];
            _.each(this.columns, (item) => {
                header.push(item, { role: 'annotation' });
            })
            _arr.push(header);

            _.each(this.data, (item, key) => {
                const _row = [key];
                _.each(item, (v, k) => {
                    _row.push(v);
                    if(this.withDescriptions) {
                        _row.push(v);
                    }
                })
                _arr.push(_row);
            });

            return _arr;
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if(this.barWidth) {
                this.options.width = this.barWidth;
            }
            this.height = this.itemHeight * count(this.data);
            this.setVisualization();
        },
        setVisualization() {
            let i = 0;
            _.each(this.colors, (color, k) => {
                this.options.series[i] = {
                    color: this.colors[k],
                };
                i++;
            })
        }
    }
}
</script>
