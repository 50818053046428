import handbooks from '@app/handbook/repositories/handbooks.js';
import { singleton,
    map,
    get } from '@candybox/helpers.js';
import Query from '@candybox/query/query.js';

class Handbook
{
    /**
     * @var {Object}
     */
    _data = null;

    /**
     * Load handbook content
     * 
     * @returns {Handbook}
     */ 
    load() {
        if (this.loaded) {
            return Promise.resolve();
        }

        let query = (new Query).limitTo(1000);
        return handbooks().search(query)
            .then((results) => {
                this._data = {};
                results.forEach((row) => {
                    let category = this._data[row.category];
                    if (category === undefined) {
                        category = new Map();
                        this._data[row.category] = category;
                    }
                    category.set(row.key, row.value);
                });
            });
    }

    /**
     * Get option(s)
     * 
     * @param {string} key
     * 
     * @return {Array|String|undefined}
     */
    get(key) {
        let dot = key.indexOf('.');
        if (dot === -1) {
            let category = get(this._data, key);
            return category === undefined 
                ? []
                : [...map(category.entries(), (e) => ({ id: e[0], value: e[1] }))];
        }
        let category = get(this._data, key.substr(0, dot));
        return category === undefined
            ? undefined
            : category.get(key.substr(dot + 1));
    }

    /**
     * Check if handbook data was loaded
     * 
     * @returns {Boolean}
     */
    get loaded() {
        return this._data !== null;
    }
}

export default singleton(() => new Handbook());