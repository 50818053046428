import RestRepository from '@candybox/repository/rest.js';
import Questionnaire from '@app/questionnaire/documents/questionnaire.js';
import questionnairesMap from '@app/questionnaire/requests/questionnaire-repository.js';
import {singleton} from "@candybox/helpers";



class QuestionnaireRepository extends RestRepository
{
    view(id) {
        return this._request('view',  {id});
    }
    importExportLog(data) {
        return this._request('importExportLog',  data);
    }
    verification(id) {
        return this._request('verification',  {id});
    }
    limitedSearch(query) {
        return this._request('limitedSearch',{
            query: this._serializeQuery(query)});
    }
    limitedSearchBySchoolQuestionnaire(query) {
        return this._request('limitedSearchBySchoolQuestionnaire',{
            query: this._serializeQuery(query)});
    }
    updateInstitutionGroup(data) {
        return this._request('updateInstitutionGroup', data);
    }
    exportExcel(query) {
        return this._request('exportExcel',{
            query: this._serializeQuery(query)});
    }
    loadFiles() {
        return this._request('loadFiles');
    }

    downloadFiles(ids) {
        return this._request('downloadFiles', {ids});
    }

    deletePersonalData(data) {
        return this._request('deletePersonalData', data)
    }
}

export default singleton(() => {
    return new QuestionnaireRepository(Questionnaire, questionnairesMap());
});

