import RestRepository from '@candybox/repository/rest.js';
import SmallAdminsAnalyticsAnalytics from '@app/analytics/documents/small-admin-analytics.js';
import SmallAdminsAnalyticsMap from '@app/analytics/requests/small-admin-analytics-repository.js';
import { singleton } from '@candybox/helpers.js';

class SmallAdminAnalyticsRepository extends RestRepository
{
    getTotal(data) {
        return this._request('totalSmallAdminAnalytics', data)
    }
    getStatuses(data) {
        return this._request('statusesAnalytics', data);
    }
}

export default singleton(() => {
    return new SmallAdminAnalyticsRepository(SmallAdminsAnalyticsAnalytics, SmallAdminsAnalyticsMap());
});
