<script>
import base from "@app/core/components/page-groups/base";

export default {
    extends: base,
    props: {
        pageTitle: {
            type: String,
            required: true,
        },
        pagePages: {
            type: Array,
            default: () => [],
        }
    },
    created() {
        this.title = this.pageTitle;
        this.pages = this.pagePages;
    }
}
</script>
