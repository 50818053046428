import Document, {Attribute} from '@candybox/structures/document.js';

class OperatorTask extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            progress_status: String,
        };
    }
}

export default OperatorTask;
