import Document, { Attribute } from '@candybox/structures/document.js';
import { isFilled } from '@app/core/helpers.js';
import RelationAttribute from '@candybox/repository/relation.js';
import types from '@app/questionnaire/repositories/types.js';
import employees from "@app/employee/repositories/employees.js";
import Answer from "@app/questionnaire/documents/answer";
import InputType from "@app/questionnaire/documents/answer/inputType";

class AnswerQuestionnaireValue extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            handbook_name: String,
            value: Object,
            input_value: String,
            input_type: Attribute.object(InputType, () => new InputType(), false),
        };
    }
}

export default AnswerQuestionnaireValue;
