import Document from '@candybox/structures/document.js';


class QuestionnaireAnalytics extends Document {
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            questionnaire_id: Number,
            questionnaire_type_id: Number,
            questionnaire_status_id: Number,
            questionnaire_card_status: String,
            card_status: String,
        };
    }

}

export default QuestionnaireAnalytics;
