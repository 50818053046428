import Document from '@candybox/structures/document.js';
import {Attribute} from "@candybox/structures/document";
import Permission from "@app/user/documents/permission";

class Group extends Document
{
    attributes() {
        return {
            id: Number,
            name: String,
            permissions: Attribute.object(Permission, () => new Permission(), false)
        };
    }
}

export default Group
