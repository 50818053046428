<script>
import BaseHistory from '@app/core/components/manage/history';
import {handbookEntry} from "@app/core/formatter";

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'card';
        },
        getAttributes(){
            return {
                status: {
                    title: this.__('Статус картки'),
                    formatter: handbookEntry('card_status'),
                },
                status_by_questionnaire: {
                    title: this.__('Статус за анкетою'),
                    formatter: handbookEntry('card_status_by_questionnaire'),
                },
                bar_code: this.__('Штрих-код'),
                benefit_code: this.__('Код пільги'),
                card_code: this.__('Номер картки'),
                qr_code: this.__('QR-код'),
                questionnaire_id: this.__('Номер анкети'),
                questionnaire_type_id: this.__('Тип картки'),
                date_of_issue: this.__('Дата видачі'),
                expiry_date: this.__('Термін дії'),
            }
        }
    },
}
</script>
