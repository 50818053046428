import Document, {Attribute} from '@candybox/structures/document.js';
import Employee from "@app/employee/documents/employee";
import constants from "@app/core/constants";

class Sample extends Document
{
    attributes() {
        return {
            id: Number,
            name: String,
            rules: Array,
            status: Attribute.string(constants.SAMPLE.STATUS.PAUSED),
            is_kc: Attribute.number(0),
            is_clear_all: Attribute.number(0),
            operators: Array,
        }
    }

    get sample_name_attr() {
        return '#' + this.id + ' (' + this.name + ')';
    }
}

export default Sample;
