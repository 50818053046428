<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged"/>
</template>

<script>
import callLogs from '@app/call-center/repositories/call-logs.js';
import manage from '@app/core/mixins/manage.js';
import {datetime, handbookEntry, phoneNumber} from '@app/core/formatter.js';
import phone from "@app/call-center/components/call-center/components/phone.vue";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'phone_number',
                title: this.__('Телефон'),
                sortable: true,
                filterable: true,
                formatter: phoneNumber(),
                component: phone,
            }, {
                name: 'type',
                title: this.__('Тип'),
                sortable: true,
                filterable: 'call_type',
                formatter: handbookEntry('call_type'),
            }, {
                name: 'started_at',
                title: this.__('Почато'),
                sortable: true,
                formatter: datetime('dd MMM HH:mm'),
            }, {
                name: 'ended_at',
                title: this.__('Закінчено'),
                sortable: true,
                formatter: datetime('dd MMM HH:mm'),
            }, {
                name: 'operator_short_name',
                title: this.__('Оператор'),
                sortable: true,
                filterable: true,
            }],
            filter: {},
            select: ['*'],
            relations: [],
        }
    },
    methods: {
        getRepository() {
            return callLogs();
        },
    },
}
</script>
