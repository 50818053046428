import { ElMessage,
    ElMessageBox } from 'element-plus'
import { nop } from '@candybox/helpers.js';

export default {
    methods: {
        $info(message) {
            ElMessage({
                showClose: true,
                message,
            });
        },
        $error(message) {
            ElMessage({
                type: 'error',
                showClose: true,
                message,
            });
        },
        $warning(message) {
            ElMessage({
                type: 'warning',
                showClose: true,
                message,
            });
        },
        $success(message) {
            ElMessage({
                type: 'success',
                showClose: true,
                message,
            });
        },
        $confirm(message, done, options = {}) {
            let {
                title,
                cancelled,
                confirmButtonText,
                cancelButtonText,
                type,
                showCancelButton,
                dangerouslyUseHTMLString
            } = {
                title: this.__('Підтвердіть дію'),
                cancelled: nop,
                confirmButtonText: this.__('Добре'),
                cancelButtonText: this.__('Скасувати'),
                type: 'warning',
                showCancelButton: true,
                dangerouslyUseHTMLString: false,
                ...options
            };
            return ElMessageBox.confirm(message, title, {
                confirmButtonText,
                cancelButtonText,
                type,
                showCancelButton,
                dangerouslyUseHTMLString,
            })
                .then(done)
                .catch(cancelled);
        },
    }
}
