<template>
    <a href=""
        @click.prevent="sendFullName"
    >
        {{ fullname }}
    </a>
</template>

<script>
import {get} from "@candybox/helpers.js";
import {assign} from "lodash";
import {mapGetters} from "vuex";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            processState: 'getProcessState',
        }),
        fullname() {
            let fullname = get(this.item, 'fullName');
            if(!fullname) {
                fullname = get(this.item, 'full_name');
            }
            if(!fullname) {
                fullname = get(this.item, 'questionnaire.full_name');
            }
            return fullname;
        },
    },
    methods: {
        sendFullName() {
            const {firstName, lastName, middleName, phoneNumber} = this.getFullNames(this.item);
            this.processState.processLog.first_name = firstName;
            this.processState.processLog.last_name = lastName;
            this.processState.processLog.middle_name = middleName;
            this.processState.phoneNumber = phoneNumber;
        },
        getFullNames(item) {
            let firstName = get(item, 'first_name');
            if(!firstName) {
                firstName = get(item, 'questionnaire.first_name');
            }
            let lastName = get(item, 'last_name');
            if(!lastName) {
                lastName = get(item, 'questionnaire.last_name');
            }
            let middleName = get(item, 'middle_name');
            if(!middleName) {
                middleName = get(item, 'questionnaire.middle_name');
            }
            let phoneNumber = get(item, 'contact_number');
            if(!phoneNumber) {
                phoneNumber = get(item, 'questionnaire.contact_number');
            }
            return {
                firstName,
                lastName,
                middleName,
                phoneNumber,
            };
        }
    }
}
</script>
