<template>
    <div class="pl-15">
        <el-button
            v-if="$can('questionnaire.download')"
            :loading="allowExport"
            @click="download">
            {{ __('Вивантаження файлів для ХП') }}
        </el-button>
        <el-button
            v-if="$can('questionnaire.download')"
            @click="importXP">
            {{ __('Завантажити відповіді з ХП') }}
        </el-button>
    </div>
</template>

<script>
import questionnaires from '@app/questionnaire/repositories/questionnaires.js';
import sampleRepostories from '@app/sample/repositories/sample.js';
import formLayout from '@app/questionnaire/components/questionnaires/form.vue';
import manage from '@app/core/mixins/manage.js';
import Questionnaire from '@app/questionnaire/documents/questionnaire.js';
import {phoneNumber, date, handbookEntry} from '@app/core/formatter.js';
import types from "@app/questionnaire/repositories/types.js";
import history from '@app/questionnaire/components/questionnaires/history.vue';
import constants from "@app/core/constants";
import {forEach, get, isArray, isObject} from "@candybox/helpers";
import fileLoader from "@app/services/file-loader";
import formImport from "@app/questionnaire/components/mixins/formImport";
import FormImport from "@app/questionnaire/components/questionnaires/form-import.vue";
import search from "@app/questionnaire/components/questionnaires/search";
import showCards from "@app/questionnaire/components/mixins/showCard";
import sample from "@app/questionnaire/components/questionnaires/sample.vue";
import exported from "@app/questionnaire/components/questionnaires/exported.vue";
import Query from "@candybox/query/query";
import setRules from "@app/sample/mixins/setRules";
import getEmployeesMixin from "@app/employee/components/employees/mixins/getEmployeesMixin.js";
import {each} from "lodash";

export default {
    mixins: [
        formImport,
    ],
    data() {
        return {
            allowExport: false,
        }
    },
    methods: {
        getRepository() {
            return questionnaires();
        },
        download() {
            this.getRepository().importExportLog(['export','Експорт файлів для ХП']);
            this.allowExport = true
            fileLoader.get('/api/questionnaires/download').then((blobUrl) => {
                let link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'export.zip')
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.allowExport = false
            });
        },
        importXP() {
            this.getRepository().importExportLog(['import','Завантаження відповіді з ХП']);
            this.importCsv();
        },
        getImportCsvComponent() {
            return FormImport;
        },
    },
    beforeDestroy() {
        fileLoader.revokeAll();
    },
}
</script>
