import FileViewer from '@app/core/components/general/FileViewer.vue';
import {FileLoader} from '@app/services/file-loader';
import {join} from "lodash/array.js";

const validFormats = ['text/plain', 'application/pdf', 'image/jpeg', 'image/png'];

export default {
    created() {
        this.fileLoader = new FileLoader()
    },
    beforeDestroy() {
        this.fileLoader.revokeAll();
    },
    methods: {
        prepareView(file, title) {
            const msgArr = [
                'Завантажте фото.',
                'Зверніть увагу,що фото має бути гарної якості, крупним планом.',
                'На фотографії мають буди відсутні печатки, надписи та числа.',
                'Розташуйте фото в рамці та переконайтесь, що обличчя гарно видно.'
            ];
            this.$confirm(join(msgArr, '<br>'), () => {
                this.view(file, title);
            }, {
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
                cancelled: () => {
                    this.clear();
                }
            });
        },
        view(file, title = '', fileData = {}, enableAddon = true) {
            this.$modalComponent(FileViewer, {file, data: fileData},
                {
                    cancel: (dialog) => {
                        this.clear();
                        dialog.close();
                    },
                    crop: (dialog, data) => {
                        this.crop(data);
                        dialog.close();
                    },
                }, {
                    title,
                    width: '500px',
                })
        },
        clear() {
            //
        },
        crop(data) {
            console.log('replace crop image and save')
        },
        download(url, name = 'file') {
            this.fileLoader.get(url).then((blobUrl) => {
                let link = document.createElement('a');
                link.href = blobUrl;
                link.download = name;
                link.click();
            });
        },
        isValidType(type) {
            return validFormats.indexOf(type) !== -1;
        },
        errorFileFormat() {
            return this.$error(__('Измените формат файла'));
        },
        selectAndView(files) {
            if (files.length === 1) {
                if (this.isValidType(files[0].type)) {
                    this.prepareView(files[0].url, files[0].name);
                } else {
                    this.download(files[0].url, files[0].name);
                }
            } else {
                this.$warning(__('Нет файлов доступных для просмотра'));
            }
        }
    }
}
