<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Назва')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name" />
                </form-row>
                <form-row
                :label="__('Дата вступу')"
                :errors="$errors['birth_date']"
                required>
                <el-date-picker
                    v-model="doc.entry_year"
                    type="year"
                    format="YYYY"
                    :disabled-date="disabledDate"/>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Менеджер навчальної групи')"
                    :errors="$errors['manager_id']"
                    required>
                    <options-provider
                        :source="getManager()"
                        value-prop="description">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.manager_id">
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
                <form-row
                    :label="__('Навчальний заклад')"
                    :errors="$errors['institution_id']"
                    required>
                    <options-provider
                        :source="getInstitution()"
                        value-prop="description">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.institution_id"
                                filterable
                                placeholder="Оберіть"
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import Query from '@candybox/query/query.js';
import employees from "@app/employee/repositories/employees";
import institution from "@app/institution/repositories/institutions";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    computed: {
        currentManager: {
            get() {
                return this.doc.$manager.$;
            },
            set(val) {
                return this.doc.$manager = val;
            },
        },
        currentInstitution: {
            get() {
                return this.doc.$institution.$;
            },
            set(val) {
                return this.doc.$institution = val;
            },
        },
    },
    data() {
        return {
            employees: null,
        }
    },
    methods: {
        getManager() {
            return employees()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.employees = items.map((emp) => ({
                        id: emp.id,
                        value: emp.full_name,
                    }));
                    this.updateCurrentManager(this.doc.manager_id);
                    return this.employees;
                });
        },
        updateCurrentManager(id) {
            this.currentManager = this.employees.find((emp) => {
                return emp.id === id;
            });
        },
        getInstitution() {
            return institution()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.institutions = items.map((inst) => ({
                        id: inst.id,
                        value: inst.name,
                    }));
                    this.updateCurrentInstitution(this.doc.institution_id);
                    return this.institutions;
                });
        },
        updateCurrentInstitution(id) {
            this.currentInstitution = this.institutions.find((inst) => {
                return inst.id === id;
            });
        },
        disabledDate(time) {
            let maxDate = new Date();
            let minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 12);
            return (time <= minDate || time >= maxDate);
        }
    },
    watch: {
        ['doc.manager_id'](id) {
            this.updateCurrentManager(id);
        },
    }
}
</script>
