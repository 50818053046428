<template>
    <div
        v-loading="loading"
        class="data-table" :class="flex ? 'flex-column' : 'rigid-column'">
        <div
            ref="tableContainer"
            class="data flex-column">
            <el-table
                ref="tableInstance"
                :data="items"
                :highlight-current-row="selectable"
                border
                :height="height"
                style="min-width: 100%"
                @current-change="selectionChanged"
                @sort-change="sortChanged"
                :row-class-name="getRowClassName"
            >
                <template v-for="(column, index) in columns">
                    <el-table-column
                        v-if="column.visible !== false"
                        :key="index"
                        :prop="column.name"
                        :width="column.width"
                        :sortable="column.sortable ? 'custom' : false">
                        <template v-slot:header>
                            <span class="column-title">{{ column.title }}</span>
                            <header-filter
                                v-if="column.filterable"
                                :source="column.filterable"
                                v-bind="column.filterOption || {}"
                                v-model="filter[column.filterProp || column.name]" />
                        </template>
                        <template v-slot:default="scope">
                            <template v-if="column.component">
                                <component
                                    :is="column.component"
                                    :item="scope.row"
                                    :column="column"
                                    v-bind="column.componentProps || {}"
                                    v-on="column.componentEvents || {}" />
                            </template>
                            <template v-else>
                                {{ formatRow(scope.row, column) }}
                            </template>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
        </div>
        <div
            v-if="withProperties"
            class="footer rigid-row"
        >
            <div class="controls flex-expand">
                <slot name="controls" />
            </div>
            <div class="pages rigid-row">
                <div class="total">
                    {{ __('Всього') }}: {{ total }}
                </div>
                <el-pagination
                    v-model:currentPage="page"
                    hide-on-single-page
                    :page-size="perPage"
                    small
                    background
                    layout="prev, pager, next"
                    :total="total" />
                <div class="settings">
                    <el-popover
                        v-model:visible="showSettings"
                        placement="top"
                        :title="__('Налаштування')"
                        :width="250">
                        <table-settings
                            :columns="columns"
                            :size="perPage"
                            @done="updateSettings"
                            @cancel="hideSettings" />
                        <template v-slot:reference>
                            <svg-icon
                                name="settings-grey"
                                class="icon-grey icon-hover"
                                @click="toggleSettings" />
                        </template>
                    </el-popover>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Query from '@candybox/query/query.js';
import { get,
    forEach,
    isArray,
    isObject } from '@candybox/helpers.js'
import headerFilter from '@app/core/components/table/filter.vue';
import tableSettings from '@app/core/components/table/settings.vue';
import { isFilled } from '@app/core/helpers.js';
import { Relation } from '@candybox/repository/relation.js';

export default {
    components: {
        headerFilter,
        tableSettings,
    },
    props: {
        repository: {
            type: Object,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        criteria: {
            type: [Object, Function],
            default: () => ({}),
        },
        select: {
            type: Array,
            default: () => ['*'],
        },
        selectable: {
            type: Boolean,
            default: true,
        },
        flex: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: () => ({}),
        },
        relations: {
            type: [String, Array, Function],
            default: null,
        },
        defaultSort: {
            type: Object,
        },
        defaultHeight: {
            type: String,
            default: null,
        },
        withProperties: {
            type: Boolean,
            default: true,
        },
        rowClassPredicate: {
            type: Function,
            default: null,
        }
    },
    data() {
        return {
            page: 1,
            count: 0,
            items: Object.freeze([]),
            perPage: 50,
            loading: false,
            sort: this.defaultSort,
            total: 0,
            height: undefined,
            showSettings: false,
        };
    },
    mounted() {
        if (this.flex) {
            this.height = this.$refs.tableContainer.scrollHeight;
        } else if(this.defaultHeight) {
            this.height = this.defaultHeight;
        }
        this.fetchData();
    },
    methods: {
        fetchData(resetCount = true) {
            this.loading = true;
            this.repository.search(this.makeQuery())
                .then((data) => {
                    return this.fetchRelations(data).then(() => {
                        this.items = Object.freeze(data.map((item) => {
                            return Object.freeze(item);
                        }));
                        if (resetCount) {
                            if (this.items.length < this.perPage) {
                                this.total = (this.page - 1) * this.perPage + this.items.length;
                            } else {
                                this.fetchCount();
                            }
                        }
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchCount() {
            this.repository.count(this.makeQuery(true))
                .then((result) => {
                    this.total = result;
                });
        },
        fetchRelations(result) {
            if (this.relations !== null) {
                return Relation.resolve(result, this.relations);
            }
            return Promise.resolve();
        },
        refresh() {
            this.fetchData();
        },
        makeQuery(forCount = false) {
            let query = (new Query)
                .where(this.criteria);
            this.addFilterCondition(query);

            if (!forCount) {
                if (this.select.length !== 0) {
                    query.select(...this.select);
                }
                this.addSortOrder(query);
                query
                    .startFrom((this.page - 1) * this.perPage)
                    .limitTo(this.perPage);
            }

            return query;
        },
        addFilterCondition(query) {
            return query.where((cond) => {
                forEach(this.filter, (val, key) => {
                    if (isFilled(val)) {
                        if (isArray(val)) {
                            cond.in(key, val);
                        } else if (isObject(val)) {
                            if (val.mode === '=') {
                                cond.eq(key, val.query);
                            } else if (val.mode === '~') {
                                cond.startsWith(key, val.query);
                            } else if (val.mode === '*') {
                                cond.contains(key, val.query);
                            } else if (val.mode === '>=') {
                                cond.gte(key, val.query);
                            } else if (val.mode === '<=') {
                                cond.lte(key, val.query);
                            } else if (val.mode === '!=') {
                                cond.neq(key, val.query);
                            }
                        } else {
                            cond.eq(key, val);
                        }
                    }
                });
            });
        },
        addSortOrder(query) {
            if (this.sort) {
                if (this.sort.order === 'descending') {
                    query.descendingBy(this.sort.column);
                } else {
                    query.ascendingBy(this.sort.column);
                }
            }
        },
        selectionChanged(row) {
            this.$emit('selection-changed', row);
        },
        sortChanged({order, prop}) {
            if (prop === null) {
                this.sort = null;
            } else {
                let col = this.getColumnConfig(prop);
                this.sort = col ? {
                    column: col.sortProp || col.name,
                    order,
                } : null;
            }
        },
        formatRow(row, column) {
            return column.formatter
                ? column.formatter(get(row, column.name), row, column)
                : get(row, column.name);
        },
        getColumnConfig(name) {
            return this.columns.find((col) => col.name === name);
        },
        toggleSettings() {
            this.showSettings = !this.showSettings;
        },
        hideSettings() {
            this.showSettings = false;
        },
        updateSettings({size, columns}) {
            this.showSettings = false;
            if (size !== this.perPage) {
                this.perPage = size;
                this.page = 1;
                this.refresh();
            }
            this.columns.forEach((col) => {
                col.visible = columns.indexOf(col.name) !== -1;
            });
        },
        getPageData () {
            return this.items || [];
        },
        getColumns () {
            return this.columns || [];
        },
        getRowClassName({row}) {
            if (this.rowClassPredicate === null) {
                return null;
            }

            switch (this.rowClassPredicate(row)) {
                case 'error': return 'error-row';
                default: return null;
            }
        }
    },
    watch: {
        filter: {
            handler() {
                this.page = 1;
                this.fetchData();
            },
            deep: true,
        },
        sort() {
            this.fetchData(false);
        },
        page() {
            this.fetchData(false);
        },
    },
}
</script>
