<template>
    <section class="dialog-body">
        <el-row
            class="form-block"
            :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Внутрішня назва')"
                    :errors="$errors['inner_name']"
                    required>
                    <el-input v-model="doc.inner_name" />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Відображати')"
                    :errors="$errors['is_show']"
                    required
                >
                    <options-provider
                        source="yes_no">
                        <template v-slot:default="data">
                            <el-radio-group
                                v-model="doc.is_show"
                                :disabled="disableChangeShowing"
                            >
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Номер')"
                    :errors="$errors['order']"
                    required>
                    <el-input v-model="doc.order" />
                </form-row>
            </el-col>

            <el-col :span="8"
                v-if="isCheckboxSet"
            >
                <form-row
                    :label="__('З мультивибором')"
                    :errors="$errors['is_multiple']"
                >
                    <options-provider
                        source="yes_no">
                        <template v-slot:default="data">
                            <el-radio-group v-model="doc.is_multiple">
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>

            <el-col :span="8">
                <form-row
                    :label="__('Обов\'язкове')"
                    :errors="$errors['is_required']"
                >
                    <options-provider
                        source="yes_no">
                        <template v-slot:default="data">
                            <el-radio-group v-model="doc.is_required">
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>

            <el-col :span="24">
                <form-row
                    :label="__('Назва')"
                    :errors="$errors['label']"
                    required
                >
                    <el-input
                        v-model="doc.label"
                        type="textarea"
                        resize="none"
                    />
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import types from "@app/questionnaire/repositories/types";
import blocks from "@app/questionnaire-constructor/repositories/blocks";
import subblocks from "@app/questionnaire-constructor/repositories/subblocks";
import answers from "@app/questionnaire-constructor/repositories/answers";
import {get} from '@candybox/helpers';
import Query from "@candybox/query/query";
import constants from "@app/core/constants.js";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isCheckboxSet() {
            return get(this.doc, 'view_type') === constants.QUESTION_VIEW_TYPE.CHECKBOX_SET;
        },
        disableChangeShowing() {
            return get(this.doc, 'is_required') === 1;
        }
    },
    watch: {
        ['doc.is_required'](val) {
            if(val) {
                this.doc.is_show = 1;
            }
        }
    }
}
</script>
