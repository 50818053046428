import Document from '@candybox/structures/document.js';
import RelationAttribute from "@candybox/repository/relation";
import employees from "@app/employee/repositories/employees";
import {isFilled} from "@app/core/helpers";

class Glossary extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
     attributes() {
        return {
            id: Number,
            type: String,
            name: String,
            code: String,
            created_at: Date,
            employee_id: Number,
            $author:  RelationAttribute.oneToOne(employees(), 'employee_id'),
        };
    }
    get full_name() {
        return [
            this.last_name,
            this.first_name,
            this.middle_name,
        ]
            .filter(isFilled)
            .join(' ');
    }
}

export default Glossary;
