import groups from "@app/user/repositories/groups";
import Query from "@candybox/query/query";
import {isObject} from "lodash";
export default {
    data() {
        return {
            groupsData: [],
            loadingPermissionGroup: false,
        }
    },
    methods: {
        groupsHandler(data) {
            data.forEach((group) => {
                this.groupsData.push(this.getGroupObj(group))
            })
        },
        getGroupObj(data) {
            const obj = {};
            obj.label = data.name;
            obj.permissions = [];
            const permission = data.permissions;
            let key = Object.keys(permission);
            key.forEach((item) => {
                if (!isNaN(item)) {
                    obj.permissions.push({
                        id: permission[item].id,
                        name: permission[item].description
                    })
                }
            })
            return obj;
        },
        loadPermissionGroups() {
            this.loadingPermissionGroup = true;
            groups()
                .search(new Query())
                .then((data) => {
                    this.groupsHandler(data);
                    this.loadingPermissionGroup = false;
                })
                .catch((e) => {
                    console.log(e);
                })
        }
    }
}
