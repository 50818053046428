<template>
    <section class="dialog-body">
        <el-row
            class="form-block"
            :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Внутрішня назва')"
                    :errors="$errors['inner_name']"
                    required>
                    <el-input v-model="doc.inner_name" />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Відображати')"
                    :errors="$errors['is_show']"
                    required
                >
                    <options-provider
                        source="yes_no">
                        <template v-slot:default="data">
                            <el-radio-group v-model="doc.is_show">
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Номер')"
                    :errors="$errors['order']"
                    required>
                    <el-input v-model="doc.order" />
                </form-row>
            </el-col>
            <el-col :span="24">
                <form-row
                    :label="__('Тип відображення')"
                    :errors="$errors['view_type']"
                    required
                >
                    <options-provider
                        source="answer_view_type"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.view_type"
                                :loading="data.loading"
                                :disabled="disableChangeViewType"
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>



            <el-col :span="24"
                v-if="isCheckboxSet"
            >
                <form-row
                    :label="__('Тип шаблону')"
                >
                    <options-provider
                        :source="checkboxesLayouts"
                    >
                        <template v-slot:default="data">
                            <el-radio-group v-model="selectedCheckboxesLayout">
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>

            <el-col :span="8"
                v-if="isCheckboxSet && !isLay3"
            >
                <form-row
                    :label="__('Префікс')"
                    :errors="$errors['prefix']"
                >
                    <el-input
                        v-model="doc.prefix"
                        type="textarea"
                        resize="none"
                    />
                </form-row>
            </el-col>
            <el-col :span="inputTypeFraction"
                v-if="isSimple || (isCheckboxSet && isLay1)"
            >
                <form-row
                    :label="__('Тип поля вводу')"
                    :errors="$errors['order']"
                >
                    <options-provider
                        source="answer_input_type"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="inputType"
                                :loading="data.loading"
                                :disabled="isCheckboxSet"
                                clearable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8"
                v-if="isCheckboxSet && !isLay3"
            >
                <form-row
                    :label="__('Постфікс')"
                    :errors="$errors['suffix']"
                >
                    <el-input
                        v-model="doc.suffix"
                        type="textarea"
                        resize="none"
                    />
                </form-row>
            </el-col>

            <el-col :span="handbookFraction"
               v-if="isDropdown || (isCheckboxSet && !isLay1)"
            >
                <form-row
                    :label="__('Словник')"
                    :errors="$errors['handbook_name']"
                >
                    <options-provider
                        source="value_for_questionnaires"
                        :item-builder="glossaryNameBuilder()"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.handbook_name"
                                :loading="data.loading"
                                clearable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="12"
                v-if="isCheckboxSet && isLay3"
            >
                <form-row
                    :label="__('Елемент словника')"
                    :errors="$errors['glossary_id']"
                >
                    <options-provider
                        :source="getGlossaries()"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.glossary_id"
                                :loading="data.loading"
                                :disabled="!doc.handbook_name"
                                clearable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>

            <el-col :span="24">
                <form-row
                    :label="__('Питання')"
                    :errors="$errors['questions']"
                >
                    <options-provider
                        :source="getQuestions()"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.questions_to_save"
                                :loading="data.loading"
                                clearable
                                multiple
                                filterable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"
                                >
                                    <span
                                        :title="item.parentsPath"
                                    >
                                        {{ item.value }}
                                    </span>
                                </el-option>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import glossary from "@app/handbook/repositories/glossary.js";
import Query from "@candybox/query/query.js";
import { map, get, set, isNull } from "lodash";
import questions from "@app/questionnaire/repositories/questions.js";
import constants from "@app/core/constants.js";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currentGlossary: null,
            glossariesNames: [],
            checkboxesLayouts: [
                {
                    id: 'lay_1',
                    value: 'Тип 1',
                },
                {
                    id: 'lay_2',
                    value: 'Тип 2',
                },
                {
                    id: 'lay_3',
                    value: 'Тип 3',
                },
            ],
            selectedCheckboxesLayout: 'lay_1',
        }
    },
    computed: {
        isSimple() {
            return get(this.doc, 'view_type') === constants.ANSWER_VIEW_TYPE.SIMPLE;
        },
        isDropdown() {
            return get(this.doc, 'view_type') === constants.ANSWER_VIEW_TYPE.DROPDOWN;
        },
        isCheckboxSet() {
            return get(this.doc, 'view_type') === constants.ANSWER_VIEW_TYPE.CHECKBOX_SET;
        },
        inputType: {
            get() {
                return get(this.doc, 'input.type.name');
            },
            set(value) {
                set(this.doc, 'input.type.name', value);
            }
        },
        disableChangeViewType() {
            return !isNull(get(this.doc, 'id', null));
        },
        isLay1() {
            return this.selectedCheckboxesLayout === 'lay_1';
        },
        isLay2() {
            return this.selectedCheckboxesLayout === 'lay_2';
        },
        isLay3() {
            return this.selectedCheckboxesLayout === 'lay_3';
        },
        inputTypeFraction() {
            return this.isSimple ? 12 : 8;
        },
        handbookFraction() {
            return this.isCheckboxSet && this.isLay2 ? 8 : 12;
        },
    },
    methods: {
        glossaryNameBuilder() {
            return (item) => ({
                id: item.key,
                value: item.value
            });
        },
        getGlossaries() {
            return glossary()
                .search(
                    (new Query)
                        .where('type', '=', this.doc.handbook_name)
                )
                .then((items) => {
                    return map(items, (item) => ({
                        id: item.id,
                        value: item.name,
                    }));
                });
        },
        getQuestions() {
            return questions()
                .search(
                    (new Query)
                        .where('view_type', '=', this.doc.view_type)
                )
                .then((items) => {
                    const items_ = map(items, (item) => ({
                        id: item.id,
                        value: `${item.inner_name ? item.inner_name : 'Без назви'} (${item.parent_path})`,
                        parentsPath: item.parent_path,
                    }));

                    return items_;
                });
        },
    },
    watch: {
        isCheckboxSet(val) {
            this.inputType = val ? constants.QUESTION_VALUE_TYPE.VALUE_TYPE_STRING : null;
        }
    }
}
</script>
