import RepositoryRequestMap from '@candybox/repository/request-map.js';
import {validator} from '@candybox/validation/validator.js';
import {singleton, get} from '@candybox/helpers.js';
import {phoneNumber, isEqual, pensionNumber, ukrainianLanguageValidator, properCaseValidator} from '@app/core/validation.js';
import constants from '@app/core/constants.js';
import {Method} from "@candybox/transport/request";
import {translator} from "@app/core/services/translation.js";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/questionnaires');

    mapping.validation('store', () => {
        return {
            questionnaire_type_id: validator()
                .required(),

            has_plastic: validator()
                .when((attr, data) => {
                    const hasValidType = get(data, 'questionnaire_type_id') === constants.QUESTIONNAIRE_TYPE.PUPIL
                        || get(data, 'status.code') !== constants.QUESTIONNAIRE_STATUS.REJECT;

                    return get(data, attr) === "0" && get(data, 'has_digital') === "0" && hasValidType;
                }, (chain) => {
                    chain.custom(isEqual, {equalValue: '0', message: "Має бути обраний хоча б один вид картки"});
                }),

            has_digital: validator()
                .when((attr, data) => {
                    const hasValidType = get(data, 'questionnaire_type_id') === constants.QUESTIONNAIRE_TYPE.PUPIL
                        || get(data, 'status.code') !== constants.QUESTIONNAIRE_STATUS.REJECT;

                    return get(data, attr) === "0" && get(data, 'has_plastic') === "0" && hasValidType;
                }, (chain) => {
                    chain.custom(isEqual, {equalValue: '0', message: "Має бути обраний хоча б один вид картки"});
                }),

            first_name: validator()
                .length(3,100)
                .custom(ukrainianLanguageValidator)
                .custom(properCaseValidator),
            last_name: validator()
                .length(3,100)
                .custom(ukrainianLanguageValidator)
                .custom(properCaseValidator),
            middle_name: validator()
                .length(3,100)
                .custom(ukrainianLanguageValidator)
                .custom(properCaseValidator),

            birth_state: validator()
                .length(0, 100),
            birth_region: validator()
                .length(0, 100),
            birth_district: validator()
                .length(0, 100),
            birth_locality: validator()
                .length(0, 100),

            registration_state: validator()
                .length(0, 255),
            registration_region: validator()
                .length(0, 100),
            registration_district: validator()
                .length(0, 100),
            registration_locality: validator()
                .length(0, 100),
            registration_street: validator()
                .length(0, 100),
            registration_house: validator()
                .length(0, 25),
            registration_building: validator()
                .length(0, 20),
            registration_apartment: validator()
                .length(0, 25),
            registration_room: validator()
                .length(0, 25),

            residential_state: validator()
                .length(0, 255),
            residential_region: validator()
                .length(0, 100),
            residential_district: validator()
                .length(0, 100),
            residential_locality: validator()
                .length(0, 100),
            residential_street: validator()
                .length(0, 100),
            residential_house: validator()
                .length(0, 100),
            residential_building: validator()
                .length(0, 100),
            residential_apartment: validator()
                .length(0, 100),
            residential_room: validator()
                .length(0, 100),

            passport_series: validator()
                .when((attr, data) => {
                    return !get(data, 'passport_id') && get(data, 'questionnaire_type_id') !== constants.QUESTIONNAIRE_TYPE.PUPIL;
                }, (chain) => {
                    chain.length(2);
                }),
            passport_number: validator()
                .when((attr, data) => {
                    return !get(data, 'passport_id') && get(data, 'questionnaire_type_id') !== constants.QUESTIONNAIRE_TYPE.PUPIL;
                }, (chain) => {
                    chain.integer()
                        .length(6);
                }),
            passport_date: validator()
                .when((attr, data) => {
                    return data[attr] && data[attr] <= data.birth_date;
                }, (chain) => {
                    chain.custom(() => {
                        return translator().translate('Дата відачі паспорту потрібна бути більша, ніж дата народження');
                    });
                }),
            passport_issue: validator()
                .when((attr, data) => {
                    return !get(data, 'passport_id') && get(data, 'questionnaire_type_id') !== constants.QUESTIONNAIRE_TYPE.PUPIL;
                }, (chain) => {
                    chain.length(0, 400);
                }),

            passport_id: validator()
                .when((attr, data) => {
                    return !get(data, 'passport_number') && get(data, 'questionnaire_type_id') !== constants.QUESTIONNAIRE_TYPE.PUPIL;
                }, (chain) => {
                    chain.integer()
                        .length(9);
                }),
            passport_id_issue: validator()
                .when((attr, data) => {
                    return !get(data, 'passport_number') && get(data, 'questionnaire_type_id') !== constants.QUESTIONNAIRE_TYPE.PUPIL;
                }, (chain) => {
                    chain.integer()
                        .length(4);
                }),

            rnokpp: validator()
                .integer()
                .length(10),

            operator_note: validator()
                .length(0, 512),

            additional_number: validator()
                .custom(phoneNumber),
            communication_ways: validator(),
            reasons: validator(),
            other_services: validator()
                .length(0, 200),

            has_wish_children: validator()
                .integer(),

            pension_number: validator()
                .length(0, 15),
            is_working_pensioner: validator(),


            educational_institution: validator()
                .integer(),
            class_number: validator()
                .integer(),
            transport: validator(),
            paid_foreign_languages: validator(),

            act_of_acceptance: validator()
              .length(0, 100),

            skills: validator(),
            sport_sections: validator(),

            is_live_in_city: validator(),
            faculty: validator()
                .length(0, 400),
            course_number: validator()
                .integer(),
            living_type: validator()
                .integer(),

            family_status: validator()
                .length(0, 255),

            is_agree: validator()
                .custom(isEqual, {equalValue: '0'}),

            issue_condition_id: validator()
              .when((attr, data) => {
                  return get(data, 'has_chip') !== '1';
              }, (chain) => {
                  chain.integer()
                    .required();
              }),
        }
    });

    mapping.map('view', {
        route: '/api/questionnaires/:id/view',
        method: Method.GET,
    });
    mapping.map('verification', {
        route: '/api/questionnaires/:id/verification',
        method: Method.GET,
    });

    mapping.map('limitedSearch', {
        route: '/api/questionnaires/limitedSearch',
        method: Method.POST,
    });
    mapping.map('limitedSearchBySchoolQuestionnaire', {
        route: '/api/questionnaires/limitedSearchBySchoolQuestionnaire',
        method: Method.POST,
    });
    mapping.map('updateInstitutionGroup', {
        route: '/api/questionnaires/updateInstitutionGroup',
        method: Method.POST,
    });
    mapping.map('exportExcel', {
        route: '/api/questionnaires/exportExcel',
        method: Method.POST,
    });
    mapping.map('loadFiles', {
        route: '/api/questionnaires/loadFiles',
        method: Method.GET,
    });
    mapping.map('importExportLog', {
        route: '/api/questionnaires/importExportLog',
        method: Method.POST,
    });

    mapping.map('downloadFiles', {
        route: '/api/questionnaires/download-orders',
        method: Method.POST,
    });

    mapping.map('deletePersonalData', {
        route: '/api/questionnaires/delete-personal-data',
        method: Method.POST,
        validation: {
            statement_id: validator()
                .required(),
        }
    })

    return mapping;
});
