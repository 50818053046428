<template>
    <section class="dialog-body">
        <form-row
            :label="__('Назва')"
            :errors="$errors['name']"
            required>
            <el-input v-model="doc.name" />
        </form-row>
        <form-row
            :label="__('Повноваження')"
            :errors="$errors['permissions']"
        >
            <el-select
                :loading="loadingPermissionGroup"
                multiple
                collapse-tags
                v-model="doc.permissions">
                <el-option-group
                    v-for="group in groupsData"
                    :key="group.label"
                    :label="group.label"
                >
                    <el-option
                        v-for="item in group.permissions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-option-group>
            </el-select>
        </form-row>
        <form-row
            class="inline inline-between"
            :label="__('Є доступ до телефонії')"
            :errors="$errors['has_voip']">
            <el-switch v-model="doc.has_voip" />
        </form-row>
        <form-row
            class="inline inline-between"
            :label="__('Задіяти розклад')"
            :errors="$errors['has_schedule']">
            <el-switch v-model="doc.has_schedule" />
        </form-row>
    </section>

</template>

<script>
import permissionGroups from "@app/user/components/mixins/permission-groups";
export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        permissionGroups
    ],
    data() {
        return {
            permissions:null,
        }
    },
    created() {
        this.loadPermissionGroups();
    }
}
</script>
