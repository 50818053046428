import App from '@candybox/app.js';
import login from '@app/user/components/login.vue';
import userModule from './store.js';
import roleList from '@app/user/components/role/table.vue';


App.boot(({router, store}) => {
    router.addRoute({
        component: login,
        path: '/auth/login',
        name: 'login',
    });
    router.addRoute('resources', {
        component: roleList,
        path: 'role',
        name: 'role',
    });
    store.registerModule('user', userModule);
});
