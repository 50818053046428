import { get } from "@candybox/helpers";

export default {
    methods: {
        getDefaultByType(question) {
            const fieldType = get(question, 'field_type');
            switch (fieldType) {
                case 'string': return '';
                case 'integer': return 0;
                case 'array':
                case 'json': return [];

                default: return null;
            }
        }
    }
}
