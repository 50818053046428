import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import {get, singleton} from '@candybox/helpers.js';
import { isFilled } from '@app/core/helpers.js';
import { phoneNumber,
    sipNumber } from '@app/core/validation.js';
import constants from "@app/core/constants";
import {Method} from "@candybox/transport/request";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/employees');

    mapping.validation('store', () => {
        return {
            first_name: validator()
                .required()
                .length(0, 100),
            middle_name: validator()
                .length(0, 100),
            last_name: validator()
                .required()
                .length(0, 100),
            contact_number: validator()
                .required()
                .custom(phoneNumber),
            email: validator()
                .required()
                .email()
                .length(0, 100),
            experience: validator()
                .length(0, 50),
            notes: validator()
                .length(0, 5000),
            position_id: validator()
                .required(),
            status: validator()
                .required(),
            sip: validator()
                .when((attr, data) => {
                    return get(data, 'has_voip');
                }, (chain) => {
                    chain.required();
                })
                .custom(sipNumber),
            sip_password: validator()
                .when((attr, data) => {
                    return get(data, 'has_voip');
                }, (chain) => {
                    chain.required();
                }),
            user: validator()
                .nested({
                    login(chain) {
                        chain.length(0, 50);
                    },
                    rnokpp(chain) {
                        chain.required()
                            .length(0, 10);
                    },
                    roles(chain) {
                        chain.required();
                    },
                }),
        }
    });
    mapping.map('limitedSearch', {
        route: '/api/employees/limitedSearch',
        method: Method.POST,
    });
    return mapping;
});
