<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged"/>
</template>

<script>
import callLogs from '@app/call-center/repositories/call-logs.js';
import manage from '@app/core/mixins/manage.js';
import {datetime, handbookEntry, phoneNumber} from '@app/core/formatter.js';
import phone from "../call-center/components/phone.vue";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'phone_number',
                title: this.__('Телефон'),
                sortable: true,
                filterable: true,
                formatter: phoneNumber(),
                component: phone,
            }, {
                name: 'started_at',
                title: this.__('Дата'),
                sortable: true,
                filterable: true,
                formatter: datetime('dd MMM HH:mm'),
            }, {
                name: 'status',
                title: this.__('Статус'),
                formatter: handbookEntry('call_log_status'),
            }],
            filter: {
                status: 'abandoned',
            },
            select: ['*'],
            relations: [],
        }
    },
    methods: {
        getRepository() {
            return callLogs();
        },
    },
}
</script>
