<script>
import BaseHistory from '@app/core/components/manage/history.vue';
import {
    date,
    phoneNumber,
    handbookEntry
} from '@app/core/formatter.js';
import RelationAttribute from "@candybox/repository/relation";
import types from "@app/questionnaire/repositories/types";
import employees from "@app/employee/repositories/employees";

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'questionnaire';
        },
        getAttributes() {
            return {
                last_name: this.__('Ім’я'),
                first_name: this.__('Прізвище'),
                middle_name: this.__('По батькові'),
                family_status: this.__('Сімейний статус'),
                birth_date: this.__('Дата народження'),

                birth_state: this.__('Країна народження'),
                birth_region: this.__('Область народження'),
                birth_district: this.__('Район народження'),
                birth_locality: this.__('Населений пункт народження'),
                rnokpp: this.__('ІПН'),

                registration_state: this.__('Країна реєстрації'),
                registration_region: this.__('Область реєстрації'),
                registration_district: this.__('Район реєстрації'),
                registration_locality: this.__('Населений пункт реєстрації'),
                registration_street: this.__('Вулиця реєстрації'),
                registration_house: this.__('Будинок реєстрації'),
                registration_building: this.__('Корпус реєстрації'),
                registration_apartment: this.__('Квартира реєстрації'),
                registration_room: this.__('Кімната реєстрації'),

                residential_state: this.__('Країна реєстрації'),
                residential_region: this.__('Область реєстрації'),
                residential_district: this.__('Район реєстрації'),
                residential_locality: this.__('Населений пункт реєстрації'),
                residential_street: this.__('Вулиця реєстрації'),
                residential_house: this.__('Будинок реєстрації'),
                residential_building: this.__('Корпус реєстрації'),
                residential_apartment: this.__('Квартира реєстрації'),
                residential_room: this.__('Кімната реєстрації'),

                contact_number: {
                    title: this.__('Номер телефону'),
                    formatter: phoneNumber(),
                },
                additional_number: {
                    title: this.__('Номер додаткового телефону'),
                    formatter: phoneNumber(),
                },
                communication_ways: this.__('Спосіб комунікації'),
                email: this.__('Email'),
                passport_series: this.__('Серія паспорту'),
                passport_number: this.__('Номер паспорту'),
                passport_date: this.__('Дата видачі паспорту'),
                passport_issue: this.__('Ким віданий'),
                passport_id: this.__('Номер id паспорту'),
                passport_id_issue: this.__('Ким віданий'),

                social_status: this.__('Соціальний статус'),
                privileges_category: this.__('Пільгова категорія'),
                pension_number: this.__('Номер пенсійоного посвідчення'),
                is_working_pensioner: this.__('Працюючий пенсіонер'),
                has_wish_children: this.__('Є діти'),

                educational_institution: this.__('Навчальний заклад'),

                class_number: this.__('Номер класу'),
                class_letter: this.__('Литера класу'),
                pupil_privileges_category: this.__('Пільгова категорія учня'),
                paid_foreign_languages: this.__('Вивчення іноземних мов'),
                sport_sections: this.__('Спортивна секція'),
                transport: this.__('Транспорт яким користується учень'),
                skills: this.__('Навички які розвиває дитина'),

                position: this.__('Посада'),
                experience: this.__('Досвід роботи'),
                organization_name: this.__('Назва організації'),
                organization_activity_field: this.__('Сфера діяльності організації'),

                faculty: this.__('Факультет'),
                course_number: this.__('Номер курса'),
                is_live_in_city: this.__('Мешкає в місті'),
                student_educational_institution: this.__('Навчальний заклад'),
                student_privileges_category: this.__('Пільгова категорія студента'),

                living_type: this.__('Житлові умови'),
                reasons: this.__('Причина замовлення карти'),
                other_services: this.__('Інщі послуги за картою'),
                catch_way: this.__('Спосіб отримання карти'),

                questionnaire_type_id: this.__('Тип анкети'),
                questionnaire_status_id: this.__('Статус анкети'),
                viewed_at: this.__('Переглянуто'),
                personal_data_deleted_at: this.__('Дата видалення персональних даних'),
            };
        },
    }
}
</script>
