<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('script.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('script.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('script.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from "@app/core/mixins/manage";
import script from "@app/script/repositories/script";
import {date} from "@app/core/formatter";
import Script from "@app/script/documents/script";
import formLayout from "@app/script/components/script/form";
import getEmployeesMixin from "@app/employee/components/employees/mixins/getEmployeesMixin.js";

export default {
    mixins: [
        manage,
        getEmployeesMixin,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: this.__('Назва'),
                    sortable: true,
                    filterable: true,
                    filterProp: 'content',
                },
                {
                    name: 'created_at',
                    title: this.__('Дата створення'),
                    formatter: date('dd MMM yyyy'),
                    sortable: true,
                },
                {
                    name: '$author.$.full_name',
                    filterable: this.getEmployees(),
                    filterProp: 'employee_id',
                    title: this.__('Автор запису'),
                },
            ],
            filter: {},
            select: ['*'],
            relations: ['$author'],
        }
    },
    methods: {
        getRepository() {
            return script();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Script, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати скрипт'),
                editTitle: this.__('Редагувати скрипт'),
                width: '760px',
            };
        },
    },
}
</script>
