import {filter, get, includes, map} from "lodash";
import {count} from "@candybox/helpers.js";
import constants from "@app/core/constants.js";

export default {
    methods: {
        filterBlocks(blocks) {
            return filter(blocks, (block) => {
                const subblocks = this.filterSubblocks(get(block, 'subblocks', []));
                const isShow = get(block, 'is_show');
                return !!isShow || !!count(subblocks);
            });
        },
        filterSubblocks(subblocks) {
            return filter(subblocks, (subblock) => {
                const questions = this.filterQuestions(get(subblock, 'questions', []));
                const isShow = get(subblock, 'is_show');
                return !!isShow || !!count(questions);
            });
        },
        filterQuestions(questions) {
            return filter(questions, (question) => {
                const questionAnswersIds = map(question.answers, (item) => item.id);
                const filteredQuestionnaireAnswers = map(
                    filter(
                        this.doc.answers,
                        (item) => {
                            let result = includes(questionAnswersIds, item.answer_id);
                            if(get(question, 'view_type') !== constants.QUESTION_VIEW_TYPE.CHECKBOX_SET) {
                                result = result && (
                                    get(item, 'value.value')
                                    || get(item, 'value.input_value')
                                );
                            }
                            return result;
                        }),
                    'answer_id'
                );

                return !!get(question, 'is_show') || !!count(filteredQuestionnaireAnswers);
            });
        }
    }
}
