<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('product.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('product.export-products')"
                @click="exportUnimportantCategory">
                {{ __('Експорт з категорії невизначені') }}
            </el-button>
            <el-button
                v-if="$can('product.import-products')"
                @click="importProducts">
                {{ __('Імпорт товірів') }}
            </el-button>
            <el-button
                v-if="$can('product.import-checks')"
                @click="importChecks">
                {{ __('Імпорт чеків') }}
            </el-button>
            <el-button
                v-if="$can('product.import-products') && $can('product.import-checks')"
                @click="importHistory">
                {{ __('Історія імпорту') }}
            </el-button>
            <input
                :ref="componentRef"
                id="upload-file"
                type="file"
                style="display: none;"
                accept=".xlsx"
                @change="onChangeFiles"
            />
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/category/components/product/form.vue';
import manage from '@app/core/mixins/manage.js';
import products from "@app/category/repositories/products";
import Product from "@app/category/documents/product";
import loadFilesMixin from "@app/category/components/product/mixins/loadFilesMixin.js";
import fileLoader from "@app/services/file-loader.js";
import {downloadFile} from "@app/core/helpers.js";
import importForm from "@app/category/components/product/importForm.vue";
import constants from "@app/core/constants.js";

export default {
    mixins: [
        manage,
        loadFilesMixin,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: this.__('Назва'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: '$category.$.name',
                    title: this.__('Категорія'),
                    sortable: false,
                    filterable: true,
                    filterProp: 'category_name',
                    sortProp: 'category_id',
                },
                {
                    name: '$category.$.unified_code',
                    title: this.__('УКТ ЗЕД'),
                    filterable: true,
                    filterProp: 'unified_code',
                }
            ],
            filter: {},
            select: ['*'],
            relations: ['$category'],
        }
    },
    methods: {
        getRepository() {
            return products();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Product, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати '),
                editTitle: this.__('Редагувати '),
                width: '760px',
            };
        },
        exportUnimportantCategory() {
            products()
                .exportProducts()
                .then((res) => {
                    const {url, filename} = res;
                    downloadFile(url, filename);
                });
        },
        importChecks() {
            this.currentUpload = constants.PRODUCTS.IMPORT_ENTITY.CHECK;
            this.openFileListWindow();
        },
        importProducts() {
            this.currentUpload = constants.PRODUCTS.IMPORT_ENTITY.PRODUCT;
            this.openFileListWindow();
        },
        importHistory() {
            this.$modalComponent(importForm, {}, {}, {
                title: 'Історія імпорту',
                width: '760px',
            });
        },
    },
}
</script>
