export default {
    methods: {
        $modalComponent(component, componentProps = {}, eventListeners = {}, modalOptions = {}) {
            this.$store.dispatch('pushComponent', {
                component: Object.freeze(component),
                componentProps,
                modalOptions,
                eventListeners,
            });
        },
    },
}