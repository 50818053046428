<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('card.update')"
                :disabled="permissionToWorkWithCard"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Операції') }}
            </el-button>
            <el-button
                v-if="$can('card.excel_export')"
                @click="exportExcel(this.filter)">
                {{ __('Екпорт .xlsx') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/card/components/card/form.vue';
import manage from '@app/core/mixins/manage.js';
import card from "@app/card/repositories/card";
import {date, handbookEntry} from "@app/core/formatter";
import history from "@app/card/components/card/history";
import types from "@app/questionnaire/repositories/types";
import {get} from "@candybox/helpers";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'bar_code',
                    title: this.__('Номер картки'),
                    width: 200,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'card_code',
                    title: this.__('Штрих-код'),
                    width: 180,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'questionnaire.full_name_attr',
                    title: this.__('ПІБ'),
                    sortable: true,
                    filterable: true,
                    sortProp: 'full_name',
                    filterProp: 'full_name',
                    width: 250,
                },
                {
                    name: 'questionnaire_id',
                    title: this.__('Номер анкети'),
                    width: 150,
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'questionnaire.family_set_id',
                    title: this.__('ID комлекту'),
                    width: 250,
                    filterable: true,
                    filterProp: 'family_set_id',
                    sortable: true,
                    sortProp: 'family_set_id',
                },
                {
                    name: 'date_of_issue',
                    title: this.__('Дата видачі'),
                    formatter: date('dd MMM yyyy'),
                    sortable: true,
                    width: 180,
                },
                {
                    name: 'expiry_date',
                    title: this.__('Термін дії'),
                    formatter: date('dd MMM yyyy'),
                    sortable: true,
                    width: 120,
                },
                {
                    name: 'type_name',
                    title: this.__('Тип картки'),
                    filterable: types(),
                    filterProp: 'questionnaire_type_id',
                    filterOption: {
                        valueProp: 'name'
                    },
                    width: 120,
                },
                {
                    name: 'status_by_questionnaire',
                    width: 250,
                    title: this.__('Статус пластикової картки за анкетою'),
                    filterable: 'card_status_by_questionnaire',
                    formatter: handbookEntry('card_status_by_questionnaire'),
                },
                {
                    name: 'status_by_questionnaire_digital',
                    width: 250,
                    title: this.__('Статус цифрової картки за анкетою'),
                    filterable: 'card_status_by_questionnaire_digital',
                    formatter: handbookEntry('card_status_by_questionnaire_digital'),
                },
                {
                    name: 'status',
                    width: 180,
                    title: this.__('Статус картки'),
                    filterable: 'card_status',
                    formatter: handbookEntry('card_status'),
                },
                {
                    name: 'questionnaire.organization_name',
                    title: this.__('Назва організації'),
                    width: 300,
                    filterable: true,
                    filterProp: 'questionnaire_organization_name',
                },
                {
                    name: 'benefit_code',
                    title: this.__('Код пільги'),
                    filterable: true,
                    sortable: true,
                    width: 120,
                },
                {
                    name: 'has_digital',
                    title: this.__('Картка - цифрова'),
                    width: 150,
                    filterable: 'yes_no',
                    component: 'table-yes-mark',
                },
                {
                    name: 'has_plastic',
                    title: this.__('Картка - пластик'),
                    width: 150,
                    filterable: 'yes_no',
                    component: 'table-yes-mark',
                }, {
                    name: 'questionnaire.has_chip',
                    title: this.__('З чіпом'),
                    sortable: true,
                    sortProp: 'questionnaire_has_chip',
                    filterProp: 'questionnaire_has_chip',
                    filterable: 'yes_no',
                    component: 'table-yes-mark',
                    width: 100,
                }],
            filter: {
                questionnaire_id: null
            },
            select: ['*'],
        }
    },
    methods: {
        getRepository() {
            return card();
        },
        getModalOptions() {
            return {
                editForm: this.makeBasicEdit(formLayout),
                editTitle: this.__('Редагувати '),
                width: '440px',
            };
        },
        getHistoryComponent() {
            return history;
        },
        exportExcel(filter) {
            this.getRepository().importExportLog(['export','Експорт в excel']);
            this.exportToExcel(filter);
        },
    },
    computed: {
        permissionToWorkWithCard() {
            if (this.selectedItem !== null) {
                if (this.selectedItem.status !== 'invalid') {
                   return this.selectedItem.status_by_questionnaire !== 'awarded' && this.selectedItem.status_by_questionnaire !== 'reissue'
                } else {
                    return true
                }
            } else {
                return true
            }
        }
    },
    created() {
        const id = get(this.$route, 'query.id');
        if(id) {
            this.filter.questionnaire_id = id;
        }
    },
}
</script>
