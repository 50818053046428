import Document, {
    Attribute } from '@candybox/structures/document.js';
import RelationAttribute from '@candybox/repository/relation.js';
import categories from "@app/category/repositories/categories.js";



class Product extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            category_id: Number,
            original_id: Number,
            type: Number,
            $category: RelationAttribute.oneToOne(categories(), 'category_id', 'id'),
        };
    }
}

export default Product;
