<template>
    <el-row class="answer">
        <el-col :span="1" class="check">
            <el-checkbox
                v-model="answerValue.value"
                :disabled="readOnly"
                @change="$emit('change-answer', answerValue)"
            />
        </el-col>
        <el-col
            :span="23"
            class="content"
        >
            <div>
                {{ answer.prefix }}
            </div>
            <form-row
                v-if="hasInput"
                class="checkbox-field-input"
            >
                <options-provider
                    v-if="handbookName || repositoryName"
                    :source="getClientRepository()"
                >
                    <template v-slot:default="data">
                        <el-select
                            :loading="data.loading"
                            v-model="answerValue.retValue.value"
                            :disabled="readOnly"
                            filterable
                        >
                            <el-option
                                v-for="item in data.items"
                                :key="item.id"
                                :label="item.value"
                                :value="item.id" />
                        </el-select>
                    </template>
                </options-provider>
                <el-input
                    v-else
                    v-model="answerValue.retValue.value"
                    :disabled="readOnly"
                />
            </form-row>
            <div
                v-if="answer.suffix"
            >
                {{ answer.suffix }}
            </div>
        </el-col>
    </el-row>
</template>

<script>
import { get } from "@candybox/helpers";
import viewMode from "@app/questionnaire/components/mixins/viewMode";
import glossaries from '@app/handbook/repositories/glossary.js';
import Query from "@candybox/query/query.js";
import institutionMixin from "@app/questionnaire/components/question/mixins/institutionMixin.js";

export default {
    name: "CheckboxField",
    mixins: [
        viewMode,
        institutionMixin,
    ],
    props: {
        answerValue: {
            type: Object,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            handbookItems: null,
        };
    },
    computed: {
        answer() {
            return get(this.answerValue, 'answer');
        },
        hasInput() {
            return get(this.answer, 'input');
        },
        handbookName() {
            return get(this.answer, 'handbook_name');
        },
        repositoryName() {
            return get(this.answer, 'client_repository_name');
        },
    },
    methods: {
        getClientRepository() {
            const repositoryName = this.repositoryName;
            if(repositoryName === 'institutions') {
                return this.getInstitutions();
            }
            return this.getHandbookRepository();
        },
        getHandbookRepository() {
            const name = this.handbookName;
            return glossaries()
                .search(
                    (new Query)
                        .where('type', '=', name)
                )
                .then((items) => {
                    const glossaryItems = items.filter((item) => {
                        return item.type === name;
                    });
                    this.glossaryItems = glossaryItems.map((item) => ({
                        id: item.id,
                        value: item.name,
                    }))
                    return this.glossaryItems;
                });
        }
    },
}
</script>
