<template>
    <div
        ref="container"
        class="pie-chart-set"
    >
        <PieChart
            v-for="(item, key) in pItems"
            :key="key"
            :config="item"
            :loading="loading"
        />
    </div>
</template>

<script>
import PieChart from "@app/analytics/components/base/charts/PieChart.vue";

export default {
    name: "PieChartSet",
    props: {
        items: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        PieChart,
    },
    data() {
        return {
            pItems: [],
        }
    },
    mounted() {
        this.pItems = this.decorateItemsWidth(this.items);
    },
    methods: {
        decorateItemsWidth(items) {
            const _items = _.clone(items);
            const fullWidth = this.$refs.container.clientWidth;
            const widthOffset = _.size(_items) > 3 ? 50 : 80;
            const width = Math.floor(fullWidth / _.size(_items)) - widthOffset;
            _.each(_items, (item) => {
                _.set(item, 'width', width);
            });
            return _items;
        }
    }
}
</script>
