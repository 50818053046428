<script>
import BaseHistory from "@app/core/components/manage/history.vue"
import {handbookEntry, phoneNumber} from "@app/core/formatter";

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'institution';
        },
        getAttributes() {
            return {
                name: this.__('Назва'),
                type: {
                    title: this.__('Тип'),
                    formatter: handbookEntry('institution_type'),
                },
                manager_id: this.__('Менеджер навчального закладу'),
                chief_name: this.__('ПІБ керівника'),
                contact_number: {
                    title: this.__('Телефон'),
                    formatter: phoneNumber(),
                },
                address: this.__('Адреса'),
            }
        }
    }
}
</script>
