<template>
    <form class="analytics-filters">
        <section class="filters">
            <slot name="filters" />
        </section>
    </form>
</template>

<script>
export default {
    name: "AnalyticsFiltersBase",
}
</script>
