import Document from '@candybox/structures/document.js';

class ProductAnalytics extends Document {
    attributes() {
        return {
            id: Number,
            per_date: Date,
            statistics: String,
        }
    }
}

export default ProductAnalytics;
