import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import {phoneNumber} from "@app/core/validation";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/distributor');

    mapping.validation('store', () => {
        return {
            name: validator()
                .required()
                .length(0, 250),
            token: validator()
                .required()
                .length(32),
            edrpou: validator()
                .required()
                .length(8),
            manager_full_name: validator()
                .length(0, 200),
            phone_number: validator()
                .required()
                .custom(phoneNumber),
            status: validator()
                .required()
                .length(0, 20),
            legal_name: validator()
                .length(0, 400),
            agreement_id: validator()
                .required()
                .integer(),
            date_of_signature: validator()
                .date(),
            website: validator()
                .length(0, 250),
            email: validator()
                .length(0, 250),
            comment: validator()
                .length(0, 400),
            categories: validator()
                .required(),
        }
    });
    return mapping;
});
