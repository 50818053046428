<template>
    <form-container class="schedule-form">
        <section class="dialog-body">
            <form-row
                :label="__('Дати')"
                :errors="$errors['dates']"
                required>
                <el-date-picker
                    v-model="dates"
                    type="dates"
                    format="DD.MM.YYYY" />
            </form-row>
        </section>
        <section
            v-for="(item, index) in doc.items"
            :key="index"
            class="dialog-body schedule-item">
            <a
                v-if="index !== 0"
                href="#"
                class="remove-item"
                @click.prevent="removeItem(item)">
                {{ __('Видалити') }}
            </a>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="form-input-group">
                        <form-row
                            :label="__('З')"
                            :errors="$errors[`items.${index}.start`]"
                            required>
                            <el-time-select
                                v-model="item.start"
                                :max-time="item.end"
                                start="00:00"
                                end="23:50"
                                step="00:10" />
                        </form-row>
                        <form-row
                            :label="__('До')"
                            :errors="$errors[`items.${index}.end`]"
                            required>
                            <el-time-select
                                v-model="item.end"
                                :min-time="item.start"
                                start="00:10"
                                end="24:00"
                                step="00:10" />
                        </form-row>
                    </div>
                </el-col>
                <el-col :span="12">
                    <form-row
                        :label="__('Тип')"
                        :errors="$errors[`items.${index}.type`]"
                        required>
                        <options-provider
                            source="schedule_item_type">
                            <template v-slot:default="data">
                                <el-select
                                    :loading="data.loading"
                                    v-model="item.type">
                                    <el-option
                                        v-for="item in data.items"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>
            </el-row>
        </section>
        <section class="dialog-body add-schedule-item text-right">
            <a href="#" @click.prevent="addItem">
                <svg-icon
                    name="plus"
                    class="icon-small">
                    {{ __('Додати') }}
                </svg-icon>
            </a>
        </section>
        <section class="dialog-footer">
            <el-button
                @click="cancel">
                {{ __('Скасувати') }}
            </el-button>
            <el-button
                type="primary"
                :loading="loading"
                @click="done">
                {{ __('Зберегти') }}
            </el-button>
        </section>
    </form-container>
</template>

<script>
import employees from '@app/employee/repositories/employees.js';
import BufferRepository from '@candybox/repository/buffer.js';
import { singleton,
    filter } from '@candybox/helpers.js';

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dates: {
            get() {
                return [...this.doc.dates];
            },
            set(val) {
                return this.doc.dates = val;
            },
        },
    },
    inject: [
        'modalApi',
    ],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        done() {
            this.loading = true;
            this.$emit('done', {
                doc: this.doc,
                stopLoading: () => {
                    this.loading = false;
                }
            });
        },
        cancel() {
            this.modalApi.close();
        },
        addItem() {
            let count = this.doc.items.length;
            if (count !== 0) {
                let prev = this.doc.items[count - 1];
                this.doc.items.push({
                    start: prev.end,
                });
            } else {
                this.doc.items.push({});
            }
        },
        removeItem(item) {
            this.$confirm(this.__('Видалити цей період?'), () => {
                this.doc.items = filter(this.doc.items, (i) => i !== item);
            });
        },
    },
}
</script>
