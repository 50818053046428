<script>
import BaseHistory from "@app/core/components/manage/history.vue"

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'institution_group';
        },
        getAttributes() {
            return {
                name: this.__('Назва'),
                entry_year: this.__('Рік вступу'),
                manager_id: this.__('Менеджер навчальної групи'),
                institution_id: this.__('Навчальний заклад'),
            }
        }
    }
}
</script>
