<template>
    <el-config-provider :locale="locale">
        <el-container
            v-loading.fullscreen.lock="hasTasks"
            class="main-container">
            <side-bar
                v-if="isVisibleSideMenu"/>
            <el-main
                :class="{ 'bg-login': $route.name === 'login' }">
                <router-view
                    v-if="deps === 0 || $route.name === 'login'"
                    :key="$route.path"/>
            </el-main>
        </el-container>
        <template
            v-for="component in components"
            :key="component.id">
            <modal
                v-bind="component.options"
                @closed="removeComponent(component.id)"/>
        </template>
    </el-config-provider>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import {transport} from '@candybox/transport/base.js';
import Response from '@candybox/transport/response.js';
import {get, is} from '@candybox/helpers.js';
import handbook from '@app/core/services/handbook.js';
import SideBar from '@app/core/components/side-bar.vue';
import {ElConfigProvider} from 'element-plus';
import localeUk from 'element-plus/lib/locale/lang/uk';
import {setupValidator} from '@app/core/validation.js';
import broadcast from '@app/services/broadcast';
import {
    CHANNEL_GENERAL,
    CHANNEL_OPERATORS,
    CHANNEL_USER,
} from '@app/services/broadcast';
import {UA} from "@app/services/sip-ua.js";

export default {
    components: {
        SideBar,
        ElConfigProvider,
    },
    computed: {
        ...mapGetters({
            components: 'components',
        }),
        hasTasks() {
            return this.tasks > 0;
        },
        isVisibleSideMenu() {
            return !['login', 'claim'].includes(this.$route.name);
        }
    },
    data() {
        return {
            tasks: 0,
            deps: 0,
            locale: localeUk,
        };
    },
    beforeMount() {
        this.setupTransport();
        this.setupEvents();
        setupValidator();
    },
    mounted() {
        this.tasks++;
        this.autologin().finally(() => {
            this.tasks--;

            broadcast.subscribe((event, data) => {
                this.$events.emit(`broadcast.${event}`, data);
            }, (channel) => {
                switch (channel) {
                    case CHANNEL_GENERAL:
                        // this.$error(__('Не удалось подписаться на основной канал оповещений'));
                        break;
                    case CHANNEL_OPERATORS:
                        this.$error(this.__('Не удалось подписаться на канал оповещений операторов'));
                        break;
                    case CHANNEL_USER:
                        // this.$error(__('Не удалось подписаться на персональный канал оповещений'));
                        break;
                }
            });
        });
    },
    methods: {
        ...mapActions({
            autologin: 'user/autologin',
            removeComponent: 'removeComponent',
            setLoginRedirect: 'user/setLoginRedirect',
        }),
        setupTransport() {
            transport()
                .appendMiddleware((request, next) => {
                    return next(request).then((res) => {
                        return res;
                    }).catch((error) => {
                        if (is(error, Response)) {
                            if (error.status === 410) {
                                return this.$router.push({name: 'claim'});
                            } else if (error.status === 401) {
                                this.setLoginRedirect({
                                    name: this.$route.name,
                                    params: this.$route.params,
                                });
                                this.$router.push({name: 'login'});
                            } else if (error.status === 403) {
                                this.$error(this.__('Немає повноважень для виконання запиту'));
                            } else if (error.status === 404) {
                                this.$error(this.__('Ресурс не знайдено'));
                            } else if (error.status >= 400 && error.status <= 499) {
                                this.$error(this.__('Запит недійсний'));
                            } else if (error.status >= 500 && error.status <= 599) {
                                this.$error(this.__('Помилка на сервері'));
                            }
                        }
                        throw error;
                    });
                });
        },
        setupEvents() {
            let hb = handbook();
            this.deps += hb.loaded ? 0 : 1;
            this.$events.on('login', () => {
                if (!hb.loaded) {
                    this.tasks++;
                    hb.load().finally(() => {
                        this.tasks--;
                        this.deps--;
                    });
                }
            });
            this.$events.on('logout', () => {
                console.log('logout');
                // broadcast.unsubscribe();
                // broadcast.disconnect();
                UA().endSession();
                UA().destroy();
                this.$router.push({name: 'login'});
            });
        },
        createSipmlScripts() {
            let head = document.getElementsByTagName('head')[0];

            let script = document.createElement('script');
            script.src = '/vendor/sipml5/SIPml-api-no-ice.js';
            script.type = 'text/javascript';

            head.appendChild(script)
        }
    },
    watch: {
        $user(user) {
            if (get(user, 'has_voip')) {
                this.createSipmlScripts();
                setTimeout(() => {
                    UA().init().then(() => {
                        UA().connect();
                    });
                }, 400)
            } else {
                console.log('user no has voip');
            }
        },
        $route(route) {
            if (get(this.$user, 'has_schedule') && !get(this.$user, 'is_work_time') && route.name === 'home') {
                this.$router.push({name: 'claim'});
            }

        }
    }
}
</script>
