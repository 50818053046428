<template>
    <el-tabs v-model="activeTab">
        <el-tab-pane
            :label="__('Загальне')"
            name="general">
            <section class="dialog-body">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-input
                            class="hidden"
                            autocomplete="false"
                        />
                        <form-row
                            :label="__('Прізвище')"
                            :errors="$errors['last_name']"
                            required>
                            <el-input v-model="doc.last_name"/>
                        </form-row>
                        <form-row
                            :label="__('Ім’я')"
                            :errors="$errors['first_name']"
                            required>
                            <el-input v-model="doc.first_name"/>
                        </form-row>
                        <form-row
                            :label="__('По батькові')"
                            :errors="$errors['middle_name']">
                            <el-input v-model="doc.middle_name"/>
                        </form-row>
                        <form-row
                            :label="__('РНОКПП')"
                            :errors="$errors['user.rnokpp']"
                            required>
                            <el-input v-model="doc.user.rnokpp"/>
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            :label="__('Номер телефону')"
                            :errors="$errors['contact_number']"
                            required>
                            <el-input v-model="doc.contact_number"/>
                        </form-row>
                        <form-row
                            :label="__('Email')"
                            :errors="$errors['email']"
                            required>
                            <el-input v-model="doc.email"/>
                        </form-row>
                        <form-row
                            :label="__('Дата народження')"
                            :errors="$errors['birth_date']">
                            <el-date-picker
                                v-model="doc.birth_date"
                                type="date"
                                format="DD.MM.YYYY"/>
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            :label="__('Досвід роботи')"
                            :errors="$errors['experience']">
                            <el-input
                                v-model="doc.experience"
                                type="number"
                            />
                        </form-row>
                        <form-row
                            :label="__('Додатково')"
                            :errors="$errors['notes']">
                            <el-input
                                type="textarea"
                                class="span-2"
                                resize="none"
                                v-model="doc.notes"/>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
        <el-tab-pane
            :label="__('Налаштування')"
            name="settings">
            <section class="dialog-body">
                <el-row
                    class="form-block"
                    :gutter="20">
                    <el-col :span="8">
                        <form-row
                            :label="__('Логин')"
                            :errors="$errors['user.login']"
                        >
                            <el-input v-model="doc.user.login"/>
                        </form-row>
                        <form-row
                            :label="__('Пароль')"
                            :errors="$errors['user.password']"
                        >
                            <el-input
                                show-password
                                autocomplete="new-password"
                                v-model="doc.user.password"
                            />
                        </form-row>
                        <form-row
                            :label="__('Посада')"
                            :errors="$errors['position_id']"
                            required>
                            <options-provider
                                :source="getPositions()">
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        v-model="doc.position_id"
                                        filterable>
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            :label="__('Роль')"
                            :errors="$errors['user.roles']"
                            required>
                            <options-provider
                                :source="getRoles()">
                                <template v-slot:default="data">
                                    <el-select
                                        multiple
                                        collapse-tags
                                        v-model="doc.user.roles">
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                        <form-row
                            :label="__('Статус')"
                            :errors="$errors['status']"
                            required>
                            <options-provider
                                source="employee_status">
                                <template v-slot:default="data">
                                    <el-select v-model="doc.status">
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                        <form-row
                            v-if="isShowInstitution"
                            :label="__('Навчальний заклад')"
                            :errors="$errors['institution']"
                        >
                            <options-provider
                                :source="getInstitutions()">
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="doc.institution_id"
                                        clearable
                                        filterable>
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            :label="__('Додаткові повноваження')"
                            :errors="$errors['user.permissions']"
                        >
                            <el-select
                                :loading="loadingPermissionGroup"
                                multiple
                                collapse-tags
                                v-model="doc.user.permissions"
                                :disabled="disabledAddAddingPermissions"
                                @click="debug(doc)">
                                <el-option-group
                                    v-for="group in groupsData"
                                    :key="group.label"
                                    :label="group.label"
                                >
                                    <el-option
                                        v-for="item in group.permissions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-option-group>
                            </el-select>
                        </form-row>
                        <div class="form-input-group mt-15">
                            <form-row
                                :label="__('Працює з')"
                                :errors="$errors['date_hired']">
                                <el-date-picker
                                    v-model="doc.date_hired"
                                    type="date"
                                    format="DD.MM.YYYY"/>
                            </form-row>
                            <form-row
                                :label="__('Звільнений')"
                                :errors="$errors['date_fired']">
                                <el-date-picker
                                    v-model="doc.date_fired"
                                    type="date"
                                    format="DD.MM.YYYY"/>
                            </form-row>
                        </div>
                    </el-col>
                </el-row>
                <el-row
                    v-if="showVoipSettings"
                    class="form-block"
                    :gutter="20">
                    <el-col :span="8">
                        <form-row
                            :label="__('Номер SIP')"
                            :errors="$errors['sip']"
                            required>
                            <el-input v-model="doc.sip"/>
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            type="password"
                            :label="__('Пароль телефонії')"
                            :errors="$errors['sip_password']"
                            required>
                            <el-input v-model="doc.sip_password"/>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import positions from '@app/employee/repositories/positions.js';
import roles from '@app/user/repositories/roles.js';
import permissions from '@app/user/repositories/permissions.js';
import Query from '@candybox/query/query.js';
import institutions from "@app/institution/repositories/institutions";
import {get} from "@candybox/helpers.js";
import {some, map, includes, filter} from 'lodash';
import constants from "@app/core/constants.js";
import permissionGroups from "@app/user/components/mixins/permission-groups";


export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    mixins: [
        permissionGroups,
    ],
    data() {
        return {
            activeTab: 'general',
            positions: null,
            isShowInstitution: false,
            roles: [],
        }
    },
    computed: {
        showVoipSettings() {
            return get(this.doc, 'has_voip', false);
        },
        currentPosition: {
            get() {
                return this.doc.$position.$;
            },
            set(val) {
                return this.doc.$position = val;
            },
        },
        disabledAddAddingPermissions() {
            return includes(get(this.$user, 'roles', []), constants.ROLES.ADMIN_JUNIOR);
        }
    },
    created() {
        this.getRolesVoip();
        this.loadPermissionGroups();
    },
    methods: {
        updateHasVoip() {
            const roles = get(this.doc, 'user.roles', []);
            this.doc.has_voip = some(map(
                filter(this.roles, (r) => includes(roles, r.id)),
                (role) => role.has_voip)
            );
        },
        getPositions() {
            return positions()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.positions = items.map((pos) => ({
                        id: pos.id,
                        value: pos.name,
                    }));
                    this.updateCurrentPosition(this.doc.position_id);
                    return this.positions;
                });
        },
        updateCurrentPosition(id) {
            this.currentPosition = this.positions.find((pos) => {
                return pos.id === id;
            });
        },
        getRoles() {
            return roles();
        },
        getRolesVoip() {
            return this.getRoles()
                .search((new Query).limitTo())
                .then((items) => {
                    this.roles = items.map((item) => ({
                        id: item.id,
                        name: item.name,
                        has_voip: item.has_voip,
                    }));
                    this.updateHasVoip();
                    return this.roles;
                });
        },
        getInstitutions() {
            return institutions();
        },
        getPermissions() {
            return permissions();
        },
        getCodeRoles(val){
            if ( val != null) {
                return roles()
                    .search((new Query).where('id', '=', val))
                    .then((items) => {
                        let codes = items.map((rol) => rol.code);
                        this.isShowInstitution = _.includes(codes, '3') || _.includes(codes, '4');
                        if (!(_.includes(codes, '3') || _.includes(codes, '4'))){
                            this.doc.institution_id = null;
                        }
                    })
            }
        },
    },
    watch: {
        ['doc.position_id'](id) {
            this.updateCurrentPosition(id);
        },
        ['doc.user.roles']: {
            immediate: true,
            handler(val) {
                this.getCodeRoles(val);
                this.updateHasVoip();
            }
        },
        ['doc.institution_id']: {
            immediate: true,
            handler(val) {
                if(val > 0){
                    return this.doc.institution_id = val;
                }
                return this.doc.institution_id = null;
            }
        },
    },
}
</script>
