<template>
    <div>
        <div>
            <ProductsFilter
                @loadDataForPeriod = "loadDataForPeriod" />
        </div>
        <el-scrollbar height="calc(100vh - 265px)">
            <PieChart
                :config="numberOfBuyersByDistricts"
                :loading="processingByDistricts"
                class="mb-80"
            />
            <BarChart
                :config="topDiscountConfig"
                :loading="processingByDiscount"
            />
            <BarChart
                :config="topCategoriesConfig"
                :loading="processingByCategories"
            />
        </el-scrollbar>
    </div>
</template>

<script>
import BarChart from "@app/analytics/components/base/charts/BarChart.vue"
import PieChart from "@app/analytics/components/base/charts/PieChart.vue";
import ProductsFilter from "@app/analytics/components/base/filters/products/ProductsFilter.vue";
import glossary from "@app/handbook/repositories/glossary";
import Query from "@candybox/query/query";
import productAnalytics from "@app/analytics/repositories/product-analytics.js";

export default {
    name: "Products",
    components: {
        BarChart,
        PieChart,
        ProductsFilter,
    },
    data() {
        return {
            statisticData: [],
            districtColors: [
                '#1B6EBE',
                '#CF0C47',
                '#278D4E',
                '#333333',
                '#DEA000',
                '#009688',
                '#3E50B4',
                '#5A0723',
                '#BBFF22'
            ],
            districtData: {},
            processingByDistricts: true,
            processingByDiscount: true,
            processingByCategories: true,
            topDiscountConfig: {
                title: 'ТОП 10 КОМПАНІЙ ПО ЗАОЩАДЖЕНИМ КОШТАМ (ГРН)',
                columns: [
                    'Значення',
                ],
                withDescriptions: true,
                colors: {
                    'Значення': '#DBA401',
                },
                data: {
                    'РЫНОК “БАРАБАШОВО”': {
                        'Значення': 250,
                    },
                    '“FRESHLINE”': {
                        'Значення': 180,
                    },
                    'БУДІВЕЛЬНІ МАТЕРІАЛИ': {
                        'Значення': 178,
                    },
                    'ООО "ТЕРА-ХАРЬКОВ"': {
                        'Значення': 160,
                    },
                    'ООО “СК «ФОРТ-ПОСТ”': {
                        'Значення': 150,
                    },
                },
                barWidth: 30,
                withLegend: false,
                height: 600,
            },
            topCategoriesConfig: {
                title: 'ТОП 10 КАТЕГОРІЙ ЗА КІЛЬКІСТЮ ПОКУПОК',
                columns: [
                    'Значення',
                ],
                withDescriptions: true,
                colors: {
                    'Значення': '#4F5A6E',
                },
                data: {
                    'ВЕЛОТРАНСПОРТ': {
                        'Значення': 250,
                    },
                    'ВОДНИЙ ТРАНСПОРТ': {
                        'Значення': 180,
                    },
                    'БУДІВЕЛЬНІ МАТЕРІАЛИ': {
                        'Значення': 178,
                    },
                    'ГОСПОДАРСЬКІ ІНСТРУМЕНТИ': {
                        'Значення': 160,
                    },
                    'ЕЛЕКТРОННІ ТОВАРИ': {
                        'Значення': 150,
                    },
                },
                barWidth: 30,
                withLegend: false,
            },
        }
    },
    methods: {
        getDistrict() {
            glossary()
                .search((new Query).where('type', '=', 'district').orderBy('name'))
                .then((district) => {
                    this.districtData = district;
                })
        },
        loadDataForPeriod(period) {
            this.processingByDistricts = true;
            productAnalytics()
                .search((new Query).where('per_date', '>=', period[0]).where('per_date', '<=', period[1]))
                .then((statistics) => {
                    this.statisticData = [];
                    statistics.forEach((row) => {
                        this.statisticData.push(row.statistics)
                    })
                })
        },
        loadDataAllTime() {
            productAnalytics()
                .search(new Query())
                .then((statistics) => {
                    statistics.forEach((row) => {
                        this.statisticData.push(row.statistics)
                    })
                })
        },
        getDistrictName(obj) {
            let result = {};
            for (let key in obj) {
               this.districtData.every((row) => {
                   if (row.id === Number(key)) {
                       result[row.name] = obj[key];
                       return false;
                   } else {
                       return true;
                   }
               })
            }
            return result;
        },
        getTopList(obj) {
            let result = {};
            let arr = Object.values(obj).sort().slice(0, 10);
            for (let name in obj) {
                if (!arr.includes(obj[name])) {
                    delete obj[name];
                }
            }
            for (let name in obj) {
                result[name] = {
                    'Значення' : Number(obj[name])
                }
            }
            return result;
        },
    },
    computed: {
        numberOfBuyersByDistricts() {
            if (this.statisticData.length !== 0 && Object.keys(this.districtData).length !== 0) {
                return {
                    title: 'КІЛЬКІСТЬ ПОКУПЦІВ (УНІКАЛЬНИХ КАРТ) ЗА РАЙОНАМИ',
                    colors: this.districtColorsObj,
                    data: this.districtDataObj,
                    height: 500,
                    barWidth: 15,
                }
            }
        },
        districtColorsObj() {
            if (this.districtData.length !== 0) {
                let i = 0;
                let colorMap = {};
                this.districtData.forEach((row) => {
                    colorMap[row.id] = this.districtColors[i];
                    i++;
                })
                return this.getDistrictName(colorMap);
            }
        },
        districtDataObj() {
            if (this.statisticData.length !== 0) {
                let i = 1;
                let result = {};
                this.districtData.forEach((row) => {
                    result[row.id] = 0;
                });
                this.statisticData.forEach((row) => {
                    let statisticByDistrict = JSON.parse(row).byDistrict;
                    for (let key in statisticByDistrict) {
                        if (i === 1) {
                            result[key] = statisticByDistrict[key];
                        } else {
                            result[key] = result[key] + statisticByDistrict[key];
                        }
                    }
                    i++;
                });
                for (let key in result) {
                    result[key] = Math.round(result[key]/this.statisticData.length);
                }
                this.processingByDistricts = false;
                return this.getDistrictName(result);
            }
        },
        topDiscountData() {
            let result = {};
            this.statisticData.forEach((row) => {
                let statisticByTopTax = JSON.parse(row).topDiscount;
                for (let name in statisticByTopTax) {
                    if (result.hasOwnProperty(name)){
                        result[name] = (Number(result[name]) + Number(statisticByTopTax[name])).toFixed(2);
                    } else {
                        result[name] = Number(statisticByTopTax[name]);
                    }
                }
            });
            return this.getTopList(result);
        },
        topCategoriesData() {
            let result = {};
            this.statisticData.forEach((row) => {
                let statisticByTopCategories = JSON.parse(row).topCategories;
                for (let name in statisticByTopCategories) {
                    if (result.hasOwnProperty(name)){
                        result[name] = Number(result[name]) + Number(statisticByTopCategories[name]);
                    } else {
                        result[name] = Number(statisticByTopCategories[name]);
                    }
                }
            });
            return this.getTopList(result);
        },
    },
    watch: {
        topDiscountData: {
            handler(value) {
                this.processingByDiscount = true;
                this.topDiscountConfig.data = value;
                this.processingByDiscount = false;
            },
            deep: true,
        },
        topCategoriesData: {
            handler(value) {
                this.processingByCategories = true;
                this.topCategoriesConfig.data = value;
                this.processingByCategories = false;
            },
            deep: true,
        }
    },
    created() {
        this.getDistrict();
        this.loadDataAllTime();
    }
}
</script>
