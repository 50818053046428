import Document, {
    Attribute } from '@candybox/structures/document.js';
import InputParam from "@app/questionnaire/documents/answer/inputParam";

class Answer extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            prefix: String,
            suffix: String,
            is_show: Number,
            order: Number,
            inner_name: String,
            handbook_name: String,
            field_name: String,
            client_repository_name: String,
            input: Attribute.object(InputParam, () => new InputParam()),
            glossary_id: Number,
            question_names: Array,
            questions_to_save: Array,
            view_type: String,
        };
    }

    get hasHandbook() {
        return !!this.handbook_name;
    }

    get hasGlossary() {
        return !!this.glossary_id
    }
}

export default Answer;
