import ShowCards from "@app/questionnaire/components/questionnaires/show-cards";

export default {
    methods: {
        showCards() {
            this.$modalComponent(ShowCards,
                {
                    selected_questionnaire_id: this.selectedItem.id,
                },
                {
                    done: (dialog) => {
                        dialog.close();
                    },
                },
                {
                title: this.__('Історія карток'),
                width: '800px',
            });
        },
    }
}
