import RestRepository from '@candybox/repository/rest.js';
import productMap from '@app/category/requests/product-repository';
import { singleton } from '@candybox/helpers.js';
import Product from "@app/category/documents/product";

class ProductRepository extends RestRepository
{
    exportProducts() {
        return this._request('exportProducts');
    }
}

export default singleton(() => {
    return new ProductRepository(Product, productMap());
});
