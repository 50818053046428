import institutions from "@app/institution/repositories/institutions.js";
import groups from "@app/institution/repositories/groups.js";
import Query from "@candybox/query/query.js";
import {get} from "lodash";
import constants from "@app/core/constants.js";

export default {
    data() {
        return {
            groups: [],
            institutions: [],
        }
    },
    methods: {
        setDisabled(value) {
            this.$store.dispatch('setSomeData', {
                key: 'disabled',
                value: value,
            });
        },
        getInstitutions() {
            const query = (new Query);
            if(this.doc) {
                const typeId = get(this.doc, 'questionnaire_type_id');
                let institutionType = null;
                if(typeId === constants.QUESTIONNAIRE_TYPE.STUDENT) {
                    institutionType = constants.INSTITUTION.TYPE.UNIVERSITY;
                } else if (typeId === constants.QUESTIONNAIRE_TYPE.PUPIL) {
                    institutionType = constants.INSTITUTION.TYPE.SCHOOL
                }
                if(institutionType) {
                    query.where('type', 'in', [institutionType])
                }
            }
            return institutions()
                .search(
                    query.limitTo(1000)
                )
                .then((items) => {
                    this.institutions = items.map((institution) => ({
                        id: institution.id,
                        value: institution.name,
                    }))
                    return this.institutions;
                });
        },
        getGroups() {
            const institution_id = this.$store.getters.getSomeData('institutions');
            if(institution_id) {
                return groups().search((new Query).where('institution_id', '=', institution_id))
                    .then((items) => {
                        const groups = items.filter((item) => item.class_number !== null);
                        this.groups = groups.map((group) => ({
                            id: group.id,
                            value: group.class_number + '-' + group.name,
                        }));
                        return this.groups;
                    })
                    .catch((err) => {
                        console.log('search errors', err);
                        this.loading = false;
                    })

            }
            return [];
        }
    }
}
