<template>
    <div
        v-loading="loading"
        class="image-viewer"
    >
        <image-cropper
            :image-object="file"
            @crop-image="onCropImage"
            @cancel="cancel"
        />
    </div>
</template>

<script>
import fileLoader from '@app/services/file-loader.js';

export default {
    props: {
        file: Object,
        data: Object
    },
    data() {
        return {
            loading: false,
            pdf: null,
            docWindow: null,
            viewerOptions: { "inline": false, "button": false, "navbar": false,
                "title": false, "toolbar": false, "tooltip": false, "movable": true,
                "zoomable": true, "rotatable": true, "scalable": true, "transition": true,
                "fullscreen": true, "keyboard": true, "url": "data-source" }
        }
    },
    beforeDestroy() {
        fileLoader.revokeAll();
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        getFileBlob() {
            return Promise.resolve(this.file.blobData);
        },
        onCropImage(args) {
            this.$emit('crop', args);
        }
    },
};
</script>
