import {
    BooleanConditionSource,
    ConditionSource, GlossaryIdConditionSource,
    GlossaryNameConditionSource,
    HandbookConditionSource, NativeConditionSource, QuestionConditionSource
} from "@app/sample/services/condition/conditionSource.js";
import types from "@app/questionnaire/repositories/types.js";
import statuses from "@app/questionnaire/repositories/statuses.js";
import institutions from "@app/institution/repositories/institutions.js";

export default {
    data() {
        return {
            fieldName: null,
            conditionService: null,
            answers: [],
            loading: false,
        }
    },
    methods: {
        init() {
            //
        },
        async makeConditionSource(fieldName) {
            let service = null;
            switch (fieldName) {
                case 'questionnaire_type_id': {
                    service = new ConditionSource(fieldName, types()); break;
                }
                case 'questionnaire_status_id': {
                    service = new ConditionSource(fieldName, statuses()); break;
                }
                case 'created_at': {
                    service = new NativeConditionSource(fieldName, null, 'native', 'date'); break;
                }
                case 'organization_name': {
                    service = new NativeConditionSource(fieldName, null, 'native', 'string'); break;
                }
                case 'institution_name': {
                    service = new ConditionSource(fieldName, institutions()); break;
                }
                case 'status_by_questionnaire': {
                    service = new HandbookConditionSource('card_status_by_questionnaire', 'card'); break;
                }
                case 'status': {
                    service = new HandbookConditionSource('card_status', 'card'); break;
                }
                case 'catch_way':
                case 'registration_district':
                case 'registration_street':
                case 'residential_district':
                case 'residential_street': {
                    service = new GlossaryNameConditionSource(fieldName, 'glossary_name'); break;
                }
                case 'social_status':
                case 'privileges_category':
                case 'pupil_privileges_category':
                case 'student_privileges_category': {
                    service = new GlossaryIdConditionSource(fieldName, 'glossary_id'); break;
                }
                case 'rnokpp':
                case 'birth_date': {
                    service = new QuestionConditionSource(fieldName); break;
                }
                case 'family_set_id':
                case 'note': {
                    service = new BooleanConditionSource(fieldName); break;
                }
            }
            this.conditionService = service;
            if(this.conditionService) {
                await this.conditionService.run();
                this.answers = this.conditionService.getAnswers();
                this.init();
            }
        },
    },
}
