<template>
    <el-tabs
        v-model="activeTab"
        v-loading="loading"
    >
        <el-tab-pane
            :label="__('Розташування')"
            name="path"
        >
            <section class="dialog-body">
                <el-row
                    class="form-block"
                    :gutter="20"
                >
                    <el-col :span="12">
                        <form-row
                            :label="__('Тип анкети')"
                        >
                            <options-provider
                                :source="getTypes()"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="typeId"
                                        :loading="data.loading"
                                        clearable
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id" />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12"
                        v-if="typeId"
                    >
                        <form-row
                            :label="__('Блок')"
                        >
                            <options-provider
                                :source="getBlocks()"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="blockId"
                                        :loading="data.loading"
                                        :disabled="!typeId"
                                        clearable
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id" />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="24">
                        <form-row
                            v-if="blockId"
                            :label="__('Підблок')"
                            :errors="$errors['questionnaire_subblock_id']"
                            required
                        >
                            <options-provider
                                :source="getSubblocks()"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="doc.questionnaire_subblock_id"
                                        :loading="data.loading"
                                        :disabled="!blockId"
                                        clearable
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id" />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
        <el-tab-pane
            :label="__('Поля')"
            name="general"
        >
            <section class="dialog-body">
                <el-row
                    class="form-block"
                    :gutter="20">
                    <el-col :span="8">
                        <form-row
                            :label="__('Внутрішня назва')"
                            :errors="$errors['inner_name']"
                        >
                            <el-input v-model="doc.inner_name" />
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            :label="__('Відображати')"
                            :errors="$errors['is_show']"
                            required
                        >
                            <options-provider
                                source="yes_no">
                                <template v-slot:default="data">
                                    <el-radio-group v-model="doc.is_show">
                                        <el-radio-button
                                            v-for="option in data.items"
                                            :key="option.id"
                                            :label="option.id">
                                            {{ option.value }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="8">
                        <form-row
                            :label="__('Номер')"
                            :errors="$errors['order']"
                            required>
                            <el-input v-model="doc.order" />
                        </form-row>
                    </el-col>
                    <el-col :span="24">
                        <form-row
                            :label="__('Назва')"
                            :errors="$errors['label']"
                            required
                        >
                            <el-input
                                v-model="doc.label"
                                type="textarea"
                                resize="none"
                            />
                        </form-row>
                    </el-col>
                    <el-col :span="24">
                        <form-row
                            :label="__('Відповіді')"
                        >
                            <options-provider
                                :source="getAnswers()"
                            >
                                <template v-slot:default="data">
                                    <el-select
                                        v-model="doc.answers_ids"
                                        :loading="data.loading"
                                        clearable
                                        multiple
                                        filterable
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import types from "@app/questionnaire/repositories/types.js"
import blocks from "@app/questionnaire/repositories/blocks.js";
import subblocks from "@app/questionnaire-constructor/repositories/subblocks";
import Query from "@candybox/query/query.js";
import {get, map} from "lodash";
import answers from "@app/questionnaire/repositories/answers.js";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeTab: 'path',
            loading: false,
            typeId: null,
            blockId: null,
        }
    },
    methods: {
        getTypes() {
            return types();
        },
        getBlocks() {
            return blocks()
                .search(
                    (new Query)
                        .where('questionnaire_type_id', '=', this.typeId)
                )
                .then((items) => {
                    return map(items,(item) => ({
                        id: item.id,
                        value: item.title,
                    }));
                });
        },
        getSubblocks() {
            return subblocks()
                .search(
                    (new Query)
                        .where('questionnaire_block_id', '=', this.blockId)
                )
                .then((items) => {
                    return map(items, (item) => ({
                        id: item.id,
                        value: item.inner_name,
                    }))
                });
        },
        getAnswers() {
            return answers()
                .search(
                    (new Query)
                        .limitTo(1000)
                )
                .then((items) => {
                    const items_ = map(items, (item) => ({
                        id: item.id,
                        value: item.inner_name,
                    }));

                    return items_;
                });
        }
    },
}
</script>
