<template>
    <data-table
        ref="table"
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        :withProperties="false"
        @selection-changed="selectionChanged">
    </data-table>
</template>

<script>
import questionnaires from '@app/questionnaire/repositories/questionnaires.js';
import manage from '@app/core/mixins/manage.js';
import {phoneNumber, date, handbookEntry} from '@app/core/formatter.js';
import types from "@app/questionnaire/repositories/types.js";
import Query from "@candybox/query/query.js";


export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'full_name_attr',
                title: this.__('ПІБ'),
                sortable: true,
                filterable: true,
            }, {
                name: 'rnokpp_attr',
                title: this.__('РНКОПП'),
                sortable: true,
                filterable: true,
            }, {
                name: 'contact_number_attr',
                title: this.__('Телефон'),
                formatter: phoneNumber(),
                sortable: true,
                filterable: true,
            }, {
                name: 'birth_date_attr',
                title: this.__('Дата народження'),
                formatter: date('dd MMM yyyy'),
                sortable: true,
                filterable: true,
            }, {
                name: '$type.$.name',
                title: this.__('Тип анкети'),
                sortable: true,
                filterable: types(),
                filterProp: 'questionnaire_type_id',
                sortProp: 'questionnaire_type_id',
            }],
            filter: {},
            select: [
                '*.last_name',
                '*.first_name',
                '*.middle_name',
                '*.contact_number',
                '*.birth_date',
                '*.rnokpp',
            ],
            relations: ['$type'],
        }
    },
    methods: {
        getRepository() {
            return questionnaires();
        },
    },
    watch: {
        selectedItem(row) {
            this.$emit('selected-item', row);
        }
    }
}
</script>
