import Document, { Attribute } from "@candybox/structures/document";
import RelationAttribute from "@candybox/repository/relation";
import employees from "@app/employee/repositories/employees";
import {isFilled} from "@app/core/helpers";

class Script extends Document {
    attributes() {
        return {
            id: Number,
            name: String,
            content: String,
            employee_id: Number,
            $author:  RelationAttribute.oneToOne(employees(), 'employee_id'),
            created_at: Date,
        };
    }

    get full_name() {
        return [
            this.last_name,
            this.first_name,
            this.middle_name,
        ]
            .filter(isFilled)
            .join(' ');
    }
}

export default Script;
