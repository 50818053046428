import Document, {
    Attribute } from '@candybox/structures/document.js';

class RetailOrder extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            traderId: String,
            sourceId: String,
            cashdeskId: String,
            cardId: String,
            orderNumber: String,
            fiscalNumber: String,
            orderTime: Date,
            totalPrice: Number,
            totalPriceWithDiscount: Number,
            percentTax: Number,
            totalTax: Number,
            paymentForm: String,
            products: String,
        };
    }

}

export default RetailOrder;
