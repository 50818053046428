<template>
    <base-page
        :page-title="title"
        :page-pages="pages"
    >
        <template #heading>
            <slot name="heading" />
        </template>
        <template #heading-addons>
            <slot name="heading-addons"/>
        </template>
        <template #footer>
            <slot name="footer"/>
        </template>
    </base-page>
</template>

<script>
import BasePage from "@app/core/components/pages/basePage";

export default {
    name: "callCenterBase",
    components: { BasePage },
    data() {
        return {
            title: this.__('Контактний центр'),
            pages: [
                {
                    index: '1',
                    title: this.__('Архів'),
                    route: { name: 'archive-calls' },
                    visible: () => this.$can('call-log.access'),
                },
                {
                    index: '2',
                    title: this.__('Реальні дзвінки'),
                    route: { name: 'real-calls' },
                    visible: () => this.$can('process-log.access'),
                },
                {
                    index: '3',
                    title: this.__('Пропущені дзвінки'),
                    route: { name: 'missed-calls' },
                    visible: () => this.$can('call.access'),
                },
                {
                    index: '4',
                    title: this.__('Завдання'),
                    route: { name: 'operator-tasks' },
                    visible: () => this.$can('operator-task.access'),
                },
            ],
        }
    }
}
</script>
