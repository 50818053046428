import Document, {
    Attribute } from '@candybox/structures/document.js';
import RelationAttribute from '@candybox/repository/relation.js';
import blocks from "@app/questionnaire/repositories/blocks";

class Type extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            key: String,
            $blocks: RelationAttribute.oneToMany(blocks(), 'questionnaire_type_id')
        };
    }
}

export default Type;
