<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :default-sort="sort"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="($can('questionnaire.create') || $can('questionnaire.create-institution_group'))"
                @click="create({readOnly: false})">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="($can('questionnaire.update') || $can('questionnaire.update-institution_group'))"
                :disabled="selectedItem === null"
                @click="edit({ readOnly: false})">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('questionnaire.delete')"
                :disabled="isDisableRemove"
                @click="removePersonalData">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('card.access')"
                :disabled="selectedItem === null"
                @click="showCards">
                {{ __('Прив\'язані картки') }}
            </el-button>
            <el-button
                v-if="$can('questionnaire.view')"
                :disabled="selectedItem === null"
                @click="view">
                {{ __('Перегляд') }}
            </el-button>
            <el-button
                v-if="$can('questionnaire.access')"
                :disabled="selectedItem === null"
                @click="verification">
                {{ __('Перевірка') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Операції') }}
            </el-button>
            <el-button
                v-if="$can('questionnaire.limited_search')"
                @click="search">
                {{ __('Пошук') }}
            </el-button>
            <el-button
                v-if="$can('questionnaire.excel_export')"
                @click="exportToExcel(this.filter)">
                {{ __('Експорт .csv') }}
            </el-button>
            <el-button
                v-if="$can('questionnaire.excel_export')"
                @click="exportedFiles">
                {{ __('Експортовані файли') }}
            </el-button>
            <el-button
                v-if="$can('sample.access')"
                @click="showSampleList"
                :type="typeSampleButton">
                {{ __('Вибірки') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import questionnaires from '@app/questionnaire/repositories/questionnaires.js';
import formLayout from '@app/questionnaire/components/questionnaires/form.vue';
import manage from '@app/core/mixins/manage.js';
import Questionnaire from '@app/questionnaire/documents/questionnaire.js';
import {phoneNumber, date, handbookEntry} from '@app/core/formatter.js';
import types from "@app/questionnaire/repositories/types.js";
import history from '@app/questionnaire/components/questionnaires/history.vue';
import constants from "@app/core/constants";
import { get } from "@candybox/helpers";
import fileLoader from "@app/services/file-loader";
import formImport from "@app/questionnaire/components/mixins/formImport";
import FormImport from "@app/questionnaire/components/questionnaires/form-import.vue";
import search from "@app/questionnaire/components/questionnaires/search";
import showCards from "@app/questionnaire/components/mixins/showCard";
import sample from "@app/questionnaire/components/questionnaires/sample.vue";
import statement from "@app/questionnaire/components/questionnaires/statement-form.vue";
import exported from "@app/questionnaire/components/questionnaires/exported.vue";
import Query from "@candybox/query/query";
import setRules from "@app/sample/mixins/setRules";
import getGlossariesMixin from "@app/employee/components/employees/mixins/getGlossariesMixin.js";
import {each, map} from "lodash";
import glossaries from "@app/handbook/repositories/glossary.js";
import employees from "@app/employee/repositories/employees.js";
import Employee from "@app/employee/documents/employee.js";
import getEmployeesMixin from "@app/employee/components/employees/mixins/getEmployeesMixin.js";

export default {
    mixins: [
        manage,
        formImport,
        showCards,
        setRules,
        getEmployeesMixin,
        getGlossariesMixin,
    ],
    data() {
        return {
            columns: [{
                name: 'id',
                title: this.__('Номер анкети'),
                sortable: true,
                filterable: true,
                width: 180,
            }, {
                name: 'source.name',
                title: this.__('Джерело даних'),
                sortable: true,
                sortProp: 'source_id',
                filterable: this.getSourceGlossaries(),
                filterProp: 'source_id',
                width: 180,
            }, {
                name: 'responsible_name',
                title: this.__('Оператор'),
                sortable: true,
                filterable: true,
                width: 180,
            }, {
                name: 'full_name_attr',
                title: this.__('ПІБ'),
                sortable: true,
                filterable: true,
                width: 250,
            }, {
                name: 'rnokpp_attr',
                title: this.__('РНКОПП'),
                sortable: true,
                filterable: true,
                width: 180,
            }, {
                name: 'contact_number_attr',
                title: this.__('Телефон'),
                formatter: phoneNumber(),
                sortable: true,
                filterable: true,
                width: 180,
            }, {
                name: 'birth_date_attr',
                title: this.__('Дата народження'),
                formatter: date('dd MMM yyyy'),
                sortable: true,
                width: 160,
            }, {
                name: 'full_passport_data_attr',
                title: this.__('Паспортні дані'),
                width: 250,
            },{
                name: 'full_registration_attr',
                title: this.__('Адреса реєстрації'),
                width: 250,
            }, {
                name: 'full_parent_attr',
                title: this.__('Привязка до батьків'),
                width: 250,
            }, {
                name: 'family_set_id',
                title: this.__('ID комлекту'),
                width: 250,
                filterable: true,
                sortable: true,
            }, {
                name: 'tags_names',
                title: this.__('Теги'),
                filterable: this.getTagsGlossary(),
                filterProp: 'tag_name',
                width: 200,
            }, {
                name: 'created_at',
                title: this.__('Дата заповнення'),
                sortable: true,
                formatter: date('dd MMM yyyy'),
                width: 160,
            }, {
                name: 'personal_data_deleted_at',
                title: this.__('Дата видалення персональних даних'),
                sortable: true,
                formatter: date('dd MMM yyyy'),
                width: 160,
            }, {
                name: 'has_chip',
                title: this.__('З чіпом'),
                sortable: true,
                filterable: 'yes_no',
                component: 'table-yes-mark',
                width: 100,
            }, {
                name: 'issue_condition',
                title: this.__('Умова видачі'),
                sortable: true,
                filterable: this.getIssueConditions(),
                formatter: (val) => get(val, 'value'),
                sortProp: 'issue_condition_id',
                filterProp: 'issue_condition_id',
                width: 140,
            }, {
                name: 'type',
                title: this.__('Тип анкети'),
                sortable: true,
                filterable: types(),
                filterProp: 'questionnaire_type_id',
                sortProp: 'questionnaire_type_id',
                width: 140,
            }, {
                name: 'status.code',
                title: this.__('Статус анкети'),
                sortable: true,
                filterable: 'questionnaire_status',
                formatter: handbookEntry('questionnaire_status'),
                filterProp: 'questionnaire_status_code',
                sortProp: 'questionnaire_status_id',
                width: 210,
            }, {
                name: 'place_of_receiving',
                title: this.__('Місце отримання анкети'),
                sortable: true,
                filterable: this.getPlacesOfReceiving(),
                formatter: (val) => get(val, 'value'),
                filterProp: 'place_of_receiving_id',
                sortProp: 'place_of_receiving_id',
                width: 210,
            }, {
                name: 'act_of_acceptance',
                title: this.__('Реквізити акта приймання передачі'),
                sortable: true,
                filterable: true,
                width: 210,
            }, {
                name: 'last_card.bar_code',
                title: this.__('Номер картки'),
                filterable: true,
                filterProp: 'bar_code',
                width: 180,
            }, {
                name: 'last_card.card_code',
                title: this.__('Штрих-код картки'),
                filterable: true,
                filterProp: 'card_code',
                width: 180,
            }, {
                name: 'last_card.status_by_questionnaire',
                title: this.__('Статус пласткової картки за анкетою'),
                filterable: 'card_status_by_questionnaire',
                formatter: handbookEntry('card_status_by_questionnaire'),
                filterProp: 'status_by_questionnaire',
                width: 250,
            }, {
                name: 'last_card.status_by_questionnaire_digital',
                title: this.__('Статус цифрової картки за анкетою'),
                filterable: 'card_status_by_questionnaire_digital',
                formatter: handbookEntry('card_status_by_questionnaire_digital'),
                filterProp: 'status_by_questionnaire_digital',
                width: 250,
            }, {
                name: 'last_card.status',
                title: this.__('Статус картки'),
                formatter: handbookEntry('card_status'),
                width: 180,
                filterable: 'card_status',
                filterProp: 'card_status',
            }, {
                name: 'note',
                title: this.__('Коментар'),
                sortable: true,
                width: 300,
            }, {
                name: 'operator_note',
                title: this.__('Коментар оператора'),
                sortable: true,
                width: 300,
            }, {
                name: 'full_organization_name_attr',
                title: this.__('Місце роботи'),
                filterable: true,
                filterProp: 'organization_name',
                sortable: true,
                width: 300,
            }, {
                name: 'educational_institution_name_attr',
                title: this.__('Назва навчального закладу'),
                filterable: true,
                filterProp: 'educational_institution_name',
                width: 300,
            }, {
                name: 'group_name_attr',
                title: this.__('Назва навчальной групи'),
                filterable: true,
                filterProp: 'institution_group_name',
                width: 300,
            }, {
                name: 'full_privileges_attr',
                title: this.__('Пільгова категорія'),
                filterable: true,
                filterProp: 'privileges',
                width: 300,
            }, {
                name: 'pension_number_attr',
                title: this.__('Серія номер пенсійного'),
                filterable: true,
                filterProp: 'pension_number',
                width: 300,
            }],
            filter: {
                full_name_attr: null,
                contact_number_attr: null,
                birth_date_attr: null,
                rnokpp_attr: null,
                created_at: null,
                id: null,
            },
            sort: {
                order: 'ascending',
                column: 'id',
            },
            select: ['*', 'lastCard.*', 'tags.*'],
            relations: [],
            sampleId: 0,
        }
    },
    methods: {
        getRepository() {
            return questionnaires();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Questionnaire, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати анкету'),
                editTitle: this.__('Редагувати анкету'),
                width: '760px',
            };
        },
        getHistoryComponent() {
            return history;
        },
        getMessages() {
            return {
                createParentConfirmation: this.__('Додати анкету дорослого?'),
                deleteConfirmation: this.__('Ви певні, що хочете видалити персональні дані?'),
                successfullyDeleted: this.__('Персональні дані успишно видалені'),
            };
        },
        documentCreated(doc) {
            if (get(doc, 'questionnaire_type_id') === constants.QUESTIONNAIRE_TYPE.PUPIL) {
                let messages = {
                    ...this.defaultMessages(),
                    ...this.getMessages(),
                };
                if (!get(doc, 'parent_id')) {
                    this.$confirm(messages.createParentConfirmation, () => {
                        this.createParentQuestionnaire(doc);
                    }, {
                        showCancelButton: false,
                    });
                }
            }
        },
        documentUpdating(doc) {
            if (!get(doc, 'parent_id')) {
                this.createParentQuestionnaire(doc);
            }
        },
        view() {
            this.getRepository().view(this.selectedItem.id)
                .then()
                .catch((err) => {
                    console.log(err)
                })
            this.edit({readOnly: true})
        },
        verification() {
            this.getRepository().verification(this.selectedItem.id)
                .then()
                .catch((err) => {
                    console.log(err)
                })
        },
        createParentQuestionnaire(doc) {
            this.create({
                child_id: get(doc, 'id'),
                disabledTypes: [
                    constants.QUESTIONNAIRE_TYPE.PUPIL,
                ]
            });
        },
        download() {

            this.getRepository().importExportLog(['export','Експорт файлів для ХП']);

            this.export = true
            fileLoader.get('/api/questionnaires/download').then((blobUrl) => {
                let link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'export.zip')
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.export = false
            });
        },
        importXP() {
            this.getRepository().importExportLog(['import','Завантаження відповіді з ХП']);
            this.importCsv();
        },
        getImportCsvComponent() {
            return FormImport;
        },
        getTagsGlossary() {
            return glossaries()
                .search(
                    (new Query)
                        .where('type', '=', 'tag')
                        .select('id', 'name')
                )
                .then((items) => {
                    return map(items, (item) => ({
                        id: item.id,
                        value: item.name,
                    }));
                })
        },
        getSourceGlossaries() {
            return this.getGlossaries('questionnaire_source');
        },
        search() {
            let messages = {
                ...this.defaultMessages(),
                ...this.getMessages(),
            };
            this.$modalComponent(search, {}, {
                done: (dialog, {doc, stopLoading}) => {
                    this.$clearErrors();
                    this.getRepository().updateInstitutionGroup(doc)
                        .then(() => {
                            dialog.close();
                            if (messages.updated) {
                                this.$success(messages.updated);
                            }
                            this.documentUpdated(doc);
                            this.refresh();
                        }).catch((err) => {
                        console.log(err);
                        stopLoading();
                        this.$catchErrors(err);
                    });
                },
            }, {
                title: 'Пошук шкільних анкет які не мають навчальний заклад',
                width: '760px',
            });
        },
        showSampleList() {
            this.$modalComponent(sample,
                {
                    selectId: this.sampleId
                },{
                    setFilterOptions: (dialog, {conditions, sampleId}) =>
                    {
                        this.conditions = conditions
                        this.sampleId = sampleId
                        if (conditions !== null) {
                            this.filter = this.getFilter()

                        } else {
                            this.filter = this.defaultFilterOption
                        }
                    }
                },
                {
                    title: 'Список вибірок',
                    width: '400px',
                });
        },
        getPlacesOfReceiving() {
          return glossaries()
            .search((new Query).where('type', '=', 'cnap'))
            .then((items) => {
              return items.map((position) => ({
                id: position.id,
                value: position.name,
              }));
            });
        },
        exportedFiles() {
            this.$modalComponent(exported, {}, {}, {
               title: 'Експортовані файли',
                width: '400px',
            });
        },
        exportToExcel(filter) {
            this.getRepository().importExportLog(['export','Експорт в excel']);
            const query = (new Query);
            this.addFilterCondition(query, filter);
            this.getRepository().exportExcel(query)
                .then((response) => {
                    this.$success('Експорт даних почався. Процес може зайняти до 30 хвилин.');
                    setTimeout(() => {
                        this.$info('Після експорту файл з`явиться у вкладинці "Експортовані файли"')
                    }, 3000)
                }).catch((err) => {
                console.log(err);
                this.$catchErrors(err);
            });
        },
        getIssueConditions() {
          return glossaries()
            .search((new Query).where('type', '=', 'chip'))
            .then((items) => {
              return items.map((glossaryItem) => ({
                id: glossaryItem.id,
                value: glossaryItem.name
              }));
            })
        },
        removePersonalData() {
            const messages = this.getMessages();
            this.$modalComponent(statement, {},{
                done: (dialog, {statement_id, stopLoading}) => {
                    this.$clearErrors();
                    this.$confirm(messages.deleteConfirmation, () => {
                        const data = {
                            statement_id,
                            questionnaire_id: this.selectedItem.id,
                        };
                        this.getRepository().deletePersonalData(data)
                            .then(() => {
                                dialog.close();
                                if (messages.successfullyDeleted) {
                                    this.$success(messages.successfullyDeleted);
                                }
                                this.refresh();
                            })
                            .catch((err) => {
                                stopLoading();
                                this.$catchErrors(err);
                            });
                        }, {
                            confirmButtonText: this.__('Видалити дані'),
                            cancelled: () => {
                                stopLoading();
                            }
                        })
                    },
                }, {
                    title: 'Завантаження копії заяви на видалення персональних даних',
                    width: '600px',
                });
        }
    },
    computed: {
        typeSampleButton() {
            if (this.sampleId !== 0) {
                return 'primary'
            } else {
                return 'default'
            }
        },
        isDisableRemove() {
            return this.selectedItem === null || get(this.selectedItem, 'personal_data_deleted_at');
        },
    },
    beforeDestroy() {
        fileLoader.revokeAll();
    },
    created() {
        const items = get(this.$route, 'query', []);
        each(items, (value, key) => {
            this.filter[key] = value;
        });
    },
}
</script>
