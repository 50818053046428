<template>
    <el-row :gutter="20"
            class="form-block">
        <el-col :span="10">
            <form-row
                :label="__('Період')"
            >
                <el-date-picker
                    v-model="datePeriod"
                    type="daterange"
                    range-separator="По"
                    start-placeholder="Початок"
                    end-placeholder="Кінець"
                    format="DD/MM/YYYY"
                    value-format="YYYY-MM-DD"
                    class="daterange"
                />
            </form-row>
        </el-col>
    </el-row>
    <data-table
        flex
        ref="table"
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('retail_order.access')"
                @click="exportToExcel(this.filter)">
                {{ __('Екпорт .xlsx') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from '@app/core/mixins/manage.js';
import retailOrder from "@app/category/repositories/retail-order";
import {datetime} from "@app/core/formatter";
import {compareAsc, format, formatDistance, subDays} from 'date-fns'


export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns:
                [{
                    name: 'cardId',
                    width: 200,
                    title: this.__('Номер КХ '),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'distributor.name',
                    width: 200,
                    title: this.__('Торгівельна мережа'),
                    sortable: true,
                    filterable: true,
                    sortProp: 'distributor_name',
                    filterProp: 'distributor_name',
                }, {
                    name: 'outlet.name',
                    width: 200,
                    title: this.__('Торгівельна точка'),
                    sortable: true,
                    filterable: true,
                    sortProp: 'outlet_name',
                    filterProp: 'outlet_name',
                }, {
                    name: 'cashdeskId',
                    width: 100,
                    title: this.__('Каса'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'orderNumber',
                    width: 100,
                    title: this.__('Номер замовлення'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'fiscalNumber',
                    width: 100,
                    title: this.__('Номер касового чеку'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'orderTime',
                    width: 100,
                    title: this.__('Час замовлення'),
                    sortable: true,
                    formatter: datetime('dd MMM HH:mm'),
                }, {
                    name: 'products',
                    width: 300,
                    title: this.__('Перелік товарів на замовлення'),
                }, {
                    name: 'totalPrice',
                    width: 100,
                    title: this.__('Загальна ціна'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'totalPriceWithDiscount',
                    width: 100,
                    title: this.__('Ціна зі знижкою'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'percentTax',
                    width: 100,
                    title: this.__('% ПДВ'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'totalTax',
                    width: 100,
                    title: this.__('Сума ПДВ'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'paymentForm',
                    width: 100,
                    title: this.__('Форма оплати'),
                    sortable: true,
                    filterable: true,
                },],
            select: ['*'],
            filterOptionList: [],
            datePeriod: '',
            filter: {},
        }
    },
    methods: {
        getRepository() {
            return retailOrder();
        },
    },
    created() {
        this.filter.datePeriod = [format(subDays(new Date(), 2).getTime(), 'yyyy-MM-dd'), format(subDays(new Date(), -1).getTime(), 'yyyy-MM-dd')];
    },
    watch: {
        datePeriod(val) {
            this.filter.datePeriod = this.datePeriod;
        }
    }
}
</script>
