<template>
    <el-aside
        :width="sideWidth">
        <div
            class="aside-wrapper"
            :class="{collapsed: isCollapse}">
            <div class="logo">
                <i class="menu-item-icon logo-item"></i>
                <div
                    v-if="version"
                    class="version"
                >
                    {{ version }}
                </div>
            </div>
            <div class="side-menu-container">
                <side-menu :is-collapse="isCollapse"></side-menu>
            </div>
            <div class="menu-footer">
                <div v-if="!isCollapse" class="user-info">
                    <svg-icon name="user-alt" class="icon-white" />
                    <span class="name">{{ userName }}</span>
                </div>
                <a href="#"
                    class="toggle-menu-btn"
                    @click.prevent="toggle"></a>
            </div>
        </div>
    </el-aside>
</template>

<script>
import SideMenu from '@app/core/components/side-menu.vue';
import {mapActions, mapGetters} from "vuex";

export default {
    components: {
        SideMenu,
    },
    data() {
        return {
            isCollapse: false,
        };
    },
    computed: {
        ...mapGetters({
            version: 'release/version',
        }),
        sideWidth() {
            return this.isCollapse ? '60px' : '280px';
        },
        appName() {
            return window.appConfig.name;
        },
        userName() {
            return this.$userName;
        },
    },
    created() {
        this.setVersion({
            system_name: 'xcard',
        });
    },
    methods: {
        ...mapActions({
            setVersion: 'release/setVersion',
        }),
        toggle() {
            this.isCollapse = !this.isCollapse;
        },
    },
}
</script>
