import Document, {
    Attribute } from "@candybox/structures/document";

import Distributor from "@app/distributor/documents/distributor";
import Glossary from "@app/handbook/documents/glossary";


class Outlet extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            outlet_id: String,
            distributor_id: Number,
            distributor: Attribute.object(Distributor, () => new Distributor()),
            manager_full_name: String,
            phone_number: String,
            address: String,
            glossary_id: Number,
            district: Attribute.object(Glossary, () => new Glossary())
        };
    }
}

export default Outlet;
