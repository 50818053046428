<script>
import BaseHistory from '@app/core/components/manage/history.vue';
import { boolean } from '@app/core/formatter.js';

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'position';
        },
        getAttributes() {
            return {
                name: this.__('Назва'),
                is_doctor: {
                    title: this.__('Є лікарем'),
                    formatter: boolean(),
                },
                is_driver: {
                    title: this.__('Є водієм'),
                    formatter: boolean(),
                },
                is_dispatcher: {
                    title: this.__('Є диспетчером'),
                    formatter: boolean(),
                },
                is_assistant: {
                    title: this.__('Є фельдшером'),
                    formatter: boolean(),
                },
                is_in_crew: {
                    title: this.__('В складі бригади'),
                    formatter: boolean(),
                },
                has_voip: {
                    title: this.__('Є телефонія'),
                    formatter: boolean(),
                },
            };
        },
    }
}
</script>