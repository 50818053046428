import Document, {
    Attribute } from '@candybox/structures/document.js';
import SubBlock from "@app/questionnaire/documents/subblock";
import RelationAttribute from "@candybox/repository/relation";
import types from "@app/questionnaire/repositories/types";

class Block extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            title: String,
            order: Number,
            is_show: Number,
            questionnaire_type_id: Number,
            $type: RelationAttribute.oneToOne(types(), 'questionnaire_type_id'),
            subblocks: Attribute.collection(SubBlock),
        };
    }
}

export default Block;
