<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Користувачі'),
            pages: [{
                index: '1',
                title: this.__('Співробітники'),
                route: {name: 'employees'},
                visible: () => (this.$can('employee.access')
                    || this.$can('employee.view_non_institution')
                    || this.$can('employee.limited_visibility')
                    || this.$can('employee.limited_access_institution')
                    || this.$can('employee.access-institution')),
            }, {
                index: '2',
                title: this.__('Ролі'),
                route: {name: 'role'},
                visible: () => (this.$can('role.access')|| this.$can('role.access-role')),
            }, {
                index: '3',
                title: this.__('Посади'),
                route: {name: 'positions'},
                visible: () => this.$can('position.access'),
            }, {
                index: '4',
                title: this.__('Запит на відкриття робочого часу'),
                route: {name: 'claim-time-work'},
                visible: () => this.$can('claim-time-work.access'),
            }, {
                index: '5',
                title: this.__('Адміністратори сервісу'),
                route: {name: 'service-employees'},
                visible: () => false,
            } ],
        }
    }
}
</script>
