<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Навчальні заклади'),
            pages: [
                {
                    index: '1',
                    title: this.__('Навчальні заклади'),
                    route: { name: 'institution' },
                    visible: () => this.$can('institution.access')? true : this.$can('institution.access-institution'),
                },
                {
                    index: '2',
                    title: this.__('Навчальні групи'),
                    route: { name: 'group' },
                    visible: () =>(this.$can('institution_group.access')
                        || this.$can('institution_group.access-institution')
                        || this.$can('institution_group.access-institution_group') ),
                },
            ],
        }
    }
}
</script>
