<template>
    <data-table
        ref="table"
        :columns="columns"
        :filter="filter"
        :repository="getRepository()"
        :criteria="criteria"
        :default-sort="sort">
    </data-table>
</template>

<script>
import { datetime } from '@app/core/formatter.js';
import actionLogs from '@app/action-log/repositories/action-logs.js';
import logEntry from '@app/core/components/manage/history-change.vue';
import employees from '@app/employee/repositories/employees.js';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'created_at',
                    title: this.__('Дата'),
                    width: 150,
                    sortable: true,
                    filterable: true,
                    formatter: datetime(),
                },
                {
                    name: 'employee.full_name',
                    title: this.__('Автор'),
                    filterable: employees(),
                    filterProp: 'employee_id',
                    filterOption: {
                        valueProp: 'full_name'
                    },
                    width: 200,
                },
                {
                    name: 'changes',
                    title: this.__('Зміни'),
                    component: Object.freeze(logEntry),
                    componentProps: {
                        attributes: this.getAttributes(),
                    },
                },
            ],
            criteria: {
                loggable_id: this.id,
                loggable_type: this.getType(),
            },
            filter: {
                employee_id: null,
            },
            sort: {
                order: 'descending',
                column: 'id',
            },
        };
    },
    methods: {
        getRepository() {
            return actionLogs();
        },
        getType() {
            throw new Error('Method getType() must be implemented in subcomponent');
        },
        getAttributes() {
            throw new Error('Method getAttributes() must be implemented in subcomponent');
        },
    },
}
</script>
