import Document from '@candybox/structures/document.js';

class Field extends Document
{
    attributes() {
        return {
            field_name: String,
            alias: String,
            doc_name: String,
        }
    }
}

export default Field;
