import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { singleton } from '@candybox/helpers.js';
import {validator} from "@candybox/validation/validator";
import {RequestMap, Method} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RequestMap();

    mapping.map('get-constructor', {
        method: Method.POST,
        route: '/api/questionnaires/constructor'
    });

    return mapping;
});
