<template>
    <AnalyticsFiltersBase
        @input="onInput"
    >
        <template #filters>
            <el-row :gutter="20">
                <el-col :span="6">
                    <form-row
                        :label="__('Період')"
                    >
                        <div class="form-input-group">
                            <el-date-picker
                                v-model="filters.periodBetween.from"
                                type="date"
                                format="DD/MM/YYYY"
                                value-format="MM/DD/YYYY"
                            />
                            <el-date-picker
                                v-model="filters.periodBetween.to"
                                type="date"
                                format="DD/MM/YYYY"
                                value-format="MM/DD/YYYY"
                            />
                        </div>
                    </form-row>
                </el-col>
                <el-col :span="6">
                    <form-row
                        :label="__('Оператор')"
                    >
                        <options-provider
                            :source="getOperators()"
                        >
                            <template v-slot:default="data">
                                <el-select
                                    v-model="filters.operator"
                                    :loading="data.loading"
                                    clearable
                                >
                                    <el-option
                                        v-for="item in data.items"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>
            </el-row>
        </template>
    </AnalyticsFiltersBase>
</template>

<script>
import AnalyticsFiltersBase from "@app/analytics/components/base/filters/AnalyticsFiltersBase.vue";
import analyticsFilterMixin from "@app/analytics/components/base/filters/mixins/analyticsFilterMixin.js";
import employees from "@app/employee/repositories/employees.js";
import Query from "@candybox/query/query.js";

export default {
    name: "OperatorsFilter",
    components: {
        AnalyticsFiltersBase,
    },
    mixins: [
        analyticsFilterMixin,
    ],
    data() {
        return {
            operators: [],
        }
    },
    methods: {
        getOperators() {
            return employees()
                .search((new Query).select('*', 'user.*').limitTo())
                .then((items) => {
                    const operators = items.filter((operator) => {
                        return _.includes(operator.user.roles, 2)
                    });
                    this.operators = operators.map((operator) => ({
                        id: operator.id,
                        value: `${operator.last_name} ${operator.first_name}`,
                    }))
                    return this.operators;
                })
        }
    },
}
</script>
