<template>
    <section class="dialog-body">
        <form-row
            :label="__('Назва')"
            :errors="$errors['name']"
            required>
            <el-input v-model="doc.name" />
        </form-row>
    </section>
</template>

<script>
export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
}
</script>
