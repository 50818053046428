<template>
    <form-row
        v-loading="loading"
        :name="property"
        :label="label"
        :required="isRequired"
        :css-class="cssClass"
        :error-prefix="errorPrefix"
        :errors="errors"
    >
        <template
            v-if="$slots['label-addon']"
            slot="label-addon">
            <slot name="label-addon" />
        </template>
        <el-upload
            ref="upload"
            :class="['upload-attachment', {'not-empty': hasAttachments, multiple: multiple}]"
            :action="''"
            :disabled="readOnly"
            :file-list="fileList"
            :limit="multiple ? limit : 1"
            :accept="accept"
            :multiple="multiple"
            :on-preview="viewFile"
            :http-request="xhr"
            :before-upload="uploadStart"
            :on-success="uploadComplete"
            :on-error="uploadError"
            :before-remove="uploadDiscard"
            :on-exceed="handleExceed"
            :show-file-list="showFileList"
            :on-remove="fileRemoved"
        >
            <template #trigger>
                <el-button
                    type="primary"
                    v-show="showButton"
                    :disabled="readOnly"
                >
                    {{ buttonText }}
                </el-button>
            </template>
            <template #tip>
                <slot name="tip">
                    <div class="el-upload__tip">
                        pdf, doc, docx, png, jpg {{ __('файли, не быльше') }} 3mb
                    </div>
                </slot>
            </template>
        </el-upload>
    </form-row>
</template>

<script>
import axios from 'axios';
import FormElement from '@app/core/mixins/form-element.js';
import FileActionMixin from '@app/core/mixins/file-action.js';
import { without, keys, values, includes, join } from 'lodash';
import fileAttachments from "@app/file-attachment/repositories/file-attachments.js";
import Query from "@candybox/query/query.js";

function upload(options) {
    let request = this.fileLoader.upload(options.file, options.file.name, {
        onUploadProgress: (event) => {
            if (event.total > 0) {
                event.percent = event.loaded / event.total * 100;
            } else {
                event.percent = 0;
            }
            options.onProgress(event);
        }
    });

    request.promise().then((response) => {
        options.onSuccess(response.data);
    }).catch((error) => {
        options.onError(error);
    });

    return request;
}

export default {
    mixins: [
        FormElement,
        FileActionMixin,
    ],
    props: {
        buttonText: {
            type: String,
            default: 'Прикріпити файл',
        },
        limit: {
            type: Number,
            default: 100,
        },
        accept: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        onPreview: {
            type: [Function, Boolean],
            default: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        showFileList: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Object,
            nullable: true,
        }
    },
    computed: {
        xhr() {
            return upload.bind(this);
        },
        showButton() {
            return true;
        },
        hasAttachments() {
            return this.multiple
                ? this.model && (this.model.length !== 0)
                : this.model !== null;
        },
    },
    data() {
        return {
            loading: false,
            fileList: [],
            countUploads: 0,
            imageUrl: null,
            finishUploads: false,
            files: [],
        };
    },
    mounted() {
        if (this.multiple) {
            if (this.model && this.model.length !== 0) {
                this.loadFiles(this.model);
            }
        } else if (this.model !== null) {
            this.loadFiles(this.model);
        }
    },
    methods: {
        handleExceed(files) {
            const file = files[0];
            this.$refs.upload.clearFiles();
            this.$refs.upload.handleStart(file);
        },
        uploadStart(response, file) {
            const res = this.validate(response);
            if (!res) {
                return res;
            }
            this.countUploads++;
            this.$emit('uploads-started', this.countUploads);
        },
        uploadComplete(response, file, fileList) {
            if (this.multiple) {
                this.model.push(response.id);
            } else {
                this.model = response.id;
            }
            this.countUploads--;
            this.$emit('uploads-completed', this.countUploads);
        },
        uploadError(error) {
            debugger
            if (!axios.isCancel(error)) {
                this.$error(this.__('Неможливо завантажити файл'));
            }
            this.countUploads--;
            this.$emit('uploads-completed', this.countUploads);
        },
        uploadDiscard(file, fileList) {
            if (file.status === 'uploading') {
                this.$refs.upload.abort(file);
                return false;
            }
        },
        fileRemoved(file, fileList) {
            if (file.response) {
                if (this.multiple) {
                    this.model = without(this.model, file.response.id);
                } else {
                    this.model = null;
                }
            }
            this.$emit('file-removed');
        },
        viewFile(file) {
            //
        },
        onError(error) {
            console.log('error', error);
        },
        loadFiles(ids) {
            this.loading = true;
            fileAttachments()
                .search(
                    (new Query)
                        .where('id', 'in', ids)
                )
                .then((results) => {
                    results.forEach((row) => {
                        this.fileList.push({
                            name: row.name,
                            url: row.url,
                            response: row,
                        });
                    });
                this.loading = false;
            });
        },
        validate(response) {
            const formats = {
                jpeg: 'image/jpeg',
                jpg: 'image/jpg',
                png: 'image/png',
                pdf: 'application/pdf',
                docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                doc: 'application/msword',
            };
            if (!includes(values(formats), response.type)) {
                this.$error(this.__('Файли потрібні бути слідуючих форматів') + join(keys(formats), ', '));
                return false
            } else if (response.size / 1024 / 1024 > 3) {
                this.$error(this.__('Розмір файлу потрібен бути менше 3Мб'));
                return false
            }

            return true;
        }
    },
    watch: {
        finishUploads(val) {
            if(val) {
                this.$emit('getUploadedFiles', this.files);
            }
        }
    }
};
</script>
