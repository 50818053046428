import Document, {Attribute} from '@candybox/structures/document.js';
import Employee from "@app/employee/documents/employee";


class ClaimTimeWork extends Document {
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            reason: String,
            status: String,
            start: Date,
            end: Date,
            created_at: Date,
            employee_id: Number,
            employee: Attribute.object(Employee, () => new Employee(), false),
        };
    }
}

export default ClaimTimeWork;
