<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('glossary.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('glossary.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('glossary.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/handbook/components/card-handbook/form.vue';
import manage from '@app/core/mixins/manage.js';
import cardHandbook from "@app/handbook/repositories/card-handbook";
import CardHandbook from "@app/handbook/documents/card-handbook";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'code',
                    title: this.__('Код'),
                    sortable: true,
                    filterable: true,
                    width: '150px',
                },
                {
                    name: 'status_khp',
                    title: this.__('Опис'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'digital_handbook_key',
                    title: this.__('Статус цифрової картки за анкетою'),
                    sortable: true,
                    filterable: 'card_status_by_questionnaire_digital',
                    formatter: (val) => {
                        return this.$handbook(`card_status_by_questionnaire_digital.${val}`);
                    },
                    width: '250px',
                },
                {
                    name: 'handbook_key',
                    title: this.__('Статус платикової картки за анкетою'),
                    sortable: true,
                    filterable: 'card_status_by_questionnaire',
                    formatter: (val) => {
                        return this.$handbook(`card_status_by_questionnaire.${val}`);
                    },
                    width: '260px',
                },
                {
                    name: 'card_status_handbook_key',
                    title: this.__('Статус картки'),
                    sortable: true,
                    filterable: 'card_status',
                    formatter: (val) => {
                        return this.$handbook(`card_status.${val}`);
                    },
                    width: '200px',
                },
                {
                    name: 'questionnaire_status_handbook_key',
                    title: this.__('Статус анкети'),
                    sortable: true,
                    filterable: 'questionnaire_status',
                    formatter: (val) => {
                        return this.$handbook(`questionnaire_status.${val}`);
                    },
                    width: '200px',
                },
            ],
            filter: {},
            select: ['*'],
        }
    },
    methods: {
        getRepository() {
            return cardHandbook();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(CardHandbook, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати код'),
                editTitle: this.__('Редагувати код'),
                width: '340px',
            };
        },
    },
}
</script>
