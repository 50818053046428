<template>
    <div
        class="column-filter"
        @click.stop="nop">
        <search-input
            v-if="source === true"
            size="small"
            v-model="val"
            @querychange="changed">
        </search-input>
        <options-provider
            v-else
            :source="source"
            :empty-option="__('Всі')"
            :valueProp="valueProp">
            <template v-slot:default="data">
                <el-select
                    size="small"
                    :loading="data.loading"
                    v-model="val"
                    clearable
                    @change="changed">
                    <el-option
                        v-for="item in data.items"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id" />
                </el-select>
            </template>
        </options-provider>
    </div>
</template>

<script>
export default {
    props: {
        source: {
            type: [Boolean, Array, Object, String],
            required: true,
        },
        modelValue: {
            type: [String, Number, Array, Object]
        },
        sourceOptions: {
            type: Object,
            default: () => ({}),
        },
        valueProp: {
            type: String,
            default: 'name',
        },
    },
    data() {
        return {
            val: this.modelValue,
        }
    },
    methods: {
        changed(val) {
            this.$emit('update:modelValue', val);
        },
        nop() {
        }
    },
    watch: {
        modelValue(v) {
            this.val = v;
        },
    },
}
</script>
