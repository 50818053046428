import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import {get, singleton} from '@candybox/helpers.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/category/category');

    mapping.validation('store', () => {
        return {
            name: validator()
                .length(0, 255).required(),
            parent_id: validator()
                .integer(),
            original_id: validator()
                .integer(),
            type: validator()
                .integer(),
            unified_code: validator()
                .required()
                .length(10),
        }
    });

    return mapping;
});
