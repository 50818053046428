<template>
    <form-container>
    <section class="dialog-body" >
        <el-row :gutter="20"
                v-if="!employee">
            <el-col :span="12" >
                <form-row
                    :label="__('РНКОПП')">
                    <el-input
                        ref="rnokpp_input"
                        maxlength="10"
                        show-word-limit
                        clearable
                        v-model="rnokpp"/>
                </form-row>
             </el-col>
            <el-col :span="12" >
                <div  style="padding-top:15px">
                <el-button
                    type="primary"
                    :loading="loading"
                    :disabled="!disabled"
                    @click="searchEmployee">
                    {{ __('Знайти') }}
                </el-button></div>
            </el-col>
        </el-row>
        <el-row :gutter="20"
                v-if="employee">
            <el-col :span="14">
                <div class="employee_pib">{{__('Прізвище')}}: {{employee.last_name}}</div>
                <div class="employee_pib">{{__('Ім’я')}}: {{employee.first_name}}</div>
                <div class="employee_pib">{{__('По батькові')}}: {{employee.middle_name}}</div>

                <el-button
                    type="danger"
                    :loading="loading"
                    @click="clear()">
                    {{ __("Очистити пошук") }}
                </el-button>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Обрати навчальний заклад')"
                    :errors="$errors['institution']">
                    <options-provider
                        :source="getInstitutions()">
                        <template v-slot:default="data">
                            <el-select
                                v-model="employee.institution_id"
                                clearable>
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>

    <section class="dialog-footer">
        <el-button
            @click="cancel">
            {{ __('Скасувати') }}
        </el-button>
        <el-button
            type="primary"
            :loading="loading"
            :disabled="disabledDone"
            @click="done">
            {{ __('Оновити') }}
        </el-button>
    </section>
    </form-container>
</template>

<script>
import Query from "@candybox/query/query";
import employees from "@app/employee/repositories/employees";
import institutions from "@app/institution/repositories/institutions";
import Employee from "@app/employee/documents/employee";

export default {
    data() {
        return {
            rnokpp:null,
            loading: false,
            employee: null,
            disabled: false,
            disabledDone: true,
        };
    },
    inject: [
        'modalApi',
    ],
    mounted(){
        this.$refs.rnokpp_input.focus();
    },
    watch: {
        rnokpp(val) {
            if(val) {
                if (val.length === 10) {
                    this.searchEmployee();
                    this.disabled = true
                }else  {
                    this.disabled = false
                }
            }
        },
        ['employee.institution_id'](val) {
            if(val) {
               this.disabledDone = false;
            }
        },
    },
    methods: {
        done() {
            this.loading = true;
            this.$emit('done', {
                doc: this.employee,
                stopLoading: () => {
                    this.loading = false;
                }
            });
        },
        cancel() {
            this.modalApi.close();
        },
        getInstitutions() {
            return institutions();
        },

        searchEmployee() {
            this.loading = true;
            return employees()
                .limitedSearch(
                    (new Query)
                        .select('*', 'user.*')
                        .where('rnokpp', '=', this.rnokpp)
                        .where('doesnt_have_institution', '=', true)
                        .where('roles_codes', '=', 4))
                .then((items) => {
                    const curItem = _.first(items);
                       if (curItem){
                           this.employee = new Employee(curItem);
                       } else {
                           this.$warning('Пошук не дав результат');
                       }
                    this.loading = false;
                })
                .catch((err) => {
                    console.log('search errors', err);
                    this.loading = false;
                })
        },
        clear(){
            this.employee = null;
            this.rnokpp = null;
            this.loading = false;
        }
    }

}
</script>
