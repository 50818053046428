<template>
    <div>
        <div>
            <PeriodFilter
                :ext-filters="filters"
            />
        </div>
        <el-scrollbar height="calc(100vh - 265px)">
            <PieChartSet
                :items="totalCountAnalytics"
                :loading="totalCountAnalyticsLoading"
                class="mb-80"
            />
            <PieChartSet
                :items="byTypeCountAnalytics"
                :loading="byTypeCountAnalyticsLoading"
                class="mb-80"
            />
            <BarChart
                :config="byTypeExternalCountAnalytics"
                :loading="byTypeExternalCountAnalyticsLoading"
            />
            <PieChartSet
                :items="byStatusesCountAnalytics"
                :loading="totalCountAnalyticsLoading"
            />
        </el-scrollbar>
    </div>
</template>

<script>

import BarChart from "@app/analytics/components/base/charts/BarChart.vue"
import PieChart from "@app/analytics/components/base/charts/PieChart.vue";
import LineChart from "@app/analytics/components/base/charts/LineChart.vue";
import PieChartSet from "@app/analytics/components/base/chart-sets/PieChartSet.vue";
import ColumnChart from "@app/analytics/components/base/charts/ColumnChart.vue";
import PeriodFilter from "@app/analytics/components/base/PeriodFilter";
import QuestionnairesAnalytics from "@app/analytics/repositories/questionnaire-analytics.js"
import Query from "@candybox/query/query.js";
import { get } from "@candybox/helpers.js";

export default {
    name: "Questionnaire-analytics",
    components: {
        PeriodFilter,
        BarChart,
        PieChart,
        LineChart,
        PieChartSet,
        ColumnChart,
    },
    data() {
        return {
            filters: {
                periodBetween: {
                    from: null,
                    to: null,
                },
            },
            totalCountAnalyticsLoading: true,
            byTypeCountAnalyticsLoading: true,
            byTypeExternalCountAnalyticsLoading: true,
            byStatusesCountAnalyticsLoading: true,
            totalCountAnalytics: [
                {
                    title: 'Всього в системі',
                    colors: {
                        'Всього в системі': '#17783C',
                    },
                    data: {
                        'Всього в системі': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Перевірку пройдено',
                    colors: {
                        'Перевірку пройдено': '#CF0C47',
                    },
                    data: {
                        'Перевірку пройдено': 200,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Вивантажені з Системи для ХП',
                    colors: {
                        'Вивантажені з Системи для ХП': '#1B6EBE',
                    },
                    data: {
                        'Вивантажені з Системи для ХП': 300,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
            ],
            byTypeCountAnalytics: [
                {
                    title: 'Пільгова',
                    colors: {
                        'Пільгова': '#CF0C47',
                    },
                    data: {
                        'Пільгова': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Звичайна',
                    colors: {
                        'Звичайна': '#4F5A6E',
                    },
                    data: {
                        'Звичайна': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Учнівська',
                    colors: {
                        'Учнівська': '#5A0723',
                    },
                    data: {
                        'Учнівська': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Комунальна',
                    colors: {
                        'Комунальна': '#F44336',
                    },
                    data: {
                        'Комунальна': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Студентська',
                    colors: {
                        'Студентська': '#0035a8',
                    },
                    data: {
                        'Студентська': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Для приватних підприємств',
                    colors: {
                        'Для приватних підприємств': '#DEA000',
                    },
                    data: {
                        'Для приватних підприємств': 100,
                    },
                    width: 200,
                    height: 200,
                    pieHole: 0.7,
                    withLegend: false,
                },
    ],
            byTypeExternalCountAnalytics: {
                title: 'Розподіл анкет за типами',
                columns: [
                    'Готується до відправки на друк',
                    'Перевірка пройдена',
                    'Передано до ТП',
                    'Надруковано',
                    'Кількість виданих Карток',
                ],
                withDescriptions: true,
                colors: {
                    'Готується до відправки на друк': '#019f41',
                    'Перевірка пройдена': '#1B6EBE',
                    'Передано до ТП': '#D5683D',
                    'Запрограмовано ХП': '#CF0C47',
                    'Кількість виданих Карток': '#DEA000',
                },
                data: {
                    'Учнівська': {
                        'Готується до відправки на друк': 333,
                        'Перевірка пройдена': 222,
                        'Передано до ТП': 111,
                        'Запрограмовано ХП': 999,
                        'Кількість виданих Карток': 999,
                    },
                    'Звичайна': {
                        'Готується до відправки на друк': 33,
                        'Перевірка пройдена': 522,
                        'Передано до ТП': 111,
                        'Запрограмовано ХП': 99,
                        'Кількість виданих Карток': 99,
                    },
                    'Комунальна': {
                        'Готується до відправки на друк': 343,
                        'Перевірка пройдена': 92,
                        'Передано до ТП': 81,
                        'Запрограмовано ХП': 349,
                        'Кількість виданих Карток': 349,
                    },
                    'Пільгова': {
                        'Готується до відправки на друк': 454,
                        'Перевірка пройдена': 245,
                        'Передано до ТП': 678,
                        'Запрограмовано ХП': 453,
                        'Кількість виданих Карток': 453,
                    },
                    'Студентська': {
                        'Готується до відправки на друк': 578,
                        'Перевірка пройдена': 54,
                        'Передано до ТП': 879,
                        'Запрограмовано ХП': 456,
                        'Кількість виданих Карток': 456,
                    },
                    'Для приватних підприємств': {
                        'Готується до відправки на друк': 578,
                        'Перевірка пройдена': 54,
                        'Передано до ТП': 879,
                        'Запрограмовано ХП': 456,
                        'Кількість виданих Карток': 456,
                    },
                },
            },
            byStatusesCountAnalytics: [
                {
                    title: 'Анкети в системі',
                    colors: {
                        'На уточненні': '#CF0C47',
                        'Не верифіковано': '#1bbe02',
                        'Верифіковано автоматично': '#0035a8',
                        'Верифіковано(виставляється оператором)': '#f19000',
                        'Дублікат': '#00BBD3',
                        'Готується до відправки до друку': '#F44336',
                        'Готується до відправки до друку без верифікації (виставляється оператором)': '#4F5A6E',
                        'Перевірка ХП': '#DEA000',
                        'Перевірка пройдена': '#5A0723',
                    },
                    data: {
                        'На уточненні': 50,
                        'Не верифіковано': 50,
                        'Верифіковано автоматично': 50,
                        'Верифіковано(виставляється оператором)': 50,
                        'Дублікат': 50,
                        'Готується до відправки до друку': 50,
                        'Готується до відправки до друку без верифікації (виставляється оператором)': 50,
                        'Перевірка ХП': 50,
                        'Перевірка пройдена': 50,
                    },
                    width: 450,
                    height: 450,
                    isValuePercent: true,
                },
                {
                    title: 'Карти в роботі',
                    colors: {
                        'Передано на друк': '#CF0C47',
                        'Передано до ТП': '#1bbe02',
                        'Друкується': '#0035a8',
                        'Запрограмовано ХП': '#000000',
                        'Готується до видачі': '#00BBD3',
                        'Вручено': '#F44336',
                        'Перевипуск': '#5A0723',
                    },
                    data: {
                        'Передано на друк': 100,
                        'Передано до ТП': 100,
                        'Друкується': 100,
                        'Запрограмовано ХП': 100,
                        'Готується до видачі': 100,
                        'Вручено': 100,
                        'Перевипуск': 100,
                    },
                    width: 450,
                    height: 450,
                    isValuePercent: true,
                },
                {
                    title: 'Карти',
                    colors: {
                        'Використовується': '#CF0C47',
                        'Заблоковано': '#1bbe02',
                        'Архів': '#0035a8',
                    },
                    data: {
                        'Використовується': 200,
                        'Заблоковано': 200,
                        'Архів': 200,
                    },
                    width: 450,
                    height: 450,
                    isValuePercent: true,
                },

            ],
        }
    },
    methods: {
        async totalCountAnalyticsLoad(filters) {
            this.totalCountAnalyticsLoading = true;
            return QuestionnairesAnalytics()
                .getTotal({filters})
                .then((res) => {
                    this.totalCountAnalytics[0].data['Всього в системі'] = get(res, 'total', 1);
                    this.totalCountAnalytics[1].data['Перевірку пройдено'] = get(res, 'passed', 1);
                    this.totalCountAnalytics[2].data['Вивантажені з Системи для ХП'] = get(res, 'loaded', 1);
                    this.totalCountAnalyticsLoading = false;
                });
        },
        async byTypeCountAnalyticsLoad(filters) {
            this.byTypeCountAnalyticsLoading = true;
            return QuestionnairesAnalytics()
                .getByType({filters})
                .then((res) => {
                    this.byTypeCountAnalytics[0].data['Пільгова'] = get(res, 'social', 1);
                    this.byTypeCountAnalytics[1].data['Звичайна'] = get(res, 'standard', 1);
                    this.byTypeCountAnalytics[2].data['Учнівська'] = get(res, 'pupil', 1);
                    this.byTypeCountAnalytics[3].data['Комунальна'] = get(res, 'communal', 1);
                    this.byTypeCountAnalytics[4].data['Студентська'] = get(res, 'student', 1);
                    this.byTypeCountAnalytics[5].data['Для приватних підприємств'] = get(res, 'private', 1);
                    this.byTypeCountAnalyticsLoading = false;
                });
        },
        async byTypeExternalCountAnalyticsLoad(filters) {
            this.byTypeExternalCountAnalyticsLoading = true;
            return QuestionnairesAnalytics()
                .getByTypeExternal({filters})
                .then((res) => {
                    this.byTypeExternalCountAnalytics.data['Пільгова']['Готується до відправки на друк'] = get(res, 'social.0', 1);
                    this.byTypeExternalCountAnalytics.data['Пільгова']['Перевірка пройдена'] = get(res, 'social.1', 1);
                    this.byTypeExternalCountAnalytics.data['Пільгова']['Передано до ТП'] = get(res, 'social.2', 1);
                    this.byTypeExternalCountAnalytics.data['Пільгова']['Запрограмовано ХП'] = get(res, 'social.3', 1);
                    this.byTypeExternalCountAnalytics.data['Пільгова']['Кількість виданих Карток'] = get(res, 'social.4', 1);

                    this.byTypeExternalCountAnalytics.data['Звичайна']['Готується до відправки на друк'] = get(res, 'standard.0', 1);
                    this.byTypeExternalCountAnalytics.data['Звичайна']['Перевірка пройдена'] = get(res, 'standard.1', 1);
                    this.byTypeExternalCountAnalytics.data['Звичайна']['Передано до ТП'] = get(res, 'standard.2', 1);
                    this.byTypeExternalCountAnalytics.data['Звичайна']['Запрограмовано ХП'] = get(res, 'standard.3', 1);
                    this.byTypeExternalCountAnalytics.data['Звичайна']['Кількість виданих Карток'] = get(res, 'standard.4', 1);

                    this.byTypeExternalCountAnalytics.data['Учнівська']['Готується до відправки на друк'] = get(res, 'pupil.0', 1);
                    this.byTypeExternalCountAnalytics.data['Учнівська']['Перевірка пройдена'] = get(res, 'pupil.1', 1);
                    this.byTypeExternalCountAnalytics.data['Учнівська']['Передано до ТП'] = get(res, 'pupil.2', 1);
                    this.byTypeExternalCountAnalytics.data['Учнівська']['Запрограмовано ХП'] = get(res, 'pupil.3', 1);
                    this.byTypeExternalCountAnalytics.data['Учнівська']['Кількість виданих Карток'] = get(res, 'pupil.4', 1);

                    this.byTypeExternalCountAnalytics.data['Комунальна']['Готується до відправки на друк'] = get(res, 'communal.0', 1);
                    this.byTypeExternalCountAnalytics.data['Комунальна']['Перевірка пройдена'] = get(res, 'communal.1', 1);
                    this.byTypeExternalCountAnalytics.data['Комунальна']['Передано до ТП'] = get(res, 'communal.2', 1);
                    this.byTypeExternalCountAnalytics.data['Комунальна']['Запрограмовано ХП'] = get(res, 'communal.3', 1);
                    this.byTypeExternalCountAnalytics.data['Комунальна']['Кількість виданих Карток'] = get(res, 'communal.4', 1);

                    this.byTypeExternalCountAnalytics.data['Студентська']['Готується до відправки на друк'] = get(res, 'student.0', 1);
                    this.byTypeExternalCountAnalytics.data['Студентська']['Перевірка пройдена'] = get(res, 'student.1', 1);
                    this.byTypeExternalCountAnalytics.data['Студентська']['Передано до ТП'] = get(res, 'student.2', 1);
                    this.byTypeExternalCountAnalytics.data['Студентська']['Запрограмовано ХП'] = get(res, 'student.3', 1);
                    this.byTypeExternalCountAnalytics.data['Студентська']['Кількість виданих Карток'] = get(res, 'student.4', 1);

                    this.byTypeExternalCountAnalytics.data['Для приватних підприємств']['Готується до відправки на друк'] = get(res, 'private.0', 1);
                    this.byTypeExternalCountAnalytics.data['Для приватних підприємств']['Перевірка пройдена'] = get(res, 'private.1', 1);
                    this.byTypeExternalCountAnalytics.data['Для приватних підприємств']['Передано до ТП'] = get(res, 'private.2', 1);
                    this.byTypeExternalCountAnalytics.data['Для приватних підприємств']['Запрограмовано ХП'] = get(res, 'private.3', 1);
                    this.byTypeExternalCountAnalytics.data['Для приватних підприємств']['Кількість виданих Карток'] = get(res, 'private.4', 1);

                    this.byTypeExternalCountAnalyticsLoading = false;
                });
        },
        async byStatusesAnalyticsLoad(filters) {
            this.totalCountAnalyticsLoading = true;
            return QuestionnairesAnalytics()
                .getByStatusesExternal({filters})
                .then((res) => {
                    this.byStatusesCountAnalytics[0].data['На уточненні'] = get(res, 'questionnaire.0', 1);
                    this.byStatusesCountAnalytics[0].data['Не верифіковано'] = get(res, 'questionnaire.1', 1);
                    this.byStatusesCountAnalytics[0].data['Верифіковано автоматично'] = get(res, 'questionnaire.2', 1);
                    this.byStatusesCountAnalytics[0].data['Верифіковано(виставляється оператором)'] = get(res, 'questionnaire.3', 1);
                    this.byStatusesCountAnalytics[0].data['Дублікат'] = get(res, 'questionnaire.4', 1);
                    this.byStatusesCountAnalytics[0].data['Готується до відправки до друку'] = get(res, 'questionnaire.5', 1);
                    this.byStatusesCountAnalytics[0].data['Готується до відправки до друку без верифікації (виставляється оператором)'] = get(res, 'questionnaire.6', 1);
                    this.byStatusesCountAnalytics[0].data['Перевірка ХП'] = get(res, 'questionnaire.7', 1);
                    this.byStatusesCountAnalytics[0].data['Перевірка пройдена'] = get(res, 'questionnaire.8', 1);

                    this.byStatusesCountAnalytics[1].data['Передано на друк'] = get(res, 'questionnaire_card.0', 1);
                    this.byStatusesCountAnalytics[1].data['Передано до ТП'] = get(res, 'questionnaire_card.1', 1);
                    this.byStatusesCountAnalytics[1].data['Друкується'] = get(res, 'questionnaire_card.2', 1);
                    this.byStatusesCountAnalytics[1].data['Запрограмовано ХП'] = get(res, 'questionnaire_card.3', 1);
                    this.byStatusesCountAnalytics[1].data['Готується до видачі'] = get(res, 'questionnaire_card.4', 1);
                    this.byStatusesCountAnalytics[1].data['Вручено'] = get(res, 'questionnaire_card.5', 1);
                    this.byStatusesCountAnalytics[1].data['Перевипуск'] = get(res, 'questionnaire_card.6', 1);

                    this.byStatusesCountAnalytics[2].data['Використовується'] = get(res, 'card.0', 1);
                    this.byStatusesCountAnalytics[2].data['Заблоковано'] = get(res, 'card.1', 1);
                    this.byStatusesCountAnalytics[2].data['Архів'] = get(res, 'card.2', 1);

                    this.totalCountAnalyticsLoading = false;
                });
        },
        async loadData() {
            const filters = this.filters.periodBetween;

            await this.totalCountAnalyticsLoad(filters)
            await this.byTypeCountAnalyticsLoad(filters)
            await this.byTypeExternalCountAnalyticsLoad(filters)
            await this.byStatusesAnalyticsLoad(filters)
        },
    },
    created() {
        this.loadData();
    },
    watch: {
        ['filters.periodBetween']: {
            handler(filters) {
                if(filters.from && filters.to) {
                    this.loadData()
                } else if (!filters.from && !filters.to) {
                    this.loadData()
                }
            },
            deep: true,
        }
    },
}
</script>
