import lts from '@app/services/lts.js';
import {count} from "@candybox/helpers.js";
import {last, get} from "lodash";
import {STATE_IN_PROGRESS} from "@app/services/sip-ua/call.js";

export default {
    namespaced: true,
    state: () => ({
        updated: false,
        connected: false,
        available: false,
        number: null,
        uaState: null,
        call: null,
        contacts: [],
        parkedCalls: [],
        processing: false,
        callState: null,
        stateManagerCounts: {
            sessionStartTime: null,
            pauseStartTime: null,
            callStartTime: null,
            conferenceStartTime: null,
            wrapupStartTime: null,
            callTotalDuration: 0,
            pauseTotalDuration: 0,
            pauseTotalCount: 0,
        }
    }),
    mutations: {
        setConnected(state, connected) {
            state.connected = connected;
        },
        setAvailable(state, available) {
            state.available = available;
        },
        setNumber(state, number) {
            state.number = number;
        },
        setUaState(state, uaState) {
            state.uaState = uaState;
        },
        setCall(state, call) {
            state.call = call;
        },
        setContacts(state, contacts) {
            state.contacts = contacts;
        },
        setParkedCalls(state, parkedCalls) {
            state.parkedCalls = parkedCalls;
        },
        setProcessing(state, processing) {
            state.processing = processing;
        },
        setCallState(state, callState) {
            state.callState = callState;
        },
        setStateManagerCounts(state, {key, value}) {
            state.stateManagerCounts[key] = value;
        },
        resetStateManagerCounts(state) {
            state.stateManagerCounts = {
                sessionStartTime: null,
                pauseStartTime: null,
                callStartTime: null,
                conferenceStartTime: null,
                wrapupStartTime: null,
                callTotalDuration: 0,
                pauseTotalDuration: 0,
                pauseTotalCount: 0,
            }
        },
        setUpdated(state, updated) {
            state.updated = updated;
        },
    },
    getters: {
        getConnected(state) {
            return state.connected;
        },
        getAvailable(state) {
            return state.available;
        },
        getNumber(state) {
            return state.number;
        },
        getUaState(state) {
            return state.uaState;
        },
        getUaStateName(state) {
            return get(state.uaState, 'name');
        },
        getCall(state) {
            return state.call;
        },
        getContacts(state) {
            return state.contacts;
        },
        getParkedCalls(state) {
            return state.parkedCalls;
        },
        getLastParkedCall(state) {
            return count(state.parkedCalls) ? last(state.parkedCalls) : null;
        },
        getProcessing(state) {
            return state.processing;
        },
        getCallState(state) {
            return state.callState;
        },
        getStateManagerCounts(state) {
            return state.stateManagerCounts;
        },
        getUpdated(state) {
            return state.updated;
        },
    },
}

