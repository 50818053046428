import Excel from 'exceljs';
import { get, map, forEach } from '@candybox/helpers.js';
import FileSaver from 'file-saver';

const ALIGNMENT = {vertical: 'middle', horizontal: 'center', wrapText: true,};
const FONT_BOLD = {
    bold: true,
    size: 10,
    font: 'Calibri',
};

const FILL_GREY = {
    type: 'pattern',
    pattern:'solid',
    fgColor: {
        argb: 'F2F2F2',
    }
};

class ExportExcel {

    _rows = [];
    _columns = [];

    constructor(data = [], columns = []) {
        this._rows = data;
        this._columns = columns;
    }

    addWorkBook() {
        return new Excel.Workbook();
    }

    addWorkSheet(workbook, name) {
        return workbook.addWorksheet(name, {
            views: [
                { state: 'frozen', ySplit: 1,},
            ],
        });
    }

    addColumns(worksheet, columns) {
        worksheet.columns = [...map(
            columns,
            (column) => ({
                header: column.title,
                key: column.name,
                style: {
                    alignment: ALIGNMENT,
                    // font: FONT_BOLD,
                    // fill: FILL_GREY,
                },
                width: 20,
                border: {
                    top: { style: 'double', color: {argb: 'FF000000'}},
                    left: {style: 'double', color: {argb: 'FF000000'}},
                    bottom: {style: 'double', color: {argb: 'FF000000'}},
                    right: {style: 'double', color: {argb: 'FF000000'}}}
            })
        )]
    }

    addRows(worksheet, columns, rows) {
        const preparedRows = [...map(
            rows,
            (row) => {
                const _dict = {};
                forEach(columns, (column) => {
                    const columnName = get(column, 'name');
                    const formatter = get(column, 'formatter');
                    const value = get(row, columnName);
                    _dict[columnName] = formatter ? formatter(value) : value;
                })
                return _dict;
            }
        )];

        forEach(preparedRows, (row) => {
            worksheet.addRow(row);
        })
        let titleRow = worksheet.getRow(1);
        titleRow.font = FONT_BOLD;
        titleRow.fill = FILL_GREY;
    }

    async export(filename, sheetname) {
        let workbook = this.addWorkBook();
        const worksheet = this.addWorkSheet(workbook, sheetname);
        this.addColumns(worksheet, this._columns);
        this.addRows(worksheet, this._columns, this._rows);

        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), filename);
    }
}

export default ExportExcel;
