import Document from '@candybox/structures/document.js';

class Tag extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            glossary_id: Number,
            is_enabled: String,
        };
    }
}

export default Tag;
