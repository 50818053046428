export default {
    data() {
        return {
            defaultFilterOption: {
                full_name_attr: null,
                contact_number_attr: null,
                birth_date_attr: null,
                rnokpp_attr: null,
                created_at: null,
                id: null,
            },
            conditions: [],
        }
    },
    methods: {
        getFilter() {
            let filterOptions = {};
            let key, value;
            this.conditions.forEach((filter) => {
                if(filter.selectedField === 'birth_date') {
                    key = 'birth_date_sample';
                } else if (filter.selectedField === 'status' && filter.source === 'card') {
                    key = 'status_by_card';
                } else {
                    key = filter.selectedField;
                }
                if (filter.secondCondition !== null) {
                    const mode = filter.firstCondition;
                    const query = filter.secondCondition === 'null' ? null : filter.secondCondition;
                    value = {
                        mode,
                        query,
                    }
                } else {
                    value = filter.firstCondition;
                }
                filterOptions[key] = value;
            })
            return filterOptions
        }
    }
}
