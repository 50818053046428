import RepositoryRequestMap from "@candybox/repository/request-map";
import { validator } from "@candybox/validation/validator";
import { singleton } from "@candybox/helpers";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('api/script');

    mapping.validation('store', () => {
        return {
            name: validator()
                .required()
                .length(0, 100),
            content: validator()
                .required()
                .length(0, 65535),
        }
    });

    return mapping;
});
