import RestRepository from '@candybox/repository/rest.js';
import QuestionnaireAnalytics from '@app/analytics/documents/questionnaire-analytics.js';
import QuestionnaireAnalyticsMap from '@app/analytics/requests/questionnaire-analytics-repository.js';
import { singleton } from '@candybox/helpers.js';

class QuestionnaireAnalyticsRepository extends RestRepository
{
    getTotal(data) {
        return this._request('totalQuestionnaireAnalytics', data);
    }
    getByType(data) {
        return this._request('byTypeQuestionnaireAnalytics', data);
    }
    getByTypeExternal(data) {
        return this._request('byTypeExternalQuestionnaireAnalytics', data);
    }
    getByStatusesExternal(data) {
        return this._request('byStatusesQuestionnaireAnalytics', data);
    }
}

export default singleton(() => {
    return new QuestionnaireAnalyticsRepository(QuestionnaireAnalytics, QuestionnaireAnalyticsMap());
});
