import RestRepository from '@candybox/repository/rest.js';
import OperatorsAnalytics from '@app/analytics/documents/operator-analytics.js';
import OperatorsAnalyticsMap from '@app/analytics/requests/operators-analytics-repository.js';
import { singleton } from '@candybox/helpers.js';

class QuestionnaireAnalyticsRepository extends RestRepository
{
    getTotal1(data) {
        return this._request('totalOperatorsAnalytics1', data);
    }
    getMarks(data) {
        return this._request('marksOperatorsAnalytics', data);
    }
    getTotal2(data) {
        return this._request('totalOperatorsAnalytics2', data);
    }
    getDurations(data) {
        return this._request('durationsOperatorsAnalytics', data);
    }
    getCallsReasons(data) {
        return this._request('callsReasonsOperatorsAnalytics', data);
    }
    getCalls(data) {
        return this._request('callsOperatorsAnalytics', data);
    }
}

export default singleton(() => {
    return new QuestionnaireAnalyticsRepository(OperatorsAnalytics, OperatorsAnalyticsMap());
});
