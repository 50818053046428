<template>
    <page :title="title">
        <template #heading>
            <slot name="heading"/>
        </template>
        <template #heading-addons>
            <slot name="heading-addons"/>
        </template>
        <el-menu
            :default-active="activePage"
            mode="horizontal"
            class="mb-15"
        >
            <el-menu-item
                v-for="page in visiblePages"
                :key="page.index"
                :index="page.index"
                @click="itemClick(page)">
                {{ page.title }}
            </el-menu-item>
        </el-menu>
        <router-view :key="$route.path" />
        <template #footer>
            <slot name="footer"/>
        </template>
    </page>
</template>

<script>
export default {
    computed: {
        visiblePages() {
            return this.pages.filter((page) => page.visible() !== false);
        },
        activePage() {
            return (this.pages.find((page) => this.isMatchedRoute(page.route)) || {}).index;
        },
    },
    mounted() {
        let visible = this.visiblePages;
        let anyMatch = visible.some((page) => this.isMatchedRoute(page.route));
        if (!anyMatch && visible.length !== 0) {
            this.$router.push(visible[0].route);
        }
    },
    data() {
        return {
            title: '',
            pages: [],
        }
    },
    methods: {
        itemClick(page) {
            this.$router.push(page.route);
        },
        isMatchedRoute(route) {
            return route && this.$route.matched.some((r) => r.name === route.name);
        },
    },
}
</script>
