<template>
    <data-table
        ref="table"
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :withProperties="false"
        @selection-changed="selectionChanged"
    >
    </data-table>
</template>

<script>
import manage from '@app/core/mixins/manage.js';
import importEntities from "@app/category/repositories/import-entities.js";
import loadFilesMixin from "@app/category/components/product/mixins/loadFilesMixin.js";
import {date, decorateString, handbookEntry} from "@app/core/formatter.js";

export default {
    props: {
        entity: {
            type: String,
            required: true,
        },
    },
    mixins: [
        manage,
        loadFilesMixin,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: this.__('Назва'),
                },
                {
                    name: 'created_at',
                    title: this.__('Дата'),
                    formatter: date(),
                },
                {
                    name: 'status',
                    title: this.__('Статус'),
                    formatter: handbookEntry('import_entity_status'),
                },
                {
                    name: 'status_bar',
                    title: this.__('Прогрес'),
                    formatter: decorateString('', '%')
                },
                {
                    name: 'rollback',
                    title: this.__('Скасування імпорту'),
                    component: this.getRollbackComponent().component,
                    componentProps: this.getRollbackComponent().props,
                    componentEvents: this.getRollbackComponent().events,
                },
            ],
            filter: {},
            select: ['*'],
        }
    },
    created() {
        this.filter['entity_type'] = this.entity;
    },
    methods: {
        getRepository() {
            return importEntities();
        },
        getRollbackComponent() {
            return {
                component: 'xretail-import-rollback-button',
                props: {
                    buttonText: this.__('Відкотити зміни'),
                },
                events: {
                    action: ({item}) => this.rollback(item),
                }
            };
        },
        rollback({id}) {
            this.$emit('rollback', id);
            this.refresh();
        },
    },
}
</script>
