import User from '@app/user/documents/user.js';
import userRequests from '@app/user/requests/user-requests.js';
import emitter from 'tiny-emitter/instance';

export default {
    namespaced: true,
    state: () => ({
        user: null,
        loginRedirect: null,
    }),
    mutations: {
        login(state, {user}) {
            state.user = user;
            emitter.emit('login', user);
        },
        logout(state) {
            let user = state.user;
            state.user = null;
            emitter.emit('logout', user);
        },
        loginRedirect(state, route) {
            state.loginRedirect = route;
        },
    },
    actions: {
        login({commit}, {login, password}) {
            return userRequests()
                .create('csrf-cookie')
                .send()
                .then(() => {
                    return userRequests()
                        .create('login', {login, password})
                        .send();
                })
                .then((response) => {
                    commit('login', {
                        user: new User(response.body),
                    });
                });
        },
        logout({commit}) {
            return userRequests()
                .create('logout')
                .send()
                .finally(() => {
                    commit('logout');
                });
        },
        ecp() {
            return userRequests()
                .create('ecp')
                .send();
        },
        autologin({commit}) {
            return userRequests()
                .create('me')
                .send()
                .then((response) => {
                    commit('login', {
                        user: new User(response.body),
                    });
                });
        },
        setLoginRedirect({commit}, route = null) {
            commit('loginRedirect', route);
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
        isLoggedIn(state) {
            return state.user !== null;
        },
        userId(state) {
            return state.user !== null ? state.user.id : null;
        },
        userName(state) {
            return state.user !== null ? state.user.name : null;
        },
        loginRedirect(state) {
            return state.loginRedirect;
        },
    },
}
