<template>
    <div class="flex-column">
        <section class="dialog-body">
            <el-scrollbar :height="tableHeight">
                <tableCallCenter
                    @selected-item="selectedItem"
                />
            </el-scrollbar>
        </section>
        <section class="dialog-footer">
            <el-button
                @click="cancel">
                {{ __('Скасувати') }}
            </el-button>
            <el-button
                type="primary"
                :loading="loading"
                @click="done">
                {{ __('Обрати') }}
            </el-button>
        </section>
    </div>
</template>

<script>

import tableCallCenter from "@app/questionnaire/components/questionnaires/tableCallCenter.vue";

export default {
    name: "SearchContact",
    components: {
        tableCallCenter
    },
    data() {
        return {
            loading: false,
            selectedRow: null,
        };
    },
    computed: {
        tableHeight() {
            return '560px';
        },
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        done() {
            this.$emit('questionnaireSelected', this.selectedRow);
        },
        selectedItem(row) {
            this.selectedRow = row;
        }
    }
};
</script>
