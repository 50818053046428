<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="12">
                <form-row
                    :label="__('РНКОПП')"
                    :errors="$errors['rnokpp_attr']"
                >
                    <el-input
                        clearable
                        v-model="searchData.rnokpp_attr"
                        :disabled="readOnly"
                    />
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Номер ID-картки')"
                    :errors="$errors['passport_id_attr']"
                >
                    <el-input
                        clearable
                        v-model="searchData.passport_id_attr"
                        :disabled="readOnly"
                    />
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Серія паспорту')"
                    :errors="$errors['passport_series_attr']"
                >
                    <el-input
                        clearable
                        v-model="searchData.passport_series_attr"
                        :disabled="readOnly"
                    />
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Номер паспорту')"
                    :errors="$errors['passport_number_attr']"
                >
                    <el-input
                        clearable
                        v-model="searchData.passport_number_attr"
                        :disabled="readOnly"
                    />
                </form-row>
            </el-col>

            <el-col :span="12">
                <form-row
                    :label="__('Номер КХ')"
                >
                    <el-input v-model="doc.parent.card_number" disabled/>
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Прізвище')"
                >
                    <el-input
                        v-model="doc.parent.last_name"
                        disabled
                    />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Ім\'я')"
                >
                    <el-input
                        v-model="doc.parent.first_name"
                        disabled
                    />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('По батькові')"
                >
                    <el-input
                        v-model="doc.parent.middle_name"
                        disabled
                    />
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20"
                v-if="!isConnected"
        >
            <el-col>
                <div
                    v-if="isNew"
                >
                    <el-button
                        v-if="isNotSearched || noResult"
                        type="primary"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="searchQuestionnaire"
                    >
                        {{ __('Знайти') }}
                    </el-button>
                    <el-button
                        v-else
                        type="primary"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="connectQuestionnaire"
                    >
                        {{ __("Зв'язати") }}
                    </el-button>
                    <el-button
                        type="danger"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="clear()"
                    >
                        {{ __("Очистити") }}
                    </el-button>
                </div>
                <div
                    v-else
                >
                    <el-button
                        v-if="isNotSearched"
                        type="primary"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="searchQuestionnaire"
                    >
                        {{ __('Знайти') }}
                    </el-button>
                    <el-button
                        v-else-if="noResult"
                        type="primary"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="createQuestionnaire"
                    >
                        {{ __('Створити') }}
                    </el-button>
                    <el-button
                        v-else
                        type="primary"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="connectQuestionnaire"
                    >
                        {{ __("Зв'язати") }}
                    </el-button>
                    <el-button
                        type="danger"
                        :loading="loading"
                        :disabled="readOnly"
                        @click="clear()"
                    >
                        {{ __("Очистити") }}
                    </el-button>
                </div>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import {get, set} from "@candybox/helpers.js";
import questionnaires from "@app/questionnaire/repositories/questionnaires.js";
import Query from "@candybox/query/query.js";
import constants from "@app/core/constants.js";
import {each, first, size, some, values} from "lodash";
import {validator} from "@candybox/validation/validator.js";
import {passportSeries} from "@app/core/validation.js";
import validationMixin from "@app/questionnaire/components/questionnaires/mixins/validationMixin.js";

export default {
    name: "parentForm",
    props: {
        doc: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [
        validationMixin,
    ],
    data() {
        return {
            loading: false,
            result: null,
            isConnected: false,
            searchData: {
                rnokpp_attr: null,
                passport_id_attr: null,
                passport_series_attr: null,
                passport_number_attr: null,
            },
            validation: {
                rnokpp_attr: validator()
                    .number()
                    .length(10),
                passport_id_attr: validator()
                    .number()
                    .length(9),
                passport_series_attr: validator()
                    .custom(passportSeries),
                passport_number_attr: validator()
                    .number()
                    .length(6),
            }
        }
    },
    computed: {
        isNew() {
            return !(!!get(this.doc, 'id'));
        },
        isNotSearched() {
            return _.isNull(this.result);
        },
        noResult() {
            return _.isUndefined(this.result);
        },
        usedSearchData() {
            const _dict = {};
            each(this.searchData, (v, k) => {
                if(v) {
                    _dict[k] = v;
                }
            })

            return _dict;
        }
    },
    methods: {
        searchQuestionnaire() {
            if(some(values(this.searchData))) {
                this.basicSearch();
            }
        },
        createQuestionnaire() {
            this.doc.parent_id = null;
            this.$emit('createQuestionnaire');
        },
        connectQuestionnaire() {
            this.doc.parent_id = this.result.id;
            this.isConnected = true;
        },
        async basicSearch() {
            this.loading = true;
            return this.validate(this.validation, this.usedSearchData)
                .then(() => {
                    const query = (new Query)
                        .where('questionnaire_type_id', '!=', constants.QUESTIONNAIRE_TYPE.PUPIL);
                    each(this.usedSearchData, (value, field) => {
                        if(value) {
                            query.where(field, '=', value)
                        }
                    });
                    return questionnaires()
                        .limitedSearch(query.limitTo(1000))
                        .then((items) => {
                            const itemsSize = size(items);
                            if(itemsSize === 0) {
                                this.$error(this.__('Нічого не знайдено'));
                            } else if(itemsSize === 1) {
                                this.result = first(items);
                                this.doc.parent.first_name = _.get(this.result, 'first_name_attr');
                                this.doc.parent.last_name = _.get(this.result, 'last_name_attr');
                                this.doc.parent.middle_name = _.get(this.result, 'middle_name_attr');
                                this.doc.parent.card_number = _.get(this.result, 'card');
                            } else {
                                this.$error(this.__('Знайдено більше 1 анкети, уточніть дані для пошуку'));
                            }

                            this.loading = false;
                        })
                        .catch((err) => {
                            this.$catchErrors(err);
                            this.loading = false;
                        })
                }).catch((err) => {
                    this.$catchErrors(err);
                    this.loading = false;
                })
        },
        clear() {
            this.result = null;
            this.doc.parent.first_name = null;
            this.doc.parent.last_name = null;
            this.doc.parent.middle_name = null;
            this.doc.parent.card_number = null;
            this.doc.parent.rnkopp = null;
        }
    },
}
</script>
