
export default {
    props: {
        num: {
            type: [String, Number],
            default: null,
        },
        question: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        label() {
            return this.getLabel(this.question.label);;
        },
    },
    methods: {
        getLabel(text) {
            if(!text) {
                return null;
            }
            const prefix = this.num ? `${this.num}. ` : '';
            return `${prefix}${text}`;
        }
    }
};
