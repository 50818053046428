import BufferRepository from '@candybox/repository/buffer.js';
import RestRepository from '@candybox/repository/rest.js';
import ClaimTimeWork from '@app/employee/documents/claim-time-work.js';
import claimTimeWorkMap from '@app/employee/requests/claim-time-work-repository.js';
import { singleton } from '@candybox/helpers.js';


class ClaimTimeWorkRepository extends RestRepository
{
    claim(data) {
        return this._request('claim', data);
    }
    confirm(data) {
        return this._request('confirm', data);
    }
    refuse(data) {
        return this._request('refuse', data);
    }
}


export default singleton(() => {
    return new ClaimTimeWorkRepository(ClaimTimeWork, claimTimeWorkMap())

});
