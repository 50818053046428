import { singleton } from '@candybox/helpers.js';
import {Method, RequestMap} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RequestMap();

    mapping.map('totalQuestionnaireAnalytics', {
        method: Method.POST,
        route: '/api/analytics/questionnaire/total'
    });

    mapping.map('byTypeQuestionnaireAnalytics', {
        method: Method.POST,
        route: '/api/analytics/questionnaire/by-type'
    });

    mapping.map('byTypeExternalQuestionnaireAnalytics', {
        method: Method.POST,
        route: '/api/analytics/questionnaire/by-type-external'
    });

    mapping.map('byStatusesQuestionnaireAnalytics', {
        method: Method.POST,
        route: '/api/analytics/questionnaire/by-statuses'
    });

    return mapping;
});
