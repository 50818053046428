import Document, {
    Attribute } from '@candybox/structures/document.js';
import Answer from "@app/questionnaire/documents/answer";
import RelationAttribute from "@candybox/repository/relation";
import blocks from "@app/questionnaire/repositories/blocks";
import types from "@app/questionnaire/repositories/types";
import subblocks from "@app/questionnaire/repositories/subblocks";

class Question extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            label: String,
            view_type: String,
            field_name: String,
            condition_field: String,
            type: String,
            is_show: Number,
            order: Number,
            inner_name: String,
            is_required: Number,
            is_multiple: Number,
            is_reserved: Number,
            questionnaire_subblock_id: Number,
            questionnaire_block_id: Number,
            questionnaire_type_id: Number,
            $subblock: RelationAttribute.oneToOne(subblocks(), 'questionnaire_subblock_id'),
            $block: RelationAttribute.oneToOne(blocks(), 'questionnaire_block_id'),
            $type: RelationAttribute.oneToOne(types(), 'questionnaire_type_id'),
            answers: Attribute.collection(Answer),
            answers_ids: Array,
            parent_path: String,
        };
    }
}

export default Question;
