import {ValidationError} from "@candybox/validation/validator.js";

export default {
    data() {
        return {
            _errors: {},
        }
    },
    methods: {
        async validate(validation, data) {
            this.$clearErrors();
            this._errors = {};
            return Promise.all(
                Object.keys(validation).map((attribute) => {
                    return validation[attribute].validate(attribute, data)
                        .catch((errors) => {
                            Object.keys(errors).map((attribute) => {
                                this._errors[attribute] = errors[attribute];
                            });
                        });
                })
            ).then(() => {
                if (Object.keys(this._errors).length !== 0) {
                    throw new ValidationError(this._errors);
                }
            });
        }
    }
}
