import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/users/roles');

    mapping.validation('store', () => {
        return {
            name: validator()
                .required()
                .length(0, 100),
        }
    });

    return mapping;
});