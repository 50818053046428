<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Категорії'),
            pages: [
                {
                    index: '1',
                    title: this.__('Категорії'),
                    route: { name: 'category' },
                    visible: () => this.$can('category.access') ? true : this.$can('category.access-category'),
                },  {
                    index: '2',
                    title: this.__('Продукти'),
                    route: { name: 'product' },
                    visible: () => this.$can('product.access')? true : this.$can('product.access-product'),
                },{
                    index: '3',
                    title: this.__('Статистика покупок'),
                    route: { name: 'retail-order' },
                    visible: () => this.$can('retail_order.access'),
                },

            ],
        }
    }
}
</script>
