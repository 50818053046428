export default {
    methods: {
        getImportCsvComponent() {
            throw new Error('Method getImportCsvComponent must be implemented');
        },
        importCsv() {
            this.$modalComponent(this.getImportCsvComponent(), {}, {},
                {
                    title: this.__('Завантаження файла'),
                    width: '400px',
                });
        },
        getImportXlsxComponent() {
            throw new Error('Method getImportCsvComponent must be implemented');
        },
        importCategoryXlsx() {
            this.$modalComponent(this.getImportXlsxComponent(), {}, {
                refresh: (dialog) => {
                    dialog.close();
                    this.refresh();
                },
            }, {
                title: this.__('Завантаження файла'),
                width: '300px',
            });
        },
    }
}
