<template>
    <div class="table-settings">
        <section class="popover-body">
            <form-row 
                :label="__('Показувати стовпчики')">
                <el-scrollbar max-height="300px">
                    <el-checkbox-group v-model="display">
                        <el-checkbox 
                            v-for="column in columns" 
                            :key="column.name" 
                            :label="column.name">
                            {{ column.title }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-scrollbar>
            </form-row>
            <form-row 
                :label="__('Кількість записів на сторінці')">
                <el-radio-group v-model="pageSize">
                    <el-radio-button label="50"></el-radio-button>
                    <el-radio-button label="100"></el-radio-button>
                    <el-radio-button label="200"></el-radio-button>
                    <el-radio-button label="400"></el-radio-button>
                </el-radio-group>
            </form-row>
        </section>
        <section class="popover-footer">
            <el-button
                @click="cancel">
                {{ __('Скасувати') }}
            </el-button>
            <el-button
                type="primary"
                @click="done">
                {{ __('Застосувати') }}
            </el-button>
        </section>
    </div>
</template>

<script>
import { map, 
    filter } from '@candybox/helpers.js';

export default {
    props: {
        columns: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            pageSize: 0,
            display: [],
        }
    },
    mounted() {
        this.reset();
    },
    methods: {
        reset() {
            this.pageSize = this.size;
            this.display = [...map(filter(
                this.columns, (col) => col.visible !== false
            ), 'name')];
        },
        done() {
            this.$emit('done', {
                size: Number(this.pageSize),
                columns: this.display,
            });
        },
        cancel() {
            this.reset();
            this.$emit('cancel');
        },
    },
}
</script>