import emitter from 'tiny-emitter/instance';
import serverTime from '@app/services/server-time';
import store from '@app/store.js';
// import RelatedAction from '@app/models/calls/related-action';
import constants from '@app/core/constants.js';
import {Contact, QuestionnaireContact, ProcessState} from '@app/services/sip-ua/process-state';
import {UA} from '@app/services/sip-ua';
import {STATE_WRAP_UP, STATE_BUSY, STATE_CONFERENCE} from '@app/services/sip-ua/state-manager';
import {makeSafe} from '@app/services/safe-close';
import lts from '@app/services/lts';
import {get} from "@candybox/helpers.js";

const getContact = (call) => {
    if (call.questionnaire) {
        return new QuestionnaireContact(call.questionnaire);
    }
    if (call.versa) {
        return new Contact(call.versa.id, call.versa.type, call.versa.name, [{
            number: call.phoneNumber,
        }]);
    }
    return new Contact(null, null, 'Неизвестный', [{
        number: call.phoneNumber,
    }]);
}

const getEnquiryContact = (enquiry) => {
    if (enquiry.client !== null) {
        let clientContact = new QuestionnaireContact(enquiry.patient);
        if (_.isEmpty(clientContact.email)) {
            clientContact.email = enquiry.email;
        }
        return clientContact;
    }
    return new Contact(null, null, enquiry.name, [{
        number: enquiry.phone_number,
    }], enquiry.email);
}

const getIsFirstVisit = (call) => {
    return call.is_first === constants.CALL.REQUEST_TYPES.REPEATED
        ? constants.PROCESS_LOG.VISIT_TYPE.RETURN
        : constants.PROCESS_LOG.VISIT_TYPE.FIRST;
}

const setContextCall = (state, call) => {
    state.processLog.call_id = call.id;
    state.processLog.source = call.source;
}

const populateContactPool = (state, call) => {
    if(get(call, 'related_contacts')) {
        call.related_contacts.forEach((contact) => {
            state.addContact(new QuestionnaireContact(contact.data));
        });
    }
}

const startProcessCall = (state, call) => {
    let contact = getContact(call);
    if (state.processing) {
        setContextCall(state, call);
    } else {
        // state.reset();
        setContextCall(state, call);
        state.processLog.sip_number = UA().number;
        state.processLog.started_at = serverTime.asString();
        state.processLog.is_incoming_call = UA().call !== null && UA().call.isIncoming;
        state.processLog.source = state.processLog.is_incoming_call ? 'incoming' : 'outgoing';
        state.processLog.is_first_visit = getIsFirstVisit(call);
        state.startProcess();
    }
    state.addContact(contact);
    if(contact.questionnaire) {
        state.selectContact(contact.uid);
    }
    state.phoneNumber = call.phoneNumber;
    store.commit('processState', state);
}

const setContextEnquiry = (state, enquiry) => {
    state.processLog.enquiry = enquiry.id;
    state.processLog.source = 'site_enquiry';
}

const startProcessEnquiry = (state, enquiry) => {
    let contact = getEnquiryContact(enquiry);
    state.reset();
    setContextEnquiry(state, enquiry);
    state.processLog.sip_number = UA().number;
    state.processLog.started_at = serverTime.asString();
    state.upsertContact(contact);
    state.phoneNumber = enquiry.phone_number;
    state.startProcess();
    store.commit('processState', state);
}

const startProcessWaitListRecord = (state, record) => {
    let contact = getEnquiryContact(record);
    state.reset();
    setContextWaitListRecord(state, record);
    state.processLog.sip_number = UA().number;
    state.processLog.started_at = serverTime.asString();
    state.upsertContact(contact);
    state.phoneNumber = record.phone_number;
    state.startProcess();
    store.commit('processState', state);
}

const setContextWaitListRecord = (state, record) => {
    state.processLog.wait_list_record = record.id;
    state.processLog.source = 'wait_list_record';
}

const startProcessUndefined = (state) => {
    // state.reset(true);
    state.processLog.source = 'call';
    state.processLog.sip_number = UA().number;
    state.processLog.started_at = serverTime.asString();
    if(get(state, 'currentContact.questionnaire')) {
        state.processLog.questionnaire_type = get(state, 'currentContact.questionnaire.questionnaire_type_id');
        state.processLog.is_questionnaire = 1;
    }
    state.startProcess();
    store.commit('processState', state);
}

const addRelatedCall = (state, call) => {
    // let contact = getContact(call);
    // state.processLog.related_actions.push(new RelatedAction({
    //     action: constants.CALL_ACTION.TYPE.CREATE,
    //     // time: serverTime.asString(),
    //     related_id: call.id,
    //     related_type: constants.CALL_ACTION.SUBJECT.CALL_LOG,
    // }));
    // state.upsertContact(contact);
    // state.phoneNumber = call.phoneNumber;
    // store.commit('processState', state);
}

makeSafe(store.state.processState, 'Вы не завершили обработку, Вы уверены, что хотите продолжить?', () => {
    return store.state.processState.processing;
});

emitter.on('logout', () => {
    if (lts.processState) {
        store.commit('clearProcessState');
    }
});

emitter.on('call:initiated', (call) => {
    if (call.isIncoming) {
        let state = store.state.processState;
        let contact = state.findContactByNumber(call.number);
        if (contact === undefined) {
            contact = new Contact(null, null, 'Неизвестный', [{
                number: call.number,
                comment: '',
            }]);
            state.addContact(contact);
        }
        state.selectContact(contact.uid);
    }
});

emitter.on('call:details', (call) => {
    // store.commit('callInfo', call);
    let state = store.state.processState;
    if (state.processing && state.hasContext()) {
        addRelatedCall(state, call);
    } else {
        startProcessCall(state, call);
    }
});

emitter.on('call:ended', (call) => {
    if (call.missed) {
        let state = store.state.processState;
        state.reset();
        store.commit('processState', state);
    }
});

emitter.on('process:enquiry', (enquiry) => {
    let state = store.state.processState;
    if (!state.processing) {
        startProcessEnquiry(state, enquiry);
        UA().stateManager.transit(STATE_WRAP_UP);
    }
});

emitter.on('process:wait-list-record', (record) => {
    let state = store.state.processState;
    if (!state.processing) {
        startProcessWaitListRecord(state, record);
        UA().stateManager.transit(STATE_WRAP_UP);
    }
});

emitter.on('operator:state-changed', ({from, to}) => {
    let state = store.state.processState;
    if (!state.processing) {
        if ([STATE_BUSY, STATE_CONFERENCE, STATE_WRAP_UP].indexOf(to) !== -1) {
            startProcessUndefined(state);
        }
    }
});

emitter.on('created:Call', (call) => {
    let state = store.state.processState;
    if (state.processing) {
        state.replaceContact(new QuestionnaireContact(call.contact));

        state.processLog.is_first_visit = getIsFirstVisit(call);
        store.commit('processState', state);
    }
});

emitter.on('updated:Call', (call) => {
    let state = store.state.processState;
    if (state.processing) {
        store.commit('processState', state);
    }
});
