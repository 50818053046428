import {
    STATE_OFFLINE,
    STATE_ONLINE,
    STATE_BUSY,
    STATE_WRAP_UP,
    STATE_AWAY,
    STATE_CONFERENCE,
} from '@app/services/sip-ua/state-manager.js';
import {
    STATE_PENDING,
    STATE_IN_PROGRESS,
    STATE_TERMINATED,
    STATE_PARKING,
    STATE_PARKED
} from '@app/services/sip-ua/call.js'
import {UA} from "@app/services/sip-ua.js";
import {mapGetters} from "vuex";
import {get} from "lodash";
import store from "@app/store.js";

export default {
    data() {
        return {
            test: 1000,
        }
    },
    computed: {
        ...mapGetters({
            connected: 'callCenter/getConnected',
            state: 'callCenter/getUaState',
            stateName: 'callCenter/getUaStateName',
            call: 'callCenter/getCall',
            callState: 'callCenter/getCallState',
            parkedCalls: 'callCenter/getParkedCalls',
            lastParkedCall: 'callCenter/getLastParkedCall',
            processState: 'getProcessState',
            isUpdated: 'callCenter/getUpdated',
        }),
        canStartSession() {
            return this.isState(STATE_OFFLINE)
                && this.connected;
        },
        canEndSession() {
            return this.canTransit(STATE_OFFLINE)
                && this.hasCall() === false;
        },
        canStartCall() {
            return this.canTransit(STATE_BUSY)
                && this.hasCall() === false;
        },
        canAnswerCall() {
            return this.hasCall((call, callState) => callState === STATE_PENDING && call.isIncoming);
        },
        canRejectCall() {
            return this.hasCall((call, callState) => callState === STATE_PENDING && call.isIncoming);
        },
        canTerminateCall() {
            return this.hasCall((call, callState) => call.isIncoming && callState === STATE_IN_PROGRESS || call.isOutgoing);
        },
        canHoldCall() {
            this.test = this.isUpdated;
            return this.isState(STATE_CONFERENCE) === false
                && this.hasCall((call, callState) => call.canBeParked);
        },
        canStartConference() {
            return this.isState(STATE_CONFERENCE) === false
                && this.hasCall((call, callState) => callState === STATE_IN_PROGRESS)
                && this.parkedCalls.length !== 0;
        },
        canUnpause() {
            return this.isState(STATE_AWAY);
        },
        canPause() {
            return this.canTransit(STATE_AWAY)
                && this.hasCall() === false;
        },
        isConference() {
            return this.isState(STATE_CONFERENCE);
        },
    },
    methods: {
        isState(state) {
            return this.stateName === state;
        },
        canTransit(state) {
            if(!this.stateName) {
                return false;
            }
            return this.state.getTransition(state) !== undefined;
        },
        hasCall(predicate = null) {
            let call = this.call;
            if (call === null) {
                return false;
            }
            if (predicate === null) {
                return true;
            }
            return predicate(call, this.callState);
        },


        startSession() {
            UA().startSession();
        },
        endSession() {
            UA().endSession();
        },
        answerCall() {
            UA().answer();
        },
        rejectCall() {
            UA().reject();
        },
        terminateCall() {
            UA().terminate();
            store.commit('callCenter/setUpdated', false);
        },
        endWrapUp() {
            UA().stateManager.transit(STATE_ONLINE);
        },
        startCall(sip) {
            this.test = 2;
            UA().makeCall(sip);
        },
        holdCall() {
            UA().park().catch(() => {
                this.$error(this.__('Не удалось поставить абонента на удержание'));
            });
            this.test = 3;
        },
        unholdCall(call) {
            UA().withdrawParked(call);
            this.test = 4;
        },
    },
};
