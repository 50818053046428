<template>
    <AnalyticsFiltersBase
        @input="onInput"
    >
        <template #filters>
            <el-row :gutter="20">
                <el-col :span="6">
                    <form-row
                        :label="__('Період')"
                    >
                        <div class="form-input-group">
                            <el-date-picker
                                v-model="filters.periodBetween.from"
                                type="date"
                                value-format="DD-MM-YYYY"
                                format="DD/MM/YYYY" />
                            <el-date-picker
                                v-model="filters.periodBetween.to"
                                type="date"
                                value-format="DD-MM-YYYY"
                                format="DD/MM/YYYY" />
                        </div>
                    </form-row>
                </el-col>
            </el-row>
        </template>
    </AnalyticsFiltersBase>
</template>

<script>
import AnalyticsFiltersBase from "@app/analytics/components/base/filters/AnalyticsFiltersBase.vue";
import analyticsFilterMixin from "@app/analytics/components/base/filters/mixins/analyticsFilterMixin.js";

export default {
    name: "RetailFilter",
    components: {
        AnalyticsFiltersBase,
    },
    mixins: [
        analyticsFilterMixin,
    ],
    data() {
        return {
            operators: [],
        }
    },
    methods: {

    },

}
</script>
