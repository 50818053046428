import format from 'date-fns/format';

const isEmpty = (val) => {
    return val === ''
        || val === null
        || val === undefined
        || (Array.isArray(val) && val.length === 0);
}

const isFilled = (val) => !isEmpty(val);

const toDateString = (date) => {
    return format(date, 'yyyy-MM-dd');
}

const toTimeString = (date) => {
    return format(date, 'HH:mm:ss');
}

const toDatetimeString = (date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
}

const truncateString = (str, len=30) => {
    if (str.length > len) {
        return str.slice(0, len-3) + "...";
    } else {
        return str;
    }
}

function getMimeType(file, fallback = null) {
    const byteArray = new Uint8Array(file).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case '89504e47':
            return 'image/png';
        case '47494638':
            return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
            return 'image/jpeg';
        default:
            return fallback;
    }
}

const getFullName = (obj) => {
    return [
        obj.last_name,
        obj.first_name,
        obj.middle_name,
    ]
        .filter(isFilled)
        .join(' ');
}

const getShortName = (obj) => {
    return [
        obj.last_name,
        obj.first_name ? `${obj.first_name.charAt(0).toUpperCase()}.` : '',
        obj.middle_name ? `${obj.middle_name.charAt(0).toUpperCase()}.` : '',
    ]
        .filter(isFilled)
        .join(' ')
}

const calcTime = (since) => {
    return since === null ? 0 : (Date.now() - since.getTime());
}

const downloadFile = (url, filename) => {
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename)
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export {
    isEmpty,
    isFilled,
    toDateString,
    toTimeString,
    toDatetimeString,
    truncateString,
    getMimeType,
    getFullName,
    getShortName,
    calcTime,
    downloadFile,
}
