import App from '@candybox/app.js';
import questionnaireList from '@app/questionnaire/components/questionnaires/table.vue';
import adminHP from "@app/questionnaire/components/questionnaires/AdminHP.vue";

App.boot(({router}) => {
    router.addRoute('questionnaires', {
        component: questionnaireList,
        path: 'questionnaires',
        name: 'questionnaires',
    });
    router.addRoute('admin_hp', {
        component: adminHP,
        path: 'load-reports',
        name: 'admin_hp',
    });
});
