import Document from '@candybox/structures/document.js';


class OperatorAnalytics extends Document {
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {};
    }

}

export default OperatorAnalytics;
