import App from '@candybox/app.js';
import operators from '@app/analytics/components/Operators.vue';
import calls from '@app/analytics/components/Calls.vue';
import retails from '@app/analytics/components/Retails.vue';
import products from '@app/analytics/components/Products.vue';
import questionnaires from '@app/analytics/components/Questionnaires.vue';
import smallAdmins from '@app/analytics/components/SmallAdmins.vue';

App.boot(({router}) => {
    router.addRoute('analytics', {
        component: operators,
        path: 'operators',
        name: 'operators',
    });
    router.addRoute('analytics', {
        component: calls,
        path: 'calls',
        name: 'calls',
    });
    router.addRoute('analytics', {
        component: retails,
        path: 'retails',
        name: 'retails',
    });
    router.addRoute('analytics', {
        component: products,
        path: 'products',
        name: 'products',
    });
    router.addRoute('analytics', {
        component: questionnaires,
        path: 'questionnaires-analytics',
        name: 'questionnaires-analytics',
    });
    router.addRoute('analytics', {
        component: smallAdmins,
        path: 'small-admins-analytics',
        name: 'small-admins-analytics',
    });
});
