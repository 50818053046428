import App from '@candybox/app.js';
import categoryList from "@app/category/components/category/table";
import productList from "@app/category/components/product/table";
import retailOrderList from "@app/category/components/retail-order/table";

App.boot(({router}) => {
    router.addRoute('categories', {
        component: categoryList,
        path: 'categories',
        name: 'category',
    });
    router.addRoute('categories', {
        component: productList,
        path: 'products',
        name: 'product',
    });
    router.addRoute('categories', {
        component: retailOrderList,
        path: 'retail-order',
        name: 'retail-order',
    });
});


