<template>
    <div class="status-panel">
<!--        <span class="current-status">{{ __('Cтатус:') }}-->
<!--            <el-popover-->
<!--                placement="top"-->
<!--                :title="__('Очереди')"-->
<!--                width="250"-->
<!--                trigger="hover"-->
<!--                @show="updateQueueList">-->
<!--                <p>{{ __('Пауза:') }} <i>{{ queuePause }}</i></p>-->
<!--                <p>{{ __('Активно:') }} <i>{{ queueActive }}</i></p>-->
<!--                <b slot="reference">{{ currentStatus }} *</b>-->
<!--            </el-popover>-->
<!--        </span>-->
<!--        <span class="spacer" />-->
        <span class="session-started">{{ __('Початок роботи:') }} <b>{{ timeToFormat(sessionStart) || __('робота не розпочата') }}</b></span>
<!--        <span class="spacer" />-->
        <span class="pauses-time">{{ __('Паузи (не на місці):') }} <b>{{ pauseTotalCount }} {{ __('пауз, загальний час:') }} {{ durationToFormat(allPauseTotalDuration) || '0 сек' }}</b></span>
<!--        <span class="spacer" />-->
        <span class="call-time">{{ __('У розмові:') }} <b>{{ durationToFormat(allCallTotalDuration) || '0 сек' }}</b></span>
<!--        <span class="spacer" />-->
        <span class="current-time">{{ __('Час:') }} <b>{{ timeToFormat(currentTime) }}</b></span>
<!--        <span class="spacer" />-->
    </div>
</template>

<script>
import {
    STATE_OFFLINE,
    STATE_ONLINE,
    STATE_BUSY,
    STATE_WRAP_UP,
    STATE_AWAY,
    STATE_CONFERENCE,
} from '@app/services/sip-ua/state-manager';
import serverTime from '@app/services/server-time';
import {durationShortFormat, time, list} from '@app/core/formatter.js';
import {UA} from '@app/services/sip-ua';
import {calcTime} from '@app/core/helpers.js';
import {mapGetters} from "vuex";
import {get} from "@candybox/helpers.js";

export default {
    computed: {
        ...mapGetters({
            stateName: 'callCenter/getUaStateName',
            stateManager: 'callCenter/getStateManagerCounts',
        }),
        currentStatus() {
            switch (this.stateName) {
                case STATE_OFFLINE: return this.__('не в работе');
                case STATE_ONLINE: return this.__('свободен');
                case STATE_BUSY: return this.__('разговор');
                case STATE_WRAP_UP: return this.__('обработка');
                case STATE_AWAY: return this.__('нет на месте');
                case STATE_CONFERENCE: return this.__('конференция');
            }
            return '';
        },
        sessionStart() {
            return get(this.stateManager, 'sessionStartTime');
        },
        callStartTime() {
            return get(this.stateManager, 'callStartTime');
        },
        callTotalDuration() {
            return get(this.stateManager, 'callTotalDuration');
        },
        currentCallDuration() {
            return this.calcTime(this.callStartTime);
        },
        pauseTotalCount() {
            return get(this.stateManager, 'pauseTotalCount');
        },
        pauseTotalDuration() {
            return get(this.stateManager, 'pauseTotalDuration');
        },
        pauseStartTime() {
            return get(this.stateManager, 'pauseStartTime');
        },
        currentPauseDuration() {
            return this.calcTime(this.pauseStartTime);
        },
        allCallTotalDuration_c() {
            return this.callTotalDuration + this.currentCallDuration;
        },
        allPauseTotalDuration_c() {
            return this.pauseTotalDuration + this.currentPauseDuration;
        },
    },
    data() {
        return {
            currentTime: null,
            queuePause: '-',
            queueActive: '-',
            allCallTotalDuration: 0,
            allPauseTotalDuration: 0,
        };
    },
    created() {
        this.updateTimersListener = () => {
            this.updateTimers();
        }
    },
    mounted() {
        this.$ticker.on(this.updateTimersListener);
        this.updateTimers();
    },
    beforeDestroy() {
        this.$ticker.off(this.updateTimersListener);
    },
    methods: {
        durationToFormat(duration) {
            return durationShortFormat(duration);
        },
        timeToFormat(value) {
            return time()(value);
        },
        updateTimers() {
            this.currentTime = serverTime.now();
            this.allCallTotalDuration = this.allCallTotalDuration_c;
            this.allPauseTotalDuration = this.allPauseTotalDuration_c;
        },
        updateQueueList() {
            let paused = UA().pausedQueues;
            let active = UA().activeQueues;
            this.queuePause = paused.length !== 0 ? list()(paused) : '-';
            this.queueActive = active.length !== 0 ? list()(active) : '-';
        },
        calcTime(value) {
            return calcTime(value);
        },
    },
};
</script>
