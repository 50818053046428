<template>
    <section class="dialog-body">
        <form-row
            :label="__('Код')"
            :errors="$errors['code']"
            required>
            <el-input v-model="doc.code" />
        </form-row>
        <form-row
            :label="__('Опис')"
            :errors="$errors['status_khp']"
            required>
            <el-input v-model="doc.status_khp"/>
        </form-row>
        <form-row
            :label="__('Статус цифрової картки за анкетою')"
            :errors="$errors['digital_handbook_key']">
            <options-provider
                source="card_status_by_questionnaire_digital">
                <template v-slot:default="data">
                    <el-select
                        :loading="data.loading"
                        v-model="doc.digital_handbook_key">
                        <el-option
                            v-for="item in data.items"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id" />
                    </el-select>
                </template>
            </options-provider>
        </form-row>
        <form-row
            :label="__('Статус пластикової картки за анкетою')"
            :errors="$errors['handbook_key']">
            <options-provider
                source="card_status_by_questionnaire">
                <template v-slot:default="data">
                    <el-select
                        :loading="data.loading"
                        v-model="doc.handbook_key">
                        <el-option
                            v-for="item in data.items"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id" />
                    </el-select>
                </template>
            </options-provider>
        </form-row>
        <form-row
            :label="__('Статус картки')"
            :errors="$errors['card_status_handbook_key']"
            required>
            <options-provider
                source="card_status">
                <template v-slot:default="data">
                    <el-select
                        :loading="data.loading"
                        v-model="doc.card_status_handbook_key">
                        <el-option
                            v-for="item in data.items"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id" />
                    </el-select>
                </template>
            </options-provider>
        </form-row>
        <form-row
            :label="__('Статус анкети')"
            :errors="$errors['questionnaire_status_handbook_key']">
            <options-provider
                source="questionnaire_status">
                <template v-slot:default="data">
                    <el-select
                        :loading="data.loading"
                        v-model="doc.questionnaire_status_handbook_key">
                        <el-option
                            v-for="item in data.items"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id" />
                    </el-select>
                </template>
            </options-provider>
        </form-row>
    </section>
</template>

<script>

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
}
</script>
