<template>
    <div class="flex-column">
        <section>
            <el-tabs
                v-model="activeTab"
            >
                <el-tab-pane
                    :label="__('Імпорт чеків')"
                    :name="types.CHECK"
                >
                    <el-scrollbar :height="tableHeight">
                        <importedTable
                            ref="checkTable"
                            :entity="constants.CHECK"
                            @rollback="rollback"
                        />
                    </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane
                    :label="__('Імпорт товарів')"
                    :name="types.PRODUCT"
                >
                    <el-scrollbar :height="tableHeight">
                        <importedTable
                            ref="productTable"
                            :entity="constants.PRODUCT"
                            @rollback="rollback"
                        />
                    </el-scrollbar>
                </el-tab-pane>
            </el-tabs>
        </section>
        <section class="dialog-footer flex justify-space-between">
            <el-button
                type="danger"
                @click="removeAll(activeTab)">
                {{ __('Видалити все') }}
            </el-button>
            <el-button
                type="primary"
                @click="refresh(activeTab)">
                {{ __('Оновити дані прогресу') }}
            </el-button>
        </section>
    </div>
</template>

<script>
import importedTable from '@app/category/components/import-entity/table.vue';
import constants from "@app/core/constants.js";
import {includes} from "lodash";
import importEntities from "@app/category/repositories/import-entities.js";

export default {
    components: {
        importedTable,
    },
    data() {
        return {
            activeTab: 'checks',
            types: {
                ALL: 'all',
                CHECK: 'checks',
                PRODUCT: 'products',
            },
            tableHeight: '560px',
        }
    },
    computed: {
        constants() {
            return constants.PRODUCTS.IMPORT_ENTITY;
        },
    },
    methods: {
        refresh(entity) {
            if(includes([this.types.CHECK, this.types.ALL], entity)) {
                this.$refs.checkTable.$refs.table.refresh();
            }
            if(includes([this.types.PRODUCT, this.types.ALL], entity)) {
                this.$refs.productTable.$refs.table.refresh();
            }
        },
        rollback(itemId) {
            importEntities()
                .importRollback(itemId)
                .catch((e) => {
                    console.log('importRollback error', e);
                })
        },
        removeAll(entity) {
            importEntities()
                .removeAll(entity)
                .then(() => {
                    this.refresh(this.activeTab);
                })
                .catch((e) => {
                    console.log('removeAll error', e);
                })
        },
    },
    watch: {
        activeTab(val) {
            this.refresh(val);
        },
    }
}
</script>
