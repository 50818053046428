<template>
    <form-row
        v-loading="loading"
        :name="property"
        :label="label"
        :required="isRequired"
        :css-class="cssClass"
        :error-prefix="errorPrefix">
        <template
            v-if="$slots['label-addon']"
            slot="label-addon">
            <slot name="label-addon" />
        </template>
        <el-upload
            ref="upload"
            :auto-upload="false"
            :class="['upload-attachment', {'not-empty': hasAttachments, multiple: multiple}]"
            :action="''"
            :disabled="readOnly"
            :file-list="fileList"
            :limit="multiple ? limit : 1"
            :accept="accept"
            :multiple="multiple"
            :on-preview="viewFile"
            :http-request="xhr"
            :before-upload="uploadStart"
            :on-success="uploadComplete"
            :on-error="uploadError"
            :before-remove="uploadDiscard"
            :on-exceed="handleExceed"
            :show-file-list="showFileList"
        >
            <template #trigger>
                <el-button
                    type="primary"
                    v-show="showButton"
                    :disabled="readOnly"
                    @click="clear"
                >
                    {{ buttonText }}
                </el-button>
            </template>
        </el-upload>
    </form-row>
</template>

<script>
import axios from 'axios';
import FormElement from '@app/core/mixins/form-element.js';
import FileActionMixin from '@app/core/mixins/file-action.js';
import {count, get, set} from '@candybox/helpers';

function upload(options) {
    let request = this.fileLoader.upload(options.file, options.file.name, {
        onUploadProgress: (event) => {
            if (event.total > 0) {
                event.percent = event.loaded / event.total * 100;
            } else {
                event.percent = 0;
            }
            options.onProgress(event);
        }
    });

    request.promise().then((response) => {
        options.onSuccess(response.data);
    }).catch((error) => {
        options.onError(error);
    });

    return request;
}

export default {
    mixins: [
        FormElement,
        FileActionMixin,
    ],
    props: {
        buttonText: {
            type: String,
            default: 'Прикріпити файл',
        },
        limit: {
            type: Number,
            default: 100,
        },
        accept: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        onPreview: {
            type: [Function, Boolean],
            default: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        showFileList: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        xhr() {
            return upload.bind(this);
        },
        showButton() {
            return true;
        },
        hasAttachments() {
            return false;
        },
    },
    data() {
        return {
            loading: false,
            fileList: [],
            countUploads: 0,
            imageUrl: null,
        };
    },
    methods: {
        handleExceed(files) {
            const file = files[0];
            this.$refs.upload.clearFiles();
            this.$refs.upload.handleStart(file);
        },
        uploadStart(response, file) {
            this.countUploads++;
            this.$emit('uploads-started', this.countUploads);
        },
        uploadComplete(response, file, fileList) {
            this.countUploads--;
            this.$emit('uploads-completed', this.countUploads);
        },
        uploadError(error) {
            if (!axios.isCancel(error)) {
                this.$error(this.__('Не удалось загрузить файл'));
            }
            this.countUploads--;
            this.$emit('uploads-completed', this.countUploads);
        },
        uploadDiscard(file, fileList) {
            if (file.status === 'uploading') {
                this.$refs.upload.abort(file);
                return false;
            }
        },
        viewFile(file) {
            //
        },
        crop(data) {
            this.$refs.upload.clearFiles();
            const file = new File(
                [data.blob],
                data.image.name,
                {type:data.image.type,
                    lastModified:new Date().getTime()}
            );
            let request = this.fileLoader.upload(file, file.name, {
                onUploadProgress: (event) => {
                    if (event.total > 0) {
                        event.percent = event.loaded / event.total * 100;
                    } else {
                        event.percent = 0;
                    }
                    this.onProgress(event);
                }
            });
            request.promise().then((response) => {
                this.setProperty(get(response, 'data.id'));
                this.setPhoto(response.data);
                // this.onSuccess(response.data);
            }).catch((error) => {
                this.onError(error);
            });
        },
        clear() {
            this.fileList = [];
            set(this.entity, 'photo', null);
        },
        setPhoto(data) {
            const _data = {
                id: data.id,
                name: data.name,
                url: data.url,
            }
            set(this.entity, 'photo', _data);
        },
        onError(error) {
            console.log('error', error);
        }
    },
    watch: {
        fileList: {
            handler(fileList) {
                if(fileList && count(fileList) === 1) {
                    this.prepareView(fileList[0].raw, 'Обрізати фото')
                }
            },
            deep: true,
        }
    }
};
</script>
