import Document, {
    Attribute } from '@candybox/structures/document.js';
import { getShortName, getFullName } from '@app/core/helpers.js';
import RelationAttribute from '@candybox/repository/relation.js';
import positions from '@app/employee/repositories/service-positions.js';
import ServiceUser from "@app/employee/documents/service-user";

class ServiceEmployee extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            first_name: String,
            middle_name: String,
            last_name: String,
            birth_date: Date,
            contact_number: String,
            email: String,
            experience: String,
            notes: String,
            position_id: Number,
            rnokpp: String,
            $position: RelationAttribute.oneToOne(positions(), 'position_id'),
            status: String,
            date_hired: Date,
            date_fired: Date,
            user: Attribute.object(ServiceUser, () => new ServiceUser(), false),
        };
    }

    /**
     * Get employee full name
     *
     * @returns {String}
     */
    get full_name() {
        return getFullName(this);
    }

    get short_name() {
        return getShortName(this);
    }
}

export default ServiceEmployee;
