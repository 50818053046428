<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="24">
                <form-row
                    :label="__('Назва')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name"/>
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24">
                <form-row
                    :label="__('Категорія')"
                    :errors="$errors['category_id']"
                    required>
                    <options-provider
                        :source="getCategories()">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.category_id"
                                filterable
                                placeholder="Оберіть"
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import Query from '@candybox/query/query.js';
import categories from "@app/category/repositories/categories";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    computed: {
        currentCategory: {
            get() {
                return this.doc.$category.$;
            },
            set(val) {
                return this.doc.$category = val;
            },
        },
    },
    methods: {
        updateCurrentCategory(id) {
            this.currentCategory = this.categories.find((inst) => {
                return inst.id === id;
            });
        },
        getCategories() {
            return categories()
                .search((new Query).orderBy('unified_code'))
                .then((items) => {
                    this.categories = items.map((category) => ({
                        id: category.id,
                        value: (category.unified_code !== null) ? category.unified_code + ' - ' + category.name : category.name,
                    }));
                    this.updateCurrentCategory(this.doc.category_id);
                    return this.categories;
                });
        },
    },
    created() {
        this.doc.type = 1;
        this.doc.original_id = null
    },
}
</script>
