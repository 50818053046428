<template>
    <div class="page">
        <div class="page-content flex-column">
            <section class="dialog-body"
            v-if="visibleForm">
                <el-row :gutter="20"
                        class="flex is-justify-center">
                    <el-col :span="6">
                        <div class="text-center">
                            <h1>{{ __('Запит на доступ до робочого кабінету') }}</h1>
                        </div>
                        <form-row
                            :label="__('Прізвище')"
                            :errors="$errors['employee.last_name']">
                            <el-input v-model="employee.last_name" disabled/>
                        </form-row>
                        <form-row
                            :label="__('Ім’я')"
                            :errors="$errors['employee.first_name']">
                            <el-input v-model="employee.first_name" disabled/>
                        </form-row>
                        <form-row
                            :label="__('По батькові')"
                            :errors="$errors['employee.middle_name']">
                            <el-input v-model="employee.middle_name" disabled/>
                        </form-row>
                        <form-row
                            :label="__('Причина')"
                            :errors="$errors['doc.type_reason']"
                            required>
                            <options-provider
                                :source="getReasonType()"
                                value-prop="description">
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        v-model="doc.type_reason">
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.value"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                        <form-row
                            :label="__('Дата')"
                            :errors="$errors['doc.reason']"
                            v-if="!reasonVisible"
                            required>
                            <el-date-picker
                                v-model="doc.reason"
                                type="date"
                                format="DD/MM/YYYY"
                                value-format="DD.MM.YYYY"
                                placeholder="Обрати дату"/>
                        </form-row>
                        <form-row
                            :label="__('Вкажіть причину')"
                            :errors="$errors['doc.reason']"
                            v-if="reasonVisible"
                            required>
                            <el-input
                                v-model="doc.reason"
                                :rows="8"
                                type="textarea" placeholder="Текст причини"/>
                        </form-row>
                        <div class="footer-buttons">
                            <el-button class="button"
                                type="primary"
                                @click="done()">
                                {{ __('Надіслати') }}
                            </el-button>
                            <el-button class="button"
                                @click="logout()">
                                {{ __('Відмінити') }}
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </section>
            <section v-else>
                <el-row :gutter="20"
                        class="flex is-justify-center">
                    <el-col :span="6">
                        <div class="text-center">
                            <h1>{{ __('Запит надісланий чекайте на підтвердження та повідомлення на електронну адресу') }}</h1>
                        </div>
                        <div class="footer-buttons">
                            <el-button class="button"
                                       @click="logout()">
                                {{ __('Вихід') }}
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </section>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import glossary from "@app/handbook/repositories/glossary";
import Query from "@candybox/query/query";
import claimTimeWork from "@app/employee/repositories/claim-time-work";


export default {
    data() {
        return {
            doc: {
                employee_id: null,
                type_reason: null,
                reason: null,
                status: null,
            },
            employee: {},
            reasonVisible: false,
            visibleForm: true,
        }
    },
    mounted() {
        this.employee = this.$user.employee;
        this.doc.status = 'new';
        this.doc.employee_id = this.$user.employee.id;
    },
    watch: {
        ['doc.type_reason'](val) {
            this.reasonVisible = val === 'Інше';
        },
    },
    methods: {
        claim: null,
        getReasonType() {
            return glossary().search((new Query)
                .where('type', '=', 'claim'))
                .then((items) => {
                    this.glossaries = items.map((gls) => ({
                        id: gls.id,
                        value: gls.name,
                    }));

                    return this.glossaries;
                })
                .catch((err) => {
                    console.log('search errors', err);
                    this.$catchErrors(err);
                })
        },
        done() {
            return claimTimeWork().claim(this.doc)
                .then(() => {
                    this.$success(this.__('Запит надіслан!'));
                    this.refresh();
                    this.visibleForm = false
                })
                .catch((err) => {
                    console.log('search errors', err);
                    this.$catchErrors(err);
                });
            this.visibleForm = false;
        },
        refresh() {
            this.doc.employee_id = null;
            this.doc.type_reason = null;
            this.doc.reason = null;
            this.doc.status = null;
        },
        logout() {
            this.logout();
        },
        ...mapActions({
            logout: 'user/logout',
        }),
    }
}
</script>
