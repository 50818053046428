import App from '@candybox/app.js';
import distributorList from '@app/distributor/components/distributor/table';
import outletList from '@app/distributor/components/outlet/table';

App.boot(({router}) => {
    router.addRoute('distributor', {
        component: distributorList,
        path: 'distributor',
        name: '/',
    });
    router.addRoute('distributor', {
        component: outletList,
        path: 'outlet',
        name: 'outlet',
    });
});
