<script>
import BaseHistory from '@app/core/components/manage/history';

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'glossary';
        },
        getAttributes(){
            return {
                type: this.__('Тип'),
                name: this.__('Назва'),
                code: this.__('Код пільги'),
            }
        }
    },
}
</script>
