<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('role.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('role.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('role.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Історія') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import roles from '@app/user/repositories/roles.js';
import manage from '@app/core/mixins/manage.js';
import Role from '@app/user/documents/role.js';
import formLayout from '@app/user/components/role/form.vue';
import history from '@app/user/components/role/history.vue';

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'name',
                title: this.__('Назва'),
                sortable: true,
                filterable: true,
            },{
                name: 'has_voip',
                width: 100,
                title: this.__('Телефонія'),
                sortable: true,
                filterable: 'yes_no',
                component: 'table-yes-mark',
            },{
                name: 'has_schedule',
                width: 100,
                title: this.__('Розклад'),
                sortable: true,
                filterable: 'yes_no',
                component: 'table-yes-mark',
            }
            ],
            filter: {
                name: null,
                has_voip: null,
                has_schedule: null,
            },
        }
    },
    methods: {
        getRepository() {
            return roles();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Role, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати роль'),
                editTitle: this.__('Редагувати роль'),
                width: '400px',
            };
        },
        getHistoryComponent() {
            return history;
        },
    },
}
</script>
