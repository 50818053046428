import App from "@candybox/app";
import partnerList from '@app/partner/components/partner/table'

App.boot(({router}) => {
    router.addRoute('partner', {
        component: partnerList,
        path: 'partner',
        name: 'partner',
    })
});
