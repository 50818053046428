import Document from '@candybox/structures/document.js';


class RetailAnalytics extends Document {
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id:                         Number,
            countProducts:              Number,
            totalDiscount:              Number,
            countPeopleUseCard:         Number,
            dateForStatisticOrders:     Number,
            discountBySocialType:       Number,
            discountByCommunalType:     Number,
            discountByStudentType:      Number,
            discountByDefaultType:      Number,
            discountBySchoolboyType:    Number,
        };
    }

}

export default RetailAnalytics;
