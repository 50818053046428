import handbook from '@app/core/services/handbook.js';
import { isFilled,
    isEmpty } from '@app/core/helpers.js';
import { isString,
    is,
    isArray,
    nop } from '@candybox/helpers.js';
import dateFormat from 'date-fns/format';
import dateLocale from 'date-fns/locale/uk';
import { translator } from '@app/core/services/translation.js';
import {formatDistanceStrict, formatDuration, intervalToDuration} from "date-fns";
import uk from "date-fns/locale/uk";
import parse from "date-fns/parse";

const handbookEntry = (category) => {
    return (val) => {
        return isFilled(val) ? handbook().get(`${category}.${val}`) : '';
    }
}

const multipleHandbooksEntry = (categories) => {
    if(isArray(categories)){
        return (val) => {
            for (let item in categories) {
                if (isFilled(val) && handbook().get(`${categories[item]}.${val}`)){
                    return handbook().get(`${categories[item]}.${val}`)
                }
            }
            return '';
        }
    }
}

const durationShortFormat = (value) => {
    return formatDistanceStrict(
        0,
        value,
        {
            locale: uk,
            includeSeconds: true,
        }
    );
};

const phoneNumber = (options = {}) => {
    return (val) => {
        if (isString(val)) {
            let result = [
                val.substr(-7, 3),
                val.substr(-4, 2),
                val.substr(-2),
            ].join(options.separator || ' ');

            if (val.length >= 10) {
                let encloseCode = options.encloseCode === true;
                result = (encloseCode ? '(' : '') + val.substr(-10, 3) + (encloseCode ? ') ' : ' ') + result;
            }

            if (val.length > 10) {
                result = val.substr(0, val.length - 10) + ' ' + result;
            }

            return result;
        }
        return '';
    }
}

const date = (format = 'd LLL yyyy') => {
    return datetime(format);
};

const dateChange = (format = 'd LLL yyyy') => {
    return datetimeChange(format);
};

const time = (format = 'HH:mm') => {
    return datetime(format);
};

const datetime = (format = 'd LLL yyyy, HH:mm') => {
    return (val) => {
        return isFilled(val)
            ? dateFormat(is(val, Date) ? val : new Date(val), format, {locale: dateLocale})
            : '';
    }
};

const datetimeChange = (formatTo = 'd LLL yyyy, HH:mm', formatFrom = 'dd.MM.yyyy') => {
    return (val) => {
        if(isFilled(val)) {
            const _val = parse(val, formatFrom, new Date());
            return dateFormat(_val, formatTo, {locale: dateLocale});
        }
        return '';
    }
};

const number = (precision = 2) => {
    return (val) => {
        return ((v) => isNaN(v) ? '' : v.toFixed(precision))(Number(val));
    }
};

const boolean = () => {
    let t = translator();
    return (val) => {
        if (isEmpty(val)) {
            return '';
        }
        return val ? t.translate('Так') : t.translate('Ні');
    }
}

const list = ({separator, itemFormatter}) => {
    return (val) => {
        return isArray(val) ? val.map(itemFormatter || nop).join(separator || ', ') : '';
    }
};

const patient = (options = {}) => {
    let t = translator();
    let df = datetime('yyyy');
    return (val) => {
        return [
            val.full_name,
            options.birth_date !== false && val.birth_date
                ? t.translate('{year} р/н', {year: df(val.birth_date)})
                : null,
            options.contact_number !== false && val.contact_number
                ? t.translate('тел. {tel}', {tel: val.contact_number})
                : null
        ]
            .filter(isFilled)
            .join(', ');
    }
}

const decorateString = (prefix = null, suffix = null) => {
    return (val) => `${prefix ?? ''}${val}${suffix ?? ''}`;
}

export {
    handbookEntry,
    multipleHandbooksEntry,
    phoneNumber,
    date,
    dateChange,
    time,
    datetime,
    number,
    list,
    boolean,
    patient,
    durationShortFormat,
    decorateString,
}
