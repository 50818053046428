import App from '@candybox/app.js';
import processLogList from '@app/call-center/components/call-process-logs/table.vue';
import callLogList from '@app/call-center/components/call-logs/table.vue';
import missedList from '@app/call-center/components/missed-call-logs/table.vue';
import operatorTaskList from '@app/call-center/components/operator-tasks/table.vue';
import callCenterModule from './store.js';

App.boot(({router, store}) => {
    router.addRoute('call-center', {
        component: processLogList,
        path: 'archive-calls',
        name: 'archive-calls',
    });
    router.addRoute('call-center', {
        component: callLogList,
        path: 'real-calls',
        name: 'real-calls',
    });
    router.addRoute('call-center', {
        component: missedList,
        path: 'missed-calls',
        name: 'missed-calls',
    });
    router.addRoute('call-center', {
        component: operatorTaskList,
        path: 'operator-tasks',
        name: 'operator-tasks',
    });
    store.registerModule('callCenter', callCenterModule);
});
