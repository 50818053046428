<template>
    <section class="dialog-body">
        <el-row>
            <el-col :span="24" style="margin-top: 5px">
                <el-button
                    style="width: 100%"
                    type="danger"
                    plain
                    @click="reset">
                    {{ __('Скинути') }}
                </el-button>
            </el-col>
        </el-row>
        <el-row v-for="sample in sampleList">
            <el-col :span="24" style="margin-top: 5px">
                <el-button
                    style="width: 100%"
                    :key="sample.id"
                    :type="sample.type"
                    @click="loadSample(sample.id)">
                    {{ '#' + sample.id + ' (' + sample.name + ')' }}
                </el-button>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import sample from "@app/sample/repositories/sample";
import Query from "@candybox/query/query";
import sampleBackendRepository from "@app/sample/requests/sample-backend-repository";
import constants from "@app/core/constants";

export default {
    props: {
        selectId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            sampleList: [],
            id: null,
        }
    },
    methods: {
        loadSample(id){
            this.id = id
            sampleBackendRepository()
                .create('sampleBackEndRequest', {id: id})
                .send()
                .then((data) => {
                    if (data.body.length > 0) {
                        this.$emit('setFilterOptions', {
                            conditions: data.body,
                            sampleId: id
                        })
                    } else {
                        this.$emit('setFilterOptions', {
                            conditions: null,
                            sampleId: id
                        })
                    }
                })
        },
        loadSampleList() {
            sample()
                .search(
                    (new Query)
                        .where('status', '!=', constants.SAMPLE.STATUS.ARCHIVED)
                        .orderBy('id')
                )
                .then((r) => {
                    r.forEach((row) => {
                        this.sampleList.push({
                            id: row.id,
                            name: row.name,
                            type: this.typeButton(row.id)
                        })
                    })
                })
        },
        reset() {
            this.id = 0
            this.$emit('setFilterOptions', {
                conditions: null,
                sampleId: 0
            })
        },
        typeButton(id) {
            if (id === this.id){
                return 'primary'
            } else {
                return 'default'
            }
        }
    },
    watch: {
        id() {
            this.sampleList.forEach((row) =>{
                row.type = this.typeButton(row.id)
            })
        }
    },
    created() {
        this.id = this.selectId
        this.loadSampleList()
    }
}
</script>
