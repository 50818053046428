import Document from '@candybox/structures/document.js';

class Role extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            permissions: Array,
            has_voip: Boolean,
            has_schedule: Boolean,
        };
    }
}

export default Role;
