<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('claim-time-work.access')"
                :disabled="!isActive"
                type="primary"
                @click="confirm">
                {{ __('Підтвердити') }}
            </el-button>
            <el-button
                v-if="$can('claim-time-work.access')"
                :disabled="!isActive"
                @click="refuse">
                {{ __('Відмовити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from '@app/core/mixins/manage.js';
import formLayout from '@app/employee/components/positions/form.vue';
import history from '@app/employee/components/positions/history.vue';
import claimTimeWork from "@app/employee/repositories/claim-time-work";
import ClaimTimeWork from "@app/employee/documents/claim-time-work";
import {date, datetime, handbookEntry} from "@app/core/formatter";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'employee.full_name',
                    title: this.__('ПІБ'),
                }, {
                    name: 'reason',
                    title: this.__('Причина'),
                    sortable: true,
                    filterable: true,
                }, {
                    name: 'created_at',
                    title: this.__('Дата'),
                    sortable: true,
                    formatter: datetime('dd MMMM yyyy'),
                }, {
                    name: 'status',
                    title: this.__('Статус'),
                    sortable: true,
                    filterable: 'claim_status',
                    formatter: handbookEntry('claim_status'),
                }],
            filter: {
                status: 'new'
            },
            select: ['*'],
            doc: {
                type: Object,
            },
            isActive: false,
        }
    },
    methods: {
        getRepository() {
            return claimTimeWork();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(ClaimTimeWork, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати посаду'),
                editTitle: this.__('Редагувати посаду'),
                width: '400px',
            };
        },
        getHistoryComponent() {
            return history;
        },
        confirm() {
            this.doc.id = this.selectedItem.id;
            this.doc.employee_id = this.selectedItem.employee_id;
            this.getRepository().confirm(this.doc)
                .then(() => {
                    this.$success(this.__('Запит підтверджено!'));
                    this.refresh();
                })
                .catch((err) => {
                    console.log('search errors', err);
                    this.$catchErrors(err);
                })
        },
        refuse() {
            this.doc.id = this.selectedItem.id;
            this.doc.employee_id = this.selectedItem.employee_id;
            this.getRepository().refuse(this.doc)
                .then(() => {
                    this.$warning(this.__('У запиті відмовлено!'));
                    this.refresh();
                })
                .catch((err) => {
                    console.log('search errors', err);
                    this.$catchErrors(err);
                })
        },
    },
    watch: {
        ['selectedItem'](item) {
            this.isActive = !(!item || item.status !== 'new');
        },

    }
}
</script>
