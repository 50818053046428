<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('answer.create')"
                @click="create('')">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('answer.update')"
                :disabled="isDisabledEdit"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/questionnaire-constructor/components/answers/form2.vue';
import manage from '@app/core/mixins/manage.js';
import Answer from "@app/questionnaire/documents/answer";
import answers from "@app/questionnaire-constructor/repositories/answers";
import {get, isEmpty} from "lodash";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'inner_name',
                title: this.__('Внутрішня назва'),
                sortable: true,
                filterable: true,
            }, {
                name: 'order',
                title: this.__('Номер'),
                sortable: true,
                filterable: true,
            }, {
                name: 'is_show',
                title: this.__('Відображати'),
                sortable: true,
                filterable: 'yes_no',
                component: 'table-yes-mark'
            }, {
                name: 'question_names',
                title: this.__('Питання'),
            }],
            filter: {},
            select: ['*'],
            relations: [],
        }
    },
    computed: {
        isDisabledEdit() {
            const relation = get(this.selectedItem, 'client_repository_name');
            return isEmpty(this.selectedItem) || !isEmpty(relation);
        },
    },
    methods: {
        getRepository() {
            return answers();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Answer, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати варіант відповіді'),
                editTitle: this.__('Редагувати варіант відповіді'),
                width: '760px',
            };
        },
    },
}
</script>

