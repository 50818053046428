<template>
    <div
        v-loading="loading"
        class="tree-component"
        :style="style"
    >
        <div
            ref="treeContainer"
            class="tree-container"
        >
            <el-tree
                :props="defaultProps"
                :data="data"
                :show-checkbox="showCheckbox"
                :accordion="accordion"
                @node-click="handleNodeClick"
            >
                <template #default="{ node, data }">
                    <span class="custom-tree-node">
                        <slot
                            name="additional"
                            :node="node"
                            :data="data"
                        >
                            <span>
                                {{ data.node.label }}
                            </span>
                        </slot>
                    </span>
                </template>
            </el-tree>
        </div>
        <div
            class="tree-footer footer rigid-row"
        >
            <div class="controls flex-expand">
                <slot name="controls" />
            </div>
        </div>
    </div>
</template>

<script>
import nodeIcon from "@app/core/components/tree/nodeIcon.vue";

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: null,
        },
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        accordion: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'label',
                isLeaf: 'leaf',
                class: 'tree'
            }
        }
    },
    computed: {
        style() {
            const _dict = {};
            if(this.height) {
                _dict.height = this.height;
                _dict.maxHeight = this.height;
            }
            return _dict;
        },
    },
    methods: {
        handleNodeClick(e) {
            this.$emit('node-click', e);
        }
    }
}
</script>
