<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Аналітика'),
            pages: [
                {
                    index: '1',
                    title: this.__('Оператори'),
                    route: { name: 'operators' },
                    visible: () => this.$can('operator_analytics.access'),
                },
                {
                    index: '2',
                    title: this.__('Дзвінки'),
                    route: { name: 'calls' },
                    visible: () => this.$can('operator_analytics.access'),
                },
                {
                    index: '3',
                    title: this.__('Торговельні мережі'),
                    route: { name: 'retails' },
                    visible: () => this.$can('analytics.retails-access'),
                },
                {
                    index: '4',
                    title: this.__('Анкети'),
                    route: { name: 'questionnaires-analytics' },
                    visible: () => this.$can('questionnaire_analytics.access'),
                },
                {
                    index: '5',
                    title: this.__('Товари'),
                    route: { name: 'products' },
                    visible: () => this.$can('analytics.products-access'),
                },
                {
                    index: '6',
                    title: this.__('Молодші адміністратори'),
                    route: { name: 'small-admins-analytics' },
                    visible: () => this.$can('small_admin_analytics.access'),
                },
            ],
        }
    }
}
</script>
