import App from '@candybox/app.js';
// import employeeList from '@app/employee/components/employees/table.vue';
// import positionList from '@app/employee/components/positions/table.vue';
// import roleList from "@app/user/components/role/table.vue";

App.boot(({router}) => {
    // router.addRoute('resources', {
    //     component: employeeList,
    //     path: 'employees',
    //     name: 'employees',
    // });
    // router.addRoute('resources', {
    //     component: positionList,
    //     path: 'positions',
    //     name: 'positions',
    // });
    // router.addRoute('resources', {
    //     component: roleList,
    //     path: 'role',
    //     name: 'role',
    // });
});
