import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import {get, singleton} from '@candybox/helpers';
import constants from "@app/core/constants";

export default singleton(() => {
        const mapping = new RepositoryRequestMap('/api/sample');

        mapping.validation('store', () => {
            return {
                name: validator()
                    .required()
                    .length(0, 100),
                rules: validator()
                    .when((attr, data) => {
                        return get(data, 'operators') === null;
                    }, (chain) => {
                        chain.required();
                    }),
                status: validator()
                    .required()
                    .length(0, 50),
                operators: validator()
                    .when((attr, data) => {
                        return get(data, 'is_kc') === 1;
                    }, (chain) => {
                        chain.required();
                    }),
            }
        });

        return mapping;
    }
);
