<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Торговельна мережа')"
                    :errors="$errors['distributor_id']"
                    required>
                    <options-provider
                        :source="getDistributors()">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.distributor_id">
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Назва точки продажу')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name"/>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Ідентифікатор')">
                    <el-input v-model="doc.outlet_id" disabled/>
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="16">
                <form-row
                    :label="__('ПІБ відповідального')"
                    :errors="$errors['manager_full_name']">
                    <el-input v-model="doc.manager_full_name"/>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Номер мобільного телефону')"
                    :errors="$errors['phone_number']">
                    <el-input v-model="doc.phone_number"/>
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="16">
                <form-row
                    :label="__('Адреса філії')"
                    :errors="$errors['address']">
                    <el-input v-model="doc.address"/>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Район міста')"
                    :errors="$errors['glossary_id']"
                    required>
                    <options-provider
                        :source="getDistrict()">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.glossary_id">
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import distributors from "@app/distributor/repositories/distributor";
import Query from "@candybox/query/query";
import glossary from "@app/handbook/repositories/glossary";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getDistributors() {
            return distributors()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.distributors = items.map((distributor) => ({
                        id: distributor.id,
                        value: distributor.name,
                    }));
                    return this.distributors;
                });
        },
        getDistrict() {
            return glossary()
                .search((new Query).where('type', '=', 'district').select('id', 'name'))
                .then((items) => {
                    this.glossary = items.map((district) => ({
                        id: district.id,
                        value: district.name,
                    }));
                    return this.glossary;
                });
        },
    },
}
</script>
