<template>
    <el-dialog
        v-bind="topModal.dialogOptions"
        v-model="visible"
        @close="close"
        @closed="closed">
        <template 
            v-for="(item, index) in stack"
            :key="index">
            <component
                v-show="index === stack.length - 1"
                ref="content"
                :is="item.component"
                v-bind="item.componentProps"
                v-on="proxy(item.eventListeners)" />
        </template>
    </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { forEach,
    pickProps,
    skipProps } from '@candybox/helpers.js';

export default {
    props: {
        component: {
            type: [Object, String],
            required: true,
        },
        componentProps: {
            type: Object,
            default: () => ({}),
        },
        modalOptions: {
            type: Object,
            required: true,
        },
        eventListeners: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            visible: false,
            stack: [],
        };
    },
    provide() {
        return {
            modalApi: {
                open: (...args) => {
                    this.pushComponent(...args);
                },
                close: () => {
                    if (this.stack.length > 1) {
                        this.popComponent();
                    } else {
                        this.close();
                    }
                },
                closeAll: () => {
                    this.close();
                },
            }
        }
    },
    computed: {
        topModal() {
            return this.stack[this.stack.length - 1];
        },
    },
    beforeMount() {
        this.stackPush(this.component, this.componentProps, this.eventListeners, {
            closeOnClickModal: false,
            ...this.modalOptions,
        });
    },
    mounted() {
        this.visible = true;
    },
    methods: {
        pushComponent(component, props = {}, eventListeners = {}, modalOptions = {}) {
            this.stackPush(component, props, eventListeners, {
                ...this.modalOptions, 
                ...modalOptions,
            });
        },
        popComponent() {
            let options = this.topModal.options;
            if (options.beforeClose === undefined || options.beforeClose(this) !== false) {
                this.stack.pop();
                if (options.afterClose !== undefined) {
                    options.afterClose(this);
                }
            }
        },
        close() {
            if (this.modalOptions.beforeClose === undefined || 
                this.modalOptions.beforeClose(this) !== false) {
                this.visible = false;
            }
        },
        closed() {
            if (this.modalOptions.afterClose !== undefined) {
                this.modalOptions.afterClose(this);
            }
            this.$emit('closed');
        },
        getTopRef(ref) {
            return _.isArray(ref) ? ref[ref.length - 1] : ref;
        },
        getTopComponent() {
            return this.getTopRef(this.$refs.content);
        },
        proxy(events) {
            let proxied = {};
            forEach(events, (listener, event) => {
                proxied[event] = new Proxy(listener, {
                    apply: (target, _, args) => {
                        return target(this, ...args);
                    },
                });
            });
            return proxied;
        },
        stackPush(component, props, eventListeners, modalOptions) {
            let nonDialogOptions = ['beforeClose', 'afterClose', 'backText'];
            this.stack.push({
                component: component,
                componentProps: props, 
                dialogOptions: skipProps(modalOptions, nonDialogOptions),
                eventListeners: eventListeners,
                options: pickProps(modalOptions, nonDialogOptions),
            });
        },
        ...mapActions({
            removeComponent: 'removeComponent',
        }),
    },
};
</script>