<template>
    <el-date-picker
        v-if="isRange"
        v-model="modelValue"
        unlink-panels
        type="daterange"
        start-placeholder="Початкова дата"
        end-placeholder="Кінцева дата"
        format="DD.MM.YYYY"
        value-format="YYYY-MM-DD"
        class="el-input"
        :disabled="disabled"
    />
    <el-date-picker
        v-else
        v-model="modelValue"
        placeholder="Дата"
        format="DD.MM.YYYY"
        value-format="YYYY-MM-DD"
        class="el-input"
        :disabled="disabled"
    />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Object, null],
            required: true,
        },
        sign: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        isRange() {
            return this.sign === '=';
        }
    },
    watch: {
        modelValue: {
            handler(val) {
                this.$emit('update:modelValue', val);
            },
            deep: true,
        },
    }
}
</script>
