import {count, get} from "@candybox/helpers.js";
import {filter, map} from "lodash";

export default {
    props: {
        attrs: {
            type: Object,
            default: () => ({}),
        }
    },
    computed: {
        parent() {
            return get(this.attrs, 'parent', null);
        },
        children() {
            return get(this.attrs, 'children', null);
        },
    },
    methods: {
        hasRequired(items) {
            const items_ = map(items, (item) => {
                return get(item, 'item.is_required', 0)
            });
            return count(filter(items_, (item) => !!item)) !== 0;
        }
    },
}
