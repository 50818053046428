import employees from "@app/employee/repositories/employees.js";
import Query from "@candybox/query/query.js";
import Employee from "@app/employee/documents/employee.js";

export default {
    methods: {
        getEmployees() {
            return employees()
                .search(
                    (new Query()).limitTo()
                )
                .then((items) => items.map((item) => ({
                    id: item.id,
                    value: (new Employee(item)).full_name,
                })));
        },
    }
}
