<template>
    <div class="sample-condition-content">
        <div class="sample-condition-table">
            <data-table
                ref="table"
                style="height: 100%"
                flex
                :repository="getRepository()"
                :columns="columns"
                @selection-changed="selectionChanged">
                <template v-slot:controls>
                    <el-button
                        @click="remove"
                        :disabled="selectedItem === null">
                        {{ __('Видалити') }}
                    </el-button>
                    <el-button
                        @click="edit"
                        :disabled="selectedItem === null">
                        {{ __('Редагувати') }}
                    </el-button>
                </template>
            </data-table>
        </div>
        <div class="sample-condition">
            <div class="sample-condition-button-group">
                <el-button @click="addSample">
                    {{ __('Створити нову') }}
                </el-button>
                <el-button @click="setFilterOptions" :disabled="!conditionsExist" :loading="work">
                    {{ __('Виконати') }}
                </el-button>
                <el-button @click="create({json: conditions})" :disabled="!conditionsExist || !isEditable">
                    {{ __('Зберегти') }}
                </el-button>
            </div>
            <div class="sample-condition-list">
                <simple-condition
                    v-if="fieldsLoaded"
                    v-for="(condition, key) in conditions"
                    :key="`c-${key}`"
                    :id="key"
                    :selected-condition="condition"
                    :db-answers-in-glossary="answersInGlossary"
                    :fields="fields"
                    :is-new-sample="isEditable"
                    @delete-condition="deleteCondition"
                    @updateConditions="updateConditions"
                />
                <el-row :gutter="20" v-if="fieldsLoaded && isEditable">
                    <el-col :span="10">
                        <el-button @click="addCondition">
                            {{ __('Додати умову') }}
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import manage from "@app/core/mixins/manage";
import sample from "@app/sample/repositories/sample";
import field from "@app/sample/repositories/field";
import Query from "@candybox/query/query";
import Condition from "@app/sample/components/sample/condition";
import Sample from "@app/sample/documents/sample";
import formLayout from "@app/sample/components/sample/form.vue";
import answers from "@app/questionnaire/repositories/answers";
import setRules from "@app/sample/mixins/setRules.js";
import sampleArchiveRepository from "@app/sample/requests/sample-backend-repository";
import {handbookEntry} from '@app/core/formatter.js';
import constants from "@app/core/constants";
import {get, isEqual} from "lodash";
import SimpleCondition from "@app/sample/components/sample/simple-condition.vue";

export default {
    name: "sample-condition",
    components: {SimpleCondition, Condition},
    mixins: [
        manage,
        setRules,
    ],
    data() {
        return {
            fields: null,
            answersInGlossary: null,
            columns: [
                {
                    name: 'sample_name_attr',
                    title: this.__('Назва вибірки'),
                },
                {
                    name: 'status',
                    title: this.__('Статус'),
                    formatter: handbookEntry('sample_status'),
                    sortable: true,
                    width: 80,
                },
            ],
            firstStart: true,
            work: false,
            isEditable: false,
        }
    },
    methods: {
        getRepository() {
            return sample();
        },
        deleteCondition(id) {
            console.log('deleteCondition', this.conditions, id);
            this.conditions.splice(id, 1)
            // delete this.conditions[id];
        },
        updateConditions(value) {
            if(this.isEditable) {
                this.conditions[value.id].selectedField = value.selectedField;
                this.conditions[value.id].firstCondition = value.firstCondition;
                this.conditions[value.id].secondCondition = value.secondCondition;
                this.conditions[value.id].source = value.source;
            }
        },
        addCondition() {
            this.conditions.push({
                selectedField: null,
                firstCondition: null,
                secondCondition: null,
                source: null
            })
        },
        incrementIndex(key) {
            return key + 1;
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Sample, formLayout),
                createTitle: this.__('Зберегти умови вибірки'),
                editForm: this.makeBasicEdit(formLayout),
                editTitle: this.__('Зберегти умови вибірки'),
                width: '760px',
            };
        },
        clear() {
            this.conditions = [];
        },
        addSample() {
            this.clear();
            this.isEditable = true;
            if(get(this.$refs, 'table.$refs.tableInstance')) {
                this.$refs.table.$refs.tableInstance.setCurrentRow(null);
            }
        },
        documentCreated() {
            this.clear();
            this.isEditable = false;
        },
        setFilterOptions() {
            this.$emit('getFilterOptions', this.getFilter())
        },
        init() {
            field()
                .search(new Query())
                .then((data) => {
                    this.fields = data
                })
            answers()
                .search(new Query())
                .then((data) => {
                    this.answersInGlossary = data.filter((row) => row.glossary_id !== null);
                })
        }
    },
    computed: {
        fieldsLoaded() {
            return this.fields !== null
                && this.answersInGlossary !== null
        },
        conditionsExist() {
            if (this.conditions.length > 0) {
                if (this.conditions.every(row => row.selectedField !== null && row.firstCondition !== null)) {
                    return !!this.conditions.every(row => row.firstCondition.length > 0);
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        isNewSample() {
            return this.selectedItem === null;
        },
    },
    watch: {
        selectedItem(data) {
            if(data) {
                this.clear();
                this.isEditable = false;
            }
            this.conditions = data !== null ? data.rules : [];
            this.firstStart = data === null;
        },
    },
    created() {
        this.init();
    }
}
</script>
