import Document, {
    Attribute } from '@candybox/structures/document.js';
import Question from "@app/questionnaire/documents/question";
import RelationAttribute from "@candybox/repository/relation";
import blocks from "@app/questionnaire/repositories/blocks";
import types from "@app/questionnaire/repositories/types";

class SubBlock extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            title: String,
            is_show: Number,
            is_required: Number,
            order: Number,
            inner_name: String,
            questionnaire_block_id: Number,
            questionnaire_type_id: Number,
            $block: RelationAttribute.oneToOne(blocks(), 'questionnaire_block_id'),
            $type: RelationAttribute.oneToOne(types(), 'questionnaire_type_id'),
            questions: Attribute.collection(Question),
        };
    }
}

export default SubBlock;
