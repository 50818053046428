import Document from '@candybox/structures/document.js';

class Permission extends Document
{
    /**
     * Attributes definition
     * 
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            group_id: Number,
            description: String,
        };
    }
}

export default Permission;