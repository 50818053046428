import defaults from "@app/questionnaire/components/question/mixins/defaults";
import getFieldLabel from "@app/questionnaire/components/mixins/getFieldLabel";
import {map, filter, includes, size, get} from "lodash";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
        isSpecial: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [
        defaults,
        getFieldLabel,
    ],
    data() {
        return {
            value: null,
            defaults: {
                registration_state: 'Україна',
                registration_locality: 'Харків',
            }
        }
    },
    computed: {
        questionAnswers() {
            return get(this.question, 'answers', []);
        },
        questionnaireAnswers() {
            return get(this.doc, 'answers', [])
        },
        isRequired() {
            return get(this.question, 'is_required') === 1;
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            //
        },
        getDefaults(fieldName) {
            return get(this.defaults, fieldName, null);
        },
    },
}
