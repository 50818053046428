import BufferRepository from '@candybox/repository/buffer.js';
import RestRepository from '@candybox/repository/rest.js';
import Position from '@app/employee/documents/position.js';
import positionsMap from '@app/employee/requests/position-repository.js';
import { singleton } from '@candybox/helpers.js';

export default singleton(() => {
    return new BufferRepository(
        new RestRepository(Position, positionsMap())
    );
});