import App from '@candybox/app.js';
import employeeList from '@app/employee/components/employees/table.vue';
import serviceEmployees from "@app/employee/components/service-employees/table.vue";
import positionList from '@app/employee/components/positions/table.vue';
import claimTimeWorkList from '@app/employee/components/claim-time-work/table.vue';
import roleList from "@app/user/components/role/table.vue";
import schedule from "@app/employee/components/schedule/calendar.vue";

App.boot(({router}) => {
    router.addRoute('resources', {
        component: employeeList,
        path: 'employees',
        name: 'employees',
    });
    router.addRoute('resources', {
        component: positionList,
        path: 'positions',
        name: 'positions',
    });
    router.addRoute('resources', {
        component: roleList,
        path: 'role',
        name: 'role',
    });
    router.addRoute('resources', {
        component: claimTimeWorkList,
        path: 'claim-time-work',
        name: 'claim-time-work',
    });
    router.addRoute({
        component: schedule,
        path: '/schedule/:id',
        name: 'schedule',
    });
    router.addRoute('resources', {
        component: serviceEmployees,
        path: 'service-employees',
        name: 'service-employees',
    });
});
