<template>
    <div
        v-if="shown"
        class="text-center">
        <el-button
            type="danger"
            @click="internalAction">
            {{ buttonText }}
        </el-button>
    </div>
</template>

<script>
import { get } from '@candybox/helpers.js'
import {includes} from "lodash";
import constants from "@app/core/constants.js";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
    computed: {
        shown() {
            return get(this.item, 'status') === constants.PRODUCTS.IMPORT_ENTITY_STATUS.SUCCESS_IMPORT;
        },
    },
    methods: {
        internalAction() {
            this.$emit('action', {item: this.item});
        }
    }
}
</script>
