<template>
    <div
        v-if="shown"
        class="text-center">
        <span class="svg-icon icon-tiny">
            <svg><use xlink:href="/svg/spritesheet/sprite.svg#check"></use></svg>
        </span>
    </div>
</template>

<script>
import { get } from '@candybox/helpers.js'
import {includes} from "lodash";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object,
            required: true,
        },
    },
    computed: {
        shown() {
            return includes([
                '1',
                true
            ], get(this.item, this.column.name));
        },
    },
}
</script>
