import Document from '@candybox/structures/document.js';
import Employee from '@app/employee/documents/employee.js';

class ActionLog extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            employee: Employee,
            old: Object,
            new: Object,
            action: String,
            created_at: Date,
            entity: String,
            loggable_type: String,
        };
    }
}

export default ActionLog;
