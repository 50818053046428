<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('position.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('position.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('position.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Історія') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import positions from '@app/employee/repositories/positions.js';
import manage from '@app/core/mixins/manage.js';
import Position from '@app/employee/documents/position.js';
import formLayout from '@app/employee/components/positions/form.vue';
import history from '@app/employee/components/positions/history.vue';

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'name',
                title: this.__('Назва'),
                sortable: true,
                filterable: true,
            }],
            filter: {
                name: null,
                has_voip: null,
            },
        }
    },
    methods: {
        getRepository() {
            return positions();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Position, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати посаду'),
                editTitle: this.__('Редагувати посаду'),
                width: '400px',
            };
        },
        getHistoryComponent() {
            return history;
        },
    },
}
</script>
