<template>
    <div
        v-if="isZero"
        class="no-data"
    >
        0
    </div>
    <BaseChart
        v-else-if="!loading"
        :chart-type="chartType"
        :chart-data="chartData"
        :title="title"
        :options="options"
        :width="width"
        :height="height"
    />
    <div
        v-else
        v-loading="loading"
        class="loading-block"
    ></div>
</template>

<script>
import constants from "@app/core/constants.js";
import BaseChart from "@app/analytics/components/base/charts/BaseChart.vue";
import chartMixin from "@app/analytics/components/base/charts/mixins/chartMixin.js";
import { set, each, size, first, values } from 'lodash';

export default {
    name: "PieChart",
    mixins: [
        chartMixin,
    ],
    components: {
        BaseChart,
    },
    data() {
        return {
            chartType: constants.CHART_TYPE.PIE,
        }
    },
    created() {
        this.init();
    },
    computed: {
        options() {
            return {
                pieHole: this.pieHole,
                pieSliceTextStyle: {
                    color: 'black',
                    fontSize: 14,
                },
                pieSliceText: 'value',
                pieStartAngle: 90,
                chartArea: {
                    width: '80%',
                    height: '80%'
                },
                slices: {},
            }
        },
        chartData() {
            const _arr = [];

            _arr.push(['', 'data']);

            each(this.data, (item, key) => {
                _arr.push([key, item]);
            });

            return _arr;
        },
        isZero() {
            if(size(this.data) === 1) {
                const item = first(values(this.data));
                return item === 0;
            }
            return false;
        }
    },
    methods: {
        init() {
            if(size(this.data) > 1) {
                this.options.pieSliceTextStyle.color = 'white';
            }
            if(!this.withLegend) {
                set(this.options, 'legend', 'none');
            }
            if(this.isValuePercent) {
                this.options.pieSliceText = 'percentage';
            }
            this.setVisualization();
        },
        setVisualization() {
            let i = 0;
            each(this.colors, (color, k) => {
                this.options.slices[i] = {
                    color: this.colors[k],
                };
                i++;
            })
        }
    }
}
</script>
