<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="($can('employee.create') || $can('employee.create-institution'))"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="($can('employee.update')|| $can('employee.update-institution'))"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('employee.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('schedule.access')"
                :disabled="selectedItem === null"
                @click="schedule">
                {{ __('Розклад') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Історія') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="action">
                {{ __('Лог') }}
            </el-button>
            <el-button
                v-if="$can('employee.limited_search')"
                @click="search">
                {{ __('Пошук') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import employees from '@app/employee/repositories/employees.js';
import formLayout from '@app/employee/components/employees/form.vue';
import history from '@app/employee/components/employees/history.vue';
import manage from '@app/core/mixins/manage.js';
import Employee from '@app/employee/documents/employee.js';
import positions from '@app/employee/repositories/positions.js';
import search from "@app/employee/components/employees/search";
import {
    handbookEntry, list,
    phoneNumber
} from '@app/core/formatter.js';
import institutions from "@app/institution/repositories/institutions";
import actionLog from "@app/employee/components/employees/action.vue";
import roles from "@app/user/repositories/roles.js";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'full_name',
                title: this.__('ПІБ'),
                width: 250,
                sortable: true,
                filterable: true,
            }, {
                name: 'contact_number',
                width: 150,
                title: this.__('Телефон'),
                sortable: true,
                filterable: true,
                formatter: phoneNumber(),
            }, {
                name: '$position.$.name',
                width: 200,
                title: this.__('Посада'),
                sortable: true,
                filterable: positions(),
                filterProp: 'position_id',
                sortProp: 'position_name',
            }, {
                name: 'institution.name',
                width: 400,
                title: this.__('Навчальний заклад'),
                filterable: institutions(),
                filterProp: 'institution_id',
            },
            {
                name: 'user.roles_name',
                width: 200,
                title: this.__('Роль'),
                filterable: this.getRoles(),
                filterProp: 'role',
                formatter: (val) => Array.isArray(val) ? val.join(', ') : val,
            },
            {
                name: 'status',
                title: this.__('Статус'),
                sortable: true,
                filterable: 'employee_status',
                formatter: handbookEntry('employee_status'),
            }],
            filter: {
                full_name: null,
                contact_number: null,
                position_id: null,
                status: null,
            },
            select: ['*', 'user.*'],
            relations: ['$position'],
        }
    },
    methods: {
        getRepository() {
            return employees();
        },
        getRoles() {
            return roles();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Employee, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати співробітника'),
                editTitle: this.__('Редагувати співробітника'),
                width: '760px',
            };
        },
        getHistoryComponent() {
            return history;
        },
        getActionComponent() {
            return actionLog;
        },
        schedule() {
            this.$router.push({
                name: 'schedule',
                params: {
                    id: this.selectedItem.id,
                },
            });
        },
        search() {
            let messages = {
                ...this.defaultMessages(),
                ...this.getMessages(),
            };
            this.$modalComponent(search, {}, {
                done: (dialog, {doc, stopLoading}) => {
                    this.$clearErrors();
                    this.performUpdate(doc).then(() => {
                        dialog.close();
                        if (messages.updated) {
                            this.$success(messages.updated);
                        }
                        this.documentUpdated(doc);
                        this.refresh();
                    }).catch((err) => {
                        stopLoading();
                        this.$catchErrors(err);
                    });
                },
            }, {
                title: 'Пошук по персоналу',
                width: '760px',
            });
        },
        action() {
            this.$modalComponent(this.getActionComponent(), {
                id: this.selectedItem.id,
            }, {}, {
                title: this.__('Історія активності'),
                width: '1100px',
            });
        },
    },
}
</script>
