<template>
    <data-table
        ref="table"
        :columns="columns"
        :filter="filter"
        :repository="getRepository()"
        :default-sort="sort"
        :default-height="tableHeight"
    >
    </data-table>
</template>

<script>
import {
    date,
    dateChange,
    datetime,
    handbookEntry,
    multipleHandbooksEntry,
    phoneNumber
} from '@app/core/formatter.js';
import actionLogs from '@app/action-log/repositories/action-logs.js';
import logEntry from '@app/core/components/manage/history-change.vue';
import employees from '@app/employee/repositories/employees.js';

export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'created_at',
                    title: this.__('Дата'),
                    width: 150,
                    sortable: true,
                    filterable: true,
                    formatter: datetime(),
                },
                {
                    name: 'employee.full_name',
                    title: this.__('Автор'),
                    width: 200,
                },
                {
                    name: 'loggable_type',
                    title: this.__('Тип'),
                    filterable: 'loggable_type',
                    formatter: handbookEntry('loggable_type'),
                    width: 200,
                },
                {
                    name: 'entity',
                    title: this.__('Сутність'),
                    width: 200,
                },
                {
                    name: 'changes',
                    title: this.__('Зміни'),
                    component: Object.freeze(logEntry),
                    componentProps: {
                        attributes: this.getAttributes(),
                    },
                },
            ],
            filter: {
                employee_id: this.id,
            },
            sort: {
                order: 'descending',
                column: 'id',
            },
            entity: null,
            tableHeight: '560px',
        };
    },
    methods: {
        getRepository() {
            return actionLogs();
        },
        getAttributes() {
            return {
                last_name: this.__('Ім’я'),
                first_name: this.__('Прізвище'),
                middle_name: this.__('По батькові'),
                birth_date: {
                    title: this.__('Дата народження'),
                    formatter: dateChange(),
                },
                contact_number: {
                    title: this.__('Номер телефону'),
                    formatter: phoneNumber(),
                },
                email: this.__('Email'),
                experience: this.__('Досвід роботи'),
                notes: this.__('Додатково'),
                position_id: this.__('Посада'),
                status: {
                    title: this.__('Статус'),
                    formatter: multipleHandbooksEntry([
                        'card_status',
                        'employee_status'
                    ]),
                },
                date_hired: {
                    title: this.__('Працює з'),
                    formatter: date(),
                },
                date_fired: {
                    title: this.__('Звільнений'),
                    formatter: date(),
                },
                sip: this.__('Номер SIP'),
                sip_password: this.__('Пароль телефонії'),
                login: this.__('Логін'),
                roles: this.__('Роль'),
                permissions: this.__('Додаткові повноваження'),
                type: this.__('Тип'),
                name: this.__('Назва'),
                code: this.__('Код пільги'),

                family_status: this.__('Сімейний статус'),


                birth_state: this.__('Країна народження'),
                birth_region: this.__('Область народження'),
                birth_district: this.__('Район народження'),
                birth_locality: this.__('Населений пункт народження'),
                rnokpp: this.__('ІПН'),

                registration_state: this.__('Країна реєстрації'),
                registration_region: this.__('Область реєстрації'),
                registration_district: this.__('Район реєстрації'),
                registration_locality: this.__('Населений пункт реєстрації'),
                registration_street: this.__('Вулиця реєстрації'),
                registration_house: this.__('Будинок реєстрації'),
                registration_building: this.__('Корпус реєстрації'),
                registration_apartment: this.__('Квартира реєстрації'),
                registration_room: this.__('Кімната реєстрації'),

                residential_state: this.__('Країна реєстрації'),
                residential_region: this.__('Область реєстрації'),
                residential_district: this.__('Район реєстрації'),
                residential_locality: this.__('Населений пункт реєстрації'),
                residential_street: this.__('Вулиця реєстрації'),
                residential_house: this.__('Будинок реєстрації'),
                residential_building: this.__('Корпус реєстрації'),
                residential_apartment: this.__('Квартира реєстрації'),
                residential_room: this.__('Кімната реєстрації'),

                additional_number: {
                    title: this.__('Номер додаткового телефону'),
                    formatter: phoneNumber(),
                },
                communication_ways: this.__('Спосіб комунікації'),

                passport_series: this.__('Серія паспорту'),
                passport_number: this.__('Номер паспорту'),
                passport_date: this.__('Дата видачі паспорту'),
                passport_issue: this.__('Ким віданий'),
                passport_id: this.__('Номер id паспорту'),
                passport_id_issue: this.__('Ким віданий'),

                social_status: this.__('Соціальний статус'),
                privileges_category: this.__('Пільгова категорія'),
                pension_number: this.__('Номер пенсійоного посвідчення'),
                is_working_pensioner: this.__('Працюючий пенсіонер'),
                has_wish_children: this.__('Є діти'),

                educational_institution: this.__('Навчальний заклад'),

                class_number: this.__('Номер класу'),
                class_letter: this.__('Литера класу'),
                pupil_privileges_category: this.__('Пільгова категорія учня'),
                paid_foreign_languages: this.__('Вивчення іноземних мов'),
                sport_sections: this.__('Спортивна секція'),
                transport: this.__('Транспорт яким користується учень'),
                skills: this.__('Навички які розвиває дитина'),

                position: this.__('Посада'),

                organization_name: this.__('Назва організації'),
                organization_activity_field: this.__('Сфера діяльності організації'),

                faculty: this.__('Факультет'),
                course_number: this.__('Номер курса'),
                is_live_in_city: this.__('Мешкає в місті'),
                student_educational_institution: this.__('Навчальний заклад'),
                student_privileges_category: this.__('Пільгова категорія студента'),

                living_type: this.__('Житлові умови'),
                reasons: this.__('Причина замовлення карти'),
                other_services: this.__('Інщі послуги за картою'),
                catch_way: this.__('Спосіб отримання карти'),

                questionnaire_type_id: this.__('Тип анкети'),
                questionnaire_status_id: this.__('Статус анкети'),
                viewed_at: this.__('Переглянуто'),

                status_by_questionnaire: {
                    title: this.__('Статус за анкетою'),
                    formatter: handbookEntry('card_status_by_questionnaire'),
                },
                bar_code: this.__('Штрих-код'),
                benefit_code: this.__('Код пільги'),
                card_code: this.__('Номер картки'),
                qr_code: this.__('QR-код'),
                questionnaire_id: this.__('Номер анкети'),

                date_of_issue: this.__('Дата видачі'),
                expiry_date: this.__('Термін дії'),


                manager_id: this.__('Менеджер'),
                chief_name: this.__('ПІБ керівника'),

                address: this.__('Адреса'),

                entry_year: this.__('Рік вступу'),

                institution_id: this.__('Навчальний заклад'),
                export: this.__('Експорт'),
                import: this.__('Імпорт'),
            };

        },
    },
}
</script>
