<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="8">
                <el-input
                    class="hidden"
                    autocomplete="false"
                />
                <form-row
                    :label="__('Прізвище')"
                    :errors="$errors['last_name']"
                    required>
                    <el-input v-model="doc.last_name"/>
                </form-row>
                <form-row
                    :label="__('Ім’я')"
                    :errors="$errors['first_name']"
                    required>
                    <el-input v-model="doc.first_name"/>
                </form-row>
                <form-row
                    :label="__('Email')"
                    :errors="$errors['email']"
                    required>
                    <el-input v-model="doc.email"/>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Номер телефону')"
                    :errors="$errors['contact_number']"
                    required>
                    <el-input v-model="doc.contact_number"/>
                </form-row>
                <form-row
                    :label="__('РНОКПП')"
                    :errors="$errors['rnokpp']"
                    required>
                    <el-input v-model="doc.rnokpp"/>
                </form-row>
                <form-row
                    :label="__('Посада')"
                    :errors="$errors['position_id']"
                    required>
                    <options-provider
                        :source="getPositions()">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.position_id"
                                filterable>
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Логин')"
                    :errors="$errors['user.login']"
                    required>
                    <el-input v-model="doc.user.login"/>
                </form-row>
                <form-row
                    :label="__('Пароль')"
                    :errors="$errors['user.password']"
                    required>
                    <el-input
                        show-password
                        autocomplete="new-password"
                        v-model="doc.user.password"
                    />
                </form-row>
                <form-row
                    :label="__('Роль')"
                    :errors="$errors['user.roles']"
                    required>
                    <options-provider
                        :source="getRoles()">
                        <template v-slot:default="data">
                            <el-select
                                multiple
                                collapse-tags
                                v-model="doc.user.roles">
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import positions from "@app/employee/repositories/service-positions.js";
import Query from "@candybox/query/query";
import roles from "@app/employee/repositories/service-role.js";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            positions: null,
        }
    },
    methods: {
        getPositions() {
            return positions()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.positions = items.map((pos) => ({
                        id: pos.id,
                        value: pos.name,
                    }));
                    this.updateCurrentPosition(this.doc.position_id);
                    return this.positions;
                });
        },
        getRoles() {
            return roles()
                .search((new Query).where('code', '=', 'service_admin').limitTo(1))
                .then((items) => {
                    this.positions = items.map((pos) => ({
                        id: pos.id,
                        value: pos.name,
                    }));
                    this.updateCurrentPosition(this.doc.position_id);
                    return this.positions;
                });
        },
        updateCurrentPosition(id) {
            this.currentPosition = this.positions.find((pos) => {
                return pos.id === id;
            });
        },
    },
    computed: {
        currentPosition: {
            get() {
                return this.doc.$position.$;
            },
            set(val) {
                return this.doc.$position = val;
            },
        },
    },
    watch: {
        ['doc.position_id'](id) {
            this.updateCurrentPosition(id);
        },
    },
    created() {
        this.doc.status = 'working';
    }
}
</script>
