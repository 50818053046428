<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="12">
                <form-row
                    :label="__('Назва партнера')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name"/>
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('Токен')"
                    :errors="$errors['token']"
                    required>
                    <el-input v-model="doc.api_token" placeholder="Натисніть кнопку генераціі ключа" disabled/>
                </form-row>
                <form-row
                    :errors="$errors['setToken']">
                    <el-button
                        type="primary"
                        @click="setToken">{{ __('Генерация ключа') }}
                    </el-button>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import FormRow from "@app/core/components/form/form-row";

export default {
    components: {FormRow},
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    methods: {
        setToken() {
            this.doc.api_token = this.randomString(32);
        },
        randomString(len) {
            var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var randomString = '';
            for (var i = 0; i < len; i++) {
                var randomPoz = Math.floor(Math.random() * charSet.length);
                randomString += charSet.substring(randomPoz, randomPoz + 1);
            }
            return randomString;
        },
    },
}
</script>
