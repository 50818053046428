<template>
    <div class="main-control">
        <div class="form mb-15"
            v-if="processState"
        >
            <el-row
                v-if="processState.processLog"
                class="form-block"
                :gutter="20"
            >
                <el-col :span="12">
                    <form-row
                        :label="__('Абонент')"
                    >
                        <template #label-addon>
                            <a href="#"
                               @click.prevent="selectContact"
                            >
                                {{ __('Додати номер') }}
                            </a>
                        </template>
                        <options-provider
                            :source="getContacts"
                        >
                            <template v-slot:default="data">
                                <el-select
                                    v-model="selectedContactName"
                                    :loading="data.loading"
                                >
                                    <el-option
                                        v-for="item in data.items"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>

                <el-col :span="12">
                    <form-row
                        name="subscriberNumber"
                        :label="__('Номер абонента')">
                        <el-input
                            v-if="!processState.currentContact"
                            v-model="processState.phoneNumber"
                            :placeholder="__('Введить номер SIP')" />
                        <options-provider
                            v-else-if="processState.currentContact.numbers.length > 1"
                            :source="processState.currentContact.numbers"
                        >
                            <template v-slot:default="data">
                                <el-select
                                    :loading="data.loading"
                                    v-model="processState.phoneNumber"
                                >
                                    <el-option
                                        v-for="item in data.items"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id" />
                                </el-select>
                            </template>
                        </options-provider>
                        <el-input
                            v-else
                            v-model="processState.phoneNumber"
                        />
                    </form-row>
                </el-col>

                <el-col :span="8">
                    <form-row
                        :label="__('Причина звернення')"
                        :errors="$errors['reason']"
                        required
                    >
                        <options-provider source="call_reason">
                            <template v-slot:default="data">
                                <el-select
                                    :loading="data.loading"
                                    v-model="processState.processLog.reason">
                                    <el-option
                                        v-for="item in data.items"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>

                <el-col :span="8">
                    <form-row
                        :label="__('Властник картки')"
                        :errors="$errors['is_card_owner']"
                        required
                    >
                        <options-provider
                            source="yes_no"
                        >
                            <template v-slot:default="data">
                                <el-radio-group v-model="processState.processLog.is_card_owner">
                                    <el-radio-button
                                        v-for="option in data.items"
                                        :key="option.id"
                                        :label="option.id">
                                        {{ option.value }}
                                    </el-radio-button>
                                </el-radio-group>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>
                <el-col :span="8">
                    <form-row
                        :label="__('Тип звернення')"
                        :errors="$errors['is_first_visit']"
                        required
                    >
                        <options-provider
                            source="call_process_visit_type"
                        >
                            <template v-slot:default="data">
                                <el-radio-group v-model="processState.processLog.is_first_visit">
                                    <el-radio-button
                                        v-for="option in data.items"
                                        :key="option.id"
                                        :label="option.id">
                                        {{ option.value }}
                                    </el-radio-button>
                                </el-radio-group>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>

                <el-col :span="8">
                    <form-row
                        :label="__('Прізвище')"
                        :errors="$errors['last_name']"
                    >
                        <el-input
                            v-model="processState.processLog.last_name"
                        />
                    </form-row>
                </el-col>
                <el-col :span="8">
                    <form-row
                        :label="__('Ім’я')"
                        :errors="$errors['first_name']"
                    >
                        <el-input
                            v-model="processState.processLog.first_name"
                        />
                    </form-row>
                </el-col>
                <el-col :span="8">
                    <form-row
                        :label="__('По батькові')"
                        :errors="$errors['middle_name']"
                    >
                        <el-input
                            v-model="processState.processLog.middle_name"
                        />
                    </form-row>
                </el-col>
                <el-col
                    v-if="isExtended"
                    :span="8"
                >
                    <form-row
                        :label="__('РНКООП')"
                        :errors="$errors['rnkoop']"
                    >
                        <el-input
                            v-model="processState.processLog.rnkoop"
                        />
                    </form-row>
                </el-col>
                <el-col
                    v-if="isExtended"
                    :span="8"
                >
                    <form-row
                        :label="__('Район проживання')"
                        :errors="$errors['district']"
                    >
                        <options-provider
                            source="district"
                        >
                            <template v-slot:default="data">
                                <el-select
                                    v-model="processState.processLog.district"
                                    :loading="data.loading"
                                >
                                    <el-option
                                        v-for="option in data.items"
                                        :key="option.id"
                                        :label="option.value"
                                        :value="option.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>
                <el-col
                    v-if="isExtended"
                    :span="8"
                >
                    <form-row
                        :label="__('Номер КХ')"
                        :errors="$errors['card_number']"
                    >
                        <el-input
                            v-model="processState.processLog.card_number"
                        />
                    </form-row>
                </el-col>
                <el-col :span="8">
                    <form-row
                        :label="__('Дата народження')"
                        :errors="$errors['birth_date']"
                    >
                        <el-date-picker
                            v-model="processState.processLog.birth_date"
                            type="date"
                            format="DD.MM.YYYY"
                        />
                    </form-row>
                </el-col>
                <el-col :span="8">
                    <form-row
                        :label="__('Реакція абонента')"
                        :errors="$errors['reaction']"
                    >
                        <options-provider
                            source="call_reaction"
                        >
                            <template v-slot:default="data">
                                <el-select
                                    v-model="processState.processLog.reaction"
                                    :loading="data.loading"
                                >
                                    <el-option
                                        v-for="option in data.items"
                                        :key="option.id"
                                        :label="option.value"
                                        :value="option.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>
                <el-col :span="8">
                    <form-row
                        :label="__('Результат опрацювання КЦ')"
                        :errors="$errors['call_result_kc']"
                        required
                    >
                        <options-provider
                            source="call_status">
                            <template v-slot:default="data">
                                <el-select
                                    v-model="processState.processLog.call_result_kc"
                                    :loading="data.loading"
                                >
                                    <el-option
                                        v-for="item in data.items"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item.id" />
                                </el-select>
                            </template>
                        </options-provider>
                    </form-row>
                </el-col>
                <el-col :span="8"
                    v-if="processState.processLog.call_result_kc === 'recall'"
                >
                    <form-row
                        :label="__('Час відкладеного дзвінка')"
                        :errors="$errors['delayed_time']"
                    >
                        <el-date-picker
                            v-model="processState.processLog.delayed_time"
                            type="datetime"
                        />
                    </form-row>
                </el-col>
                <el-col :span="24">
                    <form-row
                        :label="__('Примітка')"
                        :errors="$errors['comment']"
                    >
                        <el-input
                            v-model="processState.processLog.comment"
                            type="textarea"
                            resize="none"
                        />
                    </form-row>
                </el-col>
            </el-row>
        </div>

        <div class="buttons">
            <el-button
                v-if="canAnswerCall"
                type="primary"
                size="large"
                @click="answerCall"
            >
                {{ __('Прийняти') }}
            </el-button>
            <el-button
                v-else
                type="primary"
                :disabled="!canCallContact"
                size="large"
                @click="callContact"
            >
                {{ __('Зателефонувати') }}
            </el-button>

            <el-button
                v-if="canRejectCall"
                type="danger"
                size="large"
                @click="rejectCall"
            >
                {{ __('Відхилити') }}
            </el-button>
            <el-button
                v-else
                type="danger"
                size="large"
                :disabled="!canTerminateCall"
                @click="terminateCall"
            >
                {{ __('Завершити розмову') }}
                <span v-if="currentCallDuration !== 0">/ {{ durationToFormat(currentCallDuration) }}</span>
            </el-button>

            <el-button
                v-if="parkedCall !== null"
                :disabled="!canStartCall"
                @click="unholdCall(parkedCall)"
                size="large"
            >
                {{ __('Зняти з утримання') }}
                <span v-if="parkedCallTime !== 0">/ {{ durationToFormat(parkedCallTime) }}</span>
            </el-button>
            <el-button
                v-else
                :disabled="!canHoldCall"
                @click="holdCall"
                size="large"
            >
                {{ __('Утримувати') }}
            </el-button>

            <el-button
                :disabled="!canRedirectToQuestionnaire"
                @click="redirectToQuestionnaire"
                size="large"
            >
                {{ __('До анкети') }}
            </el-button>

            <el-button
                :disabled="!canCompleteProcess"
                @click="completeProcessCall"
                size="large"
            >
                {{ __('Завершити обробку') }}
            </el-button>

            <el-button
                :disabled="!canClearProcessLogData"
                @click="clearFormData"
                size="large"
            >
                {{ __('Очистити дані') }}
            </el-button>
        </div>
    </div>
</template>

<script>
import VoipMixin from '@app/call-center/mixins/voip.js';
import processLogs from '@app/call-center/repositories/process-logs.js'
import {get} from "@candybox/helpers.js";
import {UA} from "@app/services/sip-ua.js";
import {QuestionnaireContact} from "@app/services/sip-ua/process-state.js";
import SearchContact from "@app/call-center/components/call-center/components/SearchContact.vue";
import types from "@app/questionnaire/repositories/types.js";
import {datetime, durationShortFormat, handbookEntry} from '@app/core/formatter.js';
import constants from "@app/core/constants.js";
import {set} from 'lodash';
import store from "@app/store.js";
import {STATE_IN_PROGRESS} from "@app/services/sip-ua/call";
import ProcessLog from "@app/call-center/documents/process-log";
import {join} from "lodash/array";

const SIP_REGEXP = new RegExp('^[0-9]{3,5}|[0-9]{10,12}$');

export default {
    mixins: [
        VoipMixin,
    ],
    data() {
        return {
            selectedContact: null,
            contacts: [],
            parkedCall: null,
            currentCallDuration: 0,
            parkedCallTime: 0,
            selectedContactName: null,
        };
    },
    computed: {
        canClearProcessLogData() {
            return get(this.processState, 'processLog.hasData')
                || this.selectedContactName
                || this.processState.phoneNumber;
        },
        isProcessing() {
            return get(this.processState, 'processing');
        },
        contactId: {
            get () {
                return get(this.processState, 'currentContact.questionnaire.id');
            },
            set(value) {
                set(this.processState, 'currentContact.questionnaire.id', value)
            }
        },
        canRedirectToQuestionnaire() {
            return !!this.contactId;
        },
        isExtended() {
            const reason = get(this.processState, 'processLog.reason');
            return reason && reason !== constants.CALL.COMPLAINT;
        },
        typeRepository() {
            return types();
        },
        canCompleteProcess() {
            return this.parkedCalls.length === 0
                && !this.hasCall()
                && this.isProcessing;
        },
        canCallContact() {
            return this.canStartCall
                && this.parkedCall === null
                && this.validContactSelected();
        },
        getContacts() {
            return this.contacts.map(this.contactItemBuilder());
        },
    },
    created() {
        this.$events.on('broadcast.recall', this.recall);
        this.updateTimers = () => {
            if (this.parkedCall !== null) {
                this.parkedCallTime = this.parkedCall.waitingTime;
            }
            if (UA().stateManager.isInCall && this.callState === STATE_IN_PROGRESS) {
                this.currentCallDuration = UA().stateManager.currentCallDuration;
            }
        }
    },
    mounted() {
        this.revert();
        this.$ticker.on(this.updateTimers);
    },
    beforeDestroy() {
        this.$ticker.off(this.updateTimers);
        this.$events.off('broadcast.recall', this.recall);
    },
    methods: {
        durationToFormat(duration) {
            return durationShortFormat(duration);
        },
        getQuestionnaireTypes() {
            return types();
        },
        completeProcessCall() {
            this.$clearErrors();
            this.assignContact(this.processState.processLog);
            return this.saveProcessLog();
        },
        assignContact(processLog) {
            let contact = this.processState.currentContact;
            if (contact === undefined || contact.isUnknown) {
                processLog.contact_id = null;
                processLog.contact_type = null;
            } else {
                processLog.contact_id = contact.id;
                processLog.contact_type = contact.type;
            }
            processLog.phone_number = this.processState.phoneNumber;
            processLog.contact_number = processLog.phone_number;
        },
        saveProcessLog() {
            const doc = this.processState.processLog;
            this.$clearErrors();
            processLogs()
                .store(doc)
                .then((response) => {
                    this.$success(this.__('Результат обработки успешно сохранен'));
                    this.$events.emit('processLog:completed');
                    this.clearFormData();
                    this.processState.reset();
                    this.endWrapUp();
                }).catch((err) => {
                    this.$catchErrors(err);
                });
        },
        clearFormData() {
            this.selectedContact = null;
            this.clearForm();
            this.contactId = null;
            // this.processState.reset();
            store.commit('processState', this.processState);
            // emitter.emit('voip:cleardata');
        },
        selectContact() {
            this.$modalComponent(SearchContact, {}, {
                    cancel: (dialog) => {
                        dialog.close();
                    },
                    questionnaireSelected: (dialog, questionnaire) => {
                        dialog.close();
                        this.selectQuestionnaire(questionnaire);
                    },
                },
                {
                    title: this.__('Додати контакт'),
                    width: '760px',
                });
        },
        selectQuestionnaire(questionnaire) {
            if(questionnaire) {
                let contact = new QuestionnaireContact(questionnaire);
                this.processState.addContact(contact);
                this.processState.selectContact(contact.uid);
                this.selectedContactName = get(contact, 'questionnaire.full_name_attr', contact.uid);
                this.contacts = this.processState.contactPool;
                this.processState.phoneNumber = contact.defaultNumber;
            }
        },
        callContact() {
            let numberToCall = this.processState.phoneNumber;
            this.startCall(numberToCall);
        },
        contactItemBuilder() {
            return (item) => ({
                id: item.uid,
                value: item.name + (this.isContactInHold(item) ? this.__(' (Утримується)') : '')
            });
        },
        isContactInHold(contact) {
            return contact.numbers.some((number) => UA().isParked(number.number));
        },
        validContactSelected() {
            return get(this.processState, 'currentContact') !== undefined
                || SIP_REGEXP.test(get(this.processState, 'phoneNumber'));
        },
        revert() {
            if (this.processState.currentContact !== undefined) {
                this.selectedContact = this.processState.currentContact;
                this.updateParkedCall(this.processState.currentContact);
            }
        },
        updateParkedCall(contact) {
            if (contact !== undefined) {
                for (let number of contact.numbers) {
                    let parked = UA().getParkedCall(number.number);
                    if (parked !== undefined) {
                        this.parkedCallTime = parked.waitingTime;
                        this.parkedCall = parked;
                        return;
                    }
                }
            }
            this.parkedCallTime = 0;
            this.parkedCall = null;
        },
        redirectToQuestionnaire() {
            const route = this.$router.resolve({
                name: 'questionnaires',
                query: {
                    id: this.contactId,
                }
            });
            window.open(route.href, '_blank');
        },
        clearForm() {
            this.processState.clearProcessLog();
            this.selectedContactName = null;
            this.processState.phoneNumber = null;
        },
        recall(data) {
            if (data) {
                const processLog = new ProcessLog(data.processLog);
                if(processLog.operator_id === get(this.$user, 'employee.id')) {
                    const fullName = get(processLog, 'fullName');
                    const phone = get(processLog, 'contact_number');
                    const delayedTime = datetime('dd MMM HH:mm')(get(processLog, 'delayed_time'));
                    const reason = handbookEntry('call_reason')(get(processLog, 'reason'));

                    const _arr = [
                        this.__('Увага! У Вас заплановано дзвінок на'),
                        delayedTime,
                        `з ${fullName}`,
                        `+38${phone}`,
                        `Причина звернення ${reason}`
                    ];

                    this.$confirm(join(_arr, '<br>'), () => {}, {
                        showCancelButton: false,
                        dangerouslyUseHTMLString: true,
                    });
                }
            }
        },
    },
    watch: {
        call(val) {
            if(!val) {
                this.currentCallDuration = 0;
            }
        },
        ['processState.currentContact'](val) {
            if (val === undefined) {
                this.selectedContactName = null;
            } else {
                this.selectedContactName = get(val, 'questionnaire.full_name_attr', val.uid);
            }
        },
        selectedContactName(val) {
            if(val) {
                if(val.indexOf(':') === -1) {
                    const contact = this.processState.findContactByName(val);
                    if(contact) {
                        this.processState.selectContact(contact.uid);
                    }
                } else {
                    this.processState.selectContact(val);
                }
            }
        },
        lastParkedCall(parkedCall) {
            this.parkedCall = parkedCall;
        },
        ['processState.processLog']: {
            handler() {
                this.$store.commit('processState', this.processState);
            },
            deep: true
        }
    }
};
</script>
