import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import { phoneNumber } from '@app/core/validation.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/service-employee');

    mapping.validation('store', () => {
        return {
            first_name: validator()
                .required()
                .length(0, 100),
            middle_name: validator()
                .length(0, 100),
            last_name: validator()
                .required()
                .length(0, 100),
            contact_number: validator()
                .required()
                .custom(phoneNumber),
            email: validator()
                .email()
                .required()
                .length(0, 100),
            position_id: validator()
                .required(),
            status: validator()
                .required(),
            rnokpp: validator()
                .required()
                .length(10),
            user: validator()
                .nested({
                    login(chain) {
                        chain.required()
                            .length(0, 50);
                    },
                    password(chain) {
                        chain.required()
                            .length(0, 50);
                    },
                    roles(chain) {
                        chain.required();
                    },
                }),
        }
    });

    return mapping;
});
