<template>
    <section class="dialog-body">
        <el-row :gutter="20">
<!--            <el-col :span="8">
                <form-row
                    :label="__('Тип')"
                    :errors="$errors['type']"
                    required>
                    <options-provider
                        :source="types">
                        <template v-slot:default="data">
                            <el-select
                                @change="setCategoriesByType"
                                :loading="data.loading"
                                v-model="doc.type">
                                <el-option
                                    v-for="item in data.items"

                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>-->
            <el-col :span="12">
                <form-row
                    :label="__('Назва')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name"/>
                </form-row>
            </el-col>
            <el-col :span="12">
                <form-row
                    :label="__('УКТ ЗЕД')"
                    :errors="$errors['unified_code']"
                    required
                >
                    <el-input v-model="doc.unified_code"/>
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24" v-if="parentTitleVisible">
                <form-row
                    :label="__('Батьківська категорія')"
                    :errors="$errors['parent_id']"
                >
                    <options-provider
                        :source="categoriesForm">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.parent_id"
                                filterable
                                placeholder="Оберіть"
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="24" v-if="!parentTitleVisible">
                <form-row
                    :label="__('Оригінал')"
                    :errors="$errors['original_id']"
                    required>
                    <options-provider
                        :source="categoriesForm">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.original_id"
                                filterable
                                placeholder="Оберіть"
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"/>
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
    import Query from '@candybox/query/query.js';
    import categories from "@app/category/repositories/categories";

    export default {
        props: {
            doc: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                categories: null,
                originals: null,
                types: [
                    {
                        id: 1,
                        value: 'Категорія',
                    },
                    {
                        id: 2,
                        value: 'Синонім',
                    },
                ],
                filteredCategories: '',
                parentTitleVisible: true,
                categoriesForm: [],
            }
        },
        created() {
            if(!this.doc.id) {
                this.doc.type = 1;
            }
        },
        mounted() {
          this.setCategoriesByType();
          this.updateCurrentCategory(this.doc.category_id);
        },
        methods: {
            getCategory() {
                return categories()
                    .search((new Query).orderBy('unified_code'))
                    .then((items) => {
                        this.categories = items.map((cat) => ({
                            id: cat.id,
                            value: cat.name,
                        }));
                        this.updateCurrentCategory(this.doc.category_id);
                        return this.categories;
                    });
            },
            updateCurrentCategory(id) {
                this.currentCategory = this.categoriesForm.find((cat) => {
                    return cat.id === id;
                });
            },
            setCategoriesByType() {
                if (this.doc.type === 1) {
                    return categories()
                        .search((new Query).where('type', '=', '1').orderBy('unified_code'))
                        .then((items) => {
                            if (this.doc.id) {
                                let id = this.doc.id;
                                items.forEach(function(el, i) {
                                    if (el.id === id) {
                                        items.splice(i, 1);
                                    }
                                });
                            }
                            items.sort((a,b) => a.name.localeCompare(b.name));
                            this.categoriesForm = items.map((cat) => ({
                                id: cat.id,
                                value: (cat.unified_code !== null) ? cat.unified_code + ' ' + cat.name : cat.name,
                            }));
                            this.parentTitleVisible = true;
                            return this.categoriesForm;
                        });
                } else if (this.doc.type === 2) {
                    return categories()
                        .search((new Query).where('type', '=', '1').orderBy('unified_code'))
                        .then((items) => {
                            if (this.doc.id) {
                                let id = this.doc.id;
                                items.forEach(function(el, i) {
                                    if (el.id === id) {
                                        items.splice(i, 1);
                                    }
                                });
                            }

                            const temp = items.map((cat) => ({
                                id: cat.id,
                                value: cat.name,
                                parent_id: cat.parent_id
                            }));

                            const bottomCategories = this.getBotCategories(this.buildTree(temp));
                            bottomCategories.push(
                                {
                                    id: 1,
                                    value: "Не визначені",
                                    parent_id: null,
                                }
                            );

                            this.categoriesForm = bottomCategories.sort((a,b) => a.value.localeCompare(b.value));
                            this.parentTitleVisible = false;
                            return this.categoriesForm;
                        });
                }

            },
            buildTree(items, parent) {
                parent = parent || null;
                let result = [];

                items.forEach((item) => {
                    if (item.parent_id === parent) {
                        result.push(item);
                        item.children = this.buildTree(items, item.id);

                        if (!item.children.length) {
                            delete item.children;
                        }
                    }
                });

                return result;
            },
            getBotCategories(items, result) {
                result = result || [];

                items.forEach((item) => {
                    if (item.children) {
                        this.getBotCategories(item.children, result);
                    } else {
                        result.push(item);
                    }
                });

                return result;
            },
        },
        computed: {
            currentCategory: {
                get() {
                    return this.doc.$category.$;
                },
                set(val) {
                    return this.doc.$category = val;
                },
            },

        },
        watch: {
            ['doc.id'](id) {
                this.updateCurrentCategory(id);
            },
        }
    }
</script>
