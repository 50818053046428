<template>
    <a href=""
        @click.prevent="sendPhone"
    >
        {{ phone }}
    </a>
</template>

<script>
import {get} from "@candybox/helpers.js";
import {mapGetters} from "vuex";
import {phoneNumber} from "@app/core/formatter.js";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            processState: 'getProcessState',
        }),
        phone() {
            let phone = get(this.item, 'phone_number');
            if(!phone) {
                phone = get(this.item, 'contact_number');
            }
            if(!phone) {
                phone = get(this.item, 'questionnaire.contact_number');
            }
            return phone;
        },
    },
    methods: {
        sendPhone() {
            this.processState.phoneNumber = this.phone;
        }
    }
}
</script>
