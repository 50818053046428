<template>
    <form-row
        :label="label"
        :errors="$errors[question.field_name]"
        :required="isRequired"
        :class="{ 'special-title': isSpecial }"
    >
        <options-provider
            :source="getClientRepository()"
        >
            <template v-slot:default="data">
                <el-select
                    v-model="docField"
                    :loading="data.loading"
                    :disabled="disabled || readOnly"
                    filterable
                    clearable
                    allow-create
                >
                    <el-option
                        v-for="item in data.items"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id" />
                </el-select>
            </template>
        </options-provider>
    </form-row>
</template>

<script>
import {toArray, get, count, filter} from "@candybox/helpers";
import questionFieldMixin from "@app/questionnaire/components/question/mixins/questionFieldMixin";
import institutionMixin from "@app/questionnaire/components/question/mixins/institutionMixin";
import institutions from "@app/institution/repositories/institutions.js";
import groups from "@app/institution/repositories/groups.js";
import Query from "@candybox/query/query";
import readOnly from "@app/questionnaire/components/mixins/viewMode";
import glossaries from "@app/handbook/repositories/glossary.js";
import { set } from "lodash";

export default {
    name: "Dropdown",
    mixins: [
        questionFieldMixin,
        institutionMixin,
        readOnly,
    ],
    data() {
        return {
            value: null,
        }
    },
    computed: {
        disabled() {
            if(this.repositoryName === 'groups') {
                return this.$store.getters.getSomeData('disabled');
            }
            return false;
        },
        docField: {
            get() {
                return get(this.doc, this.answer.field_name, '');
            },
            set(value) {
                set(this.doc, this.answer.field_name, value);
            }
        },
        answer() {
            return count(this.questionAnswers) > 0 ? this.questionAnswers[0] : null;
        },
        repositoryName() {
            return get(this.answer, 'client_repository_name');
        },
    },
    beforeMount() {
        if(this.repositoryName === 'institutions' && !this.docField) {
            this.setDisabled(true);
        }
    },
    methods: {
        async init() {
            const questionnaireAnswers = !this.doc.id ? [] : this.doc.answers;
            const answerQuestionnaireList = toArray(filter(questionnaireAnswers, (item) => {
                return item.answer_id === this.answer.id;
            }));
            const answerQuestionnaire = answerQuestionnaireList ? answerQuestionnaireList[0] : null;
            this.docField = get(answerQuestionnaire, 'value.input_value', null);
            this.value = this.docField;
        },
        getHandbookName() {
            const name = get(this.answer, 'handbook_name');
            return glossaries()
                .search(
                    (new Query())
                        .where('type', '=', name)
                )
                .then((items) => {
                    const items_ = items.map((item) => ({
                        id: item.name,
                        value: name === 'edrpou' ? `${item.code} (${item.name})` : item.name,
                    }));
                    return items_;
                });
        },
        getClientRepository() {
            const repositoryName = this.repositoryName;
            if(repositoryName === 'institutions') {
                return this.getInstitutions();
            }
            if(repositoryName === 'groups') {
                return this.getGroups();
            }
            return this.getHandbookName();
        },
        setInstitutions() {
            if(this.answer.field_name === 'educational_institution' && get(this.doc, this.answer.field_name, null) === 0) {
                set(this.doc, this.answer.field_name, null);
                set(this.doc, 'class_number', null);
            }
            if(this.answer.field_name === 'class_number' && get(this.doc, this.answer.field_name, null) === 0) {
                set(this.doc, 'class_number', null);
            }
        }
    },
    watch: {
        value(val) {
            this.docField = val;
        },
        docField(val) {
            this.doc.answers_to_save[this.answer.id] = {
                input_value: val,
                answer_input_value_id: null,
                handbook_name: this.answer.handbook_name,
                answer_id: this.answer.id,
                input_type_id: get(this.answer, 'input.type.id'),
            };
            if(val && this.repositoryName === 'institutions') {
                this.setDisabled(false);
                this.$store.dispatch('setSomeData', {
                    key: 'institutions',
                    value: val,
                });
            }
            this.setInstitutions();
        }
    }
}
</script>
