<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Торговельні мережі'),
            pages: [
                {
                    index: '1',
                    title: this.__('Торговельні мережі'),
                    route: { name: '/' },
                    visible: () => this.$can('distributor.access'),
                },
                {
                    index: '2',
                    title: this.__('Торгові точки'),
                    route: { name: 'outlet'},
                    visible: () => this.$can('distributor.access'),
                },
            ],
        }
    }
}
</script>
