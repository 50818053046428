import Document, {
    Attribute } from '@candybox/structures/document.js';
import isSameDay from 'date-fns/isSameDay';
import { map, isString } from '@candybox/helpers.js';
import RelationAttribute from '@candybox/repository/relation.js';
import employees from '@app/employee/repositories/employees.js';
import { toDateString } from '@app/core/helpers.js';

class TimeAttribute extends Attribute
{
    /**
     * @inheritdoc
     */
    set(target, name, val) {
        target.set(name, isString(val) ? val.substr(0, 5) : '');
    }
}

class Item extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            schedule_id: Number,
            start: new TimeAttribute('08:00'),
            end: new TimeAttribute('20:00'),
            type: String,
        };
    }
}

class Schedule extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            dates: Attribute.collection(Date, [], false),
            employee_id: Number,
            items: Attribute.collection(Item, [], false),
            $employee: RelationAttribute.oneToOne(employees(), 'employee_id'),
        };
    }

    /**
     * Check if this schedule matches the certain day
     *
     * @param {Date} date
     * @returns {Boolean}
     */
    isDateMatching(date) {
        return this.dates.some((d) => isSameDay(d, date));
    }

    /**
     * @inheritdoc
     */
    export() {
        return {
            dates: [...map(this.dates, (d) => toDateString(d))],
            employee_id: this.employee_id,
            items: [...map(this.items, (item) => item.export())],
        };
    }
}

export default Schedule;
