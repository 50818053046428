<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="($can('institution_group.create') || $can('institution_group.create-institution'))"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="($can('institution_group.update') || $can('institution_group.update-institution'))"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="($can('institution_group.delete') || $can('institution_group.delete-institution'))"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('institution_group.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Історія') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/institution/components/group/form.vue';
import manage from '@app/core/mixins/manage.js';
import groupRepository from "@app/institution/repositories/groups";
import Group from "@app/institution/documents/group";
import history from "@app/institution/components/group/history.vue"
import getEmployeesMixin from "@app/employee/components/employees/mixins/getEmployeesMixin.js";

export default {
    mixins: [
        manage,
        getEmployeesMixin,
    ],
    data() {
        return {
            columns: [{
                name: 'full_name_group',
                title: this.__('Назва'),
                sortable: true,
                sortProp: 'full_name_group',
                filterable: true,
                filterProp: 'full_name_group',
                width: 180,
            }, {
                name: 'entry_year_human_format',
                width: 180,
                title: this.__('Рік вступу'),
                sortable: true,
                filterable: true,
            }, {
                name: 'manager.name',
                title: this.__('Менеджер навчальної групи'),
                width: 200,
                filterable: this.getEmployees(),
                filterProp: 'manager_id',
            }, {
                name: 'institution.name',
                title: this.__('Навчальний заклад'),
                filterable: true,
                sortable: true,
                filterProp: 'institution_name',
                sortProp: 'institution_name',
            }],
            filter: {},
            select: ['*'],

        }
    },
    methods: {
        getRepository() {
            return groupRepository();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Group, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати '),
                editTitle: this.__('Редагувати '),
                width: '760px',
            };
        },
        getHistoryComponent() {
            return history;
        }
    },
}
</script>
