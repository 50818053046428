import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import {phoneNumber} from "@app/core/validation";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/distributor/outlet');

    mapping.validation('store', () => {
        return {
            name: validator()
                .required()
                .length(0, 200),
            distributor_id: validator()
                .required()
                .integer(),
            manager_full_name: validator()
                .length(0, 200),
            phone_number: validator()
                .custom(phoneNumber),
            address: validator()
                .length(0, 200),
            glossary_id: validator()
                .required()
                .integer(),
        }
    });
    return mapping;
});
