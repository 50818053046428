<template>
    <div class="sample-component">
        <div class="sample-result-content">
            <data-table
                style="height: 100%"
                ref="table"
                flex
                :repository="getRepository()"
                :columns="columns"
                :filter="filter"
                :select="select">
            </data-table>
        </div>
        <sample-condition @getFilterOptions="applyFilter"/>
    </div>
</template>

<script>
import sampleCondition from "@app/sample/components/sample/sample-condition";
import questionnaires from "@app/questionnaire/repositories/questionnaires";
import {date, handbookEntry, phoneNumber} from "@app/core/formatter";

export default {
    name: "sample",
    components: {
        sampleCondition,
    },
    data() {
        return {
            columns: [{
                name: 'id',
                title: this.__('Номер анкети'),
                visible: false,
            }, {
                name: 'full_name_attr',
                title: this.__('ПІБ'),
                width: 250,
            }, {
                name: 'rnokpp_attr',
                title: this.__('РНКОПП'),
                width: 180,
            }, {
                name: 'contact_number_attr',
                title: this.__('Телефон'),
                formatter: phoneNumber(),
                width: 180,
            }, {
                name: 'birth_date_attr',
                title: this.__('Дата народження'),
                formatter: date('dd MMM yyyy'),
                width: 160,
            }, {
                name: 'type',
                title: this.__('Тип анкети'),
                width: 140,
            }, {
                name: 'status.code',
                title: this.__('Статус анкети'),
                formatter: handbookEntry('questionnaire_status'),
                width: 210,
            }, {
                name: 'last_card.card_code',
                title: this.__('Номер картки'),
                width: 180,
            }, {
                name: 'last_card.status_by_questionnaire',
                title: this.__('Статус картки за анкетою'),
                formatter: handbookEntry('card_status_by_questionnaire'),
                width: 200,
            }, {
                name: 'last_card.status',
                title: this.__('Статус картки'),
                formatter: handbookEntry('card_status'),
                width: 180,
            }],
            filter: null,
            select: ['*', 'lastCard.*'],
            export: false,
        }
    },
    methods: {
        applyFilter(filterOptions) {
            // console.log('filterOptions', filterOptions);
            if (!_.isEqual(filterOptions, this.filter)) {
                this.filter = filterOptions;
            }
        },
        getRepository() {
            return questionnaires();
        },
    }
}
</script>
