<template>
    <span
        class="svg-icon"
        :title="title">
        <svg>
            <use :xlink:href="href"></use>
        </svg><span
            v-if="$slots.default"
            class="icon-label">
            <slot></slot>
        </span>
    </span>
</template>

<script>
const SPRITE = '/svg/spritesheet/sprite.svg';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
    },
    computed: {
        href() {
            return `${SPRITE}#${this.name}`;
        },
    },
};
</script>
