<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('distributor.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('distributor.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('distributor.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from '@app/core/mixins/manage.js';
import distributor from "@app/distributor/repositories/distributor";
import Distributor from "@app/distributor/documents/distributor";
import formLayout from "@app/distributor/components/distributor/form";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: this.__('Офіційна назва партнера'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'edrpou',
                    title: this.__('ЄДРПОУ'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'manager_full_name',
                    title: this.__('ПІБ відповідального'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'phone_number',
                    title: this.__('Номер телефону відповідального'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'login',
                    title: this.__('Логін'),
                },
                {
                    name: 'token',
                    title: this.__('Токен'),
                },
                {
                    name: 'identifier',
                    title: this.__('Ідентифікатор'),
                }
            ],
            filter: {},
            select: ['*'],
            relations: []
        }
    },
    methods: {
        getRepository() {
            return distributor();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Distributor, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати '),
                editTitle: this.__('Редагувати '),
                width: '750px',
            };
        },
    },
}
</script>
