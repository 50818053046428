<template>
    <section class="dialog-body">
        <form-row>
            <div class="upload-file__wrapper">
                <input
                    type="file"
                    id="upload-file__input_1"
                    class="upload-file__input"
                    accept=".xls, .xlsx"
                    ref="uploadFile"
                    @change="previewFiles"
                />
                <label class="upload-file__label" for="upload-file__input_1">
                    <svg class="upload-file__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M286 384h-80c-14.2 1-23-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c11.6 11.6 3.7 33.1-13.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-23-23V366c0-13.3 10.7-24 24-24h136v8c0 31 24.3 56 56 56h80c30.9 0 55-26.1 57-55v-8h135c13.3 0 24 10.6 24 24zm-124 88c0-11-9-20-19-20s-19 9-20 20 9 19 20 20 21-9 20-20zm64 0c0-12-9-20-20-20s-20 9-19 20 9 20 20 20 21-9 20-20z"></path>
                    </svg>
                    <span class="upload-file__text">{{ __(addFileText) }}</span>
                </label>
            </div>
        </form-row>
        <form-row class="success__btn">
            <el-button @click="upload">Завантажити</el-button>
        </form-row>
        <form-row :label="__('Нотаток')">
            <div>{{ message }}</div>
        </form-row>

    </section>
</template>
<script>

import fileLoader from "@app/services/file-loader";
import FormRow from "@app/core/components/form/form-row";

export default {
    components: {
        FormRow,
    },
    data() {
        return {
            message: 'Файл мае бути формату XLSX',
            visible: true,
            addFileText: 'Додати файл',
        };
    },
    methods: {
        previewFiles(event) {
            if (event.target.files[0]) {
                this.addFileText = event.target.files[0].name;
            }
        },
        upload() {
            fileLoader.upload(this.$refs.uploadFile.files[0], 'file', {}, 'api/category/upload')
                .promise()
                .then(r => {
                    let data = r.data;
                    if (r.status === 200) {
                        this.visible = false;
                        this.message = data.success;
                        this.$emit('refresh');
                    }
                })
                .catch(r => {
                    this.message = 'Файл мае мати формат XLSX';
                    console.log(r);
                });
        }
    }
  }
</script>

<style>
    .success__btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .upload-file__input {
        opacity: 0;
        position: absolute;
        z-index: -1;
        overflow: hidden;
        width: 0.4px;
        height: 0.4px;
    }

    .upload-file__label {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 240px;
        border: 2px dashed #150B22;
        padding: 9px 49px;
        border-radius: 6px;
        cursor: pointer;
    }

    .upload-file__icon {
        width: 30px;
        height: auto;
        margin-right: 11px;
    }

    .upload-file__label .upload-file__text,
    .upload-file__label .upload-file__icon path {
        transition: .25s ease;
    }

    .upload-file__label:hover .upload-file__text {
        color: #116732;
    }

    .upload-file__label:hover .upload-file__icon path {
        fill: #116732;
    }

    .upload-file__label:hover {
        border: 2px dashed #116732;
    }
</style>
