import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/employees/schedule');

    mapping.validation('store', () => {
        return {
            dates: validator()
                .required(),
            items: validator()
                .each((chain) => {
                    chain.nested({
                        start(chain) {
                            chain.required();
                        },
                        end(chain) {
                            chain.required();
                        },
                        type(chain) {
                            chain.required();
                        }
                    });
                }),
        }
    });

    return mapping;
});
