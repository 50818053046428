<template>
    <form-row
        :label="label"
        :errors="$errors[question.field_name]"
        :required="isRequired"
        :class="{ 'special-title': isSpecial }"
    >
        <options-provider
            :source="answerValueList">
            <template v-slot:default="data">
                <CheckboxField
                    v-for="option in data.items"
                    :key="option.id"
                    :answer-value="option"
                    :question="question"
                    :readOnly="readOnly"
                    @change-answer="onChangeAnswer"
                />
            </template>
        </options-provider>
    </form-row>
</template>

<script>
import questionFieldMixin from "@app/questionnaire/components/question/mixins/questionFieldMixin";
import CheckboxField from "@app/questionnaire/components/question/ViewTypes/SimpleTypes/CheckboxField";
import {forEach, toArray, map, count, filter, set} from "@candybox/helpers";
import viewMode from "@app/questionnaire/components/mixins/viewMode";
import glossary from "@app/handbook/repositories/glossary";
import Query from "@candybox/query/query";
import {get} from "lodash";

export default {
    name: "CheckboxSet",
    mixins: [
        questionFieldMixin,
        viewMode,
    ],
    components: {
        CheckboxField,
    },
    data() {
        return {
            answerValueList: [],
            usedGlossary: false,
        }
    },
    computed: {
        docField: {
            get() {
                return get(
                    this.doc,
                    get(this.question, 'field_name'),
                    this.getDefaultByType(get(this.question, 'type'))
                );
            },
            set(value) {
                set(this.doc, this.question.field_name, value);
            }
        },
        isMultiple() {
            return get(this.question, 'is_multiple');
        },
    },
    methods: {
        async init() {
            const questionnaireAnswers = !this.doc.id ? [] : this.doc.answers;
            this.answerValueList = toArray(map(this.questionAnswers, (answer) => {
                let value = false;
                let retVal = null;

                const questionnaireAnswerList = toArray(filter(questionnaireAnswers, (item) => {
                    return answer.id === item.answer_id;
                }))

                const questionnaireAnswer = questionnaireAnswerList ? questionnaireAnswerList[0] : null;

                if(questionnaireAnswer) {
                    value = !!questionnaireAnswer;
                    retVal = answer.hasHandbook
                        ? get(questionnaireAnswer, 'value.value.id')
                        : get(questionnaireAnswer, 'value.input_value');
                }
                if (answer.hasGlossary) {
                    this.usedGlossary = true
                }
                return {
                    value,
                    answer,
                    retValue: {
                        prefix: answer.prefix,
                        value: retVal,
                        suffix: answer.suffix,
                    },
                }
            }));
            if (this.usedGlossary) {
                this.useGlossary()
            }
        },
        onChangeAnswer(el) {
            if(!this.isMultiple && get(el, 'value')) {
                const answerId = get(el, 'answer.id');
                this.setOneAnswer(answerId);
            }
        },
        setOneAnswer(answerId) {
            forEach(this.answerValueList, (item) => {
                if(get(item, 'answer.id') !== answerId) {
                    item.value = false;
                }
            });
        },
        useGlossary() {
            glossary()
                .search(
                    (new Query)
                        .where('type', '=', this.question.field_name)
                        .limitTo(100))
                .then(r => {
                this.answerValueList.forEach(answerRow => {
                    r.forEach(row => {
                        if (answerRow.answer.glossary_id === row.id) {
                            answerRow.answer.prefix = row.name;
                        }
                    })
                })
            })
        },
    },
    watch: {
        answerValueList: {
            handler(items) {
                const _items = toArray(filter(items, (item) => item.value));
                this.docField = count(_items) ? _items[0].answer.id : null;
                forEach(items, (item) => {
                    if(item.value) {
                        this.doc.answers_to_save[item.answer.id] = {
                            input_value: item.answer.hasHandbook ? null : item.retValue.value,
                            answer_input_value_id: item.answer.hasHandbook ? item.retValue.value : null,
                            handbook_name: item.answer.handbook_name,
                            answer_id: item.answer.id,
                            input_type_id: get(item.answer, 'input.type.id', 1),
                            field_name: get(this.question, 'field_name'),
                        }
                    } else {
                        delete this.doc.answers_to_save[item.answer.id];
                    }
                })
            },
            deep: true,
        }
    }
}
</script>

