import Document, {
    Attribute } from '@candybox/structures/document.js';
import { getShortName, getFullName } from '@app/core/helpers.js';
import User from '@app/user/documents/user.js';
import RelationAttribute from '@candybox/repository/relation.js';
import positions from '@app/employee/repositories/positions.js';
import { get } from '@candybox/helpers.js';
import Institution from "@app/institution/documents/institution";

class Employee extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            first_name: String,
            middle_name: String,
            last_name: String,
            birth_date: Date,
            contact_number: String,
            email: String,
            experience: String,
            notes: String,
            position_id: Number,
            $position: RelationAttribute.oneToOne(positions(), 'position_id'),
            status: String,
            date_hired: Date,
            date_fired: Date,
            sip: String,
            sip_password: String,
            user: Attribute.object(User, () => new User(), false),
            institution_id: Number,
            institution: Attribute.object(Institution, () => new Institution(), false),
            has_voip: Boolean,
        };
    }

    /**
     * Get employee full name
     *
     * @returns {String}
     */
    get full_name() {
        return getFullName(this);
    }

    get short_name() {
        return getShortName(this);
    }
}

export default Employee;
