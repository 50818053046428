import { ValidationError } from '@candybox/validation/validator.js';
import Response from '@candybox/transport/response.js';
import { is, get } from '@candybox/helpers.js';
import { mapGetters } from 'vuex';

export default {
    computed: mapGetters({
        $errors: 'errors',
    }),
    methods: {
        $catchErrors(error) {
            if (is(error, ValidationError)) {
                this.$store.dispatch('setErrors', get(error.errors, 'errors', error.errors));
                this.$error(this.__('Перевірте правильність введених даних'));
            } else if (is(error, Error)) {
                this.$error(error.message);
            } else if (!is(error, Response)) {
                this.$error(this.__('Помилка клієнта'));
            }
        },
        $clearErrors() {
            this.$store.dispatch('setErrors', {});
        },
    },
}
