<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged"/>
</template>

<script>
import operatorTasks from '@app/call-center/repositories/operator-tasks';
import manage from '@app/core/mixins/manage.js';
import {date} from '@app/core/formatter.js';
import {get} from "lodash";
import phone from "@app/call-center/components/call-center/components/phone";
import constants from "@app/core/constants";
import fullName from "@app/call-center/components/call-center/components/fullName";
import {mapGetters} from "vuex";
import OperatorTask from "@app/call-center/documents/operator-task";

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'questionnaire.id',
                title: this.__('Id анкети'),
                filterable: true,
                sortable: true,
            }, {
                name: 'questionnaire.full_name',
                title: this.__('ПІБ'),
                filterable: true,
                sortable: true,
                filterProp: 'questionnaire_full_name',
                sortProp: 'questionnaire_full_name',
                component: fullName,
            }, {
                name: 'questionnaire.contact_number',
                title: this.__('Телефон'),
                filterable: true,
                sortable: true,
                filterProp: 'questionnaire_contact_number',
                sortProp: 'questionnaire_contact_number',
            }, {
                name: 'questionnaire.birth_date',
                title: this.__('День народження'),
                formatter: date('dd MMM yyyy'),
                sortable: true,
                sortProp: 'questionnaire_birth_date',
            }, {
                name: 'questionnaire.rnkoop',
                filterable: true,
                sortable: true,
                title: this.__('РНКООП'),
                filterProp: 'questionnaire_rnkoop',
                sortProp: 'questionnaire_rnkoop',
            }, {
                name: 'questionnaire.card_number',
                title: this.__('Номер картки'),
            }, {
                name: 'sample.name',
                filterable: true,
                sortable: true,
                title: this.__('Вибірка'),
                filterProp: 'sample_name',
                sortProp: 'sample_name',
            }],
            filter: {},
            select: ['*', 'questionnaire', 'sample'],
            relations: [],
        }
    },
    computed: {
        ...mapGetters({
            processState: 'getProcessState',
        }),
    },
    methods: {
        getRepository() {
            return operatorTasks();
        },
        setFilters() {
            this.filter.employee_id = get(this.$user, 'employee.id');
            this.filter.progress_status = constants.OPERATOR_TASK.STATUS.WAITING;
        },
        workTask() {
            if(this.$route.name === 'operator-tasks' && this.selectedItem) {
                const doc = new OperatorTask(this.selectedItem);
                doc.progress_status = constants.OPERATOR_TASK.STATUS.WORK;
                this.performUpdate(doc)
                    .catch((err) => {
                        console.log('work task', err)
                    })
            }
        }
    },
    created() {
        this.$events.on('call:started', this.workTask);
        this.$events.on('processLog:completed', this.refresh);
        this.$events.on('broadcast:update_operators_tasks', this.refresh);
        this.setFilters();
    },
    beforeDestroy() {
        this.$events.off('call:started', this.workTask);
        this.$events.off('processLog:completed', this.refresh);
        this.$events.off('broadcast:update_operators_tasks', this.refresh);
    },
    watch: {
        selectedItem(item) {
            if(!this.processState.processing && item) {
                this.processState.processLog.operator_task_id = item.id;
            }
        },
    }
}
</script>
