import Document from '@candybox/structures/document.js';

class ImportEntity extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
        };
    }

}

export default ImportEntity;
