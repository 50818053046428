<template>
    <div class="image-cropper"
         v-if="image.src"
    >
        <div class="buttons">
            <el-button
                type="primary"
                plain
                @click="crop()"
            >
                {{ __('Зберегти') }}
            </el-button>
            <el-button
                type="primary"
                plain
                @click="cancel"
            >
                {{ __('Скасувати') }}
            </el-button>
        </div>

        <div class="cropper-wrapper">
            <img
                v-if="!isCrop"
                :src="image.src"
                alt="image"
            >
            <div
                v-if="isCrop"
                class="cropper"
            >
                <cropper
                    ref="cropper"
                    class="cropper__cropper"
                    check-orientation
                    :src="image.src"
                    :debounce="false"
                    :stencil-props="{
                      aspectRatio: 7/9
                    }"
                    @change="change"
                />
                <preview
                    class="cropper__preview"
                    :image="result.image"
                    :coordinates="result.coordinates"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getMimeType } from "@app/core/helpers";
import { Cropper, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    name: "ImageCropper",
    props: {
        imageObject: {
            type: Object,
            default: null,
        }
    },
    components: {
        Cropper,
        Preview
    },
    buttonText: {
        type: String,
        default: 'Завантажити',
    },
    data() {
        return {
            result: {
                coordinates: null,
                image: null
            },
            image: {
                src: null,
                type: null,
                name: null,
            },
            isCrop: true,
        }
    },
    mounted() {
        this.loadImage(this.imageObject);
    },
    methods: {
        changeCrop() {
            this.isCrop = !this.isCrop;
        },
        loadImage(file) {
            if (file) {
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src)
                }
                const blob = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.image = {
                        src: blob,
                        type: getMimeType(e.target.result, file.type),
                        name: file.name,
                    };
                };
                reader.readAsArrayBuffer(file);
            }
        },
        change({coordinates, image}) {
            this.result = {
                coordinates,
                image
            };
        },
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            canvas.toBlob((blob) => {
                this.image.src = URL.createObjectURL(blob);
                this.$emit('crop-image', {
                    image: this.image,
                    blob,
                });
            }, this.image.type);
            this.changeCrop();
        },
        cancel() {
            this.$emit('cancel');
        }
    },
    destroyed() {
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
        }
    },
}
</script>
