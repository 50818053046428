<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Налаштування'),
            pages: [
                {
                    index: '1',
                    title: this.__('Словники'),
                    route: { name: 'glossary' },
                    visible: () => this.$can('glossary.access'),
                },
                {
                    index: '2',
                    title: this.__('Виготовлення карток'),
                    route: { name: 'card-handbook'},
                    visible: () => this.$can('glossary.access'),
                }
            ],
        }
    }
}
</script>
