<template>
    <div class="sample-control">
        <el-row :gutter="20">
            <el-col :span="10">
                <el-select
                    v-model="fieldName"
                    :disabled="!isNewSample"
                >
                    <el-option
                        v-for="item in fields"
                        :key="item.id"
                        :label="item.alias"
                        :value="item.field_name"
                    />
                </el-select>
            </el-col>

            <template v-if="dataType">
                <el-col :span="12"
                    v-if="dataType === 'dropdown'"
                >
                    <el-select
                        v-model="selectFirstCondition"
                        :multiple="isMultiple"
                        :disabled="!isNewSample"
                    >
                        <el-option
                            v-for="item in answers"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-col>

                <el-col :span="12"
                    v-else-if="dataType === 'boolean'"
                >
                    <el-select
                        v-model="selectFirstCondition"
                        :disabled="!isNewSample"
                    >
                        <el-option
                            v-for="item in signs"
                            :key="item.id"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-col>

                <template v-else>
                    <el-col :span="4">
                        <el-select
                            v-model="selectFirstCondition"
                            :disabled="!isNewSample"
                        >
                            <el-option
                                v-for="item in signs"
                                :key="item.id"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-col>
                    <el-col :span="8">
                        <el-input
                            v-if="dataType === 'string'"
                            v-model="selectSecondCondition"
                            :disabled="!isNewSample"
                        />
                        <date-condition
                            v-if="dataType === 'date' && selectFirstCondition"
                            v-model="selectSecondCondition"
                            :sign="selectFirstCondition"
                            :disabled="!isNewSample"
                        />
                    </el-col>
                </template>
            </template>

            <el-col
                :span="2"
                :offset="dataType ? 0 : 12"
                v-if="isNewSample"
            >
                <svg-icon
                    name="delete"
                    class="icon-grey icon-hover cursor-pointer"
                    @click="deleteCondition" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import conditionFactoryMixin from "@app/sample/mixins/conditionFactoryMixin.js";
import {get} from 'lodash';
import DateCondition from "@app/sample/components/sample/condition/date-condition.vue";

export default {
    name: 'simple-condition',
    components: {DateCondition},
    mixins: [
        conditionFactoryMixin,
    ],
    props: {
        fields: {
            type: Array,
            required: true
        },
        dbAnswersInGlossary: {
            type: Array,
            required: true
        },
        selectedCondition: {
            type: Object,
            nullable: true,
        },
        id: {
            type: Number,
            required: true
        },
        isNewSample: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectFirstCondition: null,
            selectSecondCondition: null,
        }
    },
    computed: {
        dataType() {
            return get(this.conditionService, '_dataType', null);
        },
        origin() {
            return get(this.conditionService, '_origin', null);
        },
        signs() {
            return get(this.conditionService, '_signs', null);
        },
        isMultiple() {
            return get(this.conditionService, '_isMultiple', null);
        },
        resultCondition() {
            return {
                id: this.id,
                selectedField: this.fieldName,
                firstCondition: this.selectFirstCondition,
                secondCondition: this.selectSecondCondition,
                source: this.origin
            }
        },
    },
    methods: {
        deleteCondition() {
            this.$emit('delete-condition', this.id);
        },
        clear() {
            this.selectFirstCondition = null;
            this.selectSecondCondition = null;
        },
        init() {
            if(this.selectedCondition) {
                this.selectFirstCondition = this.selectedCondition.firstCondition;
                this.selectSecondCondition = this.selectedCondition.secondCondition;
            }
        }
    },
    watch: {
        dataType(val) {
            if(val === 'boolean') {
                this.selectSecondCondition = 'null';
            }
        },
        selectFirstCondition() {
            if(this.dataType === 'date' && this.isNewSample) {
                this.selectSecondCondition = null;
            }
        },
        fieldName() {
            this.clear();
            this.makeConditionSource(this.fieldName);
        },
        resultCondition(val) {
            this.$emit('updateConditions', val)
        },
        selectedCondition: {
            handler(val) {
                if(val) {
                    this.fieldName = val.selectedField;
                }
            },
            immediate: true,
        }
    }
}
</script>
