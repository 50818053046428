<template>
    <form-container>
        <form-layout
            :doc="doc"
            :attrs="attrs"
            @updating="updating"
        />
        <section class="dialog-footer">
            <el-button
                @click="cancel">
                {{ __('Скасувати') }}
            </el-button>
            <el-button v-if="!this.attrs.readOnly"
                type="primary"
                :loading="loading"
                @click="done">
                {{ __('Оновити') }}
            </el-button>
        </section>
    </form-container>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        attrs: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            doc: this.item.clone(),
            loading: false,
        };
    },
    inject: [
        'modalApi',
    ],
    methods: {
        done() {
            this.loading = true;
            this.$emit('done', {
                doc: this.doc,
                stopLoading: () => {
                    this.loading = false;
                }
            });
        },
        cancel() {
            this.modalApi.close();
        },
        updating(data=null) {
            this.$emit('updating', data);
        }
    }
}
</script>
