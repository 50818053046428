<template>
    <slot
        :items="items"
        :loading="loading"
        :query="query"
        :search="search" />
</template>

<script>
import { isArray,
    isString,
    is,
    get } from '@candybox/helpers.js';
import Query from '@candybox/query/query.js';
import { debounce } from 'lodash';

export default {
    props: {
        source: [Array, Object, String],
        lazy: {
            type: Boolean,
            default: false,
        },
        maxItems: {
            type: Number,
            default: 50,
        },
        criteria: {
            type: [Object, Function],
            default: () => ({}),
        },
        valueProp: {
            type: String,
            default: 'name',
        },
        emptyOption: {
            type: String,
        },
        minQueryLen: {
            type: Number,
            default: 1,
        },
        debounceTime: {
            type: Number,
            default: 500,
        },
        itemBuilder: {
            type: Function,
        },
    },
    data() {
        return {
            items: [],
            loading: false,
            query: () => null,
            search: '',
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (is(this.source, Promise)) {
                this.loading = true;
                this.source.then((items) => {
                    this.setItems(items);
                }).finally(() => {
                    this.loading = false;
                });
            } else if (isArray(this.source)) {
                this.setItems(this.source);
            } else if (isString(this.source)) {
                this.setItems(this.$handbook(this.source));
            } else {
                if (!this.lazy) {
                    this.fetchItems(this.source, this.criteria);
                } else {
                    this.query = debounce((q) => {
                        this.search = q;
                        if (q && q.length >= this.minQueryLen) {
                            this.fetchItems(this.source, this.criteria, q);
                        } else {
                            this.setItems([]);
                        }
                    }, this.debounceTime);
                }
            }
        },
        fetchItems(source, criteria, search = '') {
            this.loading = true;
            source.search(this.makeQuery(criteria, search))
                .then((data) => {
                    let itemBuilder = this.itemBuilder || ((v) => this.buildItem(v));
                    this.setItems(data.map(itemBuilder));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        makeQuery(criteria, search) {
            return (new Query)
                .where(criteria)
                .where((cond) => {
                    if (search) {
                        cond.contains(this.valueProp, search);
                    }
                })
                .startFrom(0)
                .limitTo(this.lazy ? this.maxItems : 1000);
        },
        setItems(items) {
            this.items = [
                ...(this.emptyOption ? [{id: '', value: this.emptyOption}] : []),
                ...items,
            ];
        },
        buildItem(item) {
            return {
                id: item.id,
                value: get(item, this.valueProp)
            }
        },
    },
    watch: {
        source() {
            this.setItems([]);
            this.init();
        },
        criteria() {
            this.setItems([]);
            this.init();
        },
    },
}
</script>
