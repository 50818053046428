<script>
import BaseHistory from '@app/core/components/manage/history.vue';
import { date,
    phoneNumber,
    handbookEntry } from '@app/core/formatter.js';

export default {
    extends: BaseHistory,
    methods: {
        getType() {
            return 'employee';
        },
        getAttributes() {
            return {
                last_name: this.__('Прізвище'),
                first_name: this.__('Ім’я'),
                middle_name: this.__('По батькові'),
                birth_date: {
                    title: this.__('Дата народження'),
                    formatter: date(),
                },
                contact_number: {
                    title: this.__('Номер телефону'),
                    formatter: phoneNumber(),
                },
                email: this.__('Email'),
                experience: this.__('Досвід роботи'),
                notes: this.__('Додатково'),
                position_id: this.__('Посада'),
                status: {
                    title: this.__('Статус'),
                    formatter: handbookEntry('employee_status'),
                },
                date_hired: {
                    title: this.__('Працює з'),
                    formatter: date(),
                },
                date_fired: {
                    title: this.__('Звільнений'),
                    formatter: date(),
                },
                sip: this.__('Номер SIP'),
                sip_password: this.__('Пароль телефонії'),
                login: this.__('Логін'),
                roles: this.__('Роль'),
                rnokpp: this.__('РНОКПП'),
                permissions: this.__('Додаткові повноваження'),
            };
        },
    }
}
</script>
