<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Назва')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name" />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Тип')"
                    :errors="$errors['type']"
                    required>
                    <options-provider
                        source="institution_type">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.type">
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Адреса')"
                    :errors="$errors['address']"
                    required>
                    <el-input v-model="doc.address" />
                </form-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('ПІБ керівника')"
                    :errors="$errors['chief_name']"
                    required>
                    <el-input v-model="doc.chief_name" />
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('ПІБ адміністратора')"
                    :errors="$errors['manager_id']"
                    required>
                    <options-provider
                        :source="getManager()"
                        value-prop="description">
                        <template v-slot:default="data">
                            <el-select
                                :loading="data.loading"
                                v-model="doc.manager_id"
                                filterable
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Телефон')"
                    :errors="$errors['contact_number']"
                    required>
                    <el-input v-model="doc.contact_number" />
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>
import Query from '@candybox/query/query.js';
import employees from "@app/employee/repositories/employees";

export default {
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    computed: {
        currentManager: {
            get() {
                return this.doc.$manager.$;
            },
            set(val) {
                return this.doc.$manager = val;
            },
        },
    },
    data() {
        return {
            employees: null,
        }
    },
    methods: {
        getManager() {
            return employees()
                .search((new Query).limitTo(1000))
                .then((items) => {
                    this.employees = items.map((emp) => ({
                        id: emp.id,
                        value: emp.full_name,
                    }));
                    this.updateCurrentManager(this.doc.manager_id);
                    return this.employees;
                });
        },
        updateCurrentManager(id) {
            this.currentManager = this.employees.find((emp) => {
                return emp.id === id;
            });
        },
    },
    watch: {
        ['doc.manager_id'](id) {
            this.updateCurrentManager(id);
        },
    }
}
</script>
