<template>
    <section class="dialog-body">
        <el-row :gutter="20">
            <el-col :span="8">
                <form-row
                    :label="__('Повна назва')"
                    :errors="$errors['name']"
                    required>
                    <el-input v-model="doc.name"/>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Статус')"
                    :errors="$errors['status']"
                    required
                >
                    <options-provider
                        source="sample_status"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.status"
                                :loading="data.loading"
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8">
                <form-row
                    :label="__('Для обзвону')"
                    :errors="$errors['is_kc']"
                    required
                >
                    <options-provider
                        source="yes_no"
                    >
                        <template v-slot:default="data">
                            <el-radio-group v-model="doc.is_kc">
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="16"
                v-if="doc.is_kc"
            >
                <form-row
                    :label="__('Оператори')"
                    :errors="$errors['oprators']"
                    required
                >
                    <options-provider
                        :source="getOperators()"
                    >
                        <template v-slot:default="data">
                            <el-select
                                v-model="doc.operators"
                                :loading="data.loading"
                                clearable
                                multiple
                            >
                                <el-option
                                    v-for="item in data.items"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id" />
                            </el-select>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
            <el-col :span="8"
                    v-if="doc.is_kc"
            >
                <form-row
                    :label="__('Видаляти всі задачі')"
                    :errors="$errors['is_clear_all']"
                    required
                >
                    <options-provider
                        source="yes_no"
                    >
                        <template v-slot:default="data">
                            <el-radio-group v-model="doc.is_clear_all">
                                <el-radio-button
                                    v-for="option in data.items"
                                    :key="option.id"
                                    :label="option.id">
                                    {{ option.value }}
                                </el-radio-button>
                            </el-radio-group>
                        </template>
                    </options-provider>
                </form-row>
            </el-col>
        </el-row>
    </section>
</template>

<script>

import FormRow from "@app/core/components/form/form-row";
import employees from "@app/employee/repositories/employees";
import Query from "@candybox/query/query";
import {includes} from "lodash";

export default {
    components: {FormRow},
    props: {
        doc: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            operators: [],
        }
    },
    created() {
        if(this.$attrs.attrs.json) {
            this.doc.rules = this.$attrs.attrs.json;
        }
    },
    methods: {
        getOperators() {
            return employees()
                .search(
                    (new Query)
                        .select('*', 'user.*')
                )
                .then((items) => {
                    const operators = items.filter((operator) => {
                        return includes(operator.user.roles, 2)
                    });
                    this.operators = operators.map((operator) => ({
                        id: operator.id,
                        value: `${operator.last_name} ${operator.first_name}`,
                    }))
                    return this.operators;
                })
        }
    }
}
</script>
