<template>
    <page
        :title="title"
        :back-route="{name: 'resources'}"
    >
        <div
            v-loading="loading"
            class="schedule-container flex-column">
            <calendar-view
                locale="uk"
                :starting-day-of-week="1"
                :show-date="date"
                :items="items"
                item-top="22px"
                item-content-height="24px"
                show-times
                :time-format-options="{ hour12: false, hour: '2-digit', minute: '2-digit' }"
                class="theme-default">
                <template v-slot:header="{ headerProps }">
                    <div class="header-container rigid-row">
                        <calendar-view-header
                            :header-props="headerProps"
                            @input="dateChanged" />
                        <el-select v-model="selected">
                            <el-option
                                v-for="item in employees"
                                :label="item.value"
                                :key="item.id"
                                :value="item.id" />
                        </el-select>
                    </div>
                </template>
                <template v-slot:dayContent="{ day }">
                    <el-checkbox v-model="selectedDates[day]" />
                </template>
            </calendar-view>
            <div class="buttons">
                <el-button
                    v-if="$can('schedule.update')"
                    @click="change">
                    {{ __('Змінити') }}
                </el-button>
                <el-button
                    v-if="$can('schedule.delete')"
                    @click="clear">
                    {{ __('Видалити') }}
                </el-button>
                <el-button
                    @click="clearSelection">
                    {{ __('Очистити вибір') }}
                </el-button>
            </div>
        </div>
    </page>
</template>

<script>
import { CalendarView,
    CalendarViewHeader } from 'vue-simple-calendar';
import employees from '@app/employee/repositories/employees.js';
import schedule from '@app/employee/repositories/schedule.js';
import Query from '@candybox/query/query.js';
import endOfMonth  from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import { toDateString, getShortName } from '@app/core/helpers.js';
import Schedule from '@app/employee/documents/schedule.js';
import Form from '@app/employee/components/schedule/form.vue';
import { forEach,
    get,
    eachToEach,
    difference } from '@candybox/helpers.js';
import parse from 'date-fns/parse';
import {date as formatDate} from '@app/core/formatter.js';
import { ValidationError } from '@candybox/validation/validator.js';

export default {
    components: {
        CalendarView,
        CalendarViewHeader,
    },
    data() {
        return {
            title: '',
            loading: true,
            selected: Number(this.$route.params.id),
            employees: [],
            date: new Date(),
            selectedDates: this.createSelectionPool(),
            items: [],
            schedule: [],
        };
    },
    beforeMount() {
        this.loadEmployees().finally(() => {
            this.loading = false;
        });
    },
    watch: {
        selected(id) {
            this.$router.push({
                name: 'schedule',
                params: {id}
            });
        }
    },
    methods: {
        loadEmployees() {
            return employees().search()
                .then((items) => {
                    this.employees = items.map((employee) => ({
                        id: employee.id,
                        value: getShortName(employee),
                    }));
                    this.updateTitle();
                    return this.loadSchedule();
                });
        },
        updateTitle() {
            let employee = this.employees.find((employee) => employee.id === this.selected);
            this.title = employee === undefined
                ? this.__('Розклад роботи співробітника')
                : this.__('Розклад роботи співробітника «{employee}»', {employee: employee.value});
        },
        loadSchedule() {
            let query = (new Query).where((cond) => {
                cond.eq('employee_id', this.selected)
                    .gte('date', toDateString(startOfMonth(this.date)))
                    .lte('date', toDateString(endOfMonth(this.date)));
            });
            return schedule().search(query)
                .then((results) => {
                    this.schedule = Object.freeze(results);
                    this.makeCalendarItems(results);
                });
        },
        dateChanged(date) {
            this.date = date;
            this.loadSchedule();
        },
        change() {
            let dates = this.getSelectedDates();
            if (dates.length === 0) {
                this.$warning(this.__('Оберіть дати для редагування'));
                return;
            }
            let selected = this.getSelectedSchedules(dates);
            let updates = selected.schedules;
            let doc = new Schedule({
                employee_id: this.selected,
                dates: dates,
                items: updates.length === 0 ? [{}] : [...updates[0].items],
            });
            this.$modalComponent(Form, {doc}, {
                done: (dialog, {doc, stopLoading}) => {
                    let dates = [...doc.dates];
                    let selected = this.getSelectedSchedules(dates);
                    let creates = selected.missing;
                    let updates = selected.schedules;
                    this.$clearErrors();
                    if (this.validateCreatePermission(creates) &&
                        this.validateTimeOverlaps(doc) &&
                        this.validateItemsAreIdentical(updates)) {
                        schedule().store(doc).then((result) => {
                            dialog.close();
                            updates.forEach((doc) => {
                                doc.items = result.items;
                            });
                            this.schedule = Object.freeze(this.schedule.concat(
                                creates.map((date) => {
                                    return new Schedule({
                                        employee_id: result.employee_id,
                                        dates: [date],
                                        items: result.items,
                                    });
                                })
                            ));
                            this.makeCalendarItems(this.schedule);
                            this.clearSelection();
                        }).catch((err) => {
                            stopLoading();
                            this.$catchErrors(err);
                        });
                    } else {
                        stopLoading();
                    }
                },
            }, {
                title: this.__('Зміна розкладу'),
                width: '440px',
            });
        },
        validateCreatePermission(newDates) {
            if (newDates.length === 0 || this.$can('schedule.create')) {
                return true;
            }
            this.$error(this.__('Ви не можете створювати розклад на нові дати.'));
            return false;
        },
        validateTimeOverlaps(doc) {
            let cross = [];

            eachToEach([...doc.items], (a, b, i, j) => {
                if (a.start && a.end && b.start && b.end) {
                    if (a.start === b.start || a.start < b.start && b.start < a.end) {
                        cross.push([i, j]);
                    } else if (b.start < a.start && a.start < b.end) {
                        cross.push([j, i]);
                    }
                }
            });

            if (cross.length !== 0) {
                let errors = {};
                cross.forEach((pair) => {
                    errors[`items.${pair[0]}.end`] =
                    errors[`items.${pair[1]}.start`] = [
                        this.__('Перетин часу'),
                    ];
                });
                this.$catchErrors(new ValidationError(errors));
                return false;
            }

            return true;
        },
        validateItemsAreIdentical(schedules) {
            if (schedules.length > 1) {
                let missmatches = [];
                let base = schedules[0];
                let others = schedules.slice(1);
                others.forEach((schedule) => {
                    if (base.items.length !== schedule.items.length) {
                        missmatches.push(schedule);
                    } else {
                        for (let i = 0; i < base.items.length; i++) {
                            if (!this.compareItems(base.items[i], schedule.items[i])) {
                                missmatches.push(schedule);
                                break;
                            }
                        }
                    }
                });

                if (missmatches.length !== 0) {
                    let formatter = formatDate();
                    let dates = [formatter(base.dates[0])]
                        .concat(missmatches.map((schedule) => formatter(schedule.dates[0])))
                        .join(', ');
                    this.$error(this.__('Години відрізняються для обраних дат ({dates}). Ви можете редагувати декілька днів одночасно лише якщо зміни в них ідентичні.', {dates}));
                    return false;
                }
            }

            return true;
        },
        compareItems(a, b) {
            return a.start === b.start
                && a.end === b.end;
        },
        getSelectedSchedules(dates) {
            let missing = [];
            let schedules = [];
            dates.forEach((date) => {
                let match = this.getDaySchedule(date);
                if (match === undefined) {
                    missing.push(date);
                } else {
                    schedules.push(match);
                }
            });
            return {
                missing,
                schedules,
            };
        },
        getDaySchedule(date) {
            return this.schedule.find((doc) => doc.isDateMatching(date));
        },
        clear() {
            let dates = this.getSelectedDates();
            if (dates.length === 0) {
                this.$warning(this.__('Оберіть дати для видалення'));
                return;
            }
            this.$confirm(this.__('Видалити розклад на обрані дні?'), () => {
                let doc = new Schedule({
                    employee_id: this.selected,
                    dates: dates,
                });
                this.loading = true;
                schedule().store(doc).then(() => {
                    this.schedule = Object.freeze(this.schedule.filter((doc) => {
                        return !dates.some((date) => doc.isDateMatching(date));
                    }));
                    this.makeCalendarItems(this.schedule);
                    this.clearSelection();
                }).catch((err) => {
                    this.$catchErrors(err);
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
        createSelectionPool() {
            return new Proxy({pool: []}, {
                get({pool}, day) {
                    if ('pool' === day) {
                        return pool;
                    }
                    return pool.indexOf(day) !== -1;
                },
                set({pool}, day, val)  {
                    if (val === true) {
                        if (pool.indexOf(day) === -1) {
                            pool.push(day);
                        }
                    } else {
                        let i = pool.indexOf(day);
                        if (i !== -1) {
                            pool.splice(i, 1);
                        }
                    }
                    return true;
                },
            });
        },
        getSelectedDates() {
            return this.selectedDates.pool.map((date) => new Date(date));
        },
        clearSelection() {
            this.selectedDates = this.createSelectionPool();
        },
        makeCalendarItems(results) {
            let items = [];
            forEach(results, (schedule) => {
                let date = toDateString(schedule.dates[0]);
                forEach(schedule.items, (item) => {
                    const titleObj = this.$handbook('schedule_item_type').find(function(scheduleItem) {
                        return scheduleItem.id === get(item, 'type');
                    });
                    items.push({
                        id: item.id,
                        startDate: parse(`${date} ${item.start}`, 'yyyy-MM-dd HH:mm', new Date),
                        endDate: parse(`${date} ${item.end}`, 'yyyy-MM-dd HH:mm', new Date),
                        title: get(titleObj, 'value'),
                        classes: this.getStyleByType(get(item, 'type')),
                    });
                });
            });
            this.items = items;
        },
        getStyleByType(type) {
            switch(type) {
                case 'holidaytime': return ['holidaytime'];
                default: return [];
            }
        }
    }
}
</script>
