import { singleton } from '@candybox/helpers.js';
import {Method, RequestMap} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RequestMap();

    mapping.map('totalOperatorsAnalytics1', {
        method: Method.POST,
        route: '/api/analytics/operator/total-1'
    });

    mapping.map('marksOperatorsAnalytics', {
        method: Method.POST,
        route: '/api/analytics/operator/marks'
    });

    mapping.map('totalOperatorsAnalytics2', {
        method: Method.POST,
        route: '/api/analytics/operator/total-2'
    });

    mapping.map('durationsOperatorsAnalytics', {
        method: Method.POST,
        route: '/api/analytics/operator/durations'
    });

    mapping.map('callsReasonsOperatorsAnalytics', {
        method: Method.POST,
        route: '/api/analytics/operator/call-reasons'
    });

    mapping.map('callsOperatorsAnalytics', {
        method: Method.POST,
        route: '/api/analytics/operator/calls'
    });



    return mapping;
});
