<template>
    <el-row>
        <el-col
            :span="12"
            :offset="6">
            <div class="login-form-content">
                <div class="login-app-logo"></div>
                <h1>{{ appName }} {{ __('вітає вас') }}</h1>
                <form class="login-form-data">
                    <div class="btn-diia1 form-footer"
                         @click="loginWithEcp" ></div>
                </form>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import {isFilled} from '@app/core/helpers.js';

export default {
    computed: {
        appName() {
            return window.appConfig.name;
        },
        canSend() {
            return isFilled(this.login)
                && isFilled(this.password);
        },
        ...mapGetters({
            loginRedirect: 'user/loginRedirect',
        })
    },
    data() {
        return {
            login: '',
            password: '',
            loading: false,
        }
    },
    methods: {
        loginWithEcp() {
            this.ecp().then((res)=>{document.location.href = res.body});
        },
        ...mapActions({
            doLogin: 'user/login',
            setLoginRedirect: 'user/setLoginRedirect',
            ecp:'user/ecp',
        }),
    },
}
</script>
