import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/questionnaires/constructor/answer');

    mapping.validation('store', () => {
        return {
            prefix: validator()
                .length(0, 400),
            suffix: validator()
                .length(0, 400),
            is_show: validator()
                .required(),
            order: validator()
                .required(),
        }
    });

    return mapping;
});
