import Document from "@candybox/structures/document";

class Partner extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            api_token: String,
        };
    }
}

export default Partner;
