import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/handbook/glossary');

    mapping.validation('store', () => {
        return {
            type: validator()
                .required()
                .length(0, 50),
            name: validator()
                .required()
                .length(0, 200),
            code: validator()
                .length(0, 10)
        }
    });

    return mapping;
});
