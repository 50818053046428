import App from '@candybox/app.js';
import constructorTree from '@app/questionnaire-constructor/components/tree.vue';
import questionList from '@app/questionnaire-constructor/components/questions/table.vue';
import answerList from '@app/questionnaire-constructor/components/answers/table.vue';

App.boot(({router}) => {
    router.addRoute('constructor', {
        component: constructorTree,
        path: 'tree',
        name: 'tree',
    });
    router.addRoute('constructor', {
        component: questionList,
        path: 'reserved',
        name: 'reserved',
    });
    router.addRoute('constructor', {
        component: answerList,
        path: 'answers',
        name: 'answers',
    });
});
