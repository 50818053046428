import App from '@candybox/app.js';
import scriptList from "@app/script/components/script/table";

App.boot(({router}) => {
    router.addRoute('script', {
        component: scriptList,
        path: 'script',
        name: 'script',
    });
});


