import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import {get, singleton} from '@candybox/helpers.js';
import {Method} from "@candybox/transport/request.js";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/category/product/import');

    mapping.map('importRollback', {
        route: '/api/category/product/import/rollback',
        method: Method.POST,
    });

    mapping.map('productsRemoveImports', {
        route: '/api/category/remove-products',
        method: Method.GET,
    });

    mapping.map('checksRemoveImports', {
        route: '/api/category/remove-checks',
        method: Method.GET,
    });

    return mapping;
});
