import Document, {
    Attribute } from '@candybox/structures/document.js';
import RelationAttribute from '@candybox/repository/relation.js';
import employees from "@app/employee/repositories/employees.js";

class Institution extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            type: String,
            manager_id: Number,
            $manager: RelationAttribute.oneToOne(employees(), 'manager_id'),
            chief_name: String,
            contact_number: String,
            address: String,
        };
    }

}

export default Institution;
