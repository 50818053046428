import RepositoryRequestMap from '@candybox/repository/request-map.js';
import {singleton} from '@candybox/helpers.js';

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/operator-task');

    mapping.validation('store', () => {
        return {}
    });

    return mapping;
});
