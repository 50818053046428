import Document from '@candybox/structures/document.js';

class CardHandbook extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            code: Number,
            status_khp: String,
            handbook_key: String,
            card_status_handbook_key: String,
            questionnaire_status_handbook_key: String,
            digital_handbook_key: String,
        };
    }
}

export default CardHandbook;
