<template>
    <data-tree
        :data="data"
        :loading="loading"
        accordion
        height="calc(100vh - 130px)"
    >
        <template v-slot:additional="data">
            <span
                :class="{ 'grey': (data.node.level > 1 && !data.data.item.is_show) }"
            >
                {{ data.node.label }}
            </span>
            <span class="custom-tree-node__additional">
                <span class="item-tree-controls">
                    <a
                        v-if="isAllowEdit(data)"
                        href="#"
                        @click.prevent.stop="editEntity(data)"
                    >
                        <svg-icon name="edit" class="icon-small"/>
                    </a>
                    <a
                        v-if="isAllowChange(data)"
                        href="#"
                        @click.prevent.stop="changeShow(data)"
                    >
                        <svg-icon
                            v-if="data.data.item.is_show"
                            name="hide"
                            class="icon-small"
                        />
                        <svg-icon
                            v-else
                            name="unhide"
                            class="icon-small"
                        />
                    </a>
                </span>
            </span>
        </template>
    </data-tree>
</template>

<script>
import blockForm from "@app/questionnaire-constructor/components/blocks/form.vue";
import subblockForm from "@app/questionnaire-constructor/components/subblocks/form.vue";
import questionForm from "@app/questionnaire-constructor/components/questions/form.vue";
import answerForm from "@app/questionnaire-constructor/components/answers/form.vue";
import constructorRequests from '@app/questionnaire-constructor/requests/constructor-requests.js'
import {map, isEmpty, get, some, includes, filter} from "lodash";
import Type from "@app/questionnaire/documents/type.js";
import Block from "@app/questionnaire/documents/block.js";
import SubBlock from "@app/questionnaire/documents/subblock.js";
import Question from "@app/questionnaire/documents/question.js";
import Answer from "@app/questionnaire/documents/answer.js";
import {count, is} from "@candybox/helpers.js";
import constants from "@app/core/constants.js";
import manage from "@app/core/mixins/manage.js";
import blocks from "@app/questionnaire/repositories/blocks.js"
import subblocks from "@app/questionnaire/repositories/subblocks.js"
import questions from "@app/questionnaire/repositories/questions.js"
import answers from "@app/questionnaire/repositories/answers.js"

export default {
    mixins: [
        manage
    ],
    data() {
        return {
            loading: false,
            data: [],
            formLayout: null,
            currentRepository: null,
            currentEntityLabel: '',
            entities: {
                [constants.QUESTIONNAIRE_CONSTRUCTOR.TYPE]: {
                    edit: false,
                    delete: false,
                    modal: null,
                    label: 'тип',
                    repository: null,
                },
                [constants.QUESTIONNAIRE_CONSTRUCTOR.BLOCK]: {
                    edit: true,
                    delete: true,
                    modal: blockForm,
                    label: 'блок',
                    repository: blocks(),
                },
                [constants.QUESTIONNAIRE_CONSTRUCTOR.SUBBLOCK]: {
                    edit: true,
                    delete: true,
                    modal: subblockForm,
                    label: 'підблок',
                    repository: subblocks(),
                },
                [constants.QUESTIONNAIRE_CONSTRUCTOR.QUESTION]: {
                    edit: true,
                    delete: true,
                    modal: questionForm,
                    label: 'питання',
                    repository: questions(),
                },
                [constants.QUESTIONNAIRE_CONSTRUCTOR.ANSWER]: {
                    edit: true,
                    delete: true,
                    modal: answerForm,
                    label: 'варіант відповіді',
                    repository: answers(),
                },
            }
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getRepository() {
            if(!this.currentRepository) {
                return null;
            }
            return this.currentRepository;
        },
        getModalOptions() {
            const formLayout = this.formLayout;
            if(!formLayout) {
                return {};
            }
            return {
                editForm: this.makeBasicEdit(formLayout),
                editTitle: this.__(`Редагувати ${this.currentEnityLabel}`),
                width: '760px',
            };
        },
        refresh() {
            this.getData();
        },

        getData() {
            this.loading = true;
            this.data = [];
            constructorRequests()
                .create('get-constructor')
                .send()
                .then((res) => {
                    this.data = this.prepareData(res.body);
                    this.loading = false;
                });
        },
        prepareData(data) {
            return map(data, (type) => ({
                label: get(type, 'name'),
                item: new Type(type),
                children: map(get(type, 'children', []), (block) => ({
                    label: get(block, 'title'),
                    item: new Block(block),
                    children: map(get(block, 'children', []), (subblock) => ({
                        label: subblock.inner_name ? subblock.inner_name : 'не задано',
                        item: new SubBlock(subblock),
                        parent: {
                            item: new Block(block),
                        },
                        children: map(get(subblock, 'children'), (question) => ({
                            label: question.inner_name ? question.inner_name :'не задано',
                            item: new Question(question),
                            parent: {
                                item: new SubBlock(subblock),
                            },
                            children: map(get(question, 'children'), (answer) => ({
                                label: answer.inner_name ? answer.inner_name : 'не задано',
                                item: new Answer(answer),
                                parent: {
                                    item: new Question(question),
                                },
                            })),
                        })),
                    }))
                }))
            }))
        },
        getEntityName(entity) {
            if(is(entity, Type)) {
                return constants.QUESTIONNAIRE_CONSTRUCTOR.TYPE;
            } else if(is(entity, Block)) {
                return constants.QUESTIONNAIRE_CONSTRUCTOR.BLOCK;
            } else if(is(entity, SubBlock)) {
                return constants.QUESTIONNAIRE_CONSTRUCTOR.SUBBLOCK;
            } else if(is(entity, Question)) {
                return constants.QUESTIONNAIRE_CONSTRUCTOR.QUESTION;
            } else if(is(entity, Answer)) {
                return constants.QUESTIONNAIRE_CONSTRUCTOR.ANSWER;
            }
        },

        getEntity(data) {
            const item = get(data, 'item');
            return this.getEntityName(item);
        },
        getEntityParam(data, param, defaults = null) {
            const entity = this.getEntity(data);
            return get(this.entities, `${entity}.${param}`, defaults);
        },
        isAllowEdit({data}) {
            const item = get(data, 'item');
            const relation = get(item, 'client_repository_name');
            return this.getEntityParam(data, 'edit') && isEmpty(relation);
        },
        isAllowChange({data}) {
            const entityName = this.getEntity(data);
            let children = get(data, 'children', []);
            let isAllow = true;
            if(includes([constants.QUESTIONNAIRE_CONSTRUCTOR.QUESTION, constants.QUESTIONNAIRE_CONSTRUCTOR.ANSWER], entityName)) {
                isAllow = includes([0, null, undefined], get(data, 'item.is_required'));
            } else if(entityName === constants.QUESTIONNAIRE_CONSTRUCTOR.SUBBLOCK) {
                const items = map(children, (item) => {
                    return get(item, 'item.is_required', 0)
                });
                isAllow = count(filter(items, (item) => item === 1)) === 0;
            } else if(entityName === constants.QUESTIONNAIRE_CONSTRUCTOR.BLOCK) {
                const subblocks = map(children, (item) => {
                    const questions = get(item, 'item.children');
                    const required = map(questions, (question) => question.is_required)
                    return count(filter(required, (item) => !!item));
                });
                isAllow = count(filter(subblocks, (item) => item > 0)) === 0;
            }
            const parent = get(data, 'parent');
            const parentEntity = this.getEntity(parent);
            if(parent && parentEntity === constants.QUESTIONNAIRE_CONSTRUCTOR.QUESTION) {
                children = get(parent, 'item.children', []);
                if(count(children) === 1) {
                    isAllow = false;
                }
            }
            return this.getEntityParam(data, 'delete') && isAllow;
        },

        editEntity({data}) {
            this.selectedItem = get(data, 'item');
            this.formLayout = this.getEntityParam(data, 'modal');
            this.currentEnityLabel = this.getEntityParam(data, 'label');
            this.currentRepository = this.getEntityParam(data, 'repository');
            this.edit({
                parent: get(data, 'parent.item'),
                children: get(data, 'children', []),
            });
        },

        changeShow({data}) {
            const item = get(data, 'item');
            if(item.is_show !== undefined) {
                item.is_show = !item.is_show ? 1 : 0;
                this.selectedItem = item;
                this.currentRepository = this.getEntityParam(data, 'repository');
                this.updateEntity(this.selectedItem);
            }
        },

        updateEntity(doc) {
            let messages = {
                ...this.defaultMessages(),
                ...this.getMessages(),
            };

            this.loading = true;
            this.$clearErrors();
            this.performUpdate(doc).then(() => {
                if (messages.updated) {
                    this.$success(messages.updated);
                }
                this.documentUpdated(doc);
                this.refresh();
            }).catch((err) => {
                this.loading = false;
                this.$catchErrors(err);
            });
        },
    }
}
</script>
