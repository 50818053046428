<template>
    <svg-icon name="tree-node" class="icon-small">
    </svg-icon>
</template>

<script>
export default {
    name: "nodeIcon"
}
</script>
