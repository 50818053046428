<template>
    <div>
        <div v-for="(change, index) in getChanges(item)"
             :key="index"
             class="change-log" >
            <template v-if="change.title == 'Переглянуто'">
                <span class="attribute">{{ change.title }}</span>
                &laquo;<span class="new" v-html="change.new" />&raquo;
            </template>
            <template v-if="change.old !== null && change.new !== null && change.title != 'Переглянуто'">
                <span class="attribute">{{ change.title }}</span> &mdash;
                {{ __('Змінено') }}:
                &laquo;<span class="old" v-html="change.old" />&raquo;
                {{ __('на') }}
                &laquo;<span class="new" v-html="change.new" />&raquo;
            </template>
            <template v-else-if="change.old !== null && change.title != 'Переглянуто'">
                <span class="attribute">{{ change.title }}</span> &mdash;
                {{ __('Видалено') }}:
                &laquo;<span class="old" v-html="change.old" />&raquo;
            </template>
            <template v-else-if="change.new !== null && change.title != 'Переглянуто'">
                <span class="attribute">{{ change.title }}</span> &mdash;
                {{ __('Додано') }}:
                &laquo;<span class="new" v-html="change.new" />&raquo;
            </template>
        </div>
    </div>
</template>

<script>
import { dedupe,
    isArray,
    isObject,
    isString,
    isNil,
    difference } from '@candybox/helpers.js';
import { isEmpty } from '@app/core/helpers.js';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        attributes: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getChanges(row) {
            let changes = [];
            let newData = row.new || {};
            let oldData = row.old || {};
            let keys = dedupe(Object.keys(newData).concat(Object.keys(oldData))).sort();
            keys.forEach((key) => {
                if (isArray(oldData[key]) && isArray(newData[key])) {
                    let added = difference(newData[key], oldData[key]);
                    let removed = difference(oldData[key], newData[key]);
                    if (added.length !== 0) {
                        changes.push({
                            title: this.fieldTitle(key),
                            old: null,
                            new: this.formatFieldValue(key, added),
                        });
                    }
                    if (removed.length !== 0) {
                        changes.push({
                            title: this.fieldTitle(key),
                            old: this.formatFieldValue(key, removed),
                            new: null,
                        });
                    }
                } else {
                    changes.push({
                        title: this.fieldTitle(key),
                        old: this.formatFieldValue(key, oldData[key]),
                        new: this.formatFieldValue(key, newData[key]),
                    });
                }
            });
            return changes;
        },
        fieldTitle(name) {
            if (isString(this.attributes[name])) {
                return this.attributes[name];
            }
            if (isObject(this.attributes[name])) {
                return this.attributes[name].title;
            }
            return name;
        },
        formatFieldValue(name, value) {
            if (isNil(value)) {
                return null;
            }
            if (isArray(value)) {
                return value
                    .map((v) => this.formatFieldValue(name, v))
                    .join(', ');
            }
            if (isObject(this.attributes[name]) && this.attributes[name].formatter !== undefined) {
                value = this.attributes[name].formatter(value);
            }
            return isEmpty(value) ? null : value;
        },
    },
}
</script>
