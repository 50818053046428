import App from '@candybox/app.js';
import reportList from "@app/report/components/report/report";

App.boot(({router}) => {
    router.addRoute('report', {
        component: reportList,
        path: 'report',
        name: 'report',
    });
});
