export default {
    props: {
        extFilters: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            filtersDefault: {
                periodSimple: 'today',
                periodBetween: {
                    from: null,
                    to: null,
                },
                operator: null,
            },
            filters: {},
            loading: false,
        }
    },
    created() {
        this.filters = _.isEmpty(this.extFilters) ? this.filtersDefault :  this.extFilters;
    },
    watch: {
        ['filters.periodBetween']: {
            handler(val) {
                this.onInput(val);
            },
            deep: true,
        },
        ['filters.operator'](val) {
            this.onInput(val);
        },
    },
    methods: {
        onInput(filters=null) {
            // const filters_ = filters ? filters : this.filters;
            // this.$emit('input', filters_);
        }
    }
}
