import RestRepository from '@candybox/repository/rest.js';
import Employee from '@app/employee/documents/employee.js';
import employeesMap from '@app/employee/requests/employee-repository.js';
import { singleton } from '@candybox/helpers.js';


class EmployeeRepository extends RestRepository
{
    limitedSearch(query) {
        return this._request('limitedSearch',{
            query: this._serializeQuery(query)});
    }
}

export default singleton(() => {
    return new EmployeeRepository(Employee, employeesMap());
});
