<template>
    <div>
        <div class="mb-15">
            <el-row :gutter="20">
                <el-col :span="4" :offset="1">
                    <el-date-picker
                        v-model="perDate"
                        type="date"
                        unlink-panels
                        start-placeholder="Дата звіту"
                        format="DD.MM.YYYY"
                        value-format="YYYY-MM-DD"
                        class="el-input"
                    />
                </el-col>
                <el-col :span="10">
                    <el-button-group>
                        <el-button
                            v-if="$can('report.access')"
                            type="primary"
                            @click="getDataFromDB">
                            {{ __('Отримати звіт') }}
                        </el-button>
                        <el-button
                            v-if="$can('report.download') && !noReport"
                            @click="download">
                            {{ __('Завантажити Excel') }}
                        </el-button>
                    </el-button-group>
                </el-col>
            </el-row>
        </div>
        <el-scrollbar height="calc(100vh - 200px)">
            <el-row :gutter="20" v-if="!noReport">
                <el-col :span="22" :offset="1">
                    <el-table
                        :data="generalData"
                        border
                        class="mb-15"
                        style="width: 100%">
                        <el-table-column
                            prop="parameter"
                            label="Параметр"/>
                        <el-table-column
                            prop="value"
                            label="Значення"
                            width="180"/>
                    </el-table>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="!noReport">
                <el-col :span="22" :offset="1">
                    <el-table
                        :data="detailingData"
                        border
                        style="width: 100%">
                        <el-table-column
                            prop="parameter"
                            label="Параметр"
                            width="220"/>
                        <el-table-column
                            v-for="item in detailingColumnName"
                            :key="item.id"
                            :prop="item.id"
                            :label="item.label"/>
                    </el-table>
                </el-col>
            </el-row>
        </el-scrollbar>
    </div>
</template>

<script>
import report from "@app/report/repositories/report";
import Query from "@candybox/query/query";
import types from "@app/questionnaire/repositories/types";
import AnalyticsFiltersBase from "@app/analytics/components/base/filters/AnalyticsFiltersBase.vue";
import fileLoader from "@app/services/file-loader";

export default {
    name: "report",
    components: {
        AnalyticsFiltersBase,
    },
    data() {
        return {
            doc: {},
            perDate: undefined,
            noReport: true,
            generalData: [
                {
                    name: 'printed',
                    parameter: 'Надруковано',
                    value: 0,
                },
                {
                    name: 'under_review_sum',
                    parameter: 'На перевірці',
                    value: 0
                },
                {
                    name: 'on_print_sum',
                    parameter: 'У друці',
                    value: 0
                },
                {
                    name: 'not_pass_test_sum',
                    parameter: 'Не пройшло перевірку',
                    value: 0
                },
                {
                    name: 'stuck_in_system_sum',
                    parameter: 'Залишається в системі',
                    value: 0
                },
                {
                    name: 'clarification_and_verification_sum',
                    parameter: 'Уточнення та веріфикация',
                    value: 0
                },
                {
                    name: 'no_benefit_confirmation_sum',
                    parameter: 'Немає підтведження пільги',
                    value: 0
                },
                {
                    name: 'uploaded_for_printing',
                    parameter: 'Вигружено на друк',
                    value: 0
                },
                {
                    name: 'total',
                    parameter: 'Всього',
                    value: 0
                },
            ],
            detailingData: [
                {
                    name: 'issued',
                    parameter: 'Видано',
                },
                {
                    name: 'stand_out',
                    parameter: 'Видається',
                },
                {
                    name: 'refusal',
                    parameter: 'Відмова',
                },
                {
                    name: 'preparation_for_issuance',
                    parameter: 'На підготовці до видачі',
                },
                {
                    name: 'under_review',
                    parameter: 'На перевірці',
                },
                {
                    name: 'on_print',
                    parameter: 'У друці',
                },
                {
                    name: 'not_pass_test',
                    parameter: 'Не пройшло перевірку',
                },
                {
                    name: 'stuck_in_system',
                    parameter: 'Залишається в системі',
                },
                {
                    name: 'clarification_and_verification',
                    parameter: 'Уточнення та веріфикация',
                },
                {
                    name: 'no_benefit_confirmation',
                    parameter: 'Немає підтведження пільги',
                },
            ],
            detailingColumnName: [],
        }
    },
    methods: {
        getDataFromDB() {
            if (this.perDate === undefined || this.perDate === null) {
                this.perDate = new Date();
                this.perDate.setDate(this.perDate.getDate() - 1);
                this.perDate = this.perDate.toISOString().split('T')[0]
            }
            report()
                .search((new Query).where('per_date', '=', this.perDate))
                .then((row) => {
                    if (row.length !== 0) {
                        this.noReport = false;
                        this.doc = row[0];
                        this.perDate = this.doc.per_date;
                    } else {
                        this.noReport = true;
                        this.$error(this.__('Немає звіту за обраний день.'))
                    }
                })
        },
        updateGeneralData() {
            this.generalData.forEach((row) => {
                row.value = this.doc[row.name]
            })
        },
        updateDetailingData() {
            let i = 0;
            for (let value of this.detailingData) {
                let obj = JSON.parse(this.doc[value.name]);
                for (let item in obj) {
                    this.detailingData[i][item] = obj[item];
                }
                i++;
            }
        },
        loadColumnName() {
            types()
                .search(new Query())
                .then((row) => {
                    row.forEach((type) => {
                        this.detailingColumnName.push({
                            id: String(type.id),
                            label: type.name
                        });
                    });
                    this.detailingColumnName.push({
                        id: 'parents',
                        label: 'Батьки'
                    })
                    this.updateDetailingData();
                })
        },
        download() {
            fileLoader.get('/api/report/download/' + this.perDate).then((blobUrl) => {
                let link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', 'Звіт за ' + this.perDate)
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
    },
    watch: {
        doc: {
            handler() {
                this.updateGeneralData();
                this.updateDetailingData();
            },
            deep: true,
        },
    },
    created() {
        this.getDataFromDB()
        this.loadColumnName();
    }
}
</script>
