<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Конструктор анкет'),
            pages: [
                {
                    index: '1',
                    title: this.__('Анкети'),
                    route: { name: 'tree' },
                    visible: () => this.$can('block.access')
                        && this.$can('subblock.access')
                        && this.$can('question.access')
                        && this.$can('answer.access'),
                },
                {
                    index: '2',
                    title: this.__('Зарезервовані питання'),
                    route: { name: 'reserved' },
                    visible: () => this.$can('question.access')
                },
                {
                    index: '3',
                    title: this.__('Варіанти відповідей'),
                    route: { name: 'answers' },
                    visible: () => this.$can('answer.access'),
                },
            ],
        }
    }
}
</script>
