import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import {Method} from "@candybox/transport/request";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/employees/claim-time-work');

    mapping.validation('store', () => {
        return {
            reason_type: validator()
                .required()
                .length(0, 100),
            reason: validator()
                .required()
                .length(0, 250),
            employee_id: validator()
                .required(),
        }
    });
    mapping.map('claim', {
        route: '/api/claim',
        method: Method.POST,
    });
    mapping.map('confirm', {
        route: '/api/employees/claim-time-work/confirm',
        method: Method.POST,
    });
    mapping.map('refuse', {
        route: '/api/employees/claim-time-work/refuse',
        method: Method.POST,
    });

    return mapping;
});
