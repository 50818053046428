import Document from '@candybox/structures/document.js';
import Employee from '@app/employee/documents/service-employee.js';

class ServiceUser extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            login: String,
            password: String,
            roles: Array,
            permissions: Array,
            employee: Employee,
        };
    }
}

export default ServiceUser;
