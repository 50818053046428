<template>
    <div v-if="isError"
        class="no-data"
    >
        {{ __('Немає даних') }}
    </div>
    <GChart
        v-else
        :type="chartType"
        :data="chartData"
        :options="chartOptions"
        :resizeDebounce="300"
        :events="chartEvents"
    />
</template>

<script>
import { GChart } from 'vue-google-charts';

export default {
    name: "BaseChart",
    props: {
        chartType: {
            type: String,
            required: true,
        },
        chartData: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        xTitle: {
            type: String,
            default: null,
        },
        yTitle: {
            type: String,
            default: null,
        },
        width: {
            type: [Number, String],
            default: '100%',
        },
        height: {
            type: Number,
            default: 600,
        },
        options: {
            type: Object,
            default: () => ({}),
        }
    },
    components: {
        GChart
    },
    data () {
        return {
            isError: false,
            chartEvents: {
                'ready': (e) => {
                    this.isError = false;
                },
                'error': (e) => {
                    this.isError = true;
                }
            }
        }
    },
    computed: {
        baseOptions() {
            return {
                title: this.title,
                subtitle: this.subtitle,
                width: this.width,
                height: this.height,
            }
        },
        chartOptions() {
            return _.merge(this.baseOptions, this.options);
        },
    }
}
</script>
