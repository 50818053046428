<template>
    <el-row v-bind:style="{'height':650 + 'px'}">
        <el-col :span="9">
            <div class="script-group">
                <options-provider
                    :source="getRepository()">
                    <template v-slot:default="data">
                        <div
                            v-for="option in data.items"
                            :id="option.id"
                            @click="getContent"
                            class="script-item"
                        >
                            {{ option.value }}
                        </div>
                    </template>
                </options-provider>
            </div>
        </el-col>
        <el-col :span="15">
                <div ref="content" class="script-content"></div>
        </el-col>
    </el-row>
</template>

<script>
import script from "@app/script/repositories/script";

export default {
    date() {
        return {
            previousId: null,
        }
    },
    methods: {
        getRepository() {
            return script();
        },
        getContent(e) {
            let id = e.target.id;
            this.getRepository().get(id).then((r) => {this.$refs.content.innerHTML = r.content});
            document.getElementById(id).style.fontWeight = "bold"
            if (this.previousId) {
                document.getElementById(this.previousId).style.fontWeight = "unset"
            }
            this.previousId = id;
        },
    },
}
</script>

<style lang="scss">
@use 'resources/sass/_variables.scss' as *;
@use "sass:color";

.script-group {
    margin-left: 20px;
    overflow-y: scroll;

    .script-item {
        font-size: 14px;
        max-width: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.script-content {
    overflow-y: scroll;
    box-shadow: 0 0 0 2px $color-grey inset;
    border-radius: 4px;
    padding:4px;
    margin-right: 22px;

    > * + * {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    mark {
        background-color: #FAF594;
    }

    hr {
        margin: 1rem 0;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }

    ul[data-type="taskList"] {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: center;

            > label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
                user-select: none;
            }

            > div {
                flex: 1 1 auto;
            }
        }
    }
}
.script-group, .script-content {
    height: 600px;
}
</style>
