<template>
    <section class="dialog-body">
        <form-row>
            <div class="upload-file__wrapper">
                <input
                    type="file"
                    ref="uploadFile"
                    class="upload-file__input"
                    accept=".csv"
                    id="upload-file-form_input"
                    @change="previewFiles"
                />
                <label class="upload-file__label" for="upload-file-form_input">
                    <svg-icon name="upload" class="upload-file__icon icon-grey"/>
                    <span class="upload-file__text">{{ __(addFileText) }}</span>
                </label>
            </div>
        </form-row>
        <form-row
            class="success__btn"
            style="margin-bottom: 30px"
            v-loading="processing"
            element-loading-text="Обробляеться...">
            <el-button
                :disabled = "!load"
                @click="upload">
                Завантажити
            </el-button>
        </form-row>
        <form-row
            class="mt-5"
            :label="__('Нотаток')">
            <div class="mb-15">{{ message }}</div>
            <el-tree :data="tree"></el-tree>
        </form-row>
    </section>
</template>
<script>

import fileLoader from "@app/services/file-loader";
import FormRow from "@app/core/components/form/form-row";
import SvgIcon from "@app/core/components/svg-icon.vue";

export default {
    components: {
        SvgIcon,
        FormRow
    },
    data() {
        return {
            message: 'Файл мае бути формату CSV (UTF-8)',
            visible: true,
            addFileText: 'Додати файл',
            processing: false,
            load: false,
            tree: [
                {
                    label: 'Оновленно',
                },
                {
                    label: 'Немае анкет або карток у БД',
                    children: []
                },
                {
                    label: 'Рядки з пустими обов\'язковими комірками',
                    children: []
                },
                {
                    label: 'Невідомі коди статусів',
                    children: []
                }
            ],
        };
    },
    methods: {
        upload() {
            this.processing = true;
            this.treeRestart();
            fileLoader.upload(this.$refs.uploadFile.files[0], 'file', {}, 'api/questionnaires/upload')
                .promise()
                .then((r) => {
                    if (r.status === 200) {
                        let log = r.data.result.result.log
                        for (let key in log) {
                            if (key === 'not_in_db') {
                                this.treeHandler(log[key], 1)
                            }
                            if (key === 'empty_row') {
                                this.treeHandler(log[key], 2)
                            }
                            if (key === 'update') {
                                this.tree[0].label = this.addBrackets(this.tree[0].label, log[key]);
                            }
                            if (key === 'unknown_code') {
                                this.treeHandler(log[key], 3)
                            }
                        }
                    }
                    this.processing = false;
                })
                .catch((e) => {
                    let data = e.response.data;
                    this.message = data.error;
                    for (let key in data) {
                        if (key !== 'error') {
                            this.message = this.message + '\r\n' + data[key];
                        }
                    }
                })
                .finally(() => {
                    this.processing = false;
                });
        },
        previewFiles(event) {
            if (event.target.files[0]) {
                this.addFileText = event.target.files[0].name;
                this.load = true;
            }
        },
        addBrackets(value, addValue) {
            return value + ' (' + addValue + ')'
        },
        treeHandler(arr, index) {
            this.tree[index].label = this.addBrackets(this.tree[index].label, arr.length);
            arr.forEach((value) => {
                this.tree[index].children.push(
                    {
                        label: value
                    }
                );
            })
        },
        treeRestart() {
            this.tree = [
                {
                    label: 'Оновленно',
                },
                {
                    label: 'Немае анкет або карток у БД',
                    children: []
                },
                {
                    label: 'Рядки з пустими обов\'язковими комірками',
                    children: []
                },
                {
                    label: 'Невідомі коди статусів',
                    children: []
                }
            ]
        }
    }
  }
</script>
