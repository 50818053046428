import App from '@candybox/app.js';
import '@app/boot.js';

App.boot(({app, router, store}) => {
    app.use(router);
    app.use(store);
    app.mount('#app');
}, 1000);

App.run();
