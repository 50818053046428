import { singleton } from '@candybox/helpers.js';
import { Method,
    RequestMap } from '@candybox/transport/request.js';
import { validator } from '@candybox/validation/validator.js';

export default singleton(() => {
    const mapping = new RequestMap();

    mapping
        .route('csrf-cookie', '/sanctum/csrf-cookie')
        .map('login', {
            method: Method.POST,
            route: '/api/auth/login',
            validation() {
                return {
                    login: validator()
                        .required(),
                    password: validator()
                        .required(),
                }
            },
        })
        .route('me', '/api/auth/me')
        .route('logout', '/api/auth/logout')
        .route('ecp', '/api/auth/ecp');

    return mapping;
});
