<template>
    <div class="buttons">
        <span
            v-if="connected"
            class="sip-number">
            {{ __('SIP') }}: <span>{{ number }}</span>
        </span>

        <a
            v-if="$can('script.access')"
            href="#"
            @click.prevent="openScripts"
        >
            <svg-icon name="person-add" class="icon-small icon-blue">
                {{ __('Скріпти') }}
            </svg-icon>
        </a>

        <a
            v-if="canStartSession"
            href="#"
            @click.prevent="startSession">
            <svg-icon name="play" class="icon-small icon-blue">
                {{ __('Розпочати роботу') }}
            </svg-icon>
        </a>
        <a
            v-if="canUnpause"
            href="#"
            @click.prevent="unpause">
            <svg-icon name="play" class="icon-small icon-blue">
                {{ __('Зняти с перерви /') }} {{ durationToFormat(currentPauseDuration) || '0' }}
            </svg-icon>
        </a>
        <a
            v-if="canPause"
            href="#"
            @click.prevent="pause">
            <svg-icon name="pause-circle" class="icon-small icon-blue">
                {{ __('Зробити перерву') }}
            </svg-icon>
        </a>
    </div>
</template>

<script>
import VoipMixin from '@app/call-center/mixins/voip.js';
import manage from "@app/core/mixins/manage";
import script from "@app/core/components/manage/script";
import callLayout from "@app/call-center/components/call/form.vue";
import Call from  "@app/call-center/documents/call.js";
import calls from "@app/call-center/repositories/calls.js";
import {get,set} from "@candybox/helpers.js";
import {UA} from "@app/services/sip-ua.js";
import {mapGetters} from "vuex";
import {calcTime} from "@app/core/helpers.js";
import {durationShortFormat} from "@app/core/formatter.js";

export default {
    mixins: [
        manage,
        VoipMixin,
    ],
    data() {
        return {
            currentPauseDuration: 0,
        };
    },
    created() {
        this.updateTimers = () => {
            if (UA().stateManager.isPaused) {
                this.currentPauseDuration = UA().stateManager.currentPauseDuration;
            }
        }
    },
    mounted() {
        this.$ticker.on(this.updateTimers);
    },
    beforeDestroy() {
        this.$ticker.off(this.updateTimers);
    },
    computed: {
        ...mapGetters({
            connected: 'callCenter/getConnected',
            number: 'callCenter/getNumber',
            available: 'callCenter/getAvailable',
            stateManager: 'callCenter/getStateManagerCounts',
        }),
        pauseDuration() {
            return calcTime(get(this.stateManager, 'pauseStartTime', 0));
        },
    },
    methods: {
        getScriptComponent() {
            return script;
        },
        openScripts() {
            this.$modalComponent(
                this.getScriptComponent(), {}, {},
                {
                    title: this.__('Скрипти'),
                    width: '836px',
                });
        },
        getRepository() {
            return calls();
        },
        // getModalOptions() {
        //     return {
        //         createForm: this.makeBasicCreate(Call, callLayout),
        //         createTitle: this.__('Додавання дзвінка'),
        //         width: '836px',
        //     };
        // },
        // createCall() {
        //     this.create();
        // },
        pause() {
            this.currentPauseDuration = 0;
            UA().available = false;
        },
        unpause() {
            UA().available = true;
        },
        durationToFormat(duration) {
            return durationShortFormat(duration);
        },
    },
};
</script>
