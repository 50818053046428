import Document, {
    Attribute } from '@candybox/structures/document.js';
import Questionnaire from "@app/questionnaire/documents/questionnaire";


class Card extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            card_code: String,
            status: String,
            date_of_issue: Date,
            expiry_date: Date,
            bar_code: String,
            status_by_questionnaire: String,
            questionnaire_id: Number,
            questionnaire: Attribute.object(Questionnaire, () => new Questionnaire(), false),
            benefit_code: String,
            questionnaire_type_id: Number,
            type_name: String,
            status_by_questionnaire_digital: String,
            has_digital: Boolean,
            has_plastic: Boolean,
        };
    }
}

export default Card;
