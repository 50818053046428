import Document, {
    Attribute } from '@candybox/structures/document.js';
import RelationAttribute from '@candybox/repository/relation.js';
import employees from "@app/employee/repositories/employees.js";
import institution from "@app/institution/repositories/institutions.js";
import {getMonth, getYear} from "date-fns";
import constants from "@app/core/constants";
import Status from "@app/questionnaire/documents/status";
import Institution from "@app/institution/documents/institution";
import Employee from "@app/employee/documents/employee";


class Group extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            entry_year: Date,
            manager_id: Number,
            manager: Attribute.object(Employee, () => new Employee(), false),
            institution_id: Number,
            institution: Attribute.object(Institution, () => new Institution(), false),
            class_number: Number,
        };
    }

    get full_name_group() {
        if (this.class_number) {
            return this.class_number + '-' + this.name
        } else {
            return 'Застаріла'
        }

    }
    get entry_year_human_format() {
        return getYear(this.entry_year);
    }
}

export default Group;
