import Document, { Attribute } from '@candybox/structures/document.js';
import { isFilled } from '@app/core/helpers.js';
import RelationAttribute from '@candybox/repository/relation.js';
import types from '@app/questionnaire/repositories/types.js';
import employees from "@app/employee/repositories/employees.js";
import Answer from "@app/questionnaire/documents/answer";
import AnswerQuestionnaireValue from "@app/questionnaire/documents/AnswerQuestionnaireValue";

class AnswerQuestionnaire extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            answer_id: Number,
            questionnaire_id: Number,
            value: Attribute.object(AnswerQuestionnaireValue, () => new AnswerQuestionnaireValue(), false),
        };
    }
}

export default AnswerQuestionnaire;
