<script>
import base from '@app/core/components/page-groups/base.vue';

export default {
    extends: base,
    data() {
        return {
            title: this.__('Всі анкети'),
            pages: [
                {
                    index: '1',
                    title: this.__('Анкети'),
                    route: { name: 'questionnaires' },
                    visible: () => (this.$can('questionnaire.access')
                        || this.$can('questionnaire.access-institution_group')
                        || this.$can('questionnaire.access-institution')
                        || this.$can('questionnaire.view_non_institution_group')),
                },
            ],
        }
    }
}
</script>
