<template>
    <component
        :is="component"
        ref="component"
        :doc="doc"
        :question="question"
        :is-special="isSpecial"
        :readOnly="readOnly"
    />
</template>

<script>
import Dropdown from "@app/questionnaire/components/question/ViewTypes/SimpleTypes/Dropdown";
import CheckboxSet from "@app/questionnaire/components/question/ViewTypes/CheckboxSet";
import Simple from "@app/questionnaire/components/question/ViewTypes/SimpleTypes/Simple";
import getFieldLabel from "@app/questionnaire/components/mixins/getFieldLabel.js";
import viewMode from "@app/questionnaire/components/mixins/viewMode";

export default {
    name: "QuestionField",
    mixins: [
        viewMode,
    ],
    props: {
        doc: {
            type: Object,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        isSpecial: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        component () {
            switch (this.question.view_type) {
                case 'dropdown': {
                    return Dropdown;
                }
                case 'checkboxset': {
                    return CheckboxSet;
                }
                default: {
                    return Simple;
                }
            }
        },
    },
}
</script>
