<template>
    <el-menu
        :default-active="activeItem"
        :collapse="isCollapse"
        class="side-menu">
        <el-menu-item
            v-for="item in visibleItem"
            :key="item.index"
            :index="item.index"
            @click.middle="middleClick(item)"
            @click="navigate(item)">
            <svg-icon
                v-if="item.icon"
                :name="item.icon"
                class="icon-white"/>
            <template v-slot:title>
                {{ item.title }}
            </template>
        </el-menu-item>
    </el-menu>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    props: {
        isCollapse: Boolean,
    },
    computed: {
        activeItem() {
            return (this.items.find((item) => this.isMatchedRoute(item.route)) || {}).index;
        },
        visibleItem() {
            return this.items.filter((item) => item.visible() !== false);
        },
    },
    data() {
        return {
            items: [
                {
                    index: '1',
                    icon: 'staff',
                    title: this.__('Адміністрування'),
                    route: {
                        name: 'employees'
                    },
                    visible: () => (this.$can('employee.access')
                        || this.$can('employee.access-institution')
                        || this.$can('employee.limited_visibility')
                        || this.$can('employee.limited_access_institution')
                        || this.$can('employee.view_non_institution')), // если есть пермишен, то попадает в боковое меню  employee.view_non_institution
                },
                {
                    index: '2',
                    icon: 'card',
                    title: this.__('Картки'),
                    route: {
                        name: 'card'
                    },
                    visible: () => this.$can('card.access'),
                },
                {
                    index: '3',
                    icon: 'questionnaire',
                    title: this.__('Анкети'),
                    route: {
                        name: 'questionnaires'
                    },
                    visible: () => (this.$can('questionnaire.access')
                        || this.$can('questionnaire.access-institution_group')
                        || this.$can('questionnaire.access-institution')
                        || this.$can('questionnaire.view_non_institution_group')), // проверка по разным пермишенам
                },
                {
                    index: '4',
                    icon: 'sample',
                    title: this.__('Вибірки'),
                    route: {
                        name: 'sample'
                    },
                    visible: () => (
                        this.$can('sample.access')
                        && this.$can('sample.create')
                        && this.$can('sample.update')
                        && this.$can('sample.delete')
                    ),
                },
                {
                    index: '5',
                    icon: 'handbook',
                    title: this.__('Довідники'),
                    route: {
                        name: 'settings'
                    },
                    visible: () => this.$can('glossary.access'),
                },
                {
                    index: '6',
                    icon: 'analytics',
                    title: this.__('Аналітика'),
                    route: {
                        name: 'analytics'
                    },
                    visible: () => this.$can('analytics.access'),
                },
                {
                    index: '7',
                    icon: 'distributor',
                    title: this.__('Торговельні мережі'),
                    route: {
                        name: 'distributor'
                    },
                    visible: () => this.$can('distributor.access'),
                },
                {
                    index: '8',
                    icon: 'institution',
                    title: this.__('Навчальні заклади'),
                    route: {
                        name: 'institution'
                    },
                    visible: () => (this.$can('institution.access')
                        || this.$can('institution.access-institution')
                        || this.$can('institution_group.access')
                        || this.$can('institution_group.access-institution_group')),
                },
                {
                    index: '9',
                    icon: 'call-center',
                    title: this.__('Контактний центр'),
                    route: {
                        name: 'call-center'
                    },
                    visible: () => this.$can('call.access'),
                },
                {
                    index: '10',
                    icon: 'script',
                    title: this.__('Скрипти'),
                    route: {
                        name: 'script'
                    },
                    visible: () => this.$can('script.access'),
                },
                {
                    index: '11',
                    icon: 'constructor',
                    title: this.__('Конструктор анкет'),
                    route: {
                        name: 'constructor'
                    },
                    visible: () => (
                        this.$can('block.access')
                        && this.$can('subblock.access')
                        && this.$can('question.access')
                        && this.$can('answer.access')
                    ),
                },
                {
                    index: '12',
                    icon: 'partner',
                    title: this.__('Партнери'),
                    route: {
                        name: 'partner'
                    },
                    visible: () => this.$can('partner.access'),
                },
                {
                    index: '13',
                    icon: 'category',
                    title: this.__('Каталог роздріб'),
                    route: {
                        name: 'categories'
                    },
                    visible: () => this.$can('category.access'),
                },
                {
                    index: '14',
                    icon: 'reports',
                    title: this.__('Звітність'),
                    route: {
                        name: 'report'
                    },
                    visible: () => this.$can('report.access'),
                },
                {
                    index: '15',
                    icon: 'reports',
                    title: this.__('Вивантаження ХП'),
                    route: {
                        name: 'admin_hp'
                    },
                    visible: () => this.$can('questionnaire.export_import_hp'),
                },
                {
                    index: '16',
                    icon: 'logout',
                    title: this.__('Вихід'),
                    click: () => {
                        this.logout();
                    },
                    visible: () => true,
                },
            ],
        };
    },
    methods: {
        navigate(item) {
            if (item.route) {
                this.$router.push(item.route);
            } else if (item.click) {
                item.click();
            }
        },
        middleClick(item) {
            if (item.route) {
                let {href} = this.$router.resolve(item.route);
                this.openInNewTab(window.location.origin + '/' + href);
            } else if (item.click) {
                item.click();
            }
        },
        isMatchedRoute(route) {
            return route && this.$route.matched.some((r) => r.name === route.name);
        },
        ...mapActions({
            logout: 'user/logout',
        }),
        openInNewTab(href) {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: href,
            }).click();
        }
    }
}
</script>
