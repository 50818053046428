<template>
    <div>
        <div>
            <RetailFilter
                :ext-filters="filters"/>
        </div>
        <el-scrollbar height="calc(100vh - 265px)">
            <PieChartSet
                v-if="!loading"
                :items="pieChartSet1"
            />
            <PieChart
                v-if="!loading"
                :config="pieData1[0]"
            />
            <BarChart
                v-if="!loading"
                :config="barData1[0]"
            />
        </el-scrollbar>
    </div>
</template>

<script>
    import RetailFilter from "@app/analytics/components/base/filters/operators/RetailFilter.vue";
    import BarChart from "@app/analytics/components/base/charts/BarChart.vue";
    import PieChart from "@app/analytics/components/base/charts/PieChart.vue";
    import PieChartSet from "@app/analytics/components/base/chart-sets/PieChartSet.vue";
    import Query from '@candybox/query/query.js';
    import {format, subDays} from 'date-fns';
    import RetailAnalytics from "@app/analytics/repositories/retail-analytics.js";
    import {get} from "@candybox/helpers.js";
    import {each, isEmpty} from 'lodash';


    export default {
        name: "Retails",
        components: {
            RetailFilter,
            BarChart,
            PieChart,

            PieChartSet,
        },
        data() {
            return {
                filters: {
                    periodBetween: {
                        from: null,
                        to: null,
                    },
                },
                loading: true,
                orderStatistics: {
                    products: 0,
                    discount: 0,
                },
            }
        },
        methods: {
            async getDataBetween(filters) {
                this.loading = true;
                return RetailAnalytics()
                    .getBetween({filters})
                    .then((res) => {

                        let result = {
                            products: 0,
                            discount: 0,
                            discountBySocialType: 0,
                            discountByCommunalType: 0,
                            discountByStudentType: 0,
                            discountByDefaultType: 0,
                            discountBySchoolboyType: 0,
                            categoriesStatistic: {}
                        };

                        result.products = res.products;
                        result.discount = res.discount;
                        result.discountBySocialType = res.discountBySocialType;
                        result.discountByCommunalType = res.discountByCommunalType;
                        result.discountByStudentType = res.discountByStudentType;
                        result.discountByDefaultType = res.discountByDefaultType;
                        result.discountBySchoolboyType = res.discountBySchoolboyType;

                        _.each(res.categoryStatistic, (value, key) => {
                            result.categoriesStatistic[key] = {
                                'Значення':
                                    parseInt(value),
                            }

                        });

                        this.orderStatistics = result;
                        this.loading = false;
                        return this.orderStatistics;
                    });
            },


            loadData() {
                if (new Date(this.filters.periodBetween.from) > new Date(this.filters.periodBetween.to)) {
                    let filters = {
                        to: this.filters.periodBetween.from,
                        from: this.filters.periodBetween.to
                    };
                    this.getDataBetween(filters);
                } else {
                    let filters = {
                        to: this.filters.periodBetween.to,
                        from: this.filters.periodBetween.from
                    };
                    this.getDataBetween(filters);
                }
            },
        },
        created() {
            this.filters.periodBetween.to = format(subDays(new Date(), 1), "dd-MM-yyyy");
            this.filters.periodBetween.from = format(subDays(new Date(), 1), "dd-MM-yyyy");
            this.loadData();
        },
        computed: {
            retailsCounts() {
                return {
                    products: this.orderStatistics.products,
                    discount: this.orderStatistics.discount,
                    discountBySocialType: this.orderStatistics.discountBySocialType,
                    discountByCommunalType: this.orderStatistics.discountByCommunalType,
                    discountByStudentType: this.orderStatistics.discountByStudentType,
                    discountByDefaultType: this.orderStatistics.discountByDefaultType,
                    discountBySchoolboyType: this.orderStatistics.discountBySchoolboyType,
                    categoriesStatistic: this.orderStatistics.categoriesStatistic
                }
            },
            pieChartSet1() {
                return [
                    {
                        title: 'ПРОДАНИХ ПОСЛУГ/ТОВАРІВ',
                        colors: {
                            'ПРОДАНИХ ПОСЛУГ/ТОВАРІВ': '#17783C',
                        },
                        data: {
                            'ПРОДАНИХ ПОСЛУГ/ТОВАРІВ': this.retailsCounts.products,
                        },
                        width: 160,
                        height: 160,
                        pieHole: 0.7,
                        withLegend: false,
                    },
                    {
                        title: 'ЗНИЖКА ДЛЯ ФІЗИЧНИХ ОСІБ',
                        colors: {
                            'ЗНИЖКА ДЛЯ ФІЗИЧНИХ ОСІБ': '#CF0C47',
                        },
                        data: {
                            'ЗНИЖКА ДЛЯ ФІЗИЧНИХ ОСІБ': this.retailsCounts.discount,
                        },
                        width: 160,
                        height: 160,
                        pieHole: 0.7,
                        withLegend: false,
                    },
                ];
            },
            pieData1() {

                return [
                    {
                        title: 'КІЛЬКІСТЬ ПОКУПЦІВ ЗА ТИПАМИ КАРТОК',
                        colors: {
                            'Пільгова': '#CF0C47',
                            'КОМУНАЛЬНА': '#1B6EBE',
                            'СТУДЕНТСЬКА': '#278D4E',
                            'ЗВИЧАЙНА': '#333333',
                            'УЧНІВСЬКА': '#DEA000',

                        },
                        data: {
                            'Пільгова': parseFloat(this.retailsCounts.discountBySocialType),
                            'КОМУНАЛЬНА': parseFloat(this.retailsCounts.discountByCommunalType),
                            'СТУДЕНТСЬКА': parseFloat(this.retailsCounts.discountByStudentType),
                            'ЗВИЧАЙНА': parseFloat(this.retailsCounts.discountByDefaultType),
                            'УЧНІВСЬКА': parseFloat(this.retailsCounts.discountBySchoolboyType),

                        },
                        height: 500,
                    }
                ];
            },
            barData1() {
                return [
                    {
                        title: 'РОЗМИР ЗНИЖКИ ПО ОСНОВНИМ КАТЕГОРИЯМ',
                        columns: [
                            'Значення',
                        ],
                        withDescriptions: true,
                        colors: {
                            'Значення': '#4F5A6E',
                        },
                        data: this.retailsCounts.categoriesStatistic,
                        barWidth: 15,
                        left: 200,
                    }
                ]
            },

        },
        watch: {
            ['filters.periodBetween']: {
                handler(filters) {
                    if (filters && filters.from && filters.to) {
                        this.loadData()
                    } else if (filters && !filters.from && !filters.to) {
                        this.loadData()
                    }
                },
                deep: true,
            },
        },
    }
</script>
