import releaseRequests from "@app/release/requests/release-requests.js";
import {get} from 'lodash';

export default {
    namespaced: true,
    state: () => ({
        version: null,
    }),
    mutations: {
        setVersion(state, {version}) {
            state.version = version;
        },
    },
    actions: {
        setVersion({commit}, {system_name}) {
            releaseRequests()
                .create('get-last', {system_name})
                .send()
                .then((res) => {
                    commit('setVersion', {
                        version: get(res, 'body.version', null)
                    })
                });
        },
    },
    getters: {
        version(state) {
            return state.version;
        },
    },
}
