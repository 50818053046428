<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('glossary.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('glossary.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('glossary.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
            <el-button
                v-if="$can('history.access')"
                :disabled="selectedItem === null"
                @click="history">
                {{ __('Операції') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import formLayout from '@app/handbook/components/glossary/form.vue';
import manage from '@app/core/mixins/manage.js';
import glossary from "@app/handbook/repositories/glossary";
import Glossary from "@app/handbook/documents/glossary";
import history from '@app/handbook/components/glossary/history';
import {date} from "@app/core/formatter";
import getEmployeesMixin from "@app/employee/components/employees/mixins/getEmployeesMixin.js";


export default {
    name: "glossary",
    mixins: [
        manage,
        getEmployeesMixin,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'type',
                    title: this.__('Тип'),
                    sortable: true,
                    filterable: 'value_for_questionnaires',
                    formatter: (val) => {
                        return this.$handbook(`value_for_questionnaires.${val}`);
                    },
                    width: '200px',
                },
                {
                    name: 'name',
                    title: this.__('Назва'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'code',
                    title: this.__('Код'),
                    sortable: true,
                    filterable: true,
                    width: '120px',
                },
                {
                    name: 'created_at',
                    title: this.__('Дата заповнення'),
                    formatter: date('dd MMM yyyy'),
                    sortable: true,
                    width: '120px',
                },
                {
                    name: '$author.$.full_name',
                    title: this.__('Автор запису'),
                    width: '200px',
                    filterable: this.getEmployees(),
                    filterProp: 'employee_id',
                },
            ],
            filter: {},
            select: ['*'],
            relations: ['$author'],
        }
    },
    methods: {
        getRepository() {
            return glossary();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Glossary, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати словник'),
                editTitle: this.__('Редагувати словник'),
                width: '340px',
            };
        },
        getHistoryComponent() {
            return history;
        },
    },
}
</script>
