import Document, {Attribute} from '@candybox/structures/document.js';
import Questionnaire from "@app/questionnaire/documents/questionnaire";
import ProcessLog from "@app/call-center/documents/process-log.js";
import {isFilled} from "@app/core/helpers";

class CallLog extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            type: String,
            source: String,
            queue: String,
            status: String,
            started_at: Date,
            ended_at: Date,
            phone_number: String,
            is_first: Number,
            operator_short_name: String,
            questionnaire: Attribute.object(Questionnaire, () => new Questionnaire(), false),
            process: Attribute.object(ProcessLog, () => new ProcessLog(), false),
        };
    }

    /**
     * Get employee full name
     *
     * @returns {String}
     */
    get full_name() {
        if(!this.questionnaire) {
            return '';
        }

        return [
            this.questionnaire.last_name,
            this.questionnaire.first_name,
            this.questionnaire.middle_name,
        ]
            .filter(isFilled)
            .join(' ');
    }
}

export default CallLog;
