<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('partner.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('partner.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('partner.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from "@app/core/mixins/manage";
import partner from "@app/partner/repositories/partner";
import Partner from "@app/partner/documents/partner";
import formLayout from "@app/partner/components/partner/form";

export default {

    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [
                {
                    name: 'name',
                    title: this.__('Назва'),
                    sortable: true,
                    filterable: true,
                },
                {
                    name: 'api_token',
                    title: this.__('Токен'),
                }
            ],
            filter: {},
            select: ['*'],
            relations: []
        }
    },
    methods: {
        getRepository() {
            return partner();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(Partner, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати '),
                editTitle: this.__('Редагувати '),
                width: '560px',
            };
        },
    },
}
</script>
