import Document, {Attribute} from '@candybox/structures/document.js';
import InputType from "@app/questionnaire/documents/answer/inputType";

class InputParam extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            validators: Object,
            type: Attribute.object(InputType, () => new InputType(), false),
        };
    }
}

export default InputParam;
