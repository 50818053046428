<template>
    <div class="page">
        <div class="page-heading mb-15">
            <div class="page-heading__header ">
                <router-link
                    v-if="backRoute"
                    :to="backRoute"
                    class="nav-back"
                >
                    <svg-icon
                        name="arrow-left"
                    />
                </router-link>
                <h2>{{ title }}</h2>
                <div>
                    <slot name="heading-addons" />
                </div>
            </div>
            <slot name="heading"/>
        </div>
        <div class="page-content flex-column">
            <slot />
        </div>
        <div class="page-footer">
            <slot name="footer"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        backRoute: {
            type: Object,
            default: null,
        },
    },
}
</script>
