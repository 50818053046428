import RestRepository from '@candybox/repository/rest.js';
import cardMap from '@app/card/requests/card-repository';
import { singleton } from '@candybox/helpers.js';
import Card from "@app/card/documents/card";


class CardRepository extends RestRepository{
    importExportLog(data) {
        return this._request('importExportLog',  data);
    }
}


export default singleton(() => {
    return new CardRepository(Card, cardMap());
});
