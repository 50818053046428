import glossary from "@app/handbook/repositories/glossary.js";
import Query from "@candybox/query/query.js";
import {map} from "lodash";

export default {
    methods: {
        getGlossaries(type) {
            return glossary()
                .search(
                    (new Query)
                        .where('type', '=', type)
                )
                .then((items) => {
                    return map(items, (item) => ({
                        id: item.id,
                        value: item.name,
                    }));
                });
        },
    }
}
