import {get, first} from "lodash";
import fileLoader from "@app/services/file-loader.js";

export default {
    data() {
        return {
            componentRef: 'uploadFiles',
            currentUpload: null,
            uploads: {
                products: {
                    url: '/api/category/import-products',
                },
                checks: {
                    url: '/api/category/import-checks',
                },
            },
        }
    },
    methods: {
        openFileListWindow(ref = null) {
            const ref_ = ref ?? this.componentRef;
            if(this.$refs[ref_]) {
                this.$refs[ref_].value = '';
                this.$refs[ref_].click();
            }
        },
        onChangeFiles(e) {
            const file = first(get(e, 'target.files'));
            if(file) {
                const {url} = this.uploads[this.currentUpload];
                const {name} = file;
                fileLoader.upload(file, name, {}, url)
                    .promise()
                    .then(() => {
                        this.$success('Імпорт даних розпочався');
                    })
                    .catch(({response}) => {
                        const msg = response.status === 422
                            ? get(response, 'data.errors.file.0', 'Невірний формат файлу')
                            : get(response, 'data.message', 'Невірний формат стовпців у файлі');
                        this.$error(msg);
                    })
            }
        }
    }
}
