import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers';

export default singleton(() => {
        const mapping = new RepositoryRequestMap('/api/sample/field');

        mapping.validation('store', () => {
            return {
                field_name: validator()
                    .required()
                    .length(0, 100),
                alias: validator()
                    .length(0, 100),
            }
        });

        return mapping;
    }
);
