<template>
    <div>
        <div>
            <SmallAdminFilter
                :ext-filters="filters"
            />
        </div>
        <el-scrollbar height="calc(100vh - 220px)">
            <PieChartSet
                :items="totalCountAnalytics"
                :loading="totalCountAnalyticsLoading"
                class="mb-80"
            />
            <BarChart
                :config="statusesAnalytics"
                :loading="statusesAnalyticsLoading"
            />
        </el-scrollbar>
    </div>
</template>

<script>
import SmallAdminFilter from "@app/analytics/components/base/filters/operators/SmallAdminFilter.vue";
import BarChart from "@app/analytics/components/base/charts/BarChart.vue"
import PieChart from "@app/analytics/components/base/charts/PieChart.vue";
import LineChart from "@app/analytics/components/base/charts/LineChart.vue";
import PieChartSet from "@app/analytics/components/base/chart-sets/PieChartSet.vue";
import ColumnChart from "@app/analytics/components/base/charts/ColumnChart.vue";
import { get } from "@candybox/helpers.js";
import { each, isEmpty } from 'lodash';
import SmallAdminsAnalytics from "@app/analytics/repositories/small-admins-analytics.js";
import {handbookEntry} from "@app/core/formatter.js";

export default {
    name: "Operators",
    components: {
        SmallAdminFilter,
        BarChart,
        PieChart,
        LineChart,
        PieChartSet,
        ColumnChart,
    },
    data() {
        return {
            filters: {
                periodBetween: {
                    from: null,
                    to: null,
                },
                operator: null,
            },
            totalCountAnalyticsLoading: true,
            statusesAnalyticsLoading: true,
            totalCountAnalytics: [
                {
                    title: 'Кількість вчителів',
                    colors: {
                        'Кількість вчителів': '#17783C',
                    },
                    data: {
                        'Кількість вчителів': 1,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Кількість оброблених анкет',
                    colors: {
                        'Кількість оброблених анкет': '#CF0C47',
                    },
                    data: {
                        'Кількість оброблених анкет': 1,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Кількість класів',
                    colors: {
                        'Кількість класів': '#1B6EBE',
                    },
                    data: {
                        'Кількість класів': 1,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
            ],
            statusesAnalytics: {
                title: 'Розподіл анкет за статусами',
                columns: [
                    'Кількість анкет',
                ],
                withDescriptions: true,
                colors: {
                    'Кількість анкет': '#00BBD3',
                },
                data: {
                    'Байдин Д С.': {
                        'Кількість анкет': 46,
                    },
                    'Ломако К.С.': {
                        'Кількість анкет': 45,
                    },
                    'Джапарідзе А.Л.': {
                        'Кількість анкет': 47,
                    },
                    'Мартинов А.А.': {
                        'Кількість анкет': 40,
                    },
                    'Сидоров С.Г.': {
                        'Кількість анкет': 48,
                    },
                    'Петров П.П.': {
                        'Кількість анкет': 5,
                    },
                    'Іванов І.І.': {
                        'Кількість анкет': 48,
                    },
                },
                barWidth: 15,
            },
        }
    },
    methods: {
        async totalCountAnalyticsLoad(filters) {
            this.totalCountAnalyticsLoading = true;
            return SmallAdminsAnalytics()
                .getTotal({filters})
                .then((res) => {
                    this.totalCountAnalytics[0].data['Кількість вчителів'] = get(res, 'teachers_count', 0);
                    this.totalCountAnalytics[1].data['Кількість оброблених анкет'] = get(res, 'total', 0);
                    this.totalCountAnalytics[2].data['Кількість класів'] = get(res, 'classes_count', 0);
                    this.totalCountAnalyticsLoading = false;
                });
        },
        async operatorsMarkAnalyticsLoad(filters) {
            this.statusesAnalyticsLoading = true;
            return SmallAdminsAnalytics()
                .getStatuses({filters})
                .then((res) => {
                    this.statusesAnalytics.data = this.prepareData(res, {
                        label: 'Кількість анкет',
                    });
                    this.statusesAnalyticsLoading = false;
                });
        },
        async loadData() {
            const filters = {
                from: this.filters.periodBetween.from,
                to: this.filters.periodBetween.to,
                operator: this.filters.operator,
            };

            await this.totalCountAnalyticsLoad(filters)
            await this.operatorsMarkAnalyticsLoad(filters)
        },
        prepareData(data, kwargs = {}) {
            const dict = {};
            each(data, (item) => {
                const category_name = handbookEntry('questionnaire_status')(get(item, 'questionnaire_status_name'));
                const questionnairesCount = get(item, 'questionnaires_count');
                dict[category_name] = {
                    [kwargs.label]: questionnairesCount
                }
            });
            return dict;
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        ['filters.periodBetween']: {
            handler(filters) {
                if(filters && filters.from && filters.to) {
                    this.loadData()
                } else if (filters && !filters.from && !filters.to) {
                    this.loadData()
                }
            },
            deep: true,
        },
        ['filters.operator']() {
            this.loadData()
        }
    },
}
</script>
