import { singleton } from '@candybox/helpers.js';
import { Method,
    RequestMap } from '@candybox/transport/request.js';
import { validator } from '@candybox/validation/validator.js';

export default singleton(() => {
    const mapping = new RequestMap();

    mapping
        .map('get-last', {
            method: Method.POST,
            route: '/api/release/get-last',
            validation() {
                return {
                    system_name: validator()
                        .required(),
                }
            },
        });

    return mapping;
});
