<template>
    <call-center-base>
        <template #heading-addons>
            <header-buttons />
        </template>
        <template #heading>
            <section
                v-loading="!connected"
                :element-loading-text="__('Підключення до серверу телефонії...')"
            >
                <main-control />
            </section>
        </template>
        <template #footer>
            <Status />
        </template>
    </call-center-base>
</template>

<script>
import callCenterBase from "@app/core/components/page-groups/callCenterBase.vue";
import MainControl from "@app/call-center/components/call-center/components/MainControl.vue";
import HeaderButtons from "@app/call-center/components/call-center/components/HeaderButtons.vue";
import Status from "@app/call-center/components/call-center/components/Status.vue";
import { mapGetters } from "vuex";

export default {
    name: "CallCenter",
    components: {
        callCenterBase,
        MainControl,
        HeaderButtons,
        Status,
    },
    computed: {
        ...mapGetters({
            connected: 'callCenter/getConnected',
        }),
    },
}
</script>
