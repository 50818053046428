<template>
    <div>
        <div>
            <OperatorsFilter
                :ext-filters="filters"
            />
        </div>
        <el-scrollbar height="calc(100vh - 265px)">
            <PieChartSet
                :items="totalCountAnalytics"
                :loading="totalCountAnalyticsLoading"
                class="mb-80"
            />
            <BarChart
                :config="operatorsMarksAnalytics"
                :loading="operatorsMarksAnalyticsLoading"
            />
        </el-scrollbar>
    </div>
</template>

<script>
import OperatorsFilter from "@app/analytics/components/base/filters/operators/OperatorsFilter.vue";
import BarChart from "@app/analytics/components/base/charts/BarChart.vue"
import PieChart from "@app/analytics/components/base/charts/PieChart.vue";
import LineChart from "@app/analytics/components/base/charts/LineChart.vue";
import PieChartSet from "@app/analytics/components/base/chart-sets/PieChartSet.vue";
import ColumnChart from "@app/analytics/components/base/charts/ColumnChart.vue";
import OperatorsAnalytics from "@app/analytics/repositories/operators-analytics.js";
import { get } from "@candybox/helpers.js";
import { each, isEmpty } from 'lodash';

export default {
    name: "Operators",
    components: {
        OperatorsFilter,
        BarChart,
        PieChart,
        LineChart,
        PieChartSet,
        ColumnChart,
    },
    data() {
        return {
            filters: {
                periodBetween: {
                    from: null,
                    to: null,
                },
                operator: null,
            },
            totalCountAnalyticsLoading: true,
            operatorsMarksAnalyticsLoading: true,
            totalCountAnalytics: [
                {
                    title: 'Всього дзвінків',
                    colors: {
                        'Всього дзвінків': '#17783C',
                    },
                    data: {
                        'Всього дзвінків': 248,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Оброблених дзвінків',
                    colors: {
                        'Оброблених дзвінків': '#CF0C47',
                    },
                    data: {
                        'Оброблених дзвінків': 170,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Закритих дзвінків(архів)',
                    colors: {
                        'Закритих дзвінків(архів)': '#1B6EBE',
                    },
                    data: {
                        'Закритих дзвінків(архів)': 140,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Дзвінків в роботі',
                    colors: {
                        'Дзвінків в роботі': '#DEA000',
                    },
                    data: {
                        'Дзвінків в роботі': 30,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
                {
                    title: 'Середня оцінка роботи операторів',
                    colors: {
                        'Середня оцінка роботи операторів': '#4F5A6E',
                    },
                    data: {
                        'Середня оцінка роботи операторів': 4.8,
                    },
                    width: 160,
                    height: 160,
                    pieHole: 0.7,
                    withLegend: false,
                },
            ],
            operatorsMarksAnalytics: {
                title: 'Оцінки операторів',
                columns: [
                    'Оцінки',
                ],
                withDescriptions: true,
                colors: {
                    'Оцінки': '#4F5A6E',
                },
                data: {
                    'Байдин Д С.': {
                        'Оцінки': 4.6,
                    },
                    'Ломако К.С.': {
                        'Оцінки': 4.5,
                    },
                    'Джапарідзе А.Л.': {
                        'Оцінки': 4.7,
                    },
                    'Мартинов А.А.': {
                        'Оцінки': 4.0,
                    },
                    'Сидоров С.Г.': {
                        'Оцінки': 4.8,
                    },
                    'Петров П.П.': {
                        'Оцінки': 5,
                    },
                    'Іванов І.І.': {
                        'Оцінки': 4.8,
                    },
                },
                barWidth: 15,
            },
        }
    },
    methods: {
        async totalCountAnalyticsLoad(filters) {
            this.totalCountAnalyticsLoading = true;
            return OperatorsAnalytics()
                .getTotal1({filters})
                .then((res) => {
                    this.totalCountAnalytics[0].data['Всього дзвінків'] = get(res, 'total', 0);
                    this.totalCountAnalytics[1].data['Оброблених дзвінків'] = get(res, 'not_ended', 0);
                    this.totalCountAnalytics[2].data['Закритих дзвінків(архів)'] = get(res, 'ended', 0);
                    this.totalCountAnalytics[3].data['Дзвінків в роботі'] = get(res, 'on_work', 0);
                    this.totalCountAnalytics[4].data['Середня оцінка роботи операторів'] = get(res, 'avg_mark', 0);
                    this.totalCountAnalyticsLoading = false;
                });
        },
        async operatorsMarkAnalyticsLoad(filters) {
            this.operatorsMarksAnalyticsLoading = true;
            return OperatorsAnalytics()
                .getMarks({filters})
                .then((res) => {
                    this.operatorsMarksAnalytics.data = this.prepareOperatorsMarkData(res, {
                        label: 'Оцінки',
                    });
                    this.operatorsMarksAnalyticsLoading = false;
                });
        },
        async loadData() {
            const filters = {
                from: this.filters.periodBetween.from,
                to: this.filters.periodBetween.to,
                operator: this.filters.operator,
            };

            await this.totalCountAnalyticsLoad(filters)
            await this.operatorsMarkAnalyticsLoad(filters)
        },
        prepareOperatorsMarkData(data, kwargs = {}) {
            const dict = {};
            each(data, (item) => {
                const operator = get(item, 'operator');
                const mark = get(item, 'mark');
                dict[operator] = {
                    [kwargs.label]: mark
                }
            });
            return dict;
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        ['filters.periodBetween']: {
            handler(filters) {
                if(filters && filters.from && filters.to) {
                    this.loadData()
                } else if (filters && !filters.from && !filters.to) {
                    this.loadData()
                }
            },
            deep: true,
        },
        ['filters.operator']() {
            this.loadData()
        }
    },
}
</script>
