<template>
    <data-table
        ref="table"
        flex
        :repository="getRepository()"
        :columns="columns"
        :filter="filter"
        :select="select"
        :relations="relations"
        @selection-changed="selectionChanged">
        <template v-slot:controls>
            <el-button
                v-if="$can('service-employee.create')"
                @click="create">
                {{ __('Додати') }}
            </el-button>
            <el-button
                v-if="$can('service-employee.update')"
                :disabled="selectedItem === null"
                @click="edit">
                {{ __('Редагувати') }}
            </el-button>
            <el-button
                v-if="$can('service-employee.delete')"
                :disabled="selectedItem === null"
                @click="remove">
                {{ __('Видалити') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import serviceEmployees from '@app/employee/repositories/service-employees';
import formLayout from '@app/employee/components/service-employees/form.vue';
import manage from '@app/core/mixins/manage.js';
import ServiceEmployee from '@app/employee/documents/service-employee';
import { phoneNumber } from '@app/core/formatter.js';

export default {
    mixins: [
        manage,
    ],
    data() {
        return {
            columns: [{
                name: 'full_name',
                title: this.__('ПІБ'),
                width: 250,
            }, {
                name: 'contact_number',
                width: 150,
                title: this.__('Телефон'),
                formatter: phoneNumber(),
            }, {
                name: '$position.$.name',
                title: this.__('Посада'),
            }, {
                name: 'rnokpp',
                title: this.__('РНОКПП'),
            }, {
                name: 'email',
                title: this.__('E-mail'),
            }, {
                name: 'user.login',
                title: this.__('Логін'),
            }],
            filter: {
                roles_codes: 'service_admin',
            },
            select: ['*', 'user.*'],
            relations: ['$position'],
        }
    },
    methods: {
        getRepository() {
            return serviceEmployees();
        },
        getModalOptions() {
            return {
                createForm: this.makeBasicCreate(ServiceEmployee, formLayout),
                editForm: this.makeBasicEdit(formLayout),
                createTitle: this.__('Додати адміністратора сервісу'),
                editTitle: this.__('Редагувати адміністратора сервісу'),
                width: '760px',
            };
        },
    },
}
</script>
