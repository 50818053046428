<template>
    <data-table
        ref="table"
        :repository="getRepository()"
        :columns="columns"
        :filter="filter">
        <template v-slot:controls>
            <el-button
                v-if="$can('card.access')"
                @click="goToCards">
                {{ __('Перейти в розділ карток') }}
            </el-button>
        </template>
    </data-table>
</template>

<script>
import manage from "@app/core/mixins/manage";
import card from "@app/card/repositories/card";
import {date, handbookEntry} from "@app/core/formatter";

export default {
    name: "show-cards",
    mixins: [
        manage,
    ],
    props: {
        selected_questionnaire_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'card_code',
                    title: this.__('Номер картки'),
                },
                {
                    name: 'date_of_issue',
                    title: this.__('Дата видачі'),
                    formatter: date('dd MMM yyyy'),
                    sortable: true,
                },
                {
                    name: 'expiry_date',
                    title: this.__('Діє до'),
                    formatter: date('dd MMM yyyy'),
                },
                {
                    name: 'status_by_questionnaire',
                    title: this.__('Стан картки'),
                    formatter: handbookEntry('card_status_by_questionnaire'),
                },
                {
                    name: 'status',
                    title: this.__('Валідність'),
                    formatter: handbookEntry('card_status'),
                }
            ],
            filter: {
                questionnaire_id: this.selected_questionnaire_id,
            },
        }
    },
    methods: {
        getRepository() {
            return card();
        },
        goToCards() {
            this.$router.push({
                name: 'card',
                query: {
                    id: this.selected_questionnaire_id,
                }
            })
            this.$emit('done');
        }
    }
}
</script>
