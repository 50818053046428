<template>
    <el-tabs v-model="activeTab">
        <el-tab-pane
            :label="__('Загальне')"
            name="general">
            <section class="dialog-body">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <form-row
                            :label="__('Офіційна назва партнера')"
                            :errors="$errors['name']"
                            required>
                            <el-input v-model="doc.name"/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Юридична назва партнера')"
                            :errors="$errors['legal_name']">
                            <el-input v-model="doc.legal_name"/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('ЄДРПОУ')"
                            :errors="$errors['edrpou']"
                            required>
                            <el-input v-model="doc.edrpou"/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('ПІБ відповідального')"
                            :errors="$errors['manager_full_name']">
                            <el-input v-model="doc.manager_full_name"/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Номер телефону відповідального')"
                            :errors="$errors['phone_number']"
                            required>
                            <el-input v-model="doc.phone_number"/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Посада відповідального')"
                            :errors="$errors['positions']">
                            <options-provider
                                :source="getPositions()">
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        v-model="doc.positions"
                                        multiple
                                        collapse-tags
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Категорія')"
                            :errors="$errors['categories']"
                            required>
                            <options-provider
                                :source="getCategory()">
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        v-model="doc.categories"
                                        multiple
                                        collapse-tags
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Статус')"
                            required>
                            <options-provider
                                source="distributor_status">
                                <template v-slot:default="data">
                                    <el-select
                                        :loading="data.loading"
                                        v-model="doc.status"
                                    >
                                        <el-option
                                            v-for="item in data.items"
                                            :key="item.id"
                                            :label="item.value"
                                            :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                                :label="__('Наявність договору про інтеграцію')"
                                :errors="$errors['agreement_id']"
                                required>
                            <options-provider
                                    :source="getAgreement()">
                                <template v-slot:default="data">
                                    <el-select
                                            :loading="data.loading"
                                            v-model="doc.agreement_id"
                                    >
                                        <el-option
                                                v-for="item in data.items"
                                                :key="item.id"
                                                :label="item.value"
                                                :value="item.id"/>
                                    </el-select>
                                </template>
                            </options-provider>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Дата підпису договору')"
                            :errors="$errors['date_of_signature']">
                            <el-date-picker
                                v-model="doc.date_of_signature"
                                format="DD.MM.YYYY"
                                value-format="YYYY-MM-DD"/>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
        <el-tab-pane
            :label="__('Графік роботи')"
            name="schedule">
            <section class="dialog-body">
                <el-row :gutter="24">
                    <el-col :span="24">
                        <form-row
                            :label="__('Робочі дні')"
                            :errors="$errors['work_schedule']">
                            <el-select v-model="workSchedule" multiple>
                                <el-option v-for="day in days"
                                           :key="day.name"
                                           :label="day.name"
                                           :value="day.name"/>
                            </el-select>
                        </form-row>
                    </el-col>
                    <el-col :span="24">
                        <form-row
                            :label="__('Робочий час')"
                            :errors="$errors['time_schedule']">
                            <el-time-picker
                                v-model="timeSchedule"
                                is-range
                                start-placeholder="З"
                                end-placeholder="По"
                                format="HH:mm"
                                class="el-input"
                                style="border-style: solid; border-width: 2px"/>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
        <el-tab-pane
            :label="__('Довідкова інформація')"
            name="info">
            <section class="dialog-body">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <form-row
                            :label="__('Адреса веб сайту')"
                            :errors="$errors['website']">
                            <el-input v-model="doc.website" />
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Електронна пошта')"
                            :errors="$errors['email']">
                            <el-input v-model="doc.email" />
                        </form-row>
                    </el-col>
                    <el-col :span="24">
                        <form-row
                            :label="__('Коментар')"
                            :errors="$errors['comment']">
                            <el-input v-model="doc.comment" />
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
        <el-tab-pane
            :label="__('Технічна інформація')"
            name="technical_information">
            <section class="dialog-body">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <form-row
                            :label="__('Ідентифікатор')"
                            :errors="$errors['identifier']">
                            <el-input v-model="doc.identifier" disabled/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Логін')"
                            :errors="$errors['login']">
                            <el-input v-model="doc.login" disabled/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Токен')"
                            :errors="$errors['token']"
                            required>
                            <el-input v-model="doc.token" placeholder="Натисніть кнопку генераціі токена" disabled/>
                        </form-row>
                    </el-col>
                    <el-col :span="12">
                        <form-row
                            :label="__('Генерація')"
                            :errors="$errors['setToken']">
                            <el-button
                                style="width: 100%"
                                type="primary"
                                @click="setToken">{{ __('Створити новий токен') }}
                            </el-button>
                        </form-row>
                    </el-col>
                </el-row>
            </section>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import md5 from 'js-md5';
import FormRow from "@app/core/components/form/form-row";
import glossaries from "@app/handbook/repositories/glossary";
import Query from "@candybox/query/query";

export default {
    components: {FormRow},
    props: {
        doc: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeTab: 'general',
            workSchedule: [],
            timeSchedule: [],
            days: [
                {
                    name: 'Понеділок'
                },
                {
                    name: 'Вівторок'
                },
                {
                    name: 'Середа'
                },
                {
                    name: 'Четвер'
                },
                {
                    name: 'П’ятниця'
                },
                {
                    name: 'Субота'
                },
                {
                    name: 'Неділя'
                },
            ],
        }
    },
    methods: {
        setToken() {
            let hashString = this.doc.name + this.doc.edrpou + this.doc.phone_number + Date.now();
            this.doc.token = md5(hashString);
        },
        getPositions() {
            return glossaries()
                .search((new Query).where('type', '=', 'distributor_position'))
                .then((items) => {
                    this.positions = items.map((position) => ({
                        id: position.id,
                        value: position.name,
                    }));
                    return this.positions;
                });
        },
        getCategory() {
            return glossaries()
                .search((new Query).where('type', '=', 'distributor_category'))
                .then((items) => {
                    this.categories = items.map((category) => ({
                        id: category.id,
                        value: category.name
                    }));
                    return this.categories;
                })
        },
        getStatus() {
            return glossaries()
                .search((new Query).where('type', '=', 'distributor_status'))
                .then((items) => {
                    this.statuses = items.map((status) => ({
                        id: status.id,
                        value: status.name
                    }));
                    return this.statuses;
                })
        },
        getAgreement() {
            return glossaries()
                .search((new Query).where('type', '=', 'distributor_agreement'))
                .then((items) => {
                    this.agreements = items.map((agreement) => ({
                        id: agreement.id,
                        value: agreement.name
                    }));
                    return this.agreements;
                })
        }
    },
    watch: {
        workSchedule: {
            handler(arr) {
                if (arr.length !== 0) {
                    this.doc.work_schedule = JSON.stringify(arr);
                } else {
                    this.doc.work_schedule = null;
                }
            }
        },
        timeSchedule: {
            handler(arr) {
                if (arr.length !== 0) {
                    this.doc.time_schedule = JSON.stringify(arr);
                } else {
                    this.doc.time_schedule = null;
                }
            }
        }
    },
    created() {
        if (this.doc.work_schedule !== null) {
            this.workSchedule = JSON.parse(this.doc.work_schedule);
        }
        if (this.doc.time_schedule !== null) {
            this.timeSchedule = JSON.parse(this.doc.time_schedule);
        }
        if (this.doc.categories.length !== 0) {
            let categoryList = [];
            this.doc.categories.forEach((row) => {
                categoryList.push(row[0]);
            })
            this.doc.categories = categoryList;
        }
        if (this.doc.positions.length !== 0) {
            let positionList = [];
            this.doc.positions.forEach((row) => {
                positionList.push(row[0]);
            })
            this.doc.positions = positionList;
        }
    }
}
</script>
