import {valueOf, get, forEach, isArray, isObject} from '@candybox/helpers.js';
import {defineComponent} from 'vue';
import basicCreate from '@app/core/components/manage/basic-create.vue';
import basicEdit from '@app/core/components/manage/basic-edit.vue';
import ExportExcel from "@app/core/services/excel.js";
import {isFilled} from "@app/core/helpers";
import Query from "@candybox/query/query";

export default {
    data() {
        return {
            selectedItem: null,
        };
    },
    methods: {
        selectionChanged(row) {
            this.selectedItem = row;
        },
        create(attrs = {}) {
            let opts = {
                ...this.defaultModalOptions(),
                ...this.getModalOptions(),
            };
            let messages = {
                ...this.defaultMessages(),
                ...this.getMessages(),
            };
            this.$modalComponent(opts.createForm, {
                attrs,
                ...valueOf(opts.createProps)
            }, {
                done: (dialog, {doc, stopLoading}) => {
                    this.$clearErrors();
                    this.performCreate(doc).then(() => {
                        dialog.close();
                        if (messages.created) {
                            this.$success(messages.created);
                        }
                        this.documentCreated(doc);
                        this.refresh();
                    }).catch((err) => {
                        stopLoading();
                        this.$catchErrors(err);
                    });
                },
                ...opts.events,
            }, {
                title: opts.createTitle,
                width: opts.width,
                backText: opts.backText,
                beforeClose: opts.beforeClose,
                afterClose: opts.afterClose,
            });
        },
        edit(attrs = {}) {
            let opts = {
                ...this.defaultModalOptions(),
                ...this.getModalOptions(),
            };
            let messages = {
                ...this.defaultMessages(),
                ...this.getMessages(),
            };
            this.$modalComponent(opts.editForm, {
                attrs,
                item: this.selectedItem,
                ...valueOf(opts.editProps),
            }, {
                done: (dialog, {doc, stopLoading}) => {
                    this.$clearErrors();
                    this.performUpdate(doc).then(() => {
                        dialog.close();
                        if (messages.updated) {
                            this.$success(messages.updated);
                        }
                        this.documentUpdated(doc);
                        this.refresh();
                    }).catch((err) => {
                        stopLoading();
                        this.$catchErrors(err);
                    });
                },
                updating: (dialog, {doc}) => {
                    this.documentUpdating(doc);
                    dialog.close();
                },
                ...opts.events,
            }, {
                title: opts.editTitle,
                width: opts.width,
                backText: opts.backText,
                beforeClose: opts.beforeClose,
                afterClose: opts.afterClose,
            });
        },
        remove() {
            let messages = {
                ...this.defaultMessages(),
                ...this.getMessages(),
            };
            this.$confirm(messages.deleteConfirmation, () => {
                let doc = this.selectedItem;
                this.performDelete(doc).then(() => {
                    if (messages.deleted) {
                        this.$success(messages.deleted);
                    }
                    this.documentDeleted(doc);
                    this.selectedItem = null;
                    this.refresh();
                });
            });
        },

        exportExcelPage() {
            const items = this.getManageTable().getPageData();
            const columns = this.getManageTable().getColumns()
            const exportExcel = new ExportExcel(items, columns)
            exportExcel.export('export.xlsx', this.__('Дані'));
        },
        exportToExcel(filter) {
            console.log(filter);
            let query = (new Query);
            this.addFilterCondition(query, filter);
            this.getRepository().search(query)
                .then((items) => {
                    const rows = items.map((item) => this.getRepository().newDocument(item));
                    const columns = this.getManageTable().getColumns();
                    const exportExcel = new ExportExcel(rows, columns)
                    exportExcel.export('export.xlsx', this.__('Дані'));
                    this.$success('Успех!');
                }).catch((err) => {
                console.log(err);
                this.$catchErrors(err);
            });
        },

        getModalOptions() {
            return {};
        },
        defaultModalOptions() {
            return {
                createForm: null,
                createProps: {},
                editForm: null,
                editProps: {},
                createTitle: this.__('Додати'),
                editTitle: this.__('Редагувати'),
                width: '600px',
                backText: this.__('Назад'),
                beforeClose: undefined,
                afterClose: undefined,
                events: {},
            };
        },
        getMessages() {
            return {};
        },
        defaultMessages() {
            return {
                deleteConfirmation: this.__('Ви певні, що хочете видалити цей запис?'),
                deleted: this.__('Запис був успішно видалений'),
                created: this.__('Запис був успішно доданий'),
                updated: this.__('Запис був успішно оновлений'),
            };
        },
        documentCreated(doc) {
        },
        documentUpdated(doc) {
        },
        documentCreating(doc) {
        },
        documentUpdating(doc) {
        },
        documentDeleted(doc) {
        },
        refresh() {
            if (this.getManageTable()) {
                this.getManageTable().refresh();
            }
        },
        getManageTable() {
            return this.$refs.table;
        },
        getRepository() {
            throw new Error('Repository is not defined');
        },
        performCreate(doc) {
            return this.getRepository().store(doc);
        },
        performUpdate(doc) {
            return this.getRepository().store(doc);
        },
        performDelete(doc) {
            return this.getRepository().delete(doc.id);
        },
        makeBasicCreate(kind, formLayout) {
            return defineComponent({
                extends: basicCreate,
                components: {
                    formLayout,
                },
                data() {
                    return {
                        doc: new kind(),
                    };
                },
            });
        },
        makeBasicEdit(formLayout) {
            return defineComponent({
                extends: basicEdit,
                components: {
                    formLayout,
                },
            });
        },
        getHistoryComponent() {
            throw new Error('Method getHistoryComponent must be implemented');
        },
        history() {
            this.$modalComponent(this.getHistoryComponent(), {
                id: this.selectedItem.id,
            }, {}, {
                title: this.__('Історія змін'),
                width: '800px',
            });
        },
        addFilterCondition(query, filters) {
            return query.where((cond) => {
                forEach(filters, (val, key) => {
                    if (isFilled(val)) {
                        if (isArray(val)) {
                            cond.in(key, val);
                        } else if (isObject(val)) {
                            if (val.mode === '=') {
                                cond.eq(key, val.query);
                            } else if (val.mode === '~') {
                                cond.startsWith(key, val.query);
                            } else if (val.mode === '*') {
                                cond.contains(key, val.query);
                            }
                        } else {
                            cond.eq(key, val);
                        }
                    }
                });
            });
        },
    },
}
