import App from '@candybox/app.js';
import institutionList from "@app/institution/components/institution/table";
import groupList from "@app/institution/components/group/table";

App.boot(({router}) => {
    router.addRoute('infrastructures', {
        component: institutionList,
        path: 'institution',
        name: 'institution',
    });
    router.addRoute('infrastructures', {
        component: groupList,
        path: 'group',
        name: 'group',
    });
});


