export default {
    props: {
        config: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        width() {
            return _.get(this.config, 'width', '100%');
        },
        height() {
            return _.get(this.config, 'height', 'auto');
        },
        withLegend() {
            return _.get(this.config, 'withLegend', true);
        },
        title() {
            return _.get(this.config, 'title', null);
        },
        data() {
            return _.get(this.config, 'data', {});
        },
        colors() {
            return _.get(this.config, 'colors', []);
        },

        // bar, line, column
        columns() {
            return _.get(this.config, 'columns', []);
        },
        withDescriptions() {
            return _.get(this.config, 'withDescriptions', false);
        },
        barWidth() {
            return _.get(this.config, 'barWidth', null);
        },
        left() {
            return _.get(this.config, 'left', 140);
        },

        // pie
        pieHole() {
            return _.get(this.config, 'pieHole', 0);
        },
        isValuePercent() {
            return _.get(this.config, 'isValuePercent', false);
        },
    }
}
