import {ProcessState} from "@app/services/sip-ua/process-state.js";
import lts from "@app/services/lts.js";
import {createStore} from "vuex";
import release from "@app/release/store.js";

let processState = new ProcessState();
if (lts.processState) {
    processState = ProcessState.unserialize(lts.processState);
}

const store = createStore({
    state: () => ({
        errors: {},
        components: [],
        componentIndex: 0,
        someData: {},
        processState: processState,
        token: lts.token,
    }),
    mutations: {
        setErrors(state, errors) {
            state.errors = errors;
        },
        pushComponent(state, options) {
            state.componentIndex++;
            state.components.push({
                options,
                id: state.componentIndex,
            });
        },
        popComponent(state) {
            state.components.pop();
        },
        removeComponent(state, id) {
            state.components = state.components.filter((c) => c.id !== id);
        },
        setSomeData(state, data) {
            const key = data.key;
            if(key) {
                state.someData[key] = data.value;
            }
        },
        processState(state, processState) {
            lts.processState = processState.serialize();
            state.processState = processState;
        },
        clearProcessState(state) {
            state.processState = new ProcessState();
            delete lts.processState;
        },
    },
    actions: {
        setErrors({commit}, errors) {
            commit('setErrors', errors);
        },
        pushComponent({commit}, component) {
            commit('pushComponent', component);
        },
        popComponent({commit}) {
            commit('popComponent');
        },
        removeComponent({commit}, id) {
            commit('removeComponent', id);
        },
        setSomeData({commit}, data) {
            commit('setSomeData', data)
        },
    },
    getters: {
        components(state) {
            return state.components;
        },
        errors(state) {
            return state.errors;
        },
        getSomeData(state) {
            return (key) => state.someData[key];
        },
        getProcessState(state) {
            return state.processState;
        }
    },
    modules: {
        release,
    },
});

export default store;
