<template>
    <div
        class="form-row"
        :class="{required: required}">
        <div class="form-row__header">
            <label
                v-if="label"
            >
                {{ label }}
            </label>
            <slot name="label-addon" />
        </div>
        <div>
            <slot />
        </div>
        <ul
            v-if="errors !== undefined && errors.length !== 0"
            class="errors">
            <li
                v-for="(error, index) in errors"
                :key="index"
                class="error">
                {{ error }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        }
    },
}
</script>
