<template>
    <form>
        <slot />
    </form>
</template>

<script>
export default {
    beforeMount() {
        this.$clearErrors();
    },
    beforeDestroy() {
        this.$clearErrors();
    },
}
</script>