import { mapGetters } from 'vuex';
import { isNil } from '@candybox/helpers.js';

export default {
    computed: mapGetters({
        $user: 'user/user',
        $userName: 'user/userName',
        $userId: 'user/userId',
    }),
    methods: {
        $can(action) {
            return !isNil(this.$user) && this.$user.can(action);
        },
    },
}
