import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { singleton } from '@candybox/helpers.js';
import {validator} from "@candybox/validation/validator";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/questionnaires/status');

    mapping.validation('store', () => {
        return {}
    });

    return mapping;
});
