import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import {Method} from "@candybox/transport/request.js";


export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/category/product');

    mapping.validation('store', () => {
        return {
            name: validator()
                .length(0, 255).required(),
            category_id: validator()
                .integer(),
            type: validator()
                .integer().required(),
        }
    });

    mapping.map('exportProducts', {
        route: '/api/category/export-products',
        method: Method.GET,
    });

    return mapping;
});
