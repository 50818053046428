import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import {get, singleton} from '@candybox/helpers.js';
import constants from "@app/core/constants.js";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/call/process');

    mapping.validation('store', () => {
        return {
            is_first_visit: validator()
                .required(),
            reason: validator()
                .required(),
            is_card_owner: validator()
                .required(),
            call_result_kc: validator()
                .required(),
        }
    });

    return mapping;
});
